import { action, makeObservable, observable } from "mobx"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { FileQueryInput } from "models/file/file"
import { ReminderQueryInput } from "components/shared/reminders/03_sharedModel/reminder"
import { DatePickerObservable } from "library/observables/datePickers"
import { addMinutes } from "date-fns"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"

export class ReminderCardModel {
  enableQuery: boolean
  dueDateTime: DatePickerObservable
  description: TextObservable
  generalErrors: GeneralError
  constructor() {
    makeObservable(this, {
      enableQuery: observable,
      setEnableQuery: action,
      handleApiErrors: action,
      reset: action,
      generalErrors: observable,
    })
    this.enableQuery = false
    this.dueDateTime = new DatePickerObservable(addMinutes(new Date(), 10))
    this.description = new TextObservable()
    this.generalErrors = {}
  }

  setEnableQuery() {
    this.enableQuery = true
  }

  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "dueDateTimeUtc")) this.dueDateTime.errorMessage = v.description
      else if (isSame(v.property, "description")) this.description.errorMessage = v.description
      else this.generalErrors[v.property] = v.description
    })
  }

  reset() {
    this.dueDateTime = new DatePickerObservable(addMinutes(new Date(), 10))
    this.description = new TextObservable()
    this.generalErrors = {}
  }
}
