import React, { useEffect, useState } from "react"
import { ButtonPrimary } from "library/styled/styled"
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu"

interface Props {
  onSubmit: (approveInvoice: boolean, sendEmail: boolean, preview: boolean) => void
  isLoading: boolean
  isAddMode: boolean
  isDraftInvoice?: boolean
}

interface SaveButtonType {
  name: string
  preview: boolean
  approveInvoice: boolean
  sendEmail: boolean
}

const saveAndPreviewButton: SaveButtonType = {
  name: "Save and Preview",
  preview: true,
  approveInvoice: false,
  sendEmail: false
}
const saveAndApproveButton: SaveButtonType = {
  name: "Save and Approve",
  preview: false,
  approveInvoice: true,
  sendEmail: false
}
const approveAndEmailButton: SaveButtonType = {
  name: "Approve and Email",
  preview: false,
  approveInvoice: true,
  sendEmail: true
}

const saveAndEmailButton: SaveButtonType = {
  name: "Save and Email",
  preview: false,
  approveInvoice: false,
  sendEmail: true
}

const addSaveButtons = [saveAndPreviewButton, saveAndApproveButton, approveAndEmailButton]
const editSaveButtonsWhenDraft = [saveAndPreviewButton, saveAndApproveButton, approveAndEmailButton]
const editSaveButtonsWhenNotDraft = [saveAndPreviewButton, saveAndEmailButton]

const AddOrEditInvoiceButtonDropdown = ({ onSubmit, isLoading, isAddMode, isDraftInvoice }: Props) => {
  const [buttons, setButtons] = useState<SaveButtonType[]>([])

  useEffect(() => {
    if (isAddMode) setButtons(addSaveButtons)
    else setButtons(isDraftInvoice ? editSaveButtonsWhenDraft : editSaveButtonsWhenNotDraft)
  }, [isAddMode, isDraftInvoice])

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <ButtonPrimary
        onClick={() => onSubmit(false, false, false)}
        type="button"
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        disabled={isLoading}
      >
        {isAddMode ? "Save as Draft" : "Save"}
        {isLoading && <i className="fas fa-spinner-third fa-spin text-white ml-2 h-4 w-4"></i>}
      </ButtonPrimary>

      <Menu
        menuButton={
          <MenuButton
            className="inline-flex items-center rounded-r-md bg-primary-900 px-2 py-2 text-white"
            style={{ borderLeft: "1px solid white" }}
          >
            {" "}
            <i className="h-5 w-5 far fa-chevron-down" aria-hidden="true" />
          </MenuButton>
        }
        transition
        align="end"
      >
        {buttons.map((item) => (
          <MenuItem
            key={item.name}
            className="font-medium"
            onClick={() => onSubmit(item.approveInvoice, item.sendEmail, item.preview)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default AddOrEditInvoiceButtonDropdown
