import React, { Fragment } from "react"
import { useQueryClient } from "react-query"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import Accordian from "library/styled/accordian"
import { WarningAlert } from "library/styled/alerts"
import TableDynamic from "library/styled/table"
import { successToaster } from "library/styled/toasters"
import { useModalState } from "utils/modalStates"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { TaskStatus } from "../../../Task/04_sharedModel/taskModel"
import { useRemoveTaskStatus, useTaskStatusQuery } from "../../../Task/services/taskService"
import { RemoveTaskStatusInput } from "../../../Task/04_sharedModel/inputModels"
import AddTaskStatus from "./addTaskStatus"
import ViewTaskStatus from "./viewTaskStatus"
import EditTaskStatus from "./editTaskStatus"

function TaskStatusList() {
  const {
    addModal,
    setAddModal,
    viewModal,
    setViewModal,
    editModal,
    setEditModal,
    alert,
    setAlert
  } = useModalState<TaskStatus>()
  const { data: taskStatus } = useTaskStatusQuery()
  const { mutate: removeTaskStatus, isLoading: isDeleting } = useRemoveTaskStatus()
  const queryClient = useQueryClient()

  const handleDelete = (state: TaskStatus) => {
    const input: RemoveTaskStatusInput = {
      taskStatusReference: state.taskStatusReference
    }

    removeTaskStatus(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.taskStatusQuery)
        successToaster("Task Status deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }

  const options = [
    {
      name: "Edit",
      icon: "pen-circle",
      action: (item: TaskStatus) => setEditModal({ isOpen: true, item: item })
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: TaskStatus) => setAlert({ isOpen: true, item: item })
    }
  ]

  return (
    <Fragment>
      <Accordian title="Task Status" action={() => setAddModal(true)} linkText="Add New Status">
        <TableDynamic
          columns={cols}
          data={taskStatus}
          dataKey="stateReference"
          options={options}
          onRowClick={(item) => setViewModal({ isOpen: true, item: item })}
        />
      </Accordian>

      <AddTaskStatus isOpen={addModal} onClose={() => setAddModal(false)} />

      <ViewTaskStatus
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        taskStatus={viewModal.item}
      />

      <EditTaskStatus
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        taskStatus={editModal.item}
      />

      <WarningAlert
        title="Task Status"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />
    </Fragment>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Name",
    key: "name",
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "2",
    name: "Description",
    key: "description",
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "3",
    name: "Display Order",
    key: "displayOrder",
    columnTypeId: ColumnType.Text.Id
  }
]

export default TaskStatusList
