import { makeAutoObservable } from "mobx"

export class SelectObservable<T = any> {
  selectedOption?: T
  errorMessage?: string

  constructor(selectedOption?: T) {
    makeAutoObservable(this)
    this.selectedOption = selectedOption
  }
}

export class MultiSelectObservable<T = any> {
  selectedOptions: T[]
  errorMessage?: string

  constructor(selectedOptions?: T[]) {
    makeAutoObservable(this)
    this.selectedOptions = selectedOptions ?? []
  }
}
