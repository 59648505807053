import { action, makeObservable, observable } from "mobx"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { Reminder } from "components/shared/reminders/03_sharedModel/reminder"
import { DatePickerObservable } from "library/observables/datePickers"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"

export class EditReminderModel {
  dueDateTime: DatePickerObservable
  description: TextObservable
  generalErrors: GeneralError
  constructor() {
    makeObservable(this, {
      handleApiErrors: action,
      reset: action,
      generalErrors: observable,
    })
    this.dueDateTime = new DatePickerObservable()
    this.description = new TextObservable()
    this.generalErrors = {}
  }

  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "dueDateTimeUtc")) this.dueDateTime.errorMessage = v.description
      else if (isSame(v.property, "description")) this.description.errorMessage = v.description
      else this.generalErrors[v.property] = v.description
    })
  }

  reset(reminder?: Reminder) {
    this.dueDateTime.date = new Date(Date.parse(reminder?.dueDateTimeUtc ?? ""))
    this.description.text = reminder?.description

    this.dueDateTime.errorMessage = undefined
    this.description.errorMessage = undefined
    this.generalErrors = {}
  }
}
