import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select from "react-select"
import { dropdownVariant } from "library/styled/dropdown"
import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"

export class SelectOptionObservable {
  reference?: string
  name?: string
  errorMessage?: string

  constructor(reference?: string, name?: string) {
    makeObservable(this, {
      reference: observable,
      name: observable,
      errorMessage: observable
    })

    this.reference = reference
    this.name = name
  }
}

export class SelectOption {
  reference: string
  name: string

  constructor(reference: string, name: string) {
    this.reference = reference
    this.name = name
  }
}

interface DropdownProps {
  name: string
  label?: string
  onChange?: (item: any) => void
  cols?: number
  options?: SelectOption[]
  value?: SelectOptionObservable
  placeholder?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  type?: "filter" | "input"
}

const InputGroupDropdown = ({
                              name,
                              label,
                              onChange,
                              cols = 3,
                              options = [],
                              value,
                              placeholder = "Optional",
                              isInModal = false,
                              isSearchable = true,
                              isClearable = false,
                              isDisabled = false,
                              type = "input"
                            }: DropdownProps) => {

  return (
    <FormGroup cols={cols}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        id={name}
        //defaultValue={{ reference: value?.reference, name: value?.name }}
        getOptionLabel={(option) => option.name ?? ""}
        getOptionValue={(option) => option.reference ?? ""}
        onChange={(e) => {
          runInAction(() => {
            if (value) {
              value.reference = e?.reference
              value.name = e?.name
            }
            if (onChange) {
              onChange(e)
            }
          })
        }}
        placeholder={placeholder}
        styles={type === "filter" ? dropdownVariant.filter : dropdownVariant.input}
        className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
        menuPortalTarget={isInModal ? null : document.body}
        menuPosition={isInModal ? undefined : "fixed"}
        isDisabled={isDisabled}
        value={value?.reference ? value : null}
      ></Select>
      {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(InputGroupDropdown)
