import React, { Dispatch, SetStateAction } from "react"
import { format } from "date-fns"
import { toast, ToastContentProps } from "react-toastify"
import { AxiosResponse } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isProvided } from "utils/extensions"

/* DevOps Configurations*/
export const fosApiUrl = "https://sydprod-auseast-drd0ebeca6crdtgn.z01.azurefd.net/api/"
export const accountAndOnBoardingPortalUrl = "https://account.fleetonstreet.com"
export const identityServerClientId = "client.portal"
export const versionNumber = "(Set by powershell)"
export const fileUploadAPI = "https://fileupload.fleetonstreet.com"
export const msTeamFeedbackUrl = "https://fleetonstreet.webhook.office.com/webhookb2/af5a939b-8766-4e12-b57d-89bf4c452d91@90d2fdd6-f9c5-4f6e-9634-505a0c986ce0/IncomingWebhook/8ac0d50efe454eb8abee67b24f8d0697/bb76675c-5e76-4d41-bdcd-fb46091b458c"
export const msTeamSupportUrl = "https://fleetonstreet.webhook.office.com/webhookb2/af5a939b-8766-4e12-b57d-89bf4c452d91@90d2fdd6-f9c5-4f6e-9634-505a0c986ce0/IncomingWebhook/1f3e98f927e24800a799e978ae1d0cb5/bb76675c-5e76-4d41-bdcd-fb46091b458c"
export const angularJsPortalUrl = "https://portal.fleetonstreet.com/#/"
export const identityServerJsClientId = "js_oidc"
export const identityServerAuthorityUrl = "https://account.fleetonstreet.com"
export const useIdentityServerLogin = "true"
export const applicationInsights = "InstrumentationKey=665de792-6a7c-4f1b-b1ec-2b58abfcd777;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/"

export const redirectKey = "F5A7FBEE-5331-46C9-B7CB-D7CF970A4BF4"

/* Local Configurations
export const versionNumber = "v1"
export const fosApiUrl = "https://syddev-southeast1-gateway-api-zbywuaqwrq-ts.a.run.app/"
export const accountAndOnBoardingPortalUrl = "https://dev-account.fleetonstreet.com"
export const identityServerClientId = "client.portal"
export const fileUploadAPI = "https://dev-file.fleetonstreet.com"
export const msTeamFeedbackUrl =
  "https://outlook.office.com/webhook/3c3297da-40a3-4287-b051-c4cf6ee1f8e8@90d2fdd6-f9c5-4f6e-9634-505a0c986ce0/IncomingWebhook/e17853fc6ec448008fa358d6b35a24d2/bb76675c-5e76-4d41-bdcd-fb46091b458c"
export const msTeamSupportUrl =
  "https://outlook.office.com/webhook/3c3297da-40a3-4287-b051-c4cf6ee1f8e8@90d2fdd6-f9c5-4f6e-9634-505a0c986ce0/IncomingWebhook/e17853fc6ec448008fa358d6b35a24d2/bb76675c-5e76-4d41-bdcd-fb46091b458c"
//export const angularJsPortalUrl = "https://dev-portal.fleetonstreet.com/#/"
export const angularJsPortalUrl = "http://localhost:44301/#/"
export const identityServerJsClientId = "js_oidc"
export const identityServerAuthorityUrl = "https://dev-account.fleetonstreet.com/"
export const applicationInsights =
  "InstrumentationKey=b6fee621-2ff0-405e-bb93-0fbd57d98bc0;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/"
*/

export const common = {
  identityServerStorageKey: "oidc.user:" + identityServerAuthorityUrl + ":" + identityServerJsClientId,
  identityServerAuthorityUrl: identityServerAuthorityUrl,
  versionNumber: "v1",
  fosApiUrl: fosApiUrl,
  authenticationApiUrl: fosApiUrl + "authentication/v1",
  legacyGraphQlUrl: fosApiUrl + "jobs/v1",
  fileApiUrl: fosApiUrl + "files/v1",
  fileUploadAPI: fileUploadAPI + "/graphql",
  customFieldApiUrl: fosApiUrl + "customFields/v1",
  peopleApiUrl: fosApiUrl + "peoples/v1",
  accountingApiUrl: fosApiUrl + "accounting/v1",
  identityApiUrl: fosApiUrl + "identity/v1",
  organisationApiUrl: fosApiUrl + "organisations/v1",
  metadataApiUrl: fosApiUrl + "metadata/v1",
  calendarApiUrl: fosApiUrl + "calendars/v1",
  timesheetApiUrl: fosApiUrl + "timesheets/v1",
  timesheetApiUrl2: fosApiUrl + "timesheets/v2",
  reminderApiUrl: fosApiUrl + "reminders/v1",
  notificationApiUrl: fosApiUrl + "notifications/v1",
  analyticApiUrl: fosApiUrl + "analytic/v1",
  activityApiUrl: fosApiUrl + "activity/v1",
  inquiryApiUrl: fosApiUrl + "inquiry/v1",
  subscriptionApiUrl: fosApiUrl + "subscription/v1",
  jobApiUrl: fosApiUrl + "jobs/v1",
  angularJsPortalUrl: angularJsPortalUrl,

  privacyPolicyUrl: "https://account.fleetonstreet.com/PrivacyPolicy/",
  termsAndCondition: "https://account.fleetonstreet.com/TermsAndConditions/",

  angularJsLinks: {
    home: angularJsPortalUrl + "home",
    jobs: angularJsPortalUrl + "jobs",
    jobScheduler: angularJsPortalUrl + "jobScheduler",
    timeEntries: angularJsPortalUrl + "timesheet",
    timeApprovals: angularJsPortalUrl + "timeApprovals",
    leaves: angularJsPortalUrl + "leaves",
    clients: angularJsPortalUrl + "clients/",
    contractors: angularJsPortalUrl + "contractors/",
    inquiries: angularJsPortalUrl + "inquiries",
    inquiryForms: angularJsPortalUrl + "inquiryForms",
    staff: angularJsPortalUrl + "staff",
    documents: angularJsPortalUrl + "documents",
    reminders: angularJsPortalUrl + "reminders",
    planDetail: angularJsPortalUrl + "planDetail",
    manageAddons: angularJsPortalUrl + "manageAddons",
    helpCenter: angularJsPortalUrl + "helpCenter",
    addJob: angularJsPortalUrl + "jobs/add",
    addClient: angularJsPortalUrl + "clients/add/",
    addStaff: angularJsPortalUrl + "staff/add/",
    profile: angularJsPortalUrl + "staff/details/",
    logout: angularJsPortalUrl + "logout",
    addReminder: angularJsPortalUrl + "reminders/add",
    jobDetails: angularJsPortalUrl + "jobs/details/",
    clientDetails: angularJsPortalUrl + "client/details/",
    staffDetails: angularJsPortalUrl + "staff/details/",
    contractorDetails: angularJsPortalUrl + "contractor/details/",
    inquiryDetails: angularJsPortalUrl + "inquiry/details//",
    leaveDetails: angularJsPortalUrl + "leave/details/",
    editReminder: angularJsPortalUrl + "reminders/edit/"
  },

  websiteVersionNumber: versionNumber,
  localStorageActiveOrganisationKey: "FOS:ActiveOrganisation",
  calendarSettings: "FOS.8A9B1B4C-43EE-4324-BBB2-AA460572B424",
  jobSchedulerFilter: "FOS.8290EF03-0A83-4D68-BE46-C564D27E4F87",
  contractorCalendarSettings: "FOS.3CE2F991-89A5-4953-B901-6A34AAC86CDA",
  contractorSchedulerFilter: "FOS.C5E15CA9-58DF-4887-9A3C-B29633D01DDE",
  timeApprovalDateFilter: "FOS.BD22B071-7503-4BCB-BFFA-4B2AB8919F9E",
  organisationCategoryCacheKey: "FOS.Category",
  metadataAddressSettingsCacheKey: "FOS.AddressSettings",
  metadataGoogleAddressTypeCacheKey: "FOS.GoogleAddressTypes",
  OrganisationDetailCacheKey: "FOS.OrganisationDetails",
  JobStatesCacheKey: "FOS.JobStates",
  ReminderFilter: "FOS.ReminderFilter",
  AppVersionCacheKey: "FOS.AppVersionCacheKey",
  userSessionKey: "B430CEB3-682F-42B2-B320-FBD2AC5913D2",
  SessionUrlKey: "FOS.C07A841C-91B7-436E-9AFF-570012495E50",
  PreviousPathStorageKey: "PreviousPathStorageKey",
  responseAction: {
    noError: "noError",
    popup: "popup",
    inline: "inline"
  }
}

export function toLocaleDateTime(utcDate: any) {
  if (utcDate === undefined || utcDate === null || utcDate.length === 0) return ""
  const date = new Date(Date.parse(utcDate))
  return format(date, "d MMM yyyy h:mm aaa")
}

export function toUtcDateTime(localDate: any) {
  if (localDate === undefined || localDate === null || localDate.length === 0) return ""
  const date = new Date(Date.parse(localDate))
  return date.toISOString().slice(0, -5) + "Z"
}

export function toUtcDate(localDate: any) {
  if (localDate === undefined || localDate === null || localDate.length === 0) return ""
  const date = new Date(Date.parse(localDate))
  return format(date, "yyyy-MM-dd")
}

export function toLocaleDate(utcDate: any) {
  if (utcDate === undefined || utcDate === null || utcDate.length === 0) return ""
  const date = new Date(Date.parse(utcDate))
  return format(date, "d MMM yyyy")
}

export function toLocaleShortDate(utcDate: any) {
  if (utcDate === undefined || utcDate === null || utcDate.length === 0) return ""
  const date = new Date(Date.parse(utcDate))
  return format(date, "EEE, d MMM")
}

export function toCurrency(price: number, currency: any) {
  if (price !== undefined && currency !== undefined)
    return price.toLocaleString("en-UK", {
      style: "currency",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      currency: currency
    })
}

export function toPlanCurrency(price: number, currency: string | undefined) {
  if (price !== undefined && currency !== undefined)
    return price.toLocaleString("en-UK", {
      style: "currency",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency: currency
    })
}

export function nullIfEmpty(value: string | undefined | null) {
  if (value === undefined) return null
  return value
}

export function emptyIfNull(value: string | undefined | null) {
  if (value === undefined || value === null) return ""
  return value
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

// This function returns response object with error details
// The consumer of this method should check if there are any errors using the action property.
// If we have errors then consumer must handle them by showing the summary and messages.
function failureV3(data: AxiosResponse) {
  let response: ErrorResponse = {
    action: common.responseAction.popup,
    code: "",
    summary: "",
    messages: []
  }
  // Check if the data contains an API error.
  let isSuccessful = data.headers["issuccessful"]
  if (isSuccessful === "True") {
    response.action = common.responseAction.noError
    return response
  }

  // Else we have an error!
  let responseMessage = data.data

  response.code = responseMessage.code
  response.summary = responseMessage.summary
  response.messages = responseMessage.messages

  if (response.code === "form_validation") {
    response.action = common.responseAction.inline
  }

  // note permissions error become popup errors because the code is set to 'Permission Errors'

  if (data.status >= 500) {
    response.summary = "Oops error occured, please try again in few minutes. Error Code: " + data.status

    // If status text is not empty then include in the response message.
    if (isProvided(data.statusText)) {
      response.summary += " Details: " + data.statusText
    }
  }

  return response
}

// This function shows all errors except the form_validations errors on the popup form.
// If we have form_validation errors the response object is return to the user to handle
// it and show them on the form.
export function handleFormErrors(data: AxiosResponse) {
  let response: any = failureV3(data)

  // In case there was no error return false.
  if (response.action === common.responseAction.noError) {
    return response
  }

  // If we have form validation errors return them to the caller else show them using popup.
  if (response.action === common.responseAction.inline) {
    response.messages.forEach((item: any) => {
      response[item.property] = item.description
    })
    return response
  }

  // Else show the error messages on popup
  handlePopupError(data)

  return response
}

// This method displays all the error message in a popup.
// If error did occur then true is returned. In which case the consumer should exist the code.
// If no error occurs this will return false.
export function handlePopupError(data: any) {
  let response = failureV3(data)

  // In case there was no error return false.
  if (response.action === common.responseAction.noError) {
    return
  }

  toast.error(Msg, {
    data: response,
    autoClose: false,
    position: "top-center",
    theme: "colored",
    icon: false,
    closeButton: false
  })
}

export function handleServerError(data: any) {
  let response = failureV3(data)

  // In case there is no popup error (server error) return.
  if (response.action !== common.responseAction.popup) {
    return
  }

  toast.error(Msg, {
    data: response,
    autoClose: false,
    position: "top-center",
    theme: "colored",
    icon: false,
    closeButton: false
  })
}

const Msg = ({ closeToast, data }: ToastContentProps<ErrorResponse>) => (
  <div className="flex">
    <div className="flex-shrink-0 pt-1">
      <i className="fas fa-times-circle text-red-400 fa-lg"></i>
    </div>
    <div className="ml-3">
      <h3 className="text-sm leading-5 text-red-700">{data?.summary}</h3>
      <div className="mt-2 leading-5 text-red-700">
        <ul className="list-disc pl-5">
          {data?.messages?.map((item) => (
            <li key={item.description} className="mt-1">
              {item.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className="ml-auto pl-3">
      <div className="-mx-1.5 -my-1.5">
        <button
          onClick={closeToast}
          className="inline-flex rounded-md p-1.5 text-red-800 hover:text-red-800 hover:bg-red-100 focus:outline-none transition ease-in-out duration-150"
          aria-label="Dismiss"
        >
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
)

// This method displays all the error message in a popup.
// If error did occur then true is returned. In which case the consumer should exist the code.
// If no error occurs this will return false.
export function handleQueryError(data: any) {
  var response = failureV3(data)

  // In case there was no error return false.
  if (response.action === common.responseAction.noError) {
    return false
  }

  const Msg = ({ closeToast }: ToastContentProps<{}>) => (
    <div className="flex">
      <div className="flex-shrink-0 pt-1">
        <i className="fas fa-times-circle text-red-400 fa-lg"></i>
      </div>
      <div className="ml-3">
        <h3 className="text-sm leading-5 text-red-700">{response.summary}</h3>
        <div className="mt-2 leading-5 text-red-700">
          <ul className="list-disc pl-5">
            {response.messages?.map((item) => (
              <li key={item.description} className="mt-1">
                {item.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            onClick={closeToast}
            className="inline-flex rounded-md p-1.5 text-red-800 hover:text-red-800 hover:bg-red-100 focus:outline-none transition ease-in-out duration-150"
            aria-label="Dismiss"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )

  toast.error(Msg, {
    autoClose: false,
    position: "top-center",
    theme: "colored",
    icon: false,
    closeButton: false
  })

  return true
}

export function handleMutationError(response: AxiosResponse, setError: Dispatch<SetStateAction<{}>>) {
  const errors = handleFormErrors(response)
  if (errors.action === common.responseAction.popup) return
  if (errors.action === common.responseAction.inline) setError(errors)
}
