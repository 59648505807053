import React from "react"
import { BooleanView, CreatedView, DescriptionView, TextView } from "library/styled/description-list"
import { Modal } from "library/styled/modal"
import { Dl } from "library/styled/styled"
import { Template } from "components/setting/template/05_sharedModel/template"
import TextAreaDynamicView from "library/styled/textAreaDynamic"

interface Props {
  isOpen: boolean
  onClose: () => void
  onEdit: () => void
  template?: Template
}

const ViewTemplateModal = ({ isOpen, onClose, onEdit, template }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Template Details" footer={false} onEdit={onEdit}>
    <Dl>
      <TextView label="Template Type" value={template?.templateTypeName} />
      <TextView label="Name" value={template?.name} />
      <BooleanView label="Disabled" value={template?.isDisabled} />
      <BooleanView label="Default" value={template?.isDefault} />
      {template?.descriptionTemplate && <TextAreaDynamicView label="Description" value={template?.descriptionTemplate} />}
      {template?.smsTemplateMessage && <TextAreaDynamicView label="Message" value={template?.smsTemplateMessage} />}
      {template?.emailTemplateSubject && <TextView cols={6} label="Email Subject" value={template?.emailTemplateSubject} />}
      {template?.emailTemplateBody && <TextAreaDynamicView label="Email Body" value={template?.emailTemplateBody} />}
      <CreatedView label="Created By" createdBy={template?.insertedBy} createdDateTimeUtc={template?.insertedDateTimeUtc} />
      <CreatedView label="Updated By" createdBy={template?.updatedBy} createdDateTimeUtc={template?.updatedDateTimeUtc} />
    </Dl>
  </Modal>
)

export default ViewTemplateModal
