import React from "react"
import { action } from "mobx"
import { observer } from "mobx-react"
import { TextObservable } from "library/observables/inputGroupText"
import { ErrorInline, LineItemTextInput } from "library/styled/styled"
import { getFormattedDisplayValue, isProvided } from "utils/extensions"

interface Props {
  id?: string
  value: TextObservable
  onBlur?: () => void
}

const LineItemText = ({ value, onBlur, id }: Props) => {
  return (
    <>
      <LineItemTextInput
        type={"text"}
        id={id}
        data-testid={id}
        className={"remove-ring-shadow hide-number-input-arrow"}
        value={value.text ?? ""}
        onBlur={action((event) => {
          // If discount amount is entered then format it
          if (!isNaN(Number(value.text ?? ""))) value.text = getFormattedDisplayValue(value.text ?? "", true, 2)
          if (onBlur) onBlur()
        })}
        onChange={action((e) => {
          value.text = e.target.value
          if (isProvided(value.errorMessage)) value.errorMessage = undefined
        })}
      />
      {value.errorMessage && <ErrorInline data-testid={`${id}-error-label`}>{value.errorMessage}</ErrorInline>}
    </>
  )
}

export default observer(LineItemText)
