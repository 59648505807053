import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { action, makeObservable } from "mobx"
import { InvoiceStatusObservable } from "components/invoices/06_sharedComponent/invoiceStatusSelection"
import { Invoice, Invoices } from "components/invoices/05_sharedModel/invoiceModel"

export class InvoiceOverviewModel {
  openSendEmailModal: BooleanObservable
  invoiceStatus: InvoiceStatusObservable

  constructor() {
    makeObservable(this, {
      setOpenSendEmailModel: action,
      setInvoiceStatus: action,
    })
    this.openSendEmailModal = new BooleanObservable()
    this.invoiceStatus = new InvoiceStatusObservable()
  }

  setOpenSendEmailModel(value: boolean) {
    this.openSendEmailModal.value = value
  }

  setInvoiceStatus(invoice: Invoice) {
    this.invoiceStatus.invoiceStatusReference = invoice.invoiceStatusReference
    this.invoiceStatus.name = invoice.invoiceStatusName
  }
}
