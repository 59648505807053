import React from "react"
import { ErrorInline, FormGroup, Label, TextInput } from "../styled/styled"
import { action, makeObservable, observable } from "mobx"
import { observer } from "mobx-react"
import { requiredValidation, maxLengthValidation, emailAddressValidation, phoneNumberValidation } from "../../utils/validation"
import ToolTip from "../styled/tooltip"

export class TextObservable {
  public text?: string
  public errorMessage?: string

  constructor(text?: string) {
    makeObservable(this, {
      text: observable,
      errorMessage: observable,
    })

    this.text = text
  }
}

interface Props {
  id: string
  error?: any
  placeholder?: string
  label?: string
  value: TextObservable
  errorKey?: string
  addMargin?: boolean
  cols?: number
  toolTip?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void

  isRequired?: boolean
  isEmail?: boolean
  isPhoneNumber?: boolean
  maxLength?: number
}

const InputGroupText = ({
  id,
  label,
  value,
  addMargin = true,
  cols = 3,
  toolTip,
  onFocus,
  onBlur,
  onChange,
  isRequired,
  isEmail,
  isPhoneNumber,
  maxLength,
}: Props) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value.errorMessage) {
      return
    }

    value.errorMessage = requiredValidation(value.text, label, isRequired)
    if (!value.errorMessage) value.errorMessage = maxLengthValidation(value.text, label, maxLength)
    if (!value.errorMessage) value.errorMessage = emailAddressValidation(value.text, label, isEmail)
    if (!value.errorMessage) value.errorMessage = phoneNumberValidation(value.text, isPhoneNumber)
  }

  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>
        {label}

        {toolTip && (
          <ToolTip text={toolTip} id={"Id"}>
            <i className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}
      </Label>

      <TextInput
        type="text"
        id={id}
        placeholder={isRequired ? "Required" : "Optional"}
        value={value.text ?? ""}
        onChange={action((e) => {
          value.text = e.target.value
          validate(true)
          if (onChange) onChange(e)
        })}
        addMargin={addMargin}
        onBlur={(event) => {
          validate()
          if (onBlur) onBlur(event)
        }}
        onFocus={onFocus}
      />
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(InputGroupText)
