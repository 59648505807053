import React, { KeyboardEvent, useEffect, useState } from "react"
import CreatableSelect from "react-select/creatable"
import { dropdownVariant } from "library/styled/dropdown"

interface Props {
  name: string
  onChange: (value: Array<any>) => void
  defaultValue?: Array<any>
}

const components = {
  DropdownIndicator: null,
}

const createOption = (label: string) => ({
  label,
  value: label + Math.random().toString(),
})

const TagsInput = ({ name, onChange, defaultValue = [] }: Props) => {
  const [value, setValue] = useState(defaultValue)
  const [inputValue, setInputValue] = useState("")

  const handleChange = (value: any) => {
    setValue(value)
  }

  useEffect(() => {
    onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("")
        setValue([...value, createOption(inputValue)])
        event.preventDefault()
        break
      default:
    }
  }
  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      name={name}
      id={name}
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value}
      styles={dropdownVariant.input}
    />
  )
}

export default TagsInput
