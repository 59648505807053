import { Switch } from "@headlessui/react"
import React, { Fragment } from "react"
import ToolTip from "library/styled/tooltip"
import Conditional from "library/styled/conditional"

interface Props {
  enabled: boolean
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
  tooltip?: string
}

const ToggleSwitch = ({ enabled, setEnabled, tooltip }: Props) => {
  return (
    <>
      <Fragment>
        <Conditional when={tooltip !== undefined && tooltip.length > 0}>
          <ToolTip text={tooltip} id="toggle">
            <Switch
              name="toggle"
              as="button"
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? "bg-secondary-100" : "bg-gray-200"}
                          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${enabled ? "translate-x-[20px]" : "translate-x-0"}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </ToolTip>
        </Conditional>

        <Conditional when={tooltip === undefined || tooltip?.length === 0}>
          <Switch
            name="toggle"
            as="button"
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? "bg-secondary-100" : "bg-gray-200"}
          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${enabled ? "translate-x-[20px]" : "translate-x-0"}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </Conditional>
      </Fragment>
    </>
  )
}

export default ToggleSwitch
