import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { makeAutoObservable, runInAction } from "mobx"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { ClientQueryInput, Clients } from "models/people/client"
import Select, { OptionProps } from "react-select"
import { useSearchClient } from "hooks/people/client"
import { debounce } from "lodash"
import ToolTip from "../styled/tooltip"
import { filterStyle, inputStyle } from "../styled/dropdown"
import { ThreeDots } from "react-loader-spinner"
import { clientStates } from "constants/people"

export class ClientMultiSelectionObservable {
  clientList: ClientKeyValue[]
  errorMessage?: string

  constructor(clients?: ClientKeyValue[]) {
    makeAutoObservable(this)
    this.clientList = clients ?? []
  }
}

export class ClientKeyValue {
  clientReference?: string
  clientName?: string
  emailAddress?: string

  constructor(clientReference?: string, name?: string, emailAddress?: string) {
    this.clientReference = clientReference
    this.clientName = name
    this.emailAddress = emailAddress
  }
}

interface Props {
  value: ClientMultiSelectionObservable
  tooltip?: string
  label?: string
  name: string
  isClearable?: boolean
  type?: "filter" | "input"
  isInModal?: boolean
  cols?: number
}

const ClientSelectionMultiComponent = ({
  value,
  tooltip,
  label = "Clients",
  name,
  isClearable = true,
  type = "input",
  isInModal = false,
  cols = 3,
}: Props) => {
  const [input, setInput] = useState<ClientQueryInput>({
    first: 100,
    searchTerm: "",
    clientStateReferences: [clientStates.active.clientStateReference],
  })
  const { data, isLoading } = useSearchClient(input)

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput({ ...input, searchTerm: searchQuery.trim() })
    }, 1000)
  ).current

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  return (
    <>
      <FormGroup cols={cols}>
        <Label htmlFor={label}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          name={name}
          id={name}
          isMulti={true}
          options={data}
          value={value.clientList}
          getOptionLabel={(option) => option.clientName ?? ""}
          getOptionValue={(option) => option.clientReference ?? ""}
          placeholder="Search by client's name, phone or email"
          isClearable={true}
          isLoading={isLoading}
          onChange={(e) => {
            runInAction(() => {
              value.clientList = [...e]
            })
          }}
          onInputChange={handleInput}
          styles={type === "filter" ? filterStyle : inputStyle}
          menuPosition={isInModal ? "fixed" : undefined}
          components={{ LoadingIndicator, Option: CustomOption }}
          noOptionsMessage={() => <h2>No Client Found</h2>}
          filterOption={() => true}
          menuPlacement={"bottom"}
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      radius="3"
      color="rgb(180, 183, 170,1)"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ marginRight: "6px" }}
      visible={true}
    />
  )
}

const CustomOption = (props: OptionProps<ClientKeyValue>) => {
  const { innerProps, innerRef, getStyles } = props
  return (
    <div ref={innerRef} {...innerProps} style={getStyles("option", props) as CSSProperties} className="overflow-hidden">
      <div className="mr-2">{props.data.clientName}</div>
      <span className="text-xs">{props.data.emailAddress}</span>
    </div>
  )
}

export default observer(ClientSelectionMultiComponent)
