import React, { useEffect, useState } from "react"
import { useMetadata } from "context/metadata-context"
import { useImages } from "../fileService"
import { entityTypes } from "constants/entityTypes"
import UploadImageModal from "./uploadImageModal"
import UploadImageModel from "./uploadImageModel"
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import CompanyPlaceholderLogo from "assets/CompanyPlaceholderLogo.png"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"

const OrganisationLogoImage = () => {
  const { organisation } = useMetadata()
  const [uploadModal, setUploadModal] = useState({ isOpen: false })
  const [uploadImageModel] = useState(
    () =>
      new UploadImageModel(
        entityTypes.organisation.entityTypeReference,
        organisation?.organisationReference,
        CompanyPlaceholderLogo
      )
  )
  const { data } = useImages({
    entityTypeReference: entityTypes.organisation.entityTypeReference,
    entityReference: organisation?.organisationReference,
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data && data.images.length > 0) {
      runInAction(() => {
        const image = data?.images[0]
        uploadImageModel.fileContentBase64 = image?.imageContent
        uploadImageModel.fileExtension = image?.extension
        uploadImageModel.fileName = image?.imageName
      })
    }
    // eslint-disable-next-line
  }, [data])

  const handleFile = () => {
    setUploadModal({ isOpen: true })
  }

  const onUploadSuccess = () => {
    void queryClient.invalidateQueries([
      keys.imageQuery,
      { entityTypeReference: entityTypes.organisation.entityTypeReference, entityReference: organisation?.organisationReference },
    ])
  }

  return (
    <>
      <UploadImageModal
        modalTitle={"Organisation Logo"}
        isOpen={uploadModal.isOpen}
        onUploadSuccess={onUploadSuccess}
        onClose={() => setUploadModal({ ...uploadModal, isOpen: false })}
        entityReference={uploadImageModel.entityReference ?? ""}
        entityTypeReference={uploadImageModel.entityTypeReference ?? ""}
        isUploadingLogo={true}
      />

      <div className={"col-span-3"}>
        <div className={"grid grid-cols-2"}>
          <div className={"col-span-1"}>
            <span className="relative inline-block cursor-pointer" onClick={() => handleFile()}>
              <img className="h-40 w-44 rounded-md" src={uploadImageModel.fileContentBase64} alt="Company Logo" />
              <i className="absolute -right-4 -bottom-4 block text-sm far fa-pen bg-blue-100 text-blue-600 shadow-md rounded-full py-1 px-1.5 focus:outline-none"></i>
            </span>
          </div>
          <div className="col-span-1"></div>
        </div>
      </div>
    </>
  )
}

export default observer(OrganisationLogoImage)
