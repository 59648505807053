import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { AddOrUpdateTaxIdentificationInput, TaxIdentifications } from "models/finance/tax"

function useTaxIdentification() {
  return useQuery(keys.taxIdentificationQuery, fetchTaxIdentifications, {
    select: (data: Response<TaxIdentifications>) => data.data.data.taxIdentification,
  })
}

function fetchTaxIdentifications() {
  return graphQLClient.fetch(query.taxIdentificationQuery, null, true, common.accountingApiUrl, keys.taxIdentificationQuery)
}

function updateTaxIdentification(input: AddOrUpdateTaxIdentificationInput) {
  return graphQLClient.mutation(
    mutation.addOrUpdateTaxIdentification,
    input,
    "AddOrUpdateTaxIdentification",
    common.accountingApiUrl
  )
}

function useUpdateTaxIdentification() {
  return useMutation(updateTaxIdentification)
}

export { useTaxIdentification, useUpdateTaxIdentification }
