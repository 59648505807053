import { DataGridColumn } from "models/shared/column"
import { Item } from "../04_itemSharedModel/itemModel"
import { ColumnType } from "constants/columns"

export const itemColumns: DataGridColumn<Item>[] = [
  {
    id: "1",
    name: "Item Name",
    isEnabled: true,
    keys: ["name"],
    order: 1,
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "2",
    name: "Item Code",
    isEnabled: true,
    keys: ["itemCode"],
    order: 2,
    columnTypeId: ColumnType.LabelId.Id,
  },
  {
    id: "3",
    name: "Description",
    isEnabled: true,
    keys: ["description"],
    order: 3,
    columnTypeId: ColumnType.Description.Id,
  },
  {
    id: "4",
    name: "Item Type",
    isEnabled: true,
    keys: ["itemType", "name"],
    order: 4,
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "5",
    name: "Sale Price",
    isEnabled: true,
    keys: ["sale", "unitPrice"],
    order: 5,
    columnTypeId: ColumnType.CurrencyFormat.Id,
  },
  {
    id: "6",
    name: "Sale Tax",
    isEnabled: true,
    keys: ["sale", "taxName"],
    order: 6,
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "7",
    name: "Inserted By",
    isEnabled: true,
    keys: ["insertedBy"],
    order: 7,
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "8",
    name: "Created On",
    isEnabled: true,
    keys: ["insertedDateTimeUtc"],
    order: 8,
    columnTypeId: ColumnType.Date.Id,
  },
]
