import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import { AddCategoryInput, Categories, RemoveCategoryInput, UpdateCategoryInput } from "models/organisation/category"

function useCategory() {
  return useQuery(keys.categoryQuery, fetchCategories, {
    select: (data: Response<Categories>) => data.data.data.categories.edges.map((item) => item.node)
  })
}

function useFilteredCategory(entityTypeReference: string) {
  return useQuery(keys.categoryQuery, fetchCategories, {
    select: (data: Response<Categories>) => data.data.data.categories
      .edges.filter(value => value.node.entityType.entityTypeReference === entityTypeReference)
      .map((item) => item.node)
  })
}

function fetchCategories() {
  const input = {
    first: 100
  }
  return graphQLClient.fetch(query.categoryQuery, input, true, common.organisationApiUrl, keys.categoryQuery)
}

function createCategory(input: AddCategoryInput) {
  return graphQLClient.mutation(mutation.createCategory, input, "CreateCategory", common.organisationApiUrl)
}

function useCreateCategory() {
  return useMutation(createCategory)
}

function updateCategory(input: UpdateCategoryInput) {
  return graphQLClient.mutation(mutation.updateCategory, input, "UpdateCategory", common.organisationApiUrl)
}

function useUpdateCategory() {
  return useMutation(updateCategory)
}

function deleteCategory(input: RemoveCategoryInput) {
  return graphQLClient.mutation(mutation.deleteCategory, input, "DeleteCategory", common.organisationApiUrl)
}

function useDeleteCategory() {
  return useMutation(deleteCategory)
}

export { useCategory, useCreateCategory, useUpdateCategory, useDeleteCategory, useFilteredCategory }
