export type emailConfiguration = {
  emailConfigReference: string
  name: string
  primaryAttributeName: string
}

export const invoiceEmailConfig: emailConfiguration = {
  emailConfigReference: "AD345121-7980-4602-93C7-1F1468D506C7",
  name: "invoice",
  primaryAttributeName: "ir",
}

export const invoicePaymentEmailConfig: emailConfiguration = {
  emailConfigReference: "F0EFB757-45B9-499D-9A6B-3D4F700A3149",
  name: "Payment Receipt",
  primaryAttributeName: "pr",
}
