import React, { useEffect, useRef } from "react"
import { action } from "mobx"
import { observer } from "mobx-react"
import { TextObservable } from "../../../library/observables/inputGroupText"
import { ErrorInline, LineItemTextAreaInput } from "../../../library/styled/styled"
import { isEmpty, isProvided } from "../../../utils/extensions"

interface Props {
  id: string
  value: TextObservable
  onBlur?: () => void
}

// 1 - When text is
const LineItemTextArea = ({ id, value, onBlur }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {    
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current!.style.height = "0px"
      const scrollHeight = textAreaRef.current!.scrollHeight

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current!.style.height = scrollHeight + "px"
    }
  }, [textAreaRef, value.text])

  return (
    <>
      <LineItemTextAreaInput
        ref={textAreaRef}
        id={id}
        data-testid={id}
        className={"scrollbar-hide remove-ring-shadow"}
        defaultValue={value.text}
        onBlur={action((event) => {
          if (onBlur) onBlur()
        })}
        onChange={action((e) => {
          value.text = isEmpty(e.target.value) ? "" : e.target.value
          if (isProvided(value.errorMessage)) value.errorMessage = undefined
        })}
      />
        {value.errorMessage && <ErrorInline data-testid={`${id}-error-label`}>{value.errorMessage}</ErrorInline>}
    </>
  )
}

export default observer(LineItemTextArea)
