import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleMutationError } from "common"
import { keys } from "constants/keys"
import { useCreateCategory } from "hooks/organisation/category"
import { InputGroupButton, InputGroupText, InputGroupTextarea } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { AddCategoryInput } from "models/organisation/category"

interface Props {
  isOpen: boolean
  onClose: () => void
  entityTypeReference: string
}

const AddCategoryModal = ({ isOpen, onClose, entityTypeReference }: Props) => {
  const { mutate: createCategory, isLoading } = useCreateCategory()
  const [error, setError] = useState({})
  const queryClient = useQueryClient()

  useEffect(() => {
    setError({})
  }, [isOpen])

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()
    const { name, description } = event.currentTarget.elements
    const input: AddCategoryInput = {
      name: name.value,
      entityTypeReference: entityTypeReference,
      description: description.value,
    }

    createCategory(input, {
      onError: (error: any) => handleMutationError(error.response, setError),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.categoryQuery)
        successToaster("Category created")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal title="Add Category" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <InputGroupText label="Name" name="name" placeholder="Required" error={error} />

          <InputGroupTextarea label="Description" name="description" error={error} />

          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}

export default AddCategoryModal
