import React from "react"
import ContentLoader from "react-content-loader"

interface ListLoaderProps {
  isLoading: boolean
  width?: number
  height: number
  columnWidths: number[]
}

const ListLoader: React.FC<ListLoaderProps> = ({ isLoading, width, height, columnWidths }) => {
  if (width === undefined || width <= 0) return <></>
  const columns = columnWidths.length
  const rows = Math.floor(height / 30) // assuming each row has a height of 30px
  const gapWidth = 30 // set the gap width to 30px

  if (isLoading) {
    // Calculate the total percentage of column widths
    const totalWidth = columnWidths.reduce((acc, width) => acc + width, 0)

    return (
      <div className="list px-6">
        <ContentLoader
          speed={2}
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          backgroundColor="#E2E8F0"
          foregroundColor="#F1F5F9"
        >
          {[...Array(rows)].map((_, rowIndex) => (
            <React.Fragment key={`row-${rowIndex}`}>
              {columnWidths.map((columnWidth, colIndex) => {
                const totalGapWidth = gapWidth * (columns - 1)
                const previousColumnWidths = columnWidths.slice(0, colIndex)
                const previousWidth = previousColumnWidths.reduce((acc, width) => acc + width, 0)
                const x = colIndex === 0 ? 0 : (previousWidth / totalWidth) * (width - totalGapWidth) + gapWidth * colIndex
                const widthPercent = (columnWidth / totalWidth) * (width - totalGapWidth)

                return (
                  <rect
                    key={`col-${colIndex}`}
                    x={x}
                    y={20 + rowIndex * 40}
                    rx={5}
                    ry={5}
                    width={widthPercent}
                    height={24} // set the height to 24
                  />
                )
              })}
            </React.Fragment>
          ))}
        </ContentLoader>
      </div>
    )
  }
  return <></>
}

export default ListLoader
