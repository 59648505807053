import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { DndModal } from "library/styled/dndModal"
import { InputGroupButton } from "library/styled/input-group"
import { Column } from "models/shared/column"
import { ResourceInput, SchedulerResource, UpdateResourceOrderInput } from "components/scheduler/05_models/models"
import _ from "lodash"
import { useReorderResource } from "components/scheduler/06_services/schedulerService"
import { handlePopupError } from "common"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"

interface Props {
  isOpen: boolean
  onClose: () => void
  resources?: SchedulerResource[]
  onChange: (cols: Column[]) => void
}

const ReorderResourceModal = ({ isOpen, onClose, resources, onChange }: Props) => {
  const [temp, setTemp] = useState<SchedulerResource[]>()
  const { mutate: reorderResources, isLoading } = useReorderResource()
  const queryClient = useQueryClient()

  useEffect(() => {
    setTemp(resources)
  }, [resources])

  // a little function to help us with reordering the result
  const reorder = (list: SchedulerResource[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(temp ?? [], result.source.index, result.destination.index)
    setTemp(items)
  }

  const handleSubmit = () => {
    const resourceInput = temp?.map((m, idx) => {
      return {
        resourceReference: m.resourceReference,
        displayOrder: idx + 1,
        isStaffMember: m.isStaffMember,
        isContractor: m.isContractor,
      }
    })
    const input: UpdateResourceOrderInput = {
      resources: resourceInput ?? [],
    }

    reorderResources(input, {
      onError: (error: any) => {
        handlePopupError(error.response)
      },
      onSuccess: () => {
        successToaster("Staff/Contractor List Reordered")
        void queryClient.invalidateQueries(keys.schedulerResourceQuery)
      },
      onSettled: () => onClose(),
    })
  }

  return (
    <>
      <DndModal
        title="Reorder Staff/Contractor"
        isOpen={isOpen}
        onClose={onClose}
        footer={true}
        action={handleSubmit}
        isLoading={isLoading}
      >
        <div className="bg-white shadow overflow-y-auto max-h-[calc(100vh-200px)] sm:rounded-md">
          <form>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="vertical-list">
                {(provided) => (
                  <ul
                    role="presentation"
                    className="divide-y divide-gray-200"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {temp?.map((resource, index) => (
                      <Draggable draggableId={resource.resourceReference} index={index} key={resource.resourceReference}>
                        {(provided, snapshot) => (
                          <li
                            key={resource.resourceReference}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="bg-white"
                            style={{
                              border: snapshot.isDragging ? "1px solid rgb(29 81 166)" : "",
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div className="block hover:bg-gray-50 hover:cursor-move">
                              <div className="flex items-center p-2">
                                <div className="min-w-0 flex-1 flex items-center">
                                  <div className="min-w-0 flex-1 px-4">
                                    <div>
                                      <div className="text-sm text-gray-700">{resource.fullName}</div>
                                    </div>
                                  </div>
                                  <div>
                                    {" "}
                                    <i className="flex-shrink-0 mr-5 text-gray-400 fas fa-grip-lines" aria-hidden="true" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </form>
        </div>
      </DndModal>
    </>
  )
}

export default ReorderResourceModal
