import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import ToolTip from "library/styled/tooltip"
import Select, { MenuListProps, OptionProps, components } from "react-select"
import { useSearchClient } from "hooks/people/client"
import { debounce } from "lodash"
import { ThreeDots } from "react-loader-spinner"
import { ClientSelectionObservable } from "../clientSelection/clientSelection"
import { ClientKeyValue } from "./clientSelectionMultiComponent"
import { requiredValidation } from "utils/validation"
import { ClientQueryInput, UpdateClientInput } from "models/people/client"
import { clientStates } from "constants/people"
import AddClientPage from "components/client/addClient/01_addClientPage/addClientPage"

interface Props {
  value: ClientSelectionObservable
  tooltip?: string
  label?: string
  name: string
  isClearable?: boolean
  type?: "filter" | "input"
  isInModal?: boolean
  placeholder?: string
  isRequired?: boolean
  onChange?: () => void
  cols?: number,
  showAddClientLink?: boolean
  isDisabled?: boolean
}

const ClientSelectionComponent = ({
                                    value,
                                    tooltip,
                                    label = "Client",
                                    name,
                                    isClearable = true,
                                    type = "input",
                                    isInModal = false,
                                    placeholder = "Search by client's name, phone or email",
                                    isRequired = false,
                                    onChange,
                                    cols = 3,
                                    showAddClientLink = false,
                                    isDisabled = false
                                  }: Props) => {
  const [showAddClient, setShowAddClient] = useState(false)
  const [input, setInput] = useState<ClientQueryInput>({
    first: 100,
    searchTerm: "",
    clientStateReferences: [clientStates.active.clientStateReference]
  })
  const { data, isLoading, refetch } = useSearchClient(input)

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput({ ...input, searchTerm: searchQuery.trim() })
    }, 1000)
  ).current

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  useEffect(() => {
    refetch()
  }, [])

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value?.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.clientReference, label, isRequired)
    })
  }

  function onCreateNewClient() {
    setShowAddClient(true)
  }

  //Called when a new client is successfully created.
  const onClientCreated = (client: UpdateClientInput) => {
    let clientFullName = `${client.primaryContact?.givenName} ${client.primaryContact?.familyName ?? ""}`
    runInAction(() => {
      value.clientName = clientFullName
      value.clientReference = client.clientReference
      value.emailAddress = client.primaryContact?.emailAddress
      value.errorMessage = undefined
    })
    if (onChange) {
      onChange()
    }
  }

  const MenuList = (props: MenuListProps<any, false, any>) => {
    return (
      <components.MenuList {...props} className="!pb-0">
        {props.children}
        {showAddClientLink &&
          <div onClick={onCreateNewClient} className={"pl-3 pt-2 pb-2 sticky bottom-0 bg-white"}>
            {" "}
            <i className="fa-regular fa-plus text-primary-800"></i>{" "}
            <span className={"hover:underline active:text-blue-400 hover:cursor-pointer text-primary-800"}>Create New Client</span>
          </div>}
      </components.MenuList>
    )
  }

  return (
    <>
      <AddClientPage
        isOpen={showAddClient}
        onClientCreated={onClientCreated}
        onClose={() => {
          refetch()
          setShowAddClient(false)
        }}
      />
      <FormGroup cols={cols}>
        <Label htmlFor={label}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          name={name}
          id={name}
          inputId={name}
          classNamePrefix={name}
          isMulti={false}
          isDisabled={isDisabled}
          options={data}
          value={value?.clientReference ? value : null}
          getOptionLabel={(option: ClientKeyValue) => option.clientName ?? ""}
          getOptionValue={(option: ClientKeyValue) => option.clientReference ?? ""}
          placeholder={placeholder}
          isClearable={true}
          isLoading={isLoading}
          onChange={(e) => {
            runInAction(() => {
              value.clientName = e?.clientName
              value.clientReference = e?.clientReference
              value.emailAddress = e?.emailAddress
              validate(true)
              if (onChange) onChange()
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          onInputChange={handleInput}
          styles={type === "filter" ? filterStyle : inputStyle}
          className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
          menuPosition={isInModal ? "fixed" : undefined}
          components={{ LoadingIndicator, Option: CustomOption, MenuList }}
          noOptionsMessage={() => <h2>No Client Found</h2>}
          filterOption={() => true}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      radius="3"
      color="rgb(180, 183, 170,1)"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ marginRight: "6px" }}
      visible={true}
    />
  )
}

const CustomOption = (props: OptionProps<ClientKeyValue>) => {
  const { innerProps, innerRef, getStyles } = props
  return (
    <div ref={innerRef} {...innerProps} style={getStyles("option", props) as CSSProperties} className="overflow-hidden">
      <div className="mr-2">{props.data.clientName}</div>
      <span className="text-xs">{props.data.emailAddress}</span>
    </div>
  )
}

export default observer(ClientSelectionComponent)
