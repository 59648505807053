export const query = {
  meQuery: `query MeQuery {
      me {
        userReference
        emailAddress
        givenName
        familyName
        aboutMe
        timezone
        isTemporaryPassword
        isEmailConfirmed
        lockExpiryUtc
        lastLoginDateTimeUtc
        updatedDateTimeUtc
        updatedBy
        insertedDateTimeUtc
        insertedBy
      }
    }`,
  organisationQuery: `query GetOrganisation {
        organisation {
           organisationId
           organisationReference
           name
           industryReference,
           referralCode
           organisationState {
            organisationStateReference
            name
           }
           isPartner
           partnerReferralCode
           updatedDateTimeUtc
           updatedBy
           insertedDateTimeUtc
           insertedBy
        }
      }`,
  userOrganisationQuery: `query UserOrganisationQuery($first: Int, $after: String) {
          userOrganisations(first: $first, after: $after) {
            edges {
              node {
                organisationId
                userOrganisationId
                organisationReference
                name
                emailAddress
                industryReference
                isOwner
                organisationState {
                  organisationStateReference
                  name
                }
                insertedDateTimeUtc
                insertedBy
              }
            }
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        }`,
  notificationHistoryQuery: `query NotificationHistoryQuery($first: Int, $after: String, $entityTypeReferences: [String], $startDateTimeUtc: String) {
          notificationHistory(first: $first, after: $after, entityTypeReferences: $entityTypeReferences, startDateTimeUtc: $startDateTimeUtc) {
            edges {
              node {
                notificationHistoryReference
                correlationId
                notificationReference
                notificationName
                entityTypeReference
                entityTypeName
                entityReference
                isRead
                title
                message
                insertedBy
                insertedDateTimeUtc
              }
            }
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        }`,
  userDetailQuery: `query UserDetailQuery($businessEntityReference: String) {
            userDetails(businessEntityReference: $businessEntityReference) {
              userReference
              givenName
              familyName
              isEmailConfirmed
              emailAddress
              isUserLocked
              staffDetail {
                staffReference
                name
                profileImageUrl
                insertedDateTimeUtc
                insertedBy
              }
            }
          }`,
  enabledFeatureQuery: `query EnabledFeatureQuery {
          enabledFeatures {
            additionalFields
            reminder
            reminder_Jobs
            reminder_StaffMembers
            reminder_Contractors
            client
            client_ClientManager
            client_Documents
            client_AdditionalFields
            client_PhoneNumbers
            client_Address
            client_MyReminders
            client_Categories
            client_Contacts
            client_Jobs
            client_Notes
            calendar
            leave
            organisation
            timesheet
            timesheet_StaffMembers
            timesheet_Jobs
            timesheet_Clients
            timesheet_Contractors
            notification
            contractor
            contractor_PhoneNumbers
            contractor_Notes
            contractor_MyReminders
            contractor_Address
            contractor_Jobs
            contractor_Documents
            contractor_Categories
            contractor_AdditionalFields
            contractor_Contacts
            activity
            activity_Clients
            activity_StaffMembers
            activity_Jobs
            activity_Contractors
            document
            document_Jobs
            document_Clients
            document_Contractors
            document_StaffMembers
            staff
            staff_Notes
            staff_AdditionalFields
            staff_Address
            staff_Jobs
            staff_MyReminders
            staff_PhoneNumbers
            staff_Documents
            staff_Contacts
            staff_Categories
            identity
            job
            job_AdditionalFields
            job_MyReminders
            job_StaffMembers
            job_Documents
            job_Schedule
            job_Client
            job_Categories
            job_SendReminderBeforeJobStart1
            job_SendReminderBeforeJobStart2
            job_Contractor
            job_Address
            job_Notes
            people
            leave_Documents
            inquiry
            inquiry_Documents
            inquiry_MyReminders
            inquiry_Notes
            inquiry_AdditionalFields
            inquiry_Client
            inquiry_Jobs
          }
        }`,
  enabledPermissionQuery: `query EnabledPermissionQuery {
          enabledPermissions {
            analytic_Read
            activity_Read
            calendar_Event_Update
            calendar_Staff_Read
            calendar_Event_Remove
            calendar_All
            client_Setting 
            client_Archive 
            client_Add
            client_Read
            client_All
            client_Update
            contractor_Add
            contractor_Setting
            contractor_Archive
            contractor_Read
            contractor_Update 
            contractor_All
            document_Settings
            job_Add
            job_Staff_Scheduler
            job_Update
            job_Read
            job_Staff_Read
            job_Contractor_Scheduler
            job_All
            job_Setting
            leave_Approve
            leave_Remove
            leave_Read
            leave_Add
            leave_All
            leave_Update
            leave_Staff_Read
            leave_Staff_Add
            leave_Staff_Update
            leave_Staff_Remove
            leave_Setting
            notification_Remove
            notification_Add
            notification_All
            organisation_Billing
            organisation_All
            organisation_Setting
            organisation_Update
            role_Read
            role_Add
            role_Remove
            role_Update
            role_All
            staff_All
            staff_Setting
            staff_Read
            staff_Archive
            staff_Add
            staff_Update
            timesheet_Update
            timesheet_All
            timesheet_Staff_Update
            timesheet_Add
            timesheet_Read
            timesheet_Remove
            timesheet_Staff_Remove
            timesheet_Setting
            timesheet_Approve
            timesheet_Staff_Read
            timesheet_Staff_Add
            inquiry_All
            inquiry_Read
            inquiry_Add
            inquiry_Update
            inquiry_Settings
            inquiry_Form_Add
            inquiry_Form_Update
            inquiry_Form_Read
            inquiry_Form_Remove
            inquiry_Remove
          }
        }`,
  jobQuery: `query JobQuery(
    $first: Int
    $after: String
    $sortReference: String
    $unAssigned: String
    $address: String
    $jobReference: String
    $insertedByStaffReference: String
    $jobNumber: String
    $startDateTimeUtc: String
    $endDateTimeUtc: String
    $stateReferences: [String]
    $clientReferences: [String]
    $staffReferences: [String]
    $organisationCategoryReferences: [String]
    $contractorReferences: [String]
    $includeJobsWithoutClients: String
  ) {
    jobs(
      first: $first
      after: $after
      sortReference: $sortReference
      unAssigned: $unAssigned
      address: $address
      jobReference: $jobReference
      jobNumber: $jobNumber
      insertedByStaffReference: $insertedByStaffReference
      startDateTimeUtc: $startDateTimeUtc
      endDateTimeUtc: $endDateTimeUtc
      stateReferences: $stateReferences
      clientReferences: $clientReferences
      staffReferences: $staffReferences
      organisationCategoryReferences: $organisationCategoryReferences
      contractorReferences: $contractorReferences
      includeJobsWithoutClients: $includeJobsWithoutClients
    ) {
      edges {
        node {
          jobReference
          jobNumber
          jobStateReference
          jobStateName
          description
          clientReference
          clientName
          clientPrimaryPhoneNumber
          schedule {
            startDateTimeUtc
            endDateTimeUtc
            timezone
          }
          address {
            route
            regionCode
            regionName
            postalCode
            city
            countryCode
            countryName
            latitude
            longitude
          }
          staffs {
            staffReference
            name
            profileImageUrl
            insertedBy
            insertedDateTimeUtc
          }
          categories {
            jobCategoryReference
            organisationCategoryReference
            insertedBy
            insertedDateTimeUtc
          }
          updatedBy
          updatedDateTimeUtc
          insertedBy
          insertedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  jobStateQuery: `query StateQuery {
    states {      
      stateReference          
      name
      color
      description
      isSystemOwned
      order
      updatedBy
      updatedDateTimeUtc
      insertedBy
      insertedDateTimeUtc                    
    }
  }`,
  unreadNotificationQuery: `query UnreadNotificationQuery {
    unreadNotifications {
      totalUnreadNotification
    }
  }`,
  customFieldDefinitionQuery: `query GetCustomFieldDefinition($first:Int,$definitionReference: String,$entityTypeReference:String,$categoryReferences:[String]){
    customFieldDefinitions(first:$first,definitionReference: $definitionReference,entityTypeReference: $entityTypeReference,categoryReferences:$categoryReferences){
      edges{      
        node{
          definitionReference
          entityReference
          displayName
          normalizedName       
          displayOrder
          entityType {
            entityTypeReference
            name
          }
          categoryReference
          description
          isMultiSelect
          isRequired
          isDisabled
          dataType {          
            dataTypeReference
            name
            isDecimalType
            isBooleanType
            isDateTimeType
            isDateType
            isNumberType
            isTextType
            isTextAreaType
            isDropDown
          }
          options{
            optionReference
            optionValue
            isDisabled
          }
        }
      }
    }
  }`,
  dataTypesQuery: `query GetDataTypes {
    dataTypes {
      dataTypeReference
      name
      isBooleanType
      isDateTimeType
      isDateType
      isDecimalType
      isNumberType
      isTextAreaType
      isTextType
      isDropDown
    }
  }`,
  categoryQuery: `query GetCategories($first: Int,$entityTypeReference: String,$categoryReferences: [String]) {
    categories(first: $first,entityTypeReference: $entityTypeReference,categoryReferences:$categoryReferences) {
      edges {
        node {
          categoryReference
          name 
          description
          entityType {
            entityTypeReference
            name
          }
        }
      }
    }
  }`,
  localizationQuery: `query localizationQuery {
    localization {
      timezone {
        timezoneCode
        name
        updatedDateTime
        updatedBy
      }
      currency {
        countryCode
        currencyCode
        name
        updatedDateTime
        updatedBy
      }
      locale {
        languageCode
        countryCode
        name
        updatedDateTime
        updatedBy
      }
      country {
        isoCode2
        name
        latitude
        longitude
        updatedDateTime
        updatedBy
      }
    }
  }`,
  industryQuery: `query IndustryQuery {
    industries {
      name
      industryReference
      description
      isVisible    
    }
  }`,
  timezoneQuery: `query TimezoneQuery {
    timezones {
      countryCode
      countryName
      timezoneCode
    }
  }`,
  featureSettingQuery: `query FeatureSettingQuery {
      featureSettings {
        featureSettingReference
        featureReference
        name
        isEnabled
        isEditable
        isVisible
        description
        updatedBy
        updatedDateTimeUtc
        insertedBy
        insertedDateTimeUtc
      }
    }`,
  phoneNumberTypeQuery: `query PhoneNumberTypeQuery {
    phoneNumberTypes {    
        phoneNumberTypeReference
        name      
        isSystemOwned
        description
        updatedDateTime
        updatedBy
        insertedDateTime
        insertedBy
      }
  }`,
  addressTypeQuery: `query AddressTypeQuery {
    addressTypes {    
        addressTypeReference
        name      
        isSystemOwned
        description
        updatedDateTime
        updatedBy
        insertedDateTime
        insertedBy
      }
  }`,
  contactTypeQuery: `query ContactTypeQuery {
    contactTypes {    
        contactTypeReference
        name      
        isSystemOwned
        description
        updatedDateTime
        updatedBy
        insertedDateTime
        insertedBy
      }
  }`,
  timeEntryTypeQuery: `query TimeEntryTypeQuery($includeLeaveTypes: String) {
    timeEntryTypes(includeLeaveTypes: $includeLeaveTypes) {      
      timeEntryTypeReference
      name    
      description
      isSystemOwned
      isPaidTime
      isLeaveType
      updatedBy
      updatedDateTimeUtc
      insertedBy
      insertedDateTimeUtc                    
    }
  }`,
  leaveTypeQuery: `query LeaveTypeQuery($includeInActive: String) {
    leaveTypes(includeInActive: $includeInActive) {    
        leaveTypeReference
        name      
        description
        isPaid
        isActive      
        isSystemOwned
        updatedDateTimeUtc
        updatedBy
        insertedDateTimeUtc
        insertedBy
      }
  }`,
  inquiryStateQuery: `query InquiryStateQuery {
    inquiryStates {      
      stateReference
      name
      color
      description
      isSystemOwned
      displayOrder
      updatedBy
      updatedDateTimeUtc
      insertedBy
      insertedDateTimeUtc                    
    }
  }`,
  inquirySourceQuery: `query InquirySourceQuery {
    inquirySources {      
      sourceReference
      name
      description
      isSystemOwned
      isActive
      order
      updatedBy
      updatedDateTimeUtc
      insertedBy
      insertedDateTimeUtc                    
    }
  }`,
  roleQuery: `query RoleQuery($first: Int, $after: String, $roleReference:String) {
    roles(first: $first, after: $after, roleReference: $roleReference) {
      edges {
        node {
          roleReference
          name
          isSystemOwned
          updatedDatetime
          updatedBy
          insertedDatetime
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  rolePermissionQuery: `query RolePermissionQuery($first:Int,$roleReference:String){
    rolePermissions(first:$first,roleReference:$roleReference){
      edges{
        node{
          category
          description
          isEnabled
          message
          name
          permissionReference
        }
      }
    }
  }`,
  partnerOrganisationQuery: `query PartnerOrganisationQuery {
    partnerOrganisations {
          organisationReference   
          organisationName     
          planName
          planTypeReference
          planTypeName        
          trialEndDateTimeUtc
          activeStaffMembers
          insertedDateTimeUtc
          subPartnerCode  
    }
  }`,
  partnerOrganisationInvoiceQuery: `query PartnerOrganisationInvoiceQuery($organisationReference: String) {
    partnerOrganisationInvoices(organisationReference: $organisationReference)  {
          createdDateTimeUtc   
          totalAmount     
          currency
          dueDateTimeUtc
          status        
          createdDateTimeUtc     
    }
  }`,
  partnerOrganisationUpcomingInvoiceQuery: `query PartnerOrganisationUpcomingInvoiceQuery($organisationReference: String) {
    partnerOrganisationUpcomingInvoice(organisationReference: $organisationReference) {
         billingContactName
         billingAddress
         billingEmailAddress
         dueDateTimeUtc 
         startingBalance
         subTotal
         total
         amountDue
         upcomingInvoiceAvailable
         currency
         upcomingInvoiceItems {
           periodStartDateTimeUtc
           periodEndDateTimeUtc
           description          
           quantity
           unitAmount
           totalAmount
           currency
           isProrated
         }
         upcomingInvoiceTaxes{
           displayLabel
           amount
         }
         upcomingInvoiceDiscounts{
           displayLabel
           amount
         }       
    }    
 }`,
  plansQuery: `query PlansQuery($planTypeReference:String) {
    plans(planTypeReference:$planTypeReference) {
      planReference
      name
      description
      planTypeReference
      planTypeName
      price
      priceLabel
      currency
    }
  }`,
  freePeriodQuery: `query FreePeriodQuery {
    freePeriods {
      freePeriodReference
      name
    }
  }`,
  discountSchemeQuery: `query DiscountSchemeQuery {
    discountSchemes {
      discountSchemeReference
      name
    }
  }`,
  reviewLinkQuery: `query ReviewLinkQuery {
    reviewLinks {
        reviewLinkReference
        reviewTypeReference
        reviewTypeName       
        reviewUrl
        message       
    }    
  }`,
  featureSettingAttributeValueQuery: `query FeatureSettingAttributeValueQuery($featureSettingAttributeValueReference: String) {
    featureSettingAttributeValue(featureSettingAttributeValueReference: $featureSettingAttributeValueReference) {
      value
    }
  }`,
  clientQuery: `query ClientQuery($first: Int, $after: String, $clientNumber: String,$clientName: String,$dateOfBirth: String,$emailAddress: String,$primaryPhoneNumber: String,$businessName: String, $searchTerm: String, $clientTypeReference: String, $clientReferences: [String], $clientManagerReference: String, $clientStateReferences: [String]) {
    clients(first: $first, after: $after,clientNumber:$clientNumber, clientName: $clientName, dateOfBirth: $dateOfBirth, emailAddress: $emailAddress,primaryPhoneNumber:$primaryPhoneNumber,businessName:$businessName, searchTerm: $searchTerm, clientTypeReference: $clientTypeReference, clientReferences: $clientReferences, clientManagerReference: $clientManagerReference, clientStateReferences: $clientStateReferences) {
      edges {
        node {
          clientReference
          clientNumber,
          clientTypeReference
          clientTypeName
          clientStateReference
          clientStateName
          businessName
          website
          businessEmailAddress
          businessPhoneNumber
          clientManager {
            managerReference
            givenName
            familyName
          }
          primaryContact {
            fullName
            emailAddress
            givenName
            familyName
            dateOfBirth
            phoneNumberReference
            phoneNumber
            phoneNumberTypeReference
            phoneNumberTypeName
          }
          primaryAddress {
            addressReference
            addressTypeReference
            addressTypeName
            route
            regionCode
            regionName
            postalCode
            city
            countryCode
            countryName
            latitude
            longitude
          }
          timezone
          currency {
            currencyCode
            countryCode
          }
          country {
            countryCode
            countryName
          }
          locale {
            languageCode
            countryCode
          }
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  contactQuery: `query ContactQuery($first: Int, $after: String, $businessEntityReference: String, $contactReferences: [String]) {
    contacts(first: $first, after: $after, businessEntityReference: $businessEntityReference, contactReferences: $contactReferences) {
      edges {
        node {
          contactReference
          businessEntityReference
          emailAddress
          givenName
          familyName
          timezone        
          dateOfBirth
          contactType {
            contactTypeReference
            name
          }
          primaryPhone {
            number
            phoneNumberTypeReference
            phoneNumberTypeName
          }
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  phoneNumberQuery: `query PhoneNumberQuery($first: Int,$after:String, $businessEntityReference: String, $phoneNumberReferences: [String]) {
    phoneNumbers(first: $first,after:$after, businessEntityReference: $businessEntityReference,phoneNumberReferences:$phoneNumberReferences) {
      edges {
        node {      
          phoneNumberReference
          businessEntityReference
          phoneNumberTypeReference        
          phoneNumberTypeName        
          number
          isPrimary
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  staffQuery: `query StaffQuery($first: Int,$after:String,$searchTerm:String, $staffReferences: [String], $staffStateReferences: [String]) {
    staffs(first: $first,after:$after,searchTerm: $searchTerm, staffReferences: $staffReferences, staffStateReferences: $staffStateReferences) {
      edges {
        node {
          staffReference
          staffNumber
          emailAddress
          givenName
          familyName
          timezone
          dateOfHire
          dateOfBirth
          staffStateName
          staffStateReference
          profileImageUrl
          description
          positionType {
            positionTypeReference
            name
          }
          primaryPhone {
            number
            phoneNumberTypeReference
            phoneNumberTypeName
          }
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  contractorQuery: `query ContractorQuery($first: Int, $after: String, $contractorNumber: String, $primaryPhoneNumber: String, $businessName: String, $searchTerm: String, $contractorReferences: [String],  $contractorStateReferences: [String]) {
    contractors(first: $first, after: $after,contractorNumber:$contractorNumber, primaryPhoneNumber:$primaryPhoneNumber,businessName:$businessName, searchTerm: $searchTerm, contractorReferences: $contractorReferences,contractorStateReferences: $contractorStateReferences) {
      edges {
        node {
          contractorReference
          contractorNumber,
          contractorStateReference
          contractorStateName
          businessName
          businessEmailAddress
          businessPhoneNumber
          primaryContact {
            emailAddress
            givenName
            familyName
            phoneNumberReference
            phoneNumber
            phoneNumberTypeReference
            phoneNumberTypeName
          }
          primaryAddress {
            addressReference
            addressTypeReference
            addressTypeName
            route
            regionCode
            regionName
            postalCode
            city
            countryCode
            countryName
            latitude
            longitude
          }
          timezone
          currency {
            currencyCode
            countryCode
          }
          country {
            countryCode
            countryName
          }
          locale {
            languageCode
            countryCode
          }
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  leaveQuery: `query LeaveQuery($first: Int, $after: String, $leaveReference: String, $leaveTypeReference: String,$leaveStatusReference:String, $staffReference: String) {
    leaves(first: $first, after: $after, leaveReference: $leaveReference, leaveTypeReference: $leaveTypeReference, 
    leaveStatusReference: $leaveStatusReference,
    staffReference: $staffReference) {
      edges {
        node {
          leaveReference
          staffReference 
          staffName
          profileImageUrl      
          leaveTypeReference
          leaveTypeName
          leaveStatusReference
          leaveStatusName
          totalDurationLabel
          description
          declinedReason        
          statusUpdatedBy
          leaveApproverStaffReference
          statusLastUpdateDateTimeUtc
          leaveEntries {
            leaveEntryReference
            startDateTimeUtc
            endDateTimeUtc
            durationInSeconds
            hours
            minutes          
          }                
          updatedBy
          updatedDateTimeUtc
          insertedBy
          insertedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  staffNameQuery: `query StaffNameQuery {
    staffNames {
        staffReference
        staffNumber
        emailAddress
        givenName
        familyName
        fullName
        initials
        isArchived
        isAuthenticated
        profileImageUrl
    }
  }`,
  reminderQuery: `query ReminderQuery($first: Int, $after: String, $reminderReference: String, $entityTypeReference: String, $entityReference: String, $startDateTimeUtc: String, $endDateTimeUtc: String, $includePastReminders: String) {
    reminders(first: $first, after: $after, reminderReference: $reminderReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference, startDateTimeUtc: $startDateTimeUtc, endDateTimeUtc: $endDateTimeUtc, includePastReminders: $includePastReminders) {
      edges {
        node {
          reminderReference
          entityTypeReference
          entityTypeName
          entityReference
          dueDateTimeUtc
          description
          updatedBy
          updatedDateTimeUtc
          insertedBy
          insertedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  addressSettingQuery: `query AddressSettingQuery {
    addressSettings {
      countryCode
      addressComponent
      displayName
      displaySequence
      description
      required
      visible
    }
  }`,
  googleAddressTypesQuery: `query GoogleAddressTypeQuery {
    googleAddressTypes {
      countryCode
      route {
        addressTypeName
        useShortValue
      }
      region {
        addressTypeName
        useShortValue
      }
      city {
        addressTypeName
        useShortValue
      }
      postCode {
        addressTypeName
        useShortValue
      }
      country {
        addressTypeName
        useShortValue
      }
    }
  }`,
  regionQuery: `query RegionQuery {
    regions {
      countryCode
      name    
      regionCode
    }
  }`,
  countryQuery: `query CountryQuery {
    countries {
      name
      countryCode,
      latitude
      longitude    
    }
  }`,
  addressQuery: `query AddressQuery($first: Int, $businessEntityReference: String, $addressReferences: [String]) {
    addresses(first: $first, businessEntityReference: $businessEntityReference, addressReferences: $addressReferences) {
      edges {
        node {
          addressReference
          businessEntityReference
          addressTypeReference
          route
          regionCode
          regionName
          postalCode
          city
          countryCode
          countryName
          isPrimary
          latitude
          longitude
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy,
          effectiveFromDateTimeUtc  
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  fileQuery: `query FileQuery($first: Int, $after: String, $fileReference: String, $entityTypeReference: String, $entityReference: String, $fileName: String, $tag: String) {
    files(first: $first, after: $after, fileReference: $fileReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference, fileName: $fileName, tag: $tag) {
      edges {
        node {
          fileReference
          fileName
          description
          sizeInBytes
          extension
          mimeType
          isFileOwner
          accessLevel {
            accessLevelReference
            name
          }
          tags {
            tagReference
            name
          }
          staffPermissions {
            staffPermissionReference
            staffReference
            permissionReference
            permissionName
          }       
          updatedBy
          updatedDateTimeUtc
          insertedBy
          insertedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }`,
  customFieldQuery: `query GetCustomFields(
    $first: Int
    $fieldReference: String
    $entityReference: String
    $entityTypeReference: String
  ) {
    customFields(
      first: $first
      fieldReference: $fieldReference
      entityReference: $entityReference
      entityTypeReference: $entityTypeReference
    ) {
      edges {
        node {
          fieldReference
          value
          definition {
            displayName
            normalizedName
            isMultiSelect
          }
          dataType {
            dataTypeReference
            name
            isBooleanType
            isDateTimeType
            isDateType
            isNumberType
            isTextType
            isDecimalType
            isTextAreaType
            isDropDown
          }
        }
      }
    }
  }`,
  customFieldOptionQuery: `query GetCustomFieldOption($first: Int, $definitionReference: String, $optionReference: String) {
    customFieldOptions(first: $first, definitionReference: $definitionReference, optionReference: $optionReference) {
      edges {
        node {
          optionReference
          optionValue
          isDisabled
        }
      }
    }
  }`,
  organisationDetailQuery: `query GetOrganisation {
  organisationDetail {
     organisationId
     organisationReference
     name
     isPartner
     emailAddress
     phoneNumber
     websiteUrl
     facebookUrl
     instagramUrl
     twitterUrl
     industryName
     industryReference
     firstDayOfWeek
     partnerCode
     currency {
      currencyCode
      countryCode
      currencyName
     }
     timezone {
      name
      timezoneCode
     }
     locale {
      name
      languageCode
      countryCode          
     }
     address {
      route
      regionCode
      regionName
      postalCode
      city
      countryCode
      countryName
      latitude
      longitude          
     }  
     country {
      isoCode2
      name
      latitude
      longitude
    }
  }
}`,
  sequenceNumberQuery: `query SequenceNumberQuery {
    sequenceNumbers {          
      sequenceNumberTypeReference
      sequenceNumberTypeName
      nextSequenceNumber
      prefix
      numberFormat
    }
  }`,
  taxIdentificationQuery: `query TaxIdentificationQuery {
    taxIdentification {          
      name
      number
      updatedDateTimeUtc
      updatedBy   
    }
  }`,
  taxQuery: `query TaxQuery {
    taxes {          
      taxReference
      name
      description
      isDefault
      isDisabled
      taxCode
      effectiveRate
      taxComponents {
        taxComponentReference
        description
        rate
        isCompound
      }
      updatedDateTimeUtc
      updatedBy  
      insertedDateTimeUtc
      insertedBy 
    }
  }`,
  currencyQuery: `query CurrencyQuery {
    currencies {          
      currencyReference
      countryCode
      currencyCode
      name        
      updatedDateTimeUtc
      updatedBy  
      insertedDateTimeUtc
      insertedBy 
    }
  }`,
  metaCurrencyQuery: `query CurrencyQuery {
    currencies {
      countryCode
      currencyCode
      currencyName
      countryName
    }
  }`,
  paymentMethodQuery: `query PaymentMethodQuery {
    paymentMethods {          
      paymentMethodReference    
      name        
      description
      isSystemOwned
      isDisabled
      updatedDateTimeUtc
      updatedBy  
      insertedDateTimeUtc
      insertedBy 
    }
  }`,
  exchangeRateQuery: `query ExchangeRateQuery  {
    exchangeRates{    
      baseCurrencyCode
      currencyCode
      rate
      updatedDateTimeUtc
      insertedDateTimeUtc         
    }
  }`,

  invoiceReminderQuery: `query InvoiceReminderQuery($invoiceReminderReference: String) {
    invoiceReminders(invoiceReminderReference: $invoiceReminderReference) {          
      invoiceReminderReference
      reminderTypeReference
      reminderTypeName
      days
      minimumInvoiceAmount
      emailSubject
      emailBody    
      sendCustomerStatement
      isEnabled
      replyToStaff {
        staffReference
        name
        profileImageUrl
        emailAddress
        isArchived
      }
      clients {
        clientReference
        name
      }
      updatedDateTimeUtc
      updatedBy  
      insertedDateTimeUtc
      insertedBy 
    }
  }`,
  templateMergeFieldQuery: `query TemplateMergeFieldQuery($templateTypeReference: String) {
    templateMergeFields(templateTypeReference: $templateTypeReference) {    
        name
        normalizedName
        category
        sampleValue
        isRequired    
    }
  }`,

  templateTypeQuery: `query TemplateTypeQuery($templateTypeReference: String) {
    templateTypes(templateTypeReference: $templateTypeReference) {
      templateTypeReference
      templateTypeName    
      description
      isVisible
      maxTemplateAllowed
      isDescriptionTemplateSupported
      isEmailTemplateSupported
      isSmsTemplateSupported
      mergeFields {
        name
        normalizedName
        category
        sampleValue
        isRequired
      }
    }
  }`,
  templateQuery: `query TemplateQuery($templateReference: String, $templateTypeReference: String, $includeDisabledTemplates: String, $emailConfigurationReference: String) {
    templates(templateReference: $templateReference, templateTypeReference: $templateTypeReference, includeDisabledTemplates: $includeDisabledTemplates, emailConfigurationReference: $emailConfigurationReference) {
      templateReference
      templateTypeReference
      templateTypeName
      name
      description
      isDisabled
      isDefault
      descriptionTemplate
      emailTemplateSubject
      emailTemplateBody
      smsTemplateMessage
      updatedBy
      updatedDateTimeUtc
      insertedBy
      insertedDateTimeUtc
      emailConfigurationReference
    }
  }`,
  invoiceQuery: `query InvoiceQuery($first: Int, $after: String, $invoiceReference: String, $invoiceTemplateReference: String, $invoiceStatusReferences: [String], $clientReference: String, $overdueInvoicesOnly: String, $saleStaffReference: String, $invoiceNumber: String, $createdDateTimeFrom: String, $createdDateTimeTo: String, $invoiceDateTimeFrom: String, $invoiceDateTimeTo: String, $dueDateTimeFrom: String, $dueDateTimeTo: String, $sentInvoicesOnly: String, $disputedInvoicesOnly: String) {
    invoices(first: $first, after: $after, invoiceReference: $invoiceReference, invoiceTemplateReference: $invoiceTemplateReference, invoiceStatusReferences: $invoiceStatusReferences, clientReference: $clientReference, overdueInvoicesOnly: $overdueInvoicesOnly, saleStaffReference: $saleStaffReference, invoiceNumber: $invoiceNumber, createdDateTimeFrom: $createdDateTimeFrom, createdDateTimeTo: $createdDateTimeTo, invoiceDateTimeFrom: $invoiceDateTimeFrom, invoiceDateTimeTo: $invoiceDateTimeTo, dueDateTimeFrom: $dueDateTimeFrom, dueDateTimeTo: $dueDateTimeTo, sentInvoicesOnly: $sentInvoicesOnly, disputedInvoicesOnly: $disputedInvoicesOnly) {
      edges {
        node {
          invoiceReference
          invoiceNumber
          externalReference
          title
          description
          paymentTerms
          invoiceStatusReference
          invoiceStatusName
          taxTypeReference
          taxTypeName
          isOverdue
          invoiceDateTimeUtc
          dueDateTimeUtc
          additionalReference
          currencyCode
          currencyRate
          projectReference
          isReminderEnabled
          isSent
          isDisputed
          client {
            clientReference
            name
          }
          clientContact {
            contactReference
            name
          }
          job {
            jobReference
            jobNumber
            description
          }
          quote {
            quoteReference
            quoteNumber
            quoteDescription
          }
          saleStaff {
            staffReference
            name
            profileImageUrl
          }
          invoiceTemplate {
            templateReference
            templateName
          }
          address {
            route
            regionCode
            regionName
            postalCode
            city
            countryCode
            countryName
            fullAddress
            latitude
            longitude
          }
          lineItemGroups {
            lineItemGroupReference
            lineItemGroupName
            bundle {
              bundleReference
              bundleNumber
              bundleCode
              description
            }
            lineItems {
              lineItemReference
              externalReference
              itemReference
              itemCode
              itemName
              description
              storageReference
              storageName
              itemDisplayOrder
              description
              quantity
              unitCostPrice
              unitMarkupPercentage
              unitMarkupAmount
              unitSalePrice
              discountPercentage
              discountAmount
              totalDiscountAmount
              taxReference
              taxName
              taxAmount
              totalAmount
              accountCode
            }
          }
          totalCostPrice
          totalGrossProfitPercentage
          totalGrossProfitAmount
          totalDiscountAmount
          subTotalAmount
          totalTaxAmount
          totalCreditAmount
          totalAmount
          totalPaidAmount
          amountDue
          concurrencyToken
          updatedDateTimeUtc
          updatedBy
          insertedDateTimeUtc
          insertedBy
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  invoiceStatusQuery: `query InvoiceStatusQuery {
    invoiceStatues {      
      invoiceStatusReference          
      name
      displayName
      bgColor
      textColor
      canManuallyTransition
      availableStatusTransitions {
          invoiceStatusReference          
          name
          displayName
          bgColor
          textColor
          canManuallyTransition
      }
    }
  }`,
  clientEmailAddressQuery: `query ClientEmailAddressQuery($clientReference: String) {
    clientEmailAddress(clientReference: $clientReference) {
      clientReference
      name
      emailAddress
      emailSource
    }
  }`,
  emailAttachmentQuery: `query EmailAttachmentQuery(
    $entityTypeReference: String
    $entityReference: String
  ) {
    files(
      entityTypeReference: $entityTypeReference
      entityReference: $entityReference
    ) {
      edges {
        node {
          fileReference
          fileName
          description
          extension
          insertedBy
          insertedDateTimeUtc
        }
      }
    }
  }`,
  documentsCardQuery: `query FileQuery($first: Int, $after: String, $fileReference: String, $entityTypeReference: String, $entityReference: String, $fileName: String, $tag: String) {
    files(first: $first, after: $after, fileReference: $fileReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference, fileName: $fileName, tag: $tag) {
      edges {
        node {
          fileReference
          fileName
          description
          sizeInBytes
          extension
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }`,
  fileSearchQuery: `query FileQuery($first: Int, $after: String, $fileReference: String, $entityTypeReference: String, $entityReference: String, $fileName: String, $tag: String) {
    files(first: $first, after: $after, fileReference: $fileReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference, fileName: $fileName, tag: $tag) {
      edges {
        node {
          fileReference
          fileName
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }`,
  clientSearchQuery: `query ClientQuery($first: Int, $after: String, $clientNumber: String,$clientName: String,$dateOfBirth: String,$emailAddress: String,$primaryPhoneNumber: String,$businessName: String, $searchTerm: String, $clientTypeReference: String, $clientReferences: [String], $clientManagerReference: String, $clientStateReferences: [String]) {
    clients(first: $first, after: $after,clientNumber:$clientNumber, clientName: $clientName, dateOfBirth: $dateOfBirth, emailAddress: $emailAddress,primaryPhoneNumber:$primaryPhoneNumber,businessName:$businessName, searchTerm: $searchTerm, clientTypeReference: $clientTypeReference, clientReferences: $clientReferences, clientManagerReference: $clientManagerReference, clientStateReferences: $clientStateReferences) {
      edges {
        node {
          clientReference
          primaryContact {
            fullName
            emailAddress
            givenName
            familyName
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  contractorSearchQuery: `query ContractorQuery($first: Int, $after: String, $contractorNumber: String, $primaryPhoneNumber: String, $businessName: String, $searchTerm: String, $contractorReferences: [String],  $contractorStateReferences: [String]) {
    contractors(first: $first, after: $after,contractorNumber:$contractorNumber, primaryPhoneNumber:$primaryPhoneNumber,businessName:$businessName, searchTerm: $searchTerm, contractorReferences: $contractorReferences,contractorStateReferences: $contractorStateReferences) {
      edges {
        node {
          contractorReference
          primaryContact {
            emailAddress
            givenName
            familyName
            phoneNumberReference
            phoneNumber
            phoneNumberTypeReference
            phoneNumberTypeName
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  noteQuery: `query NoteQuery($first: Int, $after: String, $noteReference: String, $noteTypeReference: String, $entityTypeReference: String, $entityReference: String) {
    notes(first: $first, after: $after, noteReference: $noteReference, noteTypeReference: $noteTypeReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference) {
      edges {
        node {
          noteReference
          entityTypeReference
          entityReference
          noteTypeReference
          noteTypeName
          description
          isVisibleToClient
          updatedBy
          updatedDateTimeUtc
          insertedBy
          insertedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }`,
  reportBrandingQuery: `query ReportBrandingQuery {
    reportBranding {    
        reportReference
        headerColor
        showCompanyName
        showCompanyAddress
        showEmailAddress
        showPhoneNumber
        showTaxNumber    
        updatedDateTimeUtc
        updatedBy
        insertedDateTimeUtc
        insertedBy
      }
  }`,
  noteTypeQuery: `query NoteTypeQuery {
    noteTypes {    
        noteTypeReference
        name      
        description
        isDisabled
        isSystemOwned
        updatedDateTimeUtc
        updatedBy
        insertedDateTimeUtc
        insertedBy
      }
  }`,
  emailTemplateQuery: `query EmailTemplateQuery($emailConfigurationReference: String, $entityTypeReference: String, $entityReference: String, $templateReference: String) {
    emailTemplate(emailConfigurationReference: $emailConfigurationReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference, templateReference: $templateReference) {
      emailSubject
      emailBody
    }
  }`,
  emailsQuery: `query EmailsQuery($emailDeliveryStatusReference: String, $entityTypeReference: String, $entityReference: String) {
    emails(emailDeliveryStatusReference: $emailDeliveryStatusReference, entityTypeReference: $entityTypeReference, entityReference: $entityReference) {
      emailReference
      emailConfigurationReference
      emailConfigurationName
      entityTypeReference
      entityTypeName
      emailDeliveryStatusReference
      emailDeliveryStatusName
      subject
      body
      recipients {
        recipientTypeReference
        recipientTypeName
        name
        emailAddress
      }
      attachments {
        attachmentTypeReference
        entityReference
        entityName
      }
      errorMessage
      emailOpenDateTimeUtc
      insertedBy
      insertedDateTimeUtc
    }
  }`,
  paymentQuery: `query PaymentQuery($entityTypeReference: String, $entityReference: String, $paymentReference: String) {
    payments(entityTypeReference: $entityTypeReference, entityReference: $entityReference, paymentReference: $paymentReference) {
      paymentReference
      paymentNumber
      entityTypeReference
      entityReference
      paymentDateTimeUtc
      amount
      description
      currencyCode
      currencyRate
      paymentMethodReference
      paymentMethodName
      invoice {
        invoiceReference
        invoiceNumber
      }
      bill {
        billReference
        billNumber
      }
      concurrencyToken
      updatedDateTimeUtc
      updatedBy
      insertedDateTimeUtc
      insertedBy
    }
  }`,
  itemQuery: `query ItemQuery(
    $first: Int
    $after: String
    $itemCode: String
    $itemReference: String
    $categoryReference: String
    $itemTypeReference: String
    $barcodeNumber: String
    $storageReference: String
    $adjustmentReference: String
    $supplierItemCode: String
    $includeArchived: String
    $supplierReference: String
    $search: String
  ) {
    items(
      first: $first
      after: $after
      itemCode: $itemCode
      itemReference: $itemReference
      storageReference: $storageReference
      categoryReference: $categoryReference
      barcodeNumber: $barcodeNumber
      supplierItemCode: $supplierItemCode
      itemTypeReference: $itemTypeReference
      includeArchived: $includeArchived
      adjustmentReference: $adjustmentReference
      supplierReference: $supplierReference
      search: $search
    ) {
      edges {
        node {
          itemReference
          externalReference                
          itemCode
          name
          description
          isArchived
          quantity
          manufacturer
          isTrackedItem
          useTimeBasedQuantity
          itemType  {
             itemTypeReference
             name
          }
          category {
            categoryReference
            name
            description
          }
          dimension {
            length
            width
            height
            unitMeasureCode
          }
          weight {
            weight
            unitMeasureCode
          }                
          cost {
            costingMethodReference
            costingMethodName
            unitPrice
            description
            taxReference
            taxName
            taxEffectiveRate
            taxAmount
            taxComponents {
              description
              rate
              isCompound
            }
          }
          purchase {
            unitMeasureCode
            unitMeasureQuantity
          }
          sale {
            markupPercentage
            markupAmount
            unitPrice
            description
            taxReference
            taxName
            taxEffectiveRate
            taxAmount
            taxComponents {
              description
              rate
              isCompound
            }
          }               
          inventory {
            quantityOnHand
            committedQuantity
            availableQuantity
            quantityOnOrder
            committedByQuote
            committedByJob
            quantityOnHandValue
          }
          updatedBy
          insertedBy
          insertedDateTimeUtc
          updatedDateTimeUtc
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
 }`,
  schedulerResourceQuery: `query SchedulerResourceQuery {
        schedulerResources {
          resourceReference
          givenName
          familyName
          fullName
          emailAddress
          isStaffMember
          isContractor
          displayOrder
          initials
          profileImageUrl
        }
      }`,
  schedulerEventQuery: `query SchedulerEventsQuery($startDateTimeUtc: String, $endDateTimeUtc: String, $stateReferences: [String], $resourceReference: String, $clientReference: String, $categoryReferences: [String]) {
    schedulerEvents(startDateTimeUtc: $startDateTimeUtc, endDateTimeUtc: $endDateTimeUtc, stateReferences: $stateReferences, resourceReference: $resourceReference, clientReference: $clientReference, categoryReferences: $categoryReferences) {
      eventType
      resourceReferences
      startDateTimeUtc
      endDateTimeUtc
      insertedBy
      insertedDateTimeUtc
      jobMetadata {
        jobReference
        jobNumber
        description
        clientReference
        clientName
        stateReference
        stateName
        stateColor
        addressRoute
        addressRegionCode
        addressRegionName
        addressCity
        addressCountryCode
        addressCountryName
        addressPostalCode
      }
      leaveMetadata {
        leaveReference
        leaveDescription
        leaveTypeName
        leaveTypeReference
        leaveStatusName
        leaveStatusReference
        leaveApproverReference
      }
    }
  }`,
  unassignedJobsQuery: `query UnassignedStaffJobQuery($first: Int, $after: String, $startDateTimeUtc: String, $endDateTimeUtc: String) {
      unassignedStaffJobs(first: $first, after: $after, startDateTimeUtc: $startDateTimeUtc, endDateTimeUtc: $endDateTimeUtc) {
        edges {
          node {
            jobReference
            jobNumber
            description
            startDateTimeUtc
            endDateTimeUtc
            address {
              route
              regionCode
              regionName
              postalCode
              city
              countryCode
              countryName
            }
            contractors {
              contractorReference
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }`,
  JobCountQuery: `query JobCountQuery($startDateTimeUtc: String, $endDateTimeUtc: String, $staffReference: String, $stateReference: String, $contractorReference: String, $clientReference: String) {
    jobCount(startDateTimeUtc: $startDateTimeUtc, endDateTimeUtc: $endDateTimeUtc, staffReference: $staffReference, stateReference: $stateReference, contractorReference: $contractorReference, clientReference: $clientReference) {
      dayOfMonth
      totalJobs
    }
  }`,
  taskQuery: `query TaskQuery(
        $first: Int
        $after: String
        $taskReference: String
        $entityTypeReference: String
        $entityReference: String
        $taskStatusReference: String
        $staffReference: String
        $dueDateTimeUtc: String
        $sortReference: String
        $categoryReferences: [String]
        $taskStatusReferences: [String]
      ) {
        tasks(
          first: $first
          after: $after
          taskReference: $taskReference
          entityTypeReference: $entityTypeReference
          entityReference: $entityReference
          taskStatusReference: $taskStatusReference
          staffReference: $staffReference
          dueDateTimeUtc: $dueDateTimeUtc
          sortReference: $sortReference
          categoryReferences: $categoryReferences
          taskStatusReferences: $taskStatusReferences
        ) {
          edges {
            node {
              taskReference
              entityTypeReference
              entityTypeName
              entityReference
              entityName
              taskStatusReference
              taskStatusName
              taskStatusColor
              name
              description
              dueDateUtc
              startDateTimeUtc
              endDateTimeUtc
              assignToAllStaffMembers
              concurrencyToken
              taskReminders {
                taskReminderReference
                reminderDateTimeUtc
              }
              staffMembers {
                taskStaffReference
                staffReference
                name
                profileImageUrl
              }
              taskCategory {
                taskCategoryReference
                name
              }
              updatedBy
              updatedDateTimeUtc
              insertedBy
              insertedDateTimeUtc
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }`,
  taskStatusQuery: `query TaskStatusQuery {
      taskStatus {      
        taskStatusReference          
        name
        color
        displayOrder
        description
        isSystemOwned
        isDisabled    
        updatedBy
        updatedDateTimeUtc
        insertedBy
        insertedDateTimeUtc                    
      }
    }`
}
