import React, { Fragment, useEffect, useState } from "react"
import useLocalStorageState from "utils/localStorage"
import DataGridNew from "library/styled/datagridNew"
import { observer } from "mobx-react"
import { useModalState } from "utils/modalStates"
import { useNavigate } from "react-router-dom"
import SidePanel from "library/styled/sidePanel"
import { taskListColumns } from "./taskListColumns"
import { TaskListModel } from "./taskListModel"
import { useTaskQuery, useTaskStatusQuery } from "../services/taskService"
import { Task } from "../04_sharedModel/taskModel"
import TaskListFilters from "./taskListFilters"
import TaskDetailPage from "../02_taskDetails/taskDetailPage"
import AddOrEditTaskPage from "../01_addOrEditTask/addOrEditTaskPage"

function TaskListPage() {
  const [columns, setColumns] = useLocalStorageState("taskColumns", taskListColumns)
  const [taskListModel] = useState(() => new TaskListModel())
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useTaskQuery(taskListModel.getFilter())
  const { data: taskStatus } = useTaskStatusQuery()
  const { viewModal, setViewModal } = useModalState<Task>()
  const { viewModal: addTaskViewModal, setViewModal: setAddTaskModal } = useModalState<Task>()
  const [statusColors, setStatusColors] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    if (data) taskListModel.setTaskList(data)
  }, [data])

  useEffect(() => {
    if (taskStatus) {
      let x: any = {}
      taskStatus.forEach(
        (m) =>
          (x[m.taskStatusReference] = {
            textColor: m.color,
            bgColor: m.color
          })
      )
      setStatusColors(x)
    }
  }, [taskStatus])

  return (
    <Fragment>
      <div className="px-3 pt-3 space-y-3 xl16:px-6 xl16:pt-6 xl16:space-y-6">
        <div className="">
          <TaskListFilters taskListModel={taskListModel} />
        </div>

        <div className="">
          <DataGridNew<Task>
            title="Tasks"
            buttonText="New Task"
            data={taskListModel.taskList}
            columns={columns}
            dataKey="taskReference"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onRowClick={(item) => {
              setViewModal({ item: item, isOpen: true })
            }}
            onColumnChange={setColumns}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            noItemFoundMessage="You don't have any tasks assigned."
            action={() => {
              setAddTaskModal({ item: undefined, isOpen: true })
            }}
            statusColor={statusColors}
            // taskStatus={taskStatus}
            // onStatusClick={taskReference => {
            //   alert(`test ${taskReference}`)
            // }}
          />
        </div>
      </div>

      <SidePanel
        title={`Task Details`}
        open={viewModal.isOpen}
        onClose={() => {
          setViewModal({ ...viewModal, isOpen: false })
          navigate("/tasks")
        }}
        body={<TaskDetailPage taskReference={viewModal.item?.taskReference} onDelete={() =>
          setViewModal({ ...viewModal, isOpen: false })
        } />}
        type="detailPanel"
      />

      <SidePanel
        title={`New Task`}
        open={addTaskViewModal.isOpen}
        onClose={() => {
          setAddTaskModal({ ...addTaskViewModal, isOpen: false })
          navigate("/tasks")
        }}
        body={
          <AddOrEditTaskPage
            taskReference={addTaskViewModal.item?.taskReference}
            closeSidePanel={() => {
              setAddTaskModal({ ...addTaskViewModal, isOpen: false })
              navigate("/tasks")
            }}
          />
        }
        type="detailPanel"
      />

    </Fragment>
  )
}

export default observer(TaskListPage)
