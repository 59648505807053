export interface EntityType {
  entityTypeReference: string
  name: string
  reference?: string
  isCustomFieldEnabled?: boolean
}

export interface EntityTypes {
  [key: string]: EntityType
}

export const entityTypes: EntityTypes = {
  job: {
    entityTypeReference: "5A228212-5B7C-45D1-90F5-277B1200AAE6",
    name: "Job"
  },
  staff: {
    entityTypeReference: "21B8BF6D-B9DB-4FB7-BCBE-FA7B8549E657",
    name: "Staff"
  },
  client: {
    entityTypeReference: "BA4EF615-AD7F-4BDF-9AB6-B8BA86935154",
    name: "Client"
  },
  reminder: {
    entityTypeReference: "41302DD7-AC42-4B51-9AD2-E8F0475DC088",
    name: "Reminder"
  },
  hr: {
    entityTypeReference: "ADF4F4FD-6221-4A19-989E-D9FEA0489B27",
    name: "Hr"
  },
  document: {
    entityTypeReference: "7880C83D-3C16-4581-BAB4-FDB3BCAC8855",
    name: "Document",
    reference: "DocumentEntity"
  },
  myReminder: {
    entityTypeReference: "3EF75AD6-0D3C-4272-A028-6929B7EE9A45",
    name: "My Reminder"
  },
  contractor: {
    entityTypeReference: "83F6C65D-77B5-469D-A06E-D6A1649215C4",
    name: "Contractor"
  },
  leave: {
    entityTypeReference: "027AF35A-832C-46B6-AF6A-1940EEB7FFD8",
    name: "Leave"
  },
  inquiry: {
    entityTypeReference: "20A9AD22-5367-46DB-99EB-552AEA1E5398",
    isCustomFieldEnabled: true,
    name: "Inquiry"
  },
  inquiryForm: {
    entityTypeReference: "8BAB888C-3DE3-44D3-86D0-974D19B7A16C",
    isCustomFieldEnabled: true,
    name: "Inquiry Form"
  },
  organisation: {
    entityTypeReference: "60C790EB-FA45-495B-B1D2-3532E64B99D7",
    name: "Organisation"
  },
  invoice: {
    entityTypeReference: "E5A81D68-C4EB-4A01-8333-E0B7DFB4A736",
    name: "Invoice"
  },
  payment: {
    entityTypeReference: "A7D454B8-2546-453E-B6C3-7CA190D10410",
    name: "Payment"
  },
  general: {
    entityTypeReference: "42FC1E54-822F-4A0D-AF88-76FCAF51DB7C",
    name: "General"
  },
  task: {
    entityTypeReference: "36F4582B-74E2-477E-9C5F-90C31DDDFD38",
    name: "Task"
  }
}
