import { DataGridColumn } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { Task } from "../04_sharedModel/taskModel"

export const taskListColumns: DataGridColumn<Task>[] = [
  {
    id: "1",
    name: "Title",
    isEnabled: true,
    keys: ["name"],
    textBold: true,
    order: 1,
    columnTypeId: ColumnType.Text.Id,
    toolTip: {
      tooltipDisplayThreshold: 15,
      maxTooltipCharacters: 350
    }
  },
  {
    id: "2",
    name: "Description",
    isEnabled: true,
    keys: ["description"],
    order: 2,
    columnTypeId: ColumnType.Text.Id,
    toolTip: {
      tooltipDisplayThreshold: 15,
      maxTooltipCharacters: 350
    }
  },
  {
    id: "3",
    name: "Type",
    isEnabled: true,
    keys: ["entityTypeName"],
    order: 3,
    columnTypeId: ColumnType.TaskEntity.Id
  },
  {
    id: "4",
    name: "Status",
    isEnabled: true,
    keys: ["taskStatusName"],
    helperKey: "taskStatusReference",
    order: 4,
    columnTypeId: ColumnType.Status.Id
  },
  {
    id: "5",
    name: "Category",
    isEnabled: true,
    keys: ["taskCategory"],
    helperKey: "name",
    order: 5,
    columnTypeId: ColumnType.Tags.Id
  },
  {
    id: "6",
    name: "Due Date",
    isEnabled: true,
    keys: ["dueDateUtc"],
    order: 6,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "7",
    name: "Start Date",
    isEnabled: true,
    keys: ["startDateTimeUtc"],
    order: 7,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "8",
    name: "End Date",
    isEnabled: true,
    keys: ["endDateTimeUtc"],
    order: 8,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "9",
    name: "Updated By",
    isEnabled: false,
    keys: ["updatedBy"],
    order: 9,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "10",
    name: "Updated Date",
    isEnabled: false,
    keys: ["updatedDateTimeUtc"],
    order: 10,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "11",
    name: "Created By",
    isEnabled: false,
    keys: ["insertedBy"],
    order: 11,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "12",
    name: "Created Date",
    isEnabled: false,
    keys: ["insertedDateTimeUtc"],
    order: 12,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "13",
    name: "Staff Member",
    isEnabled: false,
    keys: ["staffMembers"],
    helperKey: ["profileImageUrl"],
    order: 13,
    columnTypeId: ColumnType.StaffMemberList.Id
  }
]
