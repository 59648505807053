import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { keys } from "../../../constants/keys"
import { Response } from "../../../models/shared/response"
import { ReactQueryParams } from "../../../models/shared/common"
import { graphQLClient } from "../../../shared/graphql/graphQLClient"
import { query } from "../../../shared/graphql/queries"
import { common } from "../../../common"
import {
  AddTaskInput,
  AddTaskResponse,
  AddTaskStatusInput,
  AddTaskStatusResponse, ChangeTaskStatusInput, ChangeTaskStatusResponse,
  RemoveTaskInput,
  RemoveTaskResponse,
  RemoveTaskStatus,
  RemoveTaskStatusInput, RemoveTaskStatusResponse,
  TaskQueryInput,
  UpdateTaskInput,
  UpdateTaskResponse
} from "../04_sharedModel/inputModels"
import { Tasks, TaskStatus, TaskStatuses } from "../04_sharedModel/taskModel"
import { mutation } from "../../../shared/graphql/mutations"

// ##################################### Task Query #####################################

function useTaskQuery(input: TaskQueryInput) {
  const queryClient = useQueryClient()
  return useInfiniteQuery([keys.taskQuery, input], fetchTasks, {
    getNextPageParam: (lastPage: Response<Tasks>) => {
      if (lastPage?.data.data.tasks.pageInfo.hasNextPage) {
        return lastPage?.data.data.tasks.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Tasks>>) => {
      for (const task of data.pages.flatMap((m) => m.data.data.tasks.edges)) {
        // we have to set cache in the same way we will get the data from server
        let axiosResponse: Response<Tasks> = {
          config: {},
          headers: {},
          status: 200,
          statusText: "",
          data: {
            data: {
              tasks: {
                edges: [task],
                pageInfo: { endCursor: "", hasNextPage: false, hasPreviousPage: false, startCursor: "" }
              }
            }
          }
        }
        queryClient.setQueryData([keys.taskQuery, { taskReference: task.node.taskReference }], axiosResponse)
      }

      return data
    }
  })
}

const fetchTasks = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.taskQuery, input, true, common.jobApiUrl, keys.taskQuery)
}

function fetchTaskDetails(taskReference?: string) {
  return graphQLClient.fetch(query.taskQuery, { taskReference }, true, common.jobApiUrl, keys.taskQuery)
}

function useTaskDetailQuery(enabled: boolean, taskReference?: string) {
  return useQuery([keys.taskDetailQuery, { taskReference }], () => fetchTaskDetails(taskReference), {
    enabled: enabled,
    select: (response: Response<Tasks>) => {
      return response.data.data.tasks.edges.map((m) => m.node)[0]
    }
  })
}

// ##################################### Update Task #####################################
function updateTask(input: UpdateTaskInput) {
  return graphQLClient.mutation<UpdateTaskResponse>(mutation.updateTask, input, "UpdateTask", common.jobApiUrl)
}

function useUpdateTask() {
  return useMutation(updateTask)
}

// ##################################### Add Task ########################################
function addTask(input: AddTaskInput) {
  return graphQLClient.mutation<AddTaskResponse>(mutation.addTask, input, "AddTask", common.jobApiUrl)
}

function useAddTask() {
  return useMutation(addTask)
}

// ##################################### Add Task Status #################################
function removeTask(input: RemoveTaskInput) {
  return graphQLClient.mutation<RemoveTaskResponse>(mutation.removeTask, input, "RemoveTask", common.jobApiUrl)
}

function useRemoveTask() {
  return useMutation(removeTask)
}

// ##################################### Add Task Status #################################
function addTaskStatus(input: AddTaskStatusInput) {
  return graphQLClient.mutation<AddTaskStatusResponse>(mutation.addTaskStatus, input, "AddTaskStatus", common.jobApiUrl)
}

function useAddTaskStatus() {
  return useMutation(addTaskStatus)
}

// ##################################### Update Task Status ##############################
function updateTaskStatus(input: UpdateTaskInput) {
  return graphQLClient.mutation<UpdateTaskResponse>(mutation.updateTaskStatus, input, "UpdateTaskStatus", common.jobApiUrl)
}

function useUpdateTaskStatus() {
  return useMutation(updateTaskStatus)
}

// ##################################### Remove Task Status #############################
function removeTaskStatus(input: RemoveTaskStatusInput) {
  return graphQLClient.mutation<RemoveTaskStatusResponse>(mutation.removeTaskStatus, input, "RemoveTaskStatus", common.jobApiUrl)
}

function useRemoveTaskStatus() {
  return useMutation(removeTaskStatus)
}

// ##################################### Task Status Query #####################################

function useTaskStatusQuery() {
  return useQuery(keys.taskStatusQuery, fetchTaskStatus, {
    select: (data: Response<TaskStatuses>) => data.data.data.taskStatus
  })
}

function fetchTaskStatus() {
  return graphQLClient.fetch(query.taskStatusQuery, null, true, common.jobApiUrl, keys.taskStatusQuery)
}

// ##################################### Change Task Status #####################################
function changeTaskStatus(input: ChangeTaskStatusInput) {
  return graphQLClient.mutation<ChangeTaskStatusResponse>(mutation.changeTaskStatus, input, "ChangeTaskStatus", common.jobApiUrl)
}

function useChangeTaskStatus() {
  return useMutation(changeTaskStatus)
}

export {
  useTaskQuery,
  useTaskDetailQuery,
  useAddTask,
  useUpdateTask,
  useRemoveTask,
  useAddTaskStatus,
  useUpdateTaskStatus,
  useRemoveTaskStatus,
  useTaskStatusQuery,
  useChangeTaskStatus
}
