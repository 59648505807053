import React, { useContext } from "react"

interface LoadingContextType {
  loadingPage: boolean
  setLoadingPage: (value: boolean) => void
}

export const LoadingContext = React.createContext<LoadingContextType>({
  loadingPage: false,
  setLoadingPage: () => {},
})

export const useLoading = () => {
  const { loadingPage, setLoadingPage } = useContext(LoadingContext)

  return { loadingPage, setLoadingPage }
}
