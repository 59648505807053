import React, { useState } from "react"
import { useUnreadNotificationCount } from "hooks/notification/notification"
import { useUserDetail } from "hooks/identity/user"
import { common } from "common"
import NotificationBar from "components/notification/notification-bar"
import { useAuth } from "react-oidc-context"
import { clearLogoutData } from "../../utils/extensions"

interface Props {
  sidebarOpen: boolean
  onSidebarToggled: () => void
}

const Navbar = ({ sidebarOpen, onSidebarToggled }: Props) => {
  const { data: user, isSuccess } = useUserDetail()
  const { data: notificationCount } = useUnreadNotificationCount()
  const [openNotifications, setOpenNotifications] = useState(false)
  const auth = useAuth()

  function signOut() {
    clearLogoutData()
    auth.signoutRedirect({ extraQueryParams: { "ReactJs": "ReactJs" } }).then(value => {
      console.log("NavBar.tsx -> signoutRedirect -> success")
    }).catch(reason => {
      console.log("NavBar.tsx -> signoutRedirect -> failed", reason)
    })
  }

  return (
    <>
      <div className="topbar flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white">
          <div className="flex-1 px-2 flex justify-between">
            <div className="flex-1 flex">
              <button onClick={onSidebarToggled}
                      className="border border-primary-900 rounded-md px-2 my-4 focus:outline-none">
                <i className={`fal text-primary-900 ${sidebarOpen ? "fa-angle-right" : "fa-angle-left"}`} />
              </button>
            </div>
            <div className="mr-10 flex items-center flex-row justify-center space-x-6">
              <div className="inline-block relative">
                <button
                  onClick={() => setOpenNotifications(true)}
                  id="open-sidebar"
                  className="count-info p-2 text-gray-600 rounded-full hover:bg-primary-50 hover:text-primary-900"
                >
                  <span>
                    <svg
                      className="h-5 w-5 inline-block"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M224 480c-17.66 0-32-14.38-32-32.03h-32c0 35.31 28.72 64.03 64 64.03s64-28.72 64-64.03h-32c0 17.65-14.34 32.03-32 32.03zm209.38-145.19c-27.96-26.62-49.34-54.48-49.34-148.91 0-79.59-63.39-144.5-144.04-152.35V16c0-8.84-7.16-16-16-16s-16 7.16-16 16v17.56C127.35 41.41 63.96 106.31 63.96 185.9c0 94.42-21.39 122.29-49.35 148.91-13.97 13.3-18.38 33.41-11.25 51.23C10.64 404.24 28.16 416 48 416h352c19.84 0 37.36-11.77 44.64-29.97 7.13-17.82 2.71-37.92-11.26-51.22zM400 384H48c-14.23 0-21.34-16.47-11.32-26.01 34.86-33.19 59.28-70.34 59.28-172.08C95.96 118.53 153.23 64 224 64c70.76 0 128.04 54.52 128.04 121.9 0 101.35 24.21 138.7 59.28 172.08C421.38 367.57 414.17 384 400 384z"></path>
                    </svg>
                  </span>
                  {notificationCount !== undefined && notificationCount > 0 && (
                    <span className="label bg-accent_red-900 text-white rounded-full">{notificationCount}</span>
                  )}
                </button>
              </div>

              <a href={common.angularJsLinks.profile} className="focus:outline-none">
                {isSuccess ?
                  <img className="h-8 w-8 rounded-full" src={user.staffDetail.profileImageUrl} alt="" /> : null}
              </a>

              <button
                onClick={signOut}
                className="p-2 rounded-full focus:outline-none text-gray-600 hover:bg-primary-50 hover:text-primary-900"
              >
                <span>
                  <svg
                    className="h-5 w-5 inline-block"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <NotificationBar open={openNotifications} onClose={() => setOpenNotifications(false)}
                       unreadCount={notificationCount} />
    </>
  )
}

export default Navbar
