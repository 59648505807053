import { useQuery, useMutation, MutationFunction } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { ErrorResponse, Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import {
  AddOrUpdateTemplateInput,
  RemoveTemplateInput,
  RemoveTemplateResponse,
  TemplateQueryInput,
  Templates,
  TemplateTypes,
} from "components/setting/template/05_sharedModel/template"
import { AxiosError } from "axios"

function useTemplate(enabled: boolean, input?: TemplateQueryInput) {
  return useQuery([keys.templateQuery, input], () => fetchTemplates(input), {
    enabled: enabled,
    select: (data: Response<Templates>) => data.data.data.templates,
  })
}

function fetchTemplates(input?: TemplateQueryInput) {
  return graphQLClient.fetch(query.templateQuery, input, true, common.organisationApiUrl, keys.templateQuery)
}

function addOrUpdateTemplate(input: AddOrUpdateTemplateInput) {
  return graphQLClient.mutation(mutation.addOrUpdateTemplate, input, "AddOrUpdateTemplate", common.organisationApiUrl)
}

function useAddOrUpdateTemplate() {
  return useMutation(addOrUpdateTemplate)
}

function removeTemplate(input: RemoveTemplateInput) {
  return graphQLClient.mutation<RemoveTemplateResponse>(
    mutation.removeTemplate,
    input,
    "RemoveTemplate",
    common.organisationApiUrl
  )
}

function useRemoveTemplate() {
  return useMutation(removeTemplate)
}

function useTemplateType() {
  return useQuery(keys.templateQuery, fetchTemplateTypes, {
    select: (data: Response<TemplateTypes>) => data.data.data.templateTypes.filter((m) => m.isVisible),
  })
}

function fetchTemplateTypes() {
  return graphQLClient.fetch(query.templateTypeQuery, null, true, common.organisationApiUrl, keys.templateTypeQuery)
}

export { useTemplate, useAddOrUpdateTemplate, useRemoveTemplate, useTemplateType }
