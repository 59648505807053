export const itemQuery = {
  itemSearchQuery: `query ItemSearchQuery(
    $storageReference: String,
    $searchTerm: String,
    $includeTax: String
  ) {
    itemSearch(  
      storageReference: $storageReference
      searchTerm: $searchTerm
      includeTax: $includeTax
    ) {      
          itemReference
          itemCode
          itemName
          itemDescription
          salePrice
          saleTax {
              taxReference
              name
              effectiveRate
          }
          purchaseTax {
              taxReference
              name
              effectiveRate
          }         
      }
  }`,
}
