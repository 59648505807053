import { keys } from "constants/keys"
import { InfiniteData, useInfiniteQuery, useMutation, useQuery } from "react-query"
import { common } from "common"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import { UnreadNotifications } from "models/notification/unreadNotificationCount"
import {
  NotificationHistories,
  NotificationHistory,
  NotificationHistoryQueryInput,
  RemoveNotificationHistoryInput,
  SetNotificationHistoryToReadInput,
} from "models/notification/notificationHistory"
import { ReactQueryParams } from "models/shared/common"

function useUnreadNotificationCount() {
  return useQuery(keys.unreadNotificationQuery, fetchUnreadNotificationCount, {
    select: (response: Response<UnreadNotifications>) =>
      response.data.data.unreadNotifications.totalUnreadNotification,
  })
}

const fetchUnreadNotificationCount = () => {
  return graphQLClient.fetch(
    query.unreadNotificationQuery,
    null,
    true,
    common.notificationApiUrl,
    keys.unreadNotificationQuery
  )
}

let notifications: NotificationHistory[] = []

function useNotifications(input: NotificationHistoryQueryInput) {
  const result = useInfiniteQuery([keys.notifications, input], fetchNotifications, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.notificationHistory.pageInfo.hasNextPage) {
        return lastPage?.data.data.notificationHistory.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<NotificationHistories>>) => setNotificationData(data),
  })
  return { ...result, notifications: notifications }
}

const fetchNotifications = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(
    query.notificationHistoryQuery,
    input,
    true,
    common.notificationApiUrl,
    "NotificationHistoryQuery"
  )
}

function setNotificationData(data: InfiniteData<Response<NotificationHistories>>) {
  notifications = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.notificationHistory.edges.map((item) => item.node)
    notifications = notifications.concat(pageData)
  })
}

const fetchReminderNotifications = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(
    query.notificationHistoryQuery,
    input,
    true,
    common.notificationApiUrl,
    "NotificationHistoryQuery"
  )
}

let reminders: NotificationHistory[] = []

function useReminderNotifications(input: NotificationHistoryQueryInput) {
  const result = useInfiniteQuery([keys.reminderNotifications, input], fetchReminderNotifications, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.notificationHistory.pageInfo.hasNextPage) {
        return lastPage?.data.data.notificationHistory.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<NotificationHistories>>) => setReminderData(data),
  })
  return { ...result, reminders: reminders }
}

function setReminderData(data: InfiniteData<Response<NotificationHistories>>) {
  reminders = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.notificationHistory.edges.map((item) => item.node)
    reminders = reminders.concat(pageData)
  })
}

function removeNotification(input: RemoveNotificationHistoryInput) {
  return graphQLClient.mutation(
    mutation.removeNotification,
    input,
    "RemoveNotificationHistory",
    common.notificationApiUrl
  )
}

function useRemoveNotification() {
  return useMutation(removeNotification)
}

function readNotification(input: SetNotificationHistoryToReadInput) {
  return graphQLClient.mutation(
    mutation.readNotification,
    input,
    "SetNotificationHistoryToRead",
    common.notificationApiUrl
  )
}

function useReadNotification() {
  return useMutation(readNotification)
}

function readAllNotification(input: any) {
  return graphQLClient.mutation(
    mutation.readAllNotifications,
    input,
    "SetAllNotificationsToRead",
    common.notificationApiUrl
  )
}

function useReadAllNotification() {
  return useMutation(readAllNotification)
}

export {
  useUnreadNotificationCount,
  useNotifications,
  useReminderNotifications,
  useReadAllNotification,
  useReadNotification,
  useRemoveNotification,
}
