export const leaveStatus = {
  pending: {
    leaveStatusReference: "57AF94EE-E370-4D52-8998-A53348369D02",
    name: "Pending Approval",
    color: "#d1d1d1",
  },
  approved: {
    leaveStatusReference: "5E8E005B-D7FB-4067-B174-BBD0F0A6452F",
    name: "Approved",
    color: "#00c799",
  },
  declined: {
    leaveStatusReference: "2CB26AF3-608E-4EBD-A052-B993D0B8F135",
    name: "Declined",
    color: "#ff4d4d",
  },
}

export const leaveStatusColor = {
  "57AF94EE-E370-4D52-8998-A53348369D02": leaveStatus.pending.color,
  "5E8E005B-D7FB-4067-B174-BBD0F0A6452F": leaveStatus.approved.color,
  "2CB26AF3-608E-4EBD-A052-B993D0B8F135": leaveStatus.declined.color,
}
