import { action, makeObservable, observable } from "mobx"
import { InvoiceQueryInput } from "components/invoices/05_sharedModel/InputModels"
import { Invoice, Invoices, InvoiceStatus } from "components/invoices/05_sharedModel/invoiceModel"
import { InfiniteData } from "react-query"
import { Response } from "models/shared/response"
import { MultiSelectObservable } from "library/models/selectObservable"
import { StaffSelectionObservable } from "library/components/staffSelection"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { DateRangeObservable } from "library/observables/dateRangePicker"
import { toUtcDateTime } from "common"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"

export class InvoiceListModel {
  invoiceList: Invoice[] = []
  invoiceNumberSearch: TextObservable
  saleStaff: StaffSelectionObservable
  client: ClientSelectionObservable
  invoiceStates: MultiSelectObservable<InvoiceStatus>
  dueDateRange: DateRangeObservable
  createdDateRange: DateRangeObservable
  invoiceDateRange: DateRangeObservable
  isSent: BooleanObservable
  isDisputed: BooleanObservable

  constructor() {
    makeObservable(this, {
      invoiceList: observable,
      setInvoiceList: action
    })
    this.invoiceNumberSearch = new TextObservable("")
    this.saleStaff = new StaffSelectionObservable()
    this.client = new ClientSelectionObservable()
    this.invoiceStates = new MultiSelectObservable<InvoiceStatus>()
    this.dueDateRange = new DateRangeObservable()
    this.createdDateRange = new DateRangeObservable()
    this.invoiceDateRange = new DateRangeObservable()
    this.isDisputed = new BooleanObservable()
    this.isSent = new BooleanObservable()
  }

  getFilter() {
    const obj: InvoiceQueryInput = {
      first: 10,
      after: "",
      invoiceNumber: this.invoiceNumberSearch.text ?? "",
      invoiceReference: "",
      clientReference: this.client.clientReference ?? "",
      invoiceStatusReferences: this.invoiceStates.selectedOptions.map((m) => m.invoiceStatusReference),
      invoiceTemplateReference: "",
      overdueInvoicesOnly: "false",
      saleStaffReference: this.saleStaff.staffReference ?? "",
      createdDateTimeFrom: toUtcDateTime(this.createdDateRange.start),
      createdDateTimeTo: toUtcDateTime(this.createdDateRange.end),
      dueDateTimeFrom: toUtcDateTime(this.dueDateRange.start),
      dueDateTimeTo: toUtcDateTime(this.dueDateRange.end),
      invoiceDateTimeFrom: toUtcDateTime(this.invoiceDateRange.start),
      invoiceDateTimeTo: toUtcDateTime(this.invoiceDateRange.end),
      sentInvoicesOnly: this.isSent.value ? "true" : "false",
      disputedInvoicesOnly: this.isDisputed.value ? "true" : "false"
    }
    return obj
  }

  setInvoiceList(data: InfiniteData<Response<Invoices>>) {
    let tempList: Invoice[] = []
    data.pages.forEach((page) => {
      const pageData = page.data.data.invoices.edges.map((item) => item.node)
      tempList = tempList.concat(pageData)
    })
    this.invoiceList = tempList
  }
}
