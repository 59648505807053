import React, { useEffect, useRef, useState } from "react"
import { File } from "models/file/file"
import { Modal } from "library/styled/modal"
import FileViewer from "components/file/previewFile/fileViewer"
import { HamburgerMenu } from "library/dropdownMenu/hamburgerMenu"
import ToolTip from "library/styled/tooltip"

interface Props {
  isOpen: boolean
  onClose: () => void
  fileUrl?: string
  file?: File
}

const imageExtensions = [".jpg", ".jpeg", ".gif", ".png", ".apng", ".svg", ".bmp", ".ico"]

const PreviewFileModal = ({ isOpen, onClose, fileUrl, file }: Props) => {
  const [url, setUrl] = useState("")

  useEffect(() => {
    if (isOpen && fileUrl) setUrl(`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl ?? "")}&embedded=true`)
  }, [isOpen, fileUrl])

  const downloadFile = () => {
    if (fileUrl) {
      const link = document.createElement("a")
      link.href = fileUrl
      link.download = file?.fileName ?? ""
      link.click()
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="File Preview" width="max-w-4xl">
        <div className="max-modal-height overflow-y-auto min-h-[30rem]">
          {fileUrl && (
            <>
              {isImage(file?.extension.toLowerCase()) ? (
                <img className="" src={fileUrl} alt={file?.fileName} />
              ) : (
                <FileViewer url={url} />
              )}
            </>
          )}
        </div>
        <div className="absolute bottom-8 right-8">
          <ToolTip text="Download" showArrow={false} delay={0.5}>
            <button onClick={downloadFile} className="bg-primary-900 rounded-full p-4 shadow hover:shadow-xl">
              <i className="far fa-download fa-xl text-white"></i>
            </button>
          </ToolTip>
        </div>
      </Modal>
    </>
  )
}

export default PreviewFileModal

function isImage(ext?: string) {
  if (ext) return imageExtensions.includes(ext)
}
