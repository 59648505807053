import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { action, runInAction } from "mobx"
import { observer } from "mobx-react"
import { debounce } from "lodash"
import { TextObservable } from "../textInputGroup/textInputGroup"

interface Props {
  name: string
  placeholder?: string
  value: TextObservable
  onChange?: () => void
}

const FilterInputText = ({ name, placeholder = "Optional", value, onChange }: Props) => {
  const [text, setText] = useState("")
  const debouncedSearch = useRef(
    debounce((str: string) => {
      runInAction(() => (value.text = str))
    }, 1000)
  ).current

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  return (
    <div className="relative rounded-md shadow-sm">
      <input
        className="border-transparent shadow-md focus:ring-primary-100 focus:border focus:border-primary-100 block sm:text-sm rounded-md block w-full"
        type="text"
        name={name}
        id={name}
        placeholder={placeholder}
        value={text}
        onChange={handleInputChange}
      />
      {text ? (
        <div className="flex absolute inset-y-0 right-0 items-center pr-3">
          <button
            id="clearFilter"
            onClick={() => {
              setText("")
              runInAction(() => {
                value.text = ""
              })
            }}
          >
            <i className="fas fa-xmark text-gray-400" aria-hidden="true" />
          </button>
        </div>
      ) : (
        <div className="hidden lg:flex pointer-events-none absolute inset-y-0 right-0 items-center pr-3">
          <i className="fa-light fa-magnifying-glass text-gray-400"></i>
        </div>
      )}
    </div>
  )
}

export default observer(FilterInputText)
