import { action, makeObservable, observable } from "mobx"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { Note } from "components/shared/notes/03_sharedModel/note"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"
import { NoteType } from "components/shared/notes/03_sharedModel/noteType"
import { SelectObservable } from "library/selectInputGroup/selectInputGroup"

export class EditNoteModel {
  noteType: SelectObservable<NoteType>
  description: TextObservable
  generalErrors: GeneralError
  constructor() {
    makeObservable(this, {
      generalErrors: observable,
      handleApiErrors: action,
      reset: action,
      setNoteType: action,
    })
    this.noteType = new SelectObservable<NoteType>()
    this.description = new TextObservable()
    this.generalErrors = {}
  }

  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "description")) this.description.errorMessage = v.description
      else this.generalErrors[v.property] = v.description
    })
  }

  setNoteType(noteType: NoteType) {
    this.noteType.selectedOption = noteType
  }

  reset(note?: Note) {
    this.description.text = note?.description
    this.description.errorMessage = undefined
    this.generalErrors = {}
  }
}
