import React from "react"
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu"
import { KeyValuePair } from "models/shared/common"

interface Props<T> {
  options?: T[]
  button: React.ReactNode | JSX.Element
  onSelect: (option: T) => void
  optionLabel: keyof T
}

const MenuButtonComponent = <T extends KeyValuePair>({ options, button, onSelect, optionLabel }: Props<T>) => {
  return (
    <>
      <Menu menuButton={<MenuButton>{button}</MenuButton>} transition align="end">
        {options?.map((item, index) => (
          <MenuItem key={index} onClick={() => onSelect(item)}>
            {item[optionLabel]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MenuButtonComponent
