import React from "react"
import { Fragment, useState } from "react"
import { toLocaleDate, toLocaleDateTime } from "common"
import { ColumnType } from "constants/columns"
import { useFeature } from "context/feature-context"
import { DataGridColumn } from "models/shared/column"
import { List } from "library/styled/list"
import { ButtonSecondarySmall, TBody, Td, Th, THead } from "library/styled/styled"
import { KeyValuePair } from "models/shared/common"
import ListColumnSettingModal from "library/components/listColumnSettingModal"
import { ThreeDotsSpinner } from "library/styled/spinners"
import { safeSubString, toCurrency } from "utils/extensions"
import { useMetadata } from "context/metadata-context"
import Conditional from "library/styled/conditional"
import { fileType } from "constants/fileType"
import ToolTip from "./tooltip"
import Badge from "./badges"
import { TaskStatus } from "../../components/Task/04_sharedModel/taskModel"
import { Link } from "react-router-dom"
import InputGroupSelect from "../observables/inputGroupSelect"
import { SelectObservable } from "../selectInputGroup/selectInputGroup"

interface Props<T> {
  title: string
  buttonText: string
  columns: DataGridColumn[]
  showSetting?: boolean
  data: T[]
  dataKey: keyof T
  onRowClick: (item: T) => void
  hasNextPage: boolean | undefined
  fetchNextPage: () => void
  onColumnChange: (cols: DataGridColumn[]) => void
  statusColor?: any
  action?: any
  showNavLink?: boolean
  isLoading: boolean
  isFetchingNextPage: boolean
  noItemFoundMessage: string
  secondaryButton?: JSX.Element,
  // taskStatus?: TaskStatus[],
  // onStatusClick?: (taskReference: string) => void
}

const DataGridNew = <T extends KeyValuePair>({
                                               title,
                                               buttonText,
                                               columns,
                                               showSetting = true,
                                               data,
                                               dataKey,
                                               onRowClick,
                                               hasNextPage,
                                               fetchNextPage,
                                               onColumnChange,
                                               statusColor = {},
                                               action,
                                               showNavLink = false,
                                               isLoading,
                                               isFetchingNextPage,
                                               noItemFoundMessage,
                                               secondaryButton
                                             }: Props<T>) => {
  const [settingModal, setSettingModal] = useState(false)
  const { enabledFeatures } = useFeature()
  const { organisation } = useMetadata()

  function getTextComponent(item: T, column: DataGridColumn<any>) {
    const value = getValue(item, column.keys)
    if (!value) return
    return getToolTipComponent(column, value)
  }

  function getToolTipComponent(column: DataGridColumn<any>, value: any) {
    if (column.toolTip &&
      value.length > column.toolTip.tooltipDisplayThreshold) {

      // check and reduce the tooltip character count.
      let toolTipValue = value
      if (toolTipValue.length > column.toolTip.maxTooltipCharacters)
        toolTipValue = value.substring(0, column.toolTip.maxTooltipCharacters) + ".."

      return <>
        <ToolTip text={toolTipValue} placement={"topLeft"} delay={0}>
          <span>{value}</span>
        </ToolTip>
      </>
    }
    return value
  }

  return (
    <Fragment>
      <List
        title={title}
        buttonText={buttonText}
        action={action}
        settingIcon={true}
        settingAction={() => setSettingModal(true)}
        showNavLink={showNavLink}
        SecondaryButton={secondaryButton}
      >
        <div className="tbl-header">
          <table cellPadding="0" cellSpacing="0" border={0} className="table-fixed">
            <THead>
              <tr>
                {columns.map((column) => (
                  <Conditional
                    key={column.id}
                    when={column.isEnabled && (column.featureName === undefined || enabledFeatures?.[column.featureName])}
                  >
                    <Th className={`border border-blue_grey-200 ${column.className}`}>{column.name}</Th>
                  </Conditional>
                ))}
              </tr>
            </THead>
          </table>
        </div>
        <div className="overflow-y-auto table-scroll-max-height tbl-content ring-1 ring-black ring-opacity-5">
          <table cellPadding="0" cellSpacing="0" border={0} className={`table-fixed ${isLoading ? "opacity-50" : ""}`}>
            <TBody>
              {!isLoading && data?.length === 0 && (
                <tr>
                  <Td className="text-center">{noItemFoundMessage}</Td>
                </tr>
              )}
              {isLoading && (data === undefined || data.length === 0) && (
                <tr className="h-99">
                  <Td className="text-center"></Td>
                </tr>
              )}
              {data?.map((item: T, idx: number) => (
                <tr
                  className={`cursor-pointer hover:bg-blue_grey-100 ${idx % 2 === 0 ? undefined : "bg-blue_grey-50"}`}
                  key={item[dataKey]}
                >
                  {columns.map((column: DataGridColumn) => (
                    <Conditional
                      key={column.id}
                      when={column.isEnabled && (column.featureName === undefined || enabledFeatures?.[column.featureName])}
                    >
                      {column.columnTypeId === ColumnType.LabelId.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <div className="flex items-center">
                            <div className="rounded-lg bg-secondary-100">
                              <div
                                className="text-primary-900 font-medium text-xs px-2 py-1">{getValue(item, column.keys)}</div>
                            </div>
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.Text.Id &&
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          {getTextComponent(item, column)}
                        </Td>}
                      {column.columnTypeId === ColumnType.Email.Id &&
                        <Td onClick={() => onRowClick(item)} key={column.id}> {getValue(item, column.keys)}</Td>}
                      {column.columnTypeId === ColumnType.Date.Id && (
                        <Td onClick={() => onRowClick(item)}
                            key={column.id}>{toLocaleDate(getValue(item, column.keys))}</Td>
                      )}
                      {column.columnTypeId === ColumnType.Boolean.Id && (
                        <Td onClick={() => onRowClick(item)}
                            key={column.id}> {getValue(item, column.keys) ? "Yes" : "No"}</Td>
                      )}
                      {column.columnTypeId === ColumnType.BooleanInReverse.Id && (
                        <Td onClick={() => onRowClick(item)}
                            key={column.id}> {getValue(item, column.keys) ? "No" : "Yes"}</Td>
                      )}
                      {column.columnTypeId === ColumnType.Description.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}> {getTextComponent(item, column)}</Td>
                      )}
                      {column.columnTypeId === ColumnType.DateTime.Id && (
                        <Td onClick={() => onRowClick(item)}
                            key={column.id}>{toLocaleDateTime(getValue(item, column.keys))}</Td>
                      )}
                      {column.columnTypeId === ColumnType.CurrencyLabel.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id} className={column.className}
                            truncate={false}>
                          {toCurrency(
                            getValue(item, column.keys),
                            item[column.helperKey as keyof T],
                            `${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`
                          )}
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.CurrencyFormat.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id} className={column.className}
                            truncate={false}>
                          {toCurrency(
                            getValue(item, column.keys),
                            undefined,
                            `${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`
                          )}
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.LabelType.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <div className="flex items-center">
                            <div className={`rounded-full ${column.className} bg-opacity-20`}>
                              <div className="text-xs px-2 py-1"> {getValue(item, column.keys)}</div>
                            </div>
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.Status.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <div className="flex items-center">
                            <div
                              className={`inline-flex items-center rounded-md `}
                              style={{
                                backgroundColor: `${statusColor[item[column.helperKey as keyof T]]?.bgColor}40`,
                                color: `${statusColor[item[column.helperKey as keyof T]]?.textColor}`
                              }}
                            >
                              <div className="text-xs px-3 py-1 text-gray-900"> {getValue(item, column.keys)}</div>
                            </div>
                          </div>
                        </Td>
                      )}
                      {/*{column.columnTypeId === ColumnType.EditableStatus.Id && (*/}
                      {/*  <Td key={column.id}>*/}
                      {/*    <InputGroupSelect<TaskStatus>*/}
                      {/*      value={new SelectObservable<TaskStatus>({*/}
                      {/*        taskStatusReference: item.taskStatusReference,*/}
                      {/*        name: item.taskStatusName*/}
                      {/*      })}*/}
                      {/*      onChange={item1 => {*/}
                      {/*        if (onStatusClick && item1) {*/}
                      {/*          onStatusClick(item1.taskStatusReference)*/}
                      {/*        }*/}
                      {/*      }}*/}
                      {/*      id=""*/}
                      {/*      options={taskStatus}*/}
                      {/*      optionValue={"taskStatusReference"}*/}
                      {/*      optionLabel={"name"}*/}
                      {/*      type={"grid"}*/}
                      {/*    />*/}
                      {/*  </Td>*/}
                      {/*)}*/}
                      {column.columnTypeId === ColumnType.profileName.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <div className="flex items-center">
                            <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                            <div className="ml-2">{item.givenName + " " + item.familyName}</div>
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.StaffName.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <div className="flex items-center">
                            <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                            <div className="ml-2">{item.staffName}</div>
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.FileSize.Id && (
                        <Td
                          onClick={() => onRowClick(item)}>{(getValue(item, column.keys) / 1000000).toString().substring(0, 4)} Mb</Td>
                      )}
                      {column.columnTypeId === ColumnType.StaffMemberList.Id && (
                        <Td onClick={() => onRowClick(item)}>
                          <div className="flex overflow-hidden truncate -space-x-1">
                            {getValue(item, column.keys)?.map((option: any) => (
                              <ToolTip text={option.name} key={option[column.helperKey as keyof T]}
                                       placement={"topLeft"} delay={0}>
                                    <span key={option[column.helperKey as keyof T]}
                                          className="inline-flex items-center">
                                          <img
                                            alt=""
                                            src={option[column.helperKey as keyof T]}
                                            className="h-7 w-7 rounded-full inline-block ring-2 ring-white"
                                          />
                                        </span>
                              </ToolTip>
                            ))}
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.FileNameIcon.Id && (
                        <Td onClick={() => onRowClick(item)}>
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              {fileType[item.extension.toLowerCase()] === undefined ? (
                                <i className="fal fa-file text-xl"></i>
                              ) : (
                                <i
                                  className={`${fileType[item.extension.toLowerCase()]?.icon} text-xl`}
                                  style={{ color: fileType[item.extension.toLowerCase()]?.style?.color }}
                                ></i>
                              )}
                            </div>
                            <div className="ml-2">
                              <div
                                className="text-sm break-all text-gray-900">{getToolTipComponent(column, item.fileName)}</div>
                            </div>
                          </div>
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.Tags.Id && (
                        <Td onClick={() => onRowClick(item)}>
                          {getValue(item, column.keys)?.map((option: any) => (
                            <Badge key={option[column.helperKey as keyof T]}
                                   name={option[column.helperKey as keyof T]}></Badge>
                          ))}
                        </Td>
                      )}
                      {column.columnTypeId === ColumnType.TaskEntity.Id && (
                        <Td onClick={() => onRowClick(item)} key={column.id}>
                          <Badge
                            name={item.entityName ? item.entityTypeName + " | " + safeSubString(item.entityName, 9) : item.entityTypeName}></Badge>

                        </Td>
                      )}
                    </Conditional>
                  ))}
                </tr>
              ))}
            </TBody>
          </table>
        </div>
        {hasNextPage && (
          <div
            className="bg-white flex flex-row justify-center px-6 py-3 rounded-b-md ring-1 ring-black ring-opacity-5">
            <ButtonSecondarySmall disabled={!hasNextPage} onClick={fetchNextPage}>
              Load More
              {isFetchingNextPage && <i className="fas fa-spinner-third fa-spin ml-2"></i>}
            </ButtonSecondarySmall>
          </div>
        )}
      </List>

      <ListColumnSettingModal
        isOpen={settingModal}
        onClose={() => setSettingModal(false)}
        columns={columns}
        onChange={onColumnChange}
      />

      {isLoading && <ThreeDotsSpinner />}
    </Fragment>
  )
}
export default DataGridNew

// returns column value
const getValue = (item: any, keys: any[]) => {
  return keys[1] ? item[keys[0]]?.[keys[1]] : item[keys[0]]
}
