import React from "react"
import styled from "@emotion/styled/macro"
import * as colors from "styles/colors"
import FosLogo from "assets/FosColoredTransparent.png"
import tw from "twin.macro"

interface ErrorMessageProps {
  error: any
}

interface StyledProps {
  isLink?: boolean
  py?: number
  smallText?: boolean
  cols?: number
  addMargin?: boolean
  addPadding?: boolean
  breakAll?: boolean
  col?: string
  truncate?: boolean
  width?: number
  addExtraPadding?: boolean
}

interface FormProps {
  addVerticalPadding?: boolean
  fixHeight?: boolean
}

function FullPageLoading() {
  return (
    <div className="mt-8 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img alt="FOS" src={FosLogo} style={{ paddingLeft: 100 }} />
        <div className="mt-6 text-center text-xl text-gray-900" style={{ paddingRight: 52 }}>
          Loading settings please wait...
        </div>
      </div>
    </div>
  )
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <div role="alert" className="text-red-600 h-full flex flex-col justify-center items-center">
      <span>There was an error: </span>
      <pre className="whitespace-pre m-0 -mb-1 block">{error.message}</pre>
    </div>
  )
}

const ErrorFallback = ({ error }: ErrorMessageProps) => {
  return <ErrorMessage error={error} />
}

const FullPageErrorFallback = ({ error }: ErrorMessageProps) => {
  return (
    <div role="alert" className="text-red-500 h-screen flex flex-col justify-center items-center">
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

//#region Cards

interface CardProps {
  isList?: boolean
  children: React.ReactNode
  width?: string
}

interface CardHeaderProps {
  title: string
  buttonText?: string
  action?: () => void
  showAsLink?: boolean
  hasButton?: boolean
}

interface CardBodyProps {
  overflowHidden?: boolean
  maxHeight?: number
  children: React.ReactNode
}

const Card = ({ children, isList = false, width = "max-w-3xl sm:max-w-4xl" }: CardProps) => {
  return <div className={`px-4 pt-4 sm:px-6 mx-auto ${isList ? "" : width}`}>{children}</div>
}

const CardHeader = ({ title, buttonText = "Add", action, showAsLink = false, hasButton = true }: CardHeaderProps) => {
  return (
    <div
      className="bg-white px-4 py-4 border-b border-gray-200 sm:px-6 rounded-t-md shadow ring-1 ring-black ring-opacity-5">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        </div>
        {hasButton && (
          <div className="ml-4 mt-2 flex-shrink-0">
            {!showAsLink && (
              <ButtonSecondary onClick={action}>
                <i className="fal fa-plus mr-2"></i>
                {buttonText}
              </ButtonSecondary>
            )}
            {showAsLink && <A onClick={action}>{buttonText}</A>}
          </div>
        )}
      </div>
    </div>
  )
}

const CardBody = ({ children, overflowHidden = true }: CardBodyProps) => {
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="block min-w-full align-middle">
          <div
            className={`bg-white shadow ring-1 ring-black ring-opacity-5 rounded-b-md ${
              overflowHidden ? "" : `overflow-y-auto card-scroll-max-height`
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
//#endregion

//#region Tables
const Table = styled.table`
  ${tw`min-w-full`}
`
const THead = styled.thead`
  ${tw`bg-blue_grey-100`}
`
const Th = styled.th`
  ${tw`py-3.5 pl-4 pr-3 text-left text-sm text-gray-700 sm:pl-6`}
  ${({ width }: StyledProps) => width && ((width === 2 && tw`w-1/2`) || (width === 3 && tw`w-1/3`) || (width === 4 && tw`w-1/4`))}
`
const TBody = styled.tbody`
  ${tw`bg-white`}
`
const Td = styled.td`
  ${tw`pl-4 text-sm text-gray-500 sm:pl-6 break-words align-middle`};
  ${({ isLink = false }: StyledProps) => isLink && tw`relative pr-4 text-right font-medium sm:pr-6`}
  ${({ py = 4 }) => (py === 4 ? tw`py-2.5` : tw`py-2`)}
  ${({ truncate = true }: StyledProps) => truncate && tw`truncate`}
  ${({ width }: StyledProps) => width && ((width === 2 && tw`w-1/2`) || (width === 3 && tw`w-1/3`) || (width === 4 && tw`w-1/4`))}
`
//#endregion

//#region Anchor tag
const A = styled.a`
  ${tw`text-primary-800 hover:text-primary-900 cursor-pointer text-sm`}
`

const ButtonLink = styled.button`
  ${tw`text-primary-800 hover:text-primary-900 cursor-pointer focus:outline-none`}
`

const ErrorInline = styled.div`
  ${tw`text-red-500 pt-1`}
  ${({ smallText = false }: StyledProps) => (smallText ? tw`text-xs` : tw`text-sm`)}
`
//#endregion

//#region Date display
const Dl = styled.dl`
  ${tw`grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 p-6`}
  ${({ addExtraPadding = true }: StyledProps) => addExtraPadding && tw`gap-y-8`}
`
const DlGroup = styled.div`
  ${({ cols = 1 }: StyledProps) => (cols === 1 ? tw`sm:col-span-1` : tw`col-span-2`)}
`
const Dt = styled.div`
  ${tw`text-sm font-medium text-gray-900`}
`
const Dd = styled.div`
  ${tw`mt-1 text-sm text-gray-600`}
  ${({ breakAll = false }: StyledProps) => (breakAll ? tw`break-all` : "")}
  ${({ isLink = false }: StyledProps) => isLink && tw`text-primary-800`}
`
//#endregion

//#region Inputs
const TextInput = styled.input`
  ${tw`shadow-sm focus:ring-primary-100 focus:border-primary-100 block w-full sm:text-sm border-gray-300 rounded-md py-1.5`}
  ${({ addMargin = true }: StyledProps) => addMargin && tw`mt-1`}
`

const InlineEditInput = styled.input`
  ${tw`focus:ring-primary-100 focus:border focus:border-primary-100 block w-full sm:text-sm rounded-md py-1.5`}
`
const CheckboxInput = styled.input`
  ${tw`focus:ring-primary-800 h-4 w-4 text-primary-900 border-gray-300 rounded`}
`
const TextArea = styled.textarea`
  ${tw`mt-1 shadow-sm focus:ring-primary-100 focus:border-primary-100 block w-full sm:text-sm border-gray-300 rounded-md py-1.5`}
`
const LineItemTextInput = styled.input`
  ${tw`border-0 w-full sm:text-sm px-0 text-center`}
`

const LineItemTextAreaInput = styled.textarea`
  ${tw`mt-1 border-0 w-full h-full sm:text-sm py-1.5`}
`
const Label = styled.label`
  ${tw`block text-sm font-medium text-gray-700`}
`
const LabelLight = styled.label`
  ${tw`block text-sm text-gray-700`}
`

const Form = styled.form`
  ${tw`px-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6`}
  ${({ addVerticalPadding = true }: FormProps) => addVerticalPadding && tw`py-6`}
  ${({ fixHeight = false }: FormProps) => fixHeight && tw`overflow-y-auto max-h-[calc(100vh - 200px)]`}
`
const FormGroup = styled.div`
  ${({ cols = 3 }: StyledProps) => (cols === 3 ? tw`sm:col-span-3` : tw`sm:col-span-6`)}
`
const ColSpan = styled.div`
  ${({ cols = 3 }: StyledProps) => (cols === 3 ? tw`sm:col-span-3` : tw`sm:col-span-6`)}
`

const ButtonSecondarySmall = styled.button`
  ${tw`border border-gray-300 bg-white font-medium py-1 px-3 rounded-md text-sm text-gray-500 text-center hover:shadow active:shadow-inner`}
`

const ButtonSecondary = styled.button`
  ${tw`border border-gray-300 bg-white font-medium py-1 px-3 py-1.5 rounded-md text-sm text-gray-500 text-center hover:shadow active:shadow-inner`}
`

const ButtonPrimary = styled.button`
  ${tw`bg-primary-900 px-3 py-1.5 rounded-md text-sm text-white text-center hover:shadow-lg`}
`

const Button = styled.button({
  color: "white",
  border: "0",
  backgroundColor: colors.primary,
  borderRadius: "5px",
  display: "inline-block",
  padding: "6px 12px",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "1.42857143",
  textAlign: "center",
  verticalAlign: "middle"
})

const ButtonLight = styled.button`
  ${tw`bg-blue-100 font-medium text-primary-900 px-3 py-1 rounded-md text-sm text-center hover:shadow-md`}
`
const Grid = styled.div`
  ${tw`p-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6`}
`
//#endregion

export {
  Button,
  ButtonSecondary,
  ButtonPrimary,
  FullPageErrorFallback,
  ErrorMessage,
  ErrorFallback,
  Table,
  THead,
  Th,
  TBody,
  Td,
  A,
  Card,
  CardHeader,
  CardBody,
  TextInput,
  Label,
  Form,
  FormGroup,
  TextArea,
  CheckboxInput,
  ErrorInline,
  Dl,
  DlGroup,
  Dd,
  Dt,
  ColSpan,
  ButtonSecondarySmall,
  Grid,
  ButtonLight,
  ButtonLink,
  FullPageLoading,
  InlineEditInput,
  LabelLight,
  LineItemTextAreaInput,
  LineItemTextInput
}
