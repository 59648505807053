import React from "react"
import { entityTypes } from "constants/entityTypes"
import CategorySetting from "components/setting/shared/category/list-category"
import TaskStatusList from "./taskStatus/taskStatus"

function TaskSetting() {
  return (
    <div className="pb-10 space-y-2.5">
      <TaskStatusList />
      <CategorySetting entityTypeReference={entityTypes.task.entityTypeReference} />
    </div>
  )
}

export default TaskSetting
