export const NumberSequenceTypes = {
  invoice: {
    id: 100,
    reference: "8A697416-1368-438B-A3A2-AAE6D61FA558",
    name: "Invoice",
  },
  payment: {
    id: 200,
    reference: "37CC298E-CBB5-4785-87F1-FF918177A457",
    name: "Payment",
  },
}
