import React, { useEffect, useState } from "react"
import { action, Reaction, reaction, runInAction } from "mobx"
import { observer } from "mobx-react"
import { NumberObservable } from "../../../library/observables/inputGroupNumber"
import { ErrorInline, LineItemTextInput } from "../../../library/styled/styled"
import { isEmpty, getFormattedDisplayValue, isProvided, isSame } from "../../../utils/extensions"
import {
  integerValidation,
  maxNumberValidation,
  minNumberValidation,
  numberValidation,
  requiredNumberValidation,
} from "utils/validation"

interface Props {
  id?: string
  value: NumberObservable
  onBlur?: () => void
  decimalsLimit?: number
  onChange?: () => void
  /** If false decimals will only display if user types them */
  alwaysShowDecimals?: boolean
  label?: string
  isRequired?: boolean
  minValue?: number
  maxValue?: number
  isNumber?: boolean
}

const LineItemNumber = ({
  value,
  decimalsLimit,
  onBlur,
  alwaysShowDecimals = false,
  id,
  onChange,
  label,
  isRequired,
  minValue,
  maxValue,
  isNumber,
}: Props) => {
  function setNumberValue() {
    if (isEmpty(value.displayValue)) {
      runInAction(() => {
        value.number = undefined
      })
      return
    }

    const newValue = getFormattedDisplayValue(value.displayValue!, alwaysShowDecimals, decimalsLimit)
    runInAction(() => {
      value.number = Number(newValue)
      value.displayValue = newValue
    })
  }

  /**
   * This method is called every single time user types in the input box.
   * However, we only return formatted number when value is not changed by onChange method.
   * Usage example, when changing price, lineItem calculate method will be called. Calculate method will call
   * SetValue method on NumberObserver which will set isUpdatedByOnChanged to false and we will format the value.
   */
  function getValue() {
    // When value is updated by on change event we can't format it.
    if (value.isUpdatedByOnChanged) return value.displayValue ?? ""

    // However, if value was set by calculating totals or any other means.
    // Then we should always format and display.
    return getFormattedDisplayValue(value.displayValue!, alwaysShowDecimals, decimalsLimit)
  }

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredNumberValidation(value.number, label, isRequired)
      if (!value.errorMessage) value.errorMessage = numberValidation(value.number, label, isNumber)
      if (!value.errorMessage) value.errorMessage = minNumberValidation(value.number, label, minValue)
      if (!value.errorMessage) value.errorMessage = maxNumberValidation(value.number, label, maxValue)
    })
  }

  return (
    <>
      <LineItemTextInput
        type={"number"}
        id={id}
        data-testid={id}
        className={"remove-ring-shadow hide-number-input-arrow"}
        value={getValue()}
        disabled={value.isDisabled}
        onBlur={action((event) => {
          setNumberValue()
          validate()
          if (onBlur) onBlur()
        })}
        onChange={action((e) => {
          // Make sure the value has changed before setting on update flag.
          if (!isSame(value.displayValue, e.target.value)) value.isUpdatedByOnChanged = true

          value.displayValue = e.target.value
          if (isProvided(value.errorMessage)) value.errorMessage = undefined
          validate(true)
          if (onChange) onChange()
        })}
      />
      {value.errorMessage && <ErrorInline data-testid={`${id}-error-label`}>{value.errorMessage}</ErrorInline>}
    </>
  )
}

export default observer(LineItemNumber)
