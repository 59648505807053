import React, { useEffect, useState } from "react"
import { SelectingEventArgs, TabComponent } from "@syncfusion/ej2-react-navigations"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import InvoiceOverview from "components/invoices/03_invoiceDetail/overview/invoiceOverview"
import { entityTypes } from "constants/entityTypes"
import NoteListTemplate from "components/shared/notes/01_noteCard/noteListTemplate"
import { useNavigate } from "react-router-dom"
import ReminderListTemplate from "components/shared/reminders/01_reminderCard/reminderListTemplate"
import DocumentListTemplate from "components/shared/documents/01_documentCard/documentListTemplate"
import EmailListTemplate from "components/invoices/03_invoiceDetail/emails/invoiceEmailList"
import SendEmailPage from "components/invoices/sendEmail/sendEmailModal"
import { invoiceEmailConfig } from "constants/emailConfiguration"
import { successToaster } from "library/styled/toasters"
import { MenuOption } from "library/dropdownMenu/hamburgerMenu"
import { HamburgerMenu } from "library/dropdownMenu/hamburgerMenu"
import { WarningAlert } from "library/styled/alerts"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { GenerateReportLinkInput, RemoveInvoiceInput } from "components/invoices/05_sharedModel/InputModels"
import { useGenerateReportLink, useInvoiceDetail, useRemoveInvoice } from "components/invoices/services/invoiceService"
import { useQueryClient } from "react-query"
import { useModalState } from "utils/modalStates"
import { invoiceStatus } from "constants/invoiceStatus"
import AddPaymentModal from "components/payment/01_addPayment/addPaymentModal"
import InvoicePreferencesDetail from "components/invoices/03_invoiceDetail/preferences/invoicePreferences"
import ToolTip from "library/styled/tooltip"
import ViewInvoicePdfModal from "components/invoices/03_invoiceDetail/viewInvoicePdf/viewInvoicePdfModal"
import { reportType } from "constants/reportType"

interface Props {
  invoiceReference?: string
  isOpenInModal?: boolean
  setModalTitle?: (title: string) => void
}

const InvoiceDetailTabs = ({ invoiceReference, isOpenInModal = false, setModalTitle }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: invoice } = useInvoiceDetail(enabled, invoiceReference)
  const [selectedTab, setSelectedTab] = useState(0)
  const [emailModal, setEmailModal] = useState(false)
  const { addModal: addPaymentModal, setAddModal: setAddPaymentModal, alert, setAlert } = useModalState<Invoice>()
  const { alert: voidInvoiceAlert, setAlert: setVoidInvoiceAlert } = useModalState<boolean>()
  const [pdfModal, setPdfModal] = useState({
    isOpen: false,
    link: ""
  })
  const { mutate: removeInvoice, isLoading: isDeleting } = useRemoveInvoice()
  const { mutate: generateLink } = useGenerateReportLink()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (invoiceReference) {
      setEnabled(true)
      setPdfModal({ ...pdfModal, link: "" })
      if (setModalTitle) setModalTitle(invoice?.invoiceNumber ?? "")
    }
  }, [invoiceReference, invoice])

  function onEmailSent() {
    setEmailModal(false)
    void queryClient.invalidateQueries([
      keys.emailsQuery,
      { entityReference: invoiceReference, entityTypeReference: entityTypes.invoice.entityTypeReference }
    ])
    successToaster(`Invoice ${invoice?.invoiceNumber} sent`)
  }

  const handleDelete = (voidInvoice: boolean) => {
    const input: RemoveInvoiceInput = {
      invoiceReference: invoice?.invoiceReference ?? "",
      concurrencyToken: invoice?.concurrencyToken ?? "",
      voidInvoice: voidInvoice
    }
    removeInvoice(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.invoiceQuery])
        successToaster(`Invoice ${invoice?.invoiceNumber} ${voidInvoice ? " voided" : " deleted"}`)
      },
      onSettled: () => {
        if (voidInvoice) {
          setVoidInvoiceAlert({ ...voidInvoiceAlert, isOpen: false })
        } else {
          setAlert({ ...alert, isOpen: false })
        }
      }
    })
  }

  const getReportLink = () => {
    const input: GenerateReportLinkInput = {
      reportReference: reportType.invoice.reference,
      reportParameters: [
        {
          normalizedName: "ir",
          value: invoiceReference ?? ""
        }
      ]
    }
    generateLink(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (response) => {
        if (response) setPdfModal({ link: response.data.data.generateReportLink.reportUrl, isOpen: true })
      }
    })
  }

  const menuOptions: MenuOption[] = [
    {
      name: "Add Payment",
      iconClass: "far fa-credit-card text-green-700",
      action: () => setAddPaymentModal(true),
      disabled: !(
        (invoice?.invoiceStatusReference === invoiceStatus.approved.reference ||
          invoice?.invoiceStatusReference === invoiceStatus.overdue.reference) &&
        invoice.amountDue > 0
      ),
      tooltip: "Payments can only be made if the invoice is approved, overdue, and has a balance due."
    },
    {
      name: "Email Invoice",
      iconClass: "far fa-envelope text-blue-500",
      action: () => setEmailModal(true)
    },
    {
      name: "Preview Invoice",
      iconClass: "far fa-eye text-teal-700",
      action: () => {
        if (pdfModal.link) setPdfModal({ ...pdfModal, isOpen: true })
        else getReportLink()
      }
    },
    {
      name: "Void",
      iconClass: "far fa-ban text-red-400",
      action: () => setVoidInvoiceAlert({ item: true, isOpen: true }),
      disabled: invoice && invoice?.totalPaidAmount > 0,
      tooltip: "Invoice with payment cannot be voided."
    },
    {
      name: "Delete",
      iconClass: "far fa-trash text-red-800",
      action: () => setAlert({ item: invoice, isOpen: true }),
      disabled: invoice && invoice?.totalPaidAmount > 0,
      tooltip: "Invoice with payment cannot be deleted."
    }
  ]

  const handleSendEmail = () => {
    setPdfModal({ ...pdfModal, isOpen: false })
    setEmailModal(true)
  }

  return (
    <>
      <TabComponent
        id="defaultTab"
        selectedItem={0}
        selecting={(args: SelectingEventArgs) => setSelectedTab(args.selectingIndex)}
        animation={{ next: { effect: "None" }, previous: { effect: "None" } }}
      >
        <div className="e-tab-header">
          <div> Overview</div>
          <div> Preferences</div>
          <div> Emails</div>
          <div> Documents</div>
          <div> Notes</div>
          <div> Reminders</div>
        </div>
        <div className={`absolute ${isOpenInModal ? "top-2 right-14" : "top-1 right-20"}`}>
          {(invoice && invoice.totalPaidAmount > 0) || invoice?.invoiceStatusReference === invoiceStatus.void.reference ? (
            <ToolTip
              text={
                invoice?.invoiceStatusReference === invoiceStatus.void.reference
                  ? "Invoices cannot be edited once voided."
                  : "Invoices cannot be edited once payments have been allocated to them."
              }
              placement="bottom"
              showArrow={false}
            >
              <button className="px-2 py-0.5 rounded-md text-gray-400">
                <i className="far fa-pen-to-square h-4 text-sm"></i>
              </button>
            </ToolTip>
          ) : (
            <ToolTip text="Edit Invoice" delay={0.5} placement="bottom" showArrow={false}>
              <button
                className="px-2 py-0.5 rounded-md text-sky-700 hover:bg-sky-100"
                onClick={() => navigate(`/invoices/edit/${invoice?.invoiceReference}`)}
              >
                <i className="far fa-pen-to-square h-4 text-sm"></i>
              </button>
            </ToolTip>
          )}
        </div>

        <div className="absolute top-2 right-6">
          <HamburgerMenu options={menuOptions} />
        </div>

        <div className="e-content">
          <div>
            <InvoiceOverview invoice={invoice} isOpenInModal={isOpenInModal} />
          </div>
          <div>
            <InvoicePreferencesDetail invoice={invoice} isOpenInModal={isOpenInModal} />
          </div>
          <div>
            <EmailListTemplate
              entityReference={invoice?.invoiceReference}
              entityTypeReference={entityTypes.invoice.entityTypeReference}
              isSelected={selectedTab === 2}
              isOpenInModal={isOpenInModal}
            />
          </div>
          <div>
            <DocumentListTemplate
              entityReference={invoice?.invoiceReference}
              entityTypeReference={entityTypes.invoice.entityTypeReference}
              isTabComponent={true}
              isSelected={selectedTab === 3}
              isOpenInModal={isOpenInModal}
            />
          </div>
          <div>
            <NoteListTemplate
              entityReference={invoice?.invoiceReference}
              entityTypeReference={entityTypes.invoice.entityTypeReference}
              isSelected={selectedTab === 4}
              isTabComponent={true}
              isOpenInModal={isOpenInModal}
            />
          </div>
          <div>
            <ReminderListTemplate
              entityReference={invoice?.invoiceReference}
              entityTypeReference={entityTypes.invoice.entityTypeReference}
              isTabComponent={true}
              isSelected={selectedTab === 5}
              isOpenInModal={isOpenInModal}
            />
          </div>
        </div>
      </TabComponent>

      <WarningAlert
        title="Invoice"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={value => handleDelete(false)}
        isLoading={isDeleting}
      />

      <WarningAlert
        title="Invoice"
        value={voidInvoiceAlert.item}
        isOpen={voidInvoiceAlert.isOpen}
        fullTitle={"Void Invoice?"}
        onClose={() => setVoidInvoiceAlert({ ...voidInvoiceAlert, isOpen: false })}
        onDelete={value => handleDelete(true)}
        isLoading={isDeleting}
        buttonText={"Void"}
      />

      <SendEmailPage
        title="Email Invoice"
        senderEntityTypeReference={entityTypes.invoice.entityTypeReference}
        senderEntityReference={invoice?.invoiceReference ?? ""}
        recipientEntityTypeReference={entityTypes.client.entityTypeReference}
        recipientEntityReference={invoice?.client.clientReference ?? ""}
        onEmailSent={onEmailSent}
        onClose={() => setEmailModal(false)}
        isOpen={emailModal}
        emailConfiguration={invoiceEmailConfig}
      ></SendEmailPage>

      <AddPaymentModal
        entityReference={invoice?.invoiceReference ?? ""}
        entityTypeReference={entityTypes.invoice.entityTypeReference}
        invoiceCurrencyCode={invoice?.currencyCode ?? ""}
        concurrencyToken={invoice?.concurrencyToken ?? ""}
        isOpen={addPaymentModal}
        onClose={() => setAddPaymentModal(false)}
        clientReference={invoice?.client.clientReference ?? ""}
      />

      <ViewInvoicePdfModal
        isOpen={pdfModal.isOpen}
        onClose={() => setPdfModal({ ...pdfModal, isOpen: false })}
        reportLink={pdfModal.link}
        invoiceReference={invoice?.invoiceReference ?? ""}
        concurrencyToken={invoice?.concurrencyToken ?? ""}
        isEditMode={invoice?.invoiceStatusReference === invoiceStatus.paid.reference && true}
        onSendEmail={handleSendEmail}
        canApproveInvoice={
          invoice?.invoiceStatusReference === invoiceStatus.draft.reference ||
          invoice?.invoiceStatusReference === invoiceStatus.submitted.reference
        }
      />
    </>
  )
}

export default InvoiceDetailTabs
