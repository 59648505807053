import { action, makeObservable, observable } from "mobx"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { File, StaffPermission, UpdateFileInput } from "models/file/file"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isContains, isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"
import { TagsObservable } from "library/tagInputGroup/tagInputGroup"
import { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { fileAccess, FileAccess, filePermission } from "constants/fileAccess"
import { StaffName } from "models/people/staffName"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"

export interface FilePermission {
  [key: string]: {
    read: BooleanObservable
    update: BooleanObservable
    delete: BooleanObservable
  }
}

export class EditFileModel {
  fileName: TextObservable
  tags: TagsObservable
  description: TextObservable
  fileAccess: SelectObservable<FileAccess>
  staffPermissions: FilePermission
  generalErrors: GeneralError
  enableQuery: boolean
  constructor() {
    makeObservable(this, {
      setFileData: action,
      setStaffFilePermissions: action,
      clearErrors: action,
      generalErrors: observable,
      enableQuery: observable,
      setEnableQuery: action,
      handleApiErrors: action,
    })
    this.fileName = new TextObservable()
    this.tags = new TagsObservable()
    this.description = new TextObservable()
    this.fileAccess = new SelectObservable<FileAccess>()
    this.staffPermissions = {}
    this.generalErrors = {}
    this.enableQuery = false
  }

  setFileData(file: File) {
    this.fileName.text = file.fileName.split(".")[0]
    this.tags.list = file.tags
    this.description.text = file.description
    this.fileAccess.setValue({
      reference: file.accessLevel.accessLevelReference,
      name: getAccessLevelName(file.accessLevel.accessLevelReference, file.staffPermissions),
    })
  }

  setStaffFilePermissions(staffMembers: StaffName[], staffPermissions: StaffPermission[]) {
    staffMembers.forEach((staff) => {
      this.staffPermissions[staff.staffReference] = {
        read: new BooleanObservable(),
        update: new BooleanObservable(),
        delete: new BooleanObservable(),
      }
    })

    staffPermissions.forEach((m) => {
      if (m.permissionReference === filePermission.read.id) this.staffPermissions[m.staffReference].read.value = true
      else if (m.permissionReference === filePermission.update.id) this.staffPermissions[m.staffReference].update.value = true
      else if (m.permissionReference === filePermission.delete.id) this.staffPermissions[m.staffReference].delete.value = true
    })
  }

  getUpdateFileInput(file?: File) {
    const input: UpdateFileInput = {
      fileReference: file?.fileReference ?? "",
      fileName: this.fileName.text ? this.fileName.text + file?.extension : "",
      description: this.description.text ?? "",
      tags: this.tags.list.map((tag) => ({ ...tag })),
      accessLevelReference: this.fileAccess.selectedOption?.reference ?? "",
      staffPermissions: [],
    }

    if (this.fileAccess.selectedOption?.name === fileAccess.private.name) {
      let permissions: StaffPermission[] = []
      const staffReferences = Object.keys(this.staffPermissions)

      staffReferences.forEach((staffReference) => {
        if (this.staffPermissions[staffReference].read.value) {
          permissions.push({
            staffReference,
            permissionReference: filePermission.read.id,
            staffPermissionReference: "",
          })
        }
        if (this.staffPermissions[staffReference].update.value) {
          permissions.push({
            staffReference,
            permissionReference: filePermission.update.id,
            staffPermissionReference: "",
          })
        }
        if (this.staffPermissions[staffReference].delete.value) {
          permissions.push({
            staffReference,
            permissionReference: filePermission.delete.id,
            staffPermissionReference: "",
          })
        }
      })

      input.staffPermissions = permissions
    }

    return input
  }

  clearErrors() {
    this.fileName.errorMessage = undefined
    this.tags.errorMessage = undefined
    this.description.errorMessage = undefined
    this.fileAccess.errorMessage = undefined
    this.generalErrors = {}
  }

  setEnableQuery(enabled: boolean) {
    this.enableQuery = enabled
  }

  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "fileName")) this.fileName.errorMessage = v.description
      else if (isContains(v.property, "tags")) this.tags.errorMessage = v.description
      else if (isContains(v.property, "description")) this.description.errorMessage = v.description
      else if (isContains(v.property, "accessLevelReference")) this.fileAccess.errorMessage = v.description
      else {
        this.generalErrors[v.property] = v.description
      }
    })
  }
}

function getAccessLevelName(reference?: string, permissions?: StaffPermission[]) {
  if (reference === fileAccess.allStaff.reference) return fileAccess.allStaff.name
  if (permissions && permissions.length > 0) return fileAccess.private.name
  return fileAccess.onlyMe.name
}
