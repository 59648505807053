import { action, makeObservable } from "mobx"
import { ItemSearchObservable } from "../06_sharedComponent/lineItemSelection"
import { TaxRateObservable } from "../../../library/components/itemTaxSelection"
import { TextObservable } from "library/observables/inputGroupText"
import { NumberObservable } from "library/observables/inputGroupNumber"
import { TaxTypeObservable } from "library/components/taxTypeSelection"
import { hasValidationError, isEmpty } from "utils/extensions"
import { LineItem } from "components/invoices/05_sharedModel/invoiceModel"

export default class InvoiceLineItemObservable {
  lineItemReference: TextObservable
  itemSelection: ItemSearchObservable
  description: TextObservable
  quantity: NumberObservable
  salePrice: NumberObservable

  discount: TextObservable
  discountPercentage: NumberObservable
  discountAmount: NumberObservable
  taxRateSelection: TaxRateObservable
  totalDiscountAmount: number = 0

  totalLineDiscountAmount: NumberObservable
  totalLineAmount: NumberObservable
  totalLineTaxAmount: NumberObservable

  constructor(lineItem?: LineItem) {     
    makeObservable(this, {})
    this.lineItemReference = new TextObservable(lineItem?.lineItemReference)
    this.itemSelection = new ItemSearchObservable(lineItem?.itemReference, lineItem?.itemName)
    this.description = new TextObservable(lineItem?.description)
    this.quantity = new NumberObservable(lineItem?.quantity)
    this.salePrice = new NumberObservable(lineItem?.unitSalePrice)
    this.discountPercentage = new NumberObservable(lineItem?.discountPercentage)
    this.discountAmount = new NumberObservable(lineItem?.discountAmount)
    this.discount = new TextObservable(
      this.discountPercentage.displayValue
        ? this.discountPercentage.number + "%"
        : this.discountAmount.displayValue
        ? this.discountAmount.number?.toString()
        : undefined
    )
    this.taxRateSelection = new TaxRateObservable(lineItem?.taxReference, lineItem?.taxName)
    this.totalLineAmount = new NumberObservable(lineItem?.totalAmount)
    this.totalLineDiscountAmount = new NumberObservable(lineItem?.discountAmount)
    this.totalLineTaxAmount = new NumberObservable(lineItem?.taxAmount)
    if(lineItem){
      //display manually entered Tax in edit screen and disable tax selection
      if(isEmpty(lineItem?.taxReference)){
        this.totalLineTaxAmount.isUpdatedByOnChanged = true
        this.taxRateSelection.setNoTaxAndDisable()
      }
    }
  }

  calculateLineTotals(taxType: TaxTypeObservable) {    
    let salePrice = this.salePrice.number ?? 0
    let quantity = this.quantity.number ?? 0
    let totalLineAmount = quantity * salePrice
    let totalLineDiscountAmount = 0
    let totalLineTaxAmount = 0

    //1 -Calculate Discount
    this.parseDiscount()

    if (this.discountAmount.number) {
      totalLineAmount -= this.discountAmount.number
      totalLineDiscountAmount = this.discountAmount.number
    }

    if (this.discountPercentage.number) {
      let discountAmount = totalLineAmount * (this.discountPercentage.number / 100)
      discountAmount = totalLineDiscountAmount = discountAmount
      totalLineAmount -= discountAmount
    }
    this.totalDiscountAmount = totalLineDiscountAmount

    //2 Calculate Tax
    if (taxType.isNoTax()) {
      this.totalLineTaxAmount.clearAndDisableField()
      this.taxRateSelection.setNoTaxAndDisable()
    } else if (this.totalLineTaxAmount.isUpdatedByOnChanged) {      
      // If the user manually set the line tax amount then we don't change it and just keep as to what user set.
      totalLineTaxAmount = this.totalLineTaxAmount.number ?? 0
      this.totalLineTaxAmount.setValue(totalLineTaxAmount, 2, false)
    } else {
      if (taxType.isExclusive()) {
        totalLineTaxAmount = totalLineAmount * (this.taxRateSelection.taxRate / 100)
        this.totalLineTaxAmount.enableField()
        this.totalLineTaxAmount.setValue(totalLineTaxAmount, 2, false)
        this.taxRateSelection.enableTaxRateSelection()
      } else if (taxType.isInclusive()) {
        //https://www.youtube.com/watch?v=3_qxctJ5yD4
        let salePriceWithTax = (totalLineAmount / (100 + this.taxRateSelection.taxRate)) * 100
        let taxAmount = totalLineAmount - salePriceWithTax
        this.totalLineTaxAmount.enableField()
        this.totalLineTaxAmount.setValue(taxAmount, 2, false)
        this.taxRateSelection.enableTaxRateSelection()
      }
    }

    //3 - Set Total Amounts
    this.totalLineAmount.setValue(totalLineAmount, 2)
    this.totalLineDiscountAmount.setValue(totalLineDiscountAmount, 2)
  }

  parseDiscount() {
    // Case 1 - when discount text is not set, or it is empty then we need to clear
    // DiscountAmount and DiscountPercentage
    if (!this.discount.text || this.discount.text.length <= 0) {
      this.discountAmount.number = undefined
      this.discountPercentage.number = undefined

      // Case 2 - Work out whether user is applying discount percentage or flat discount amount.
    } else if (this.discount.text.includes("%")) {
      // Remove the percentage sign from discount value provided by user.
      let discountPercentage = this.discount.text.slice(0, -1)

      if (this.isNumber(discountPercentage)) {
        this.discountPercentage.number = Number(discountPercentage)
        this.discountAmount.number = undefined
      } else {
        // If user provided an alphabetical character clear discounts
        this.discountAmount.number = undefined
        this.discountPercentage.number = undefined
      }
    } else {
      if (this.isNumber(this.discount.text)) {
        this.discountAmount.number = Number(this.discount.text)
        this.discountPercentage.number = undefined
      } else {
        this.discountAmount.number = undefined
        this.discountPercentage.number = undefined
      }
    }
  }

  clearDiscount() {
    this.discount.text = ""
    this.discountAmount.number = undefined
    this.discountPercentage.number = undefined
  }

  isNumber(str: string): boolean {
    if (str.trim() === "") {
      return false
    }

    return !Number.isNaN(Number(str))
  }

  clearErrors() {
    if (this.lineItemReference.errorMessage) this.lineItemReference.errorMessage = undefined

    if (this.itemSelection.errorMessage) this.itemSelection.errorMessage = undefined

    if (this.description.errorMessage) this.description.errorMessage = undefined

    if (this.quantity.errorMessage) this.quantity.errorMessage = undefined

    if (this.salePrice.errorMessage) this.salePrice.errorMessage = undefined

    if (this.discount.errorMessage) this.discount.errorMessage = undefined

    if (this.discountPercentage.errorMessage) this.discountPercentage.errorMessage = undefined

    if (this.discountAmount.errorMessage) this.discountAmount.errorMessage = undefined

    if (this.taxRateSelection.errorMessage) this.taxRateSelection.errorMessage = undefined

    if (this.totalLineAmount.errorMessage) this.totalLineAmount.errorMessage = undefined

    if (this.totalLineDiscountAmount.errorMessage) this.totalLineDiscountAmount.errorMessage = undefined

    if (this.totalLineDiscountAmount.errorMessage) this.totalLineDiscountAmount.errorMessage = undefined
  }

  get isLineItemInvalid() {
    return hasValidationError([
      this.description.errorMessage,
      this.quantity.errorMessage,
      this.salePrice.errorMessage,
      this.discount.errorMessage,
      this.discountPercentage.errorMessage,
      this.discountAmount.errorMessage,
      this.taxRateSelection.errorMessage,
    ])
  }
}
