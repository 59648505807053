import React from "react"
import { Tab } from "@headlessui/react"
import { entityTypes } from "constants/entityTypes"
import {
  useNotifications,
  useReadAllNotification,
  useReadNotification,
  useReminderNotifications,
  useRemoveNotification
} from "hooks/notification/notification"
import { classNames, common, toLocaleDateTime } from "common"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import {
  NotificationHistory,
  NotificationHistoryQueryInput,
  RemoveNotificationHistoryInput,
  SetNotificationHistoryToReadInput
} from "models/notification/notificationHistory"
import { useNavigate } from "react-router-dom"
import TaskDetailPage from "../Task/02_taskDetails/taskDetailPage"
import SidePanel from "../../library/styled/sidePanel"
import { useModalState } from "../../utils/modalStates"
import { Task } from "../Task/04_sharedModel/taskModel"

interface Props {
  onClose: () => void
  unreadCount: number | undefined
}

const NotificationTabs = ({ onClose, unreadCount = 0 }: Props) => {
  const navigate = useNavigate()
  const { viewModal, setViewModal } = useModalState<Task>()

  let input: NotificationHistoryQueryInput = {
    first: 10,
    after: "",
    entityTypeReferences: [
      entityTypes.job.entityTypeReference,
      entityTypes.staff.entityTypeReference,
      entityTypes.client.entityTypeReference,
      entityTypes.contractor.entityTypeReference,
      entityTypes.leave.entityTypeReference,
      entityTypes.inquiry.entityTypeReference,
      entityTypes.job.entityTypeReference
    ]
  }
  let remindersInput: NotificationHistoryQueryInput = {
    first: 10,
    after: "",
    entityTypeReferences: [entityTypes.myReminder.entityTypeReference]
  }

  const { notifications, hasNextPage, fetchNextPage } = useNotifications(input)
  const {
    reminders,
    hasNextPage: hasNextReminderPage,
    fetchNextPage: fetchNextReminderPage
    // isLoading: isLoadingReminders,
  } = useReminderNotifications(remindersInput)

  const { mutate: removeNotification } = useRemoveNotification()
  const { mutate: readNotification } = useReadNotification()
  const { mutate: readAllNotifications } = useReadAllNotification()
  const queryClient = useQueryClient()

  interface Tabs {
    [key: string]: {
      data: NotificationHistory[]
      hasNextPage: boolean | undefined
      fetchNextPage: any
    }
  }

  var categories: Tabs = {
    Notifications: {
      data: notifications,
      hasNextPage: hasNextPage,
      fetchNextPage: fetchNextPage
    },
    "My Reminders": {
      data: reminders,
      hasNextPage: hasNextReminderPage,
      fetchNextPage: fetchNextReminderPage
    }
  }

  const handleViewDetails = (notification: NotificationHistory) => {
    if (!notification.isRead) {
      handleReadNotification(notification)
    }
    viewEntityDetails(notification)
  }

  const handleReadNotification = (notification: NotificationHistory) => {
    const input: SetNotificationHistoryToReadInput = {
      notificationHistoryReference: notification.notificationHistoryReference
    }
    readNotification(input, {
      onSuccess: () => {
        if (notification.title === "Reminder") void queryClient.invalidateQueries(keys.reminderNotifications)
        else queryClient.invalidateQueries(keys.notifications)
        void queryClient.invalidateQueries(keys.unreadNotificationQuery)
      }
    })
  }

  const handleReadAllNotification = () => {
    readAllNotifications(
      {},
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(keys.notifications)
          void queryClient.invalidateQueries(keys.reminderNotifications)
          void queryClient.invalidateQueries(keys.unreadNotificationQuery)
        }
      }
    )
  }

  const handleRemoveNotification = (notification: NotificationHistory) => {
    const input: RemoveNotificationHistoryInput = {
      notificationHistoryReference: notification.notificationHistoryReference
    }
    removeNotification(input, {
      onSuccess: () => {
        if (notification.title === "Reminder") void queryClient.invalidateQueries(keys.reminderNotifications)
        else queryClient.invalidateQueries(keys.notifications)
        void queryClient.invalidateQueries(keys.unreadNotificationQuery)
      }
    })
  }

  const viewEntityDetails = function(notification: NotificationHistory) {
    let url = ""
    if (notification.entityTypeReference === entityTypes.invoice.entityTypeReference) {
      url = `invoices/${notification.entityReference}`
      navigate(url, { replace: true })
      return
    } else if (notification.entityTypeReference === entityTypes.task.entityTypeReference) {
      url = `tasks/${notification.entityReference}`
      navigate(url)

      setViewModal({ item: { taskReference: notification.entityReference }, isOpen: true })

      return
    } else if (notification.entityTypeReference === entityTypes.job.entityTypeReference) url = common.angularJsLinks.jobDetails
    else if (notification.entityTypeReference === entityTypes.client.entityTypeReference)
      url = common.angularJsLinks.clientDetails
    else if (notification.entityTypeReference === entityTypes.staff.entityTypeReference) url = common.angularJsLinks.staffDetails
    else if (notification.entityTypeReference === entityTypes.contractor.entityTypeReference)
      url = common.angularJsLinks.contractorDetails
    else if (notification.entityTypeReference === entityTypes.leave.entityTypeReference) url = common.angularJsLinks.leaveDetails
    else if (notification.entityTypeReference === entityTypes.inquiry.entityTypeReference)
      url = common.angularJsLinks.inquiryDetails
    else if (notification.entityTypeReference === entityTypes.myReminder.entityTypeReference)
      url = common.angularJsLinks.editReminder

    url += notification.entityReference
    window.open(url, "_self")
  }

  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="flex px-6 justify-between border-b-2 border-gray-200 h-14 m-auto">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "text-sm leading-5",
                  "focus:outline-none",
                  selected ? "text-primary-900 border-b border-primary-900" : "text-gray-600 "
                )
              }
            >
              {category}
            </Tab>
          ))}

          <div className="flex items-center">
            <button
              disabled={unreadCount === 0}
              onClick={handleReadAllNotification}
              type="button"
              className={`focus:outline-none rounded-full hover:bg-primary-50 px-2 py-1 ${
                unreadCount === 0 ? "text-gray-400" : "text-primary-900"
              }`}
            >
              <span className="sr-only">Mark all as read</span>
              <i className="fal fa-eye"></i>
            </button>
          </div>

          <div className="flex items-center">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none px-2 py-1 hover:bg-gray-200"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <i className="fal fa-times fa-lg"></i>
            </button>
          </div>
        </Tab.List>
        <Tab.Panels className="">
          {Object.values(categories).map((post, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames("rounded-xl bg-white overflow-y-auto notification-bar-height", "focus:outline-none")}
            >
              <ul className="divide-y divide-gray-200">
                {post.data.map((item) => (
                  <li key={item.notificationHistoryReference}
                      className={`px-3 py-2 relative ${item.isRead ? "bg-white" : ""}`}>
                    <div>
                      <div className="-m-1 p-1 block">
                        <div className="min-w-0 relative">
                          <div className="">
                            <div className="flex flex-row justify-between">
                              <div className="pr-0">
                                <button
                                  disabled={item.isRead}
                                  onClick={() => handleReadNotification(item)}
                                  style={{ fontSize: "13px" }}
                                  className={`text-left ${
                                    item.isRead
                                      ? "text-gray-500 break-words mr-3"
                                      : "text-gray-800 font-medium break-words hover:text-gray-800"
                                  }`}
                                >
                                  {item.title}
                                </button>
                              </div>
                              <div className="pl-0">
                                <button
                                  onClick={() => handleRemoveNotification(item)}
                                  aria-label="Delete notification"
                                  className="pt-1 text-gray-300 hover:text-gray-400 transition ease-in-out duration-150 focus:outline-none"
                                >
                                  <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="text-xs leading-5 text-gray-500 pt-1 truncate">{item.message}</div>

                            <div className="pt-1 text-xs">
                              <span>
                                <button onClick={() => handleViewDetails(item)} className="text-primary-900">{`${
                                  item.entityTypeReference === entityTypes.myReminder.entityTypeReference
                                    ? "Edit"
                                    : "View " + item.entityTypeName
                                }`}</button>
                              </span>
                              <div className="float-right leading-5 text-gray-500 truncate">
                                {toLocaleDateTime(item.insertedDateTimeUtc)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}

                <li className="px-12 py-5 text-center">
                  <button
                    className="text-primary-900 cursor-pointer text-sm"
                    disabled={!post.hasNextPage}
                    onClick={post.fetchNextPage}
                  >
                    Load more
                  </button>
                </li>
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>

      <SidePanel
        title={`Task`}
        open={viewModal.isOpen}
        onClose={() => {
          setViewModal({ ...viewModal, isOpen: false })
        }}
        body={<TaskDetailPage taskReference={viewModal.item?.taskReference} />}
        type="detailPanel"
      />
    </div>
  )
}

export default NotificationTabs
