import { Disclosure } from "@headlessui/react"
import FosLogo from "assets/FosReversedTransparent.png"
import { NavLink } from "react-router-dom"
import { classNames, common, redirectKey } from "common"
import React, { Fragment, useState } from "react"
import { useFeature } from "context/feature-context"
import { useMetadata } from "context/metadata-context"

interface Props {
  sidebarOpen: boolean
}

const Sidebar = ({ sidebarOpen }: Props) => {
  const { enabledFeatures, enabledPermissions } = useFeature()
  const { organisation } = useMetadata()
  const [navigation] = useState([
    {
      name: "Home",
      href: common.angularJsLinks.home,
      icon: "analytics",
      current: true,
      isNavLink: false,
      isVisible: enabledPermissions?.analytic_Read
    },
    {
      name: "Jobs",
      //href: "jobs",
      href: common.angularJsLinks.jobs,
      icon: "clipboard-check",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.job && enabledPermissions?.job_Read
    },
    {
      name: "Invoices",
      href: "invoices",
      icon: "file-invoice-dollar",
      current: false,
      isNavLink: true,
      isVisible: true
    },
    {
      name: "Items",
      href: "items",
      icon: "th-list",
      current: false,
      isNavLink: true,
      isVisible: true
    },
    {
      name: "Scheduler",
      //href: "scheduler",
      href: common.angularJsLinks.jobScheduler,
      icon: "tasks-alt",
      current: false,
      isNavLink: false,
      isVisible:
        enabledFeatures?.job &&
        (enabledFeatures.job_StaffMembers || enabledFeatures.job_Contractor) &&
        enabledPermissions?.job_Staff_Read
    },
    {
      name: "Tasks",
      href: "tasks",
      icon: "list-check",
      current: false,
      isNavLink: true,
      isVisible: true
    },
    {
      name: "Timesheet",
      href: "/timesheet",
      icon: "clock",
      current: false,
      isVisible: enabledFeatures?.timesheet && enabledPermissions?.timesheet_Read,
      children: [
        { name: "Time Entries", url: common.angularJsLinks.timeEntries, isNavLink: false },
        { name: "Time Approvals", url: common.angularJsLinks.timeApprovals, isNavLink: false }
      ]
    },
    {
      name: "Leaves",
      href: common.angularJsLinks.leaves,
      icon: "island-tropical",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.leave && enabledPermissions?.leave_Read
    },
    {
      name: "Clients",
      href: common.angularJsLinks.clients,
      icon: "users",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.client && enabledPermissions?.client_Read
    },
    {
      name: "Contractors",
      href: common.angularJsLinks.contractors,
      icon: "user-hard-hat",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.contractor && enabledPermissions?.contractor_Read
    },
    {
      name: "Inquiry",
      href: "/inquiry",
      icon: "question-circle",
      current: false,
      isVisible: enabledFeatures?.inquiry && enabledPermissions?.inquiry_Read,
      children: [
        { name: "Inquiries", url: common.angularJsLinks.inquiries, isNavLink: false },
        { name: "Forms", url: common.angularJsLinks.inquiryForms, isNavLink: false }
      ]
    },
    {
      name: "Staff",
      href: common.angularJsLinks.staff,
      icon: "user",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.staff && enabledPermissions?.staff_Read
    },
    {
      name: "Documents",
      href: "documents",
      icon: "folders",
      current: false,
      isNavLink: true,
      isVisible: enabledFeatures?.document
    },
    {
      name: "My Reminders",
      href: common.angularJsLinks.reminders,
      icon: "alarm-clock",
      current: false,
      isNavLink: false,
      isVisible: enabledFeatures?.reminder
    },
    {
      name: "Plan & Addons",
      href: common.angularJsLinks.planDetail,
      icon: "calendar-clock",
      current: false,
      isVisible: enabledPermissions?.organisation_Billing
    },
    {
      name: "Settings",
      href: "settings",
      icon: "cog",
      current: false,
      isNavLink: true,
      isVisible: enabledPermissions?.organisation_Setting
    },
    {
      name: "Help Center",
      href: common.angularJsLinks.helpCenter,
      icon: "info",
      current: false,
      isNavLink: false,
      isVisible: true
    }
  ])

  return (
    <>
      <div>
        <div
          id="sidebarContainer"
          className={
            sidebarOpen ? "mini-sidebar sidebar flex w-64 flex-col overflow-y-auto" : "sidebar flex w-64 flex-col overflow-y-auto"
          }
        >
          <div id="sidebar" className="sidebar-content flex flex-col flex-grow pt-10 bg-primary-900">
            <div className="flex items-center flex-shrink-0">
              <img className="" src={FosLogo} alt="fos" />
            </div>
            <button disabled className="clear text-center tracking-wider py-5 text-gray-300"
                    style={{ fontSize: "13px" }}>
              {organisation?.name}
            </button>
            <div className="flex-1 flex flex-col">
              <nav className="flex-1" aria-label="Sidebar">
                {navigation.map(
                  (item) =>
                    item.isVisible && (
                      <Fragment key={item.name}>
                        {!item.children ? (
                          <div key={item.name}>
                            {item.isNavLink ? (
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive ? "active-link" : "bg-primary-900",
                                    "group text-white w-full flex items-center nav-link text-base font-light hover:text-white"
                                  )
                                }
                              >
                                <span className="font-light">
                                  <i className={`inline-block align-middle w-5 h-4 mr-2 fal fa-${item.icon}`}></i>
                                  {item.name}
                                </span>
                              </NavLink>
                            ) : (
                              <a
                                href={`${item.href}`}
                                className="bg-primary-900 text-white w-full flex items-center nav-link text-base font-light hover:text-white"
                              >
                                <span className="font-light">
                                  <i className={`inline-block align-middle w-5 h-4 mr-2 fal fa-${item.icon}`}></i>
                                  {item.name}
                                </span>
                              </a>
                            )}
                          </div>
                        ) : (
                          <Disclosure as="div" key={item.name} className="">
                            {({ open }) => (
                              <>
                                <div className={classNames(open ? "highlight-border" : "", "")}>
                                  <Disclosure.Button
                                    className={classNames(
                                      item.current || open ? "active-child-link" : "bg-primary-900 text-white",
                                      "group w-full flex items-center nav-link text-left text-base font-light focus:outline-none"
                                    )}
                                  >
                                    <span className="font-light flex-1">
                                      <i className={`inline-block align-middle w-5 h-4 mr-2 fal fa-${item.icon}`}></i>
                                      {item.name}
                                    </span>
                                    <i
                                      className={`${open ? "fal fa-chevron-up" : "fal fa-chevron-down"} font-semibold`}
                                      style={{ fontSize: "10px" }}
                                    />
                                  </Disclosure.Button>
                                  <div
                                    className="transition-all"
                                    style={
                                      open
                                        ? {
                                          height: `${item.children.length * 34}px`,
                                          transitionDuration: "0.4s"
                                        }
                                        : { height: 0, transitionDuration: "0.4s", opacity: 0 }
                                    }
                                  >
                                    <Disclosure.Panel>
                                      {item.children.map((subItem) => (
                                        <Disclosure.Button key={subItem.name} as="div">
                                          {subItem.isNavLink ? (
                                            <NavLink
                                              className={({ isActive }) =>
                                                classNames(
                                                  isActive ? "text-white" : "text-gray-400",
                                                  "nav-second-level-link group w-full flex items-center pl-11 pr-2 py-2 text-sm hover:text-white font-light"
                                                )
                                              }
                                              to={subItem.url}
                                            >
                                              {subItem.name}
                                            </NavLink>
                                          ) : (
                                            <a
                                              className="text-gray-400 nav-second-level-link w-full flex items-center pl-11 pr-2 py-2 text-sm hover:text-white font-light"
                                              href={subItem.url}
                                            >
                                              {subItem.name}
                                            </a>
                                          )}
                                        </Disclosure.Button>
                                      ))}
                                    </Disclosure.Panel>
                                  </div>
                                </div>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </Fragment>
                    )
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
