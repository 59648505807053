import React from "react"
import { observer } from "mobx-react"
import { ErrorInline, FormGroup, Label, TextInput } from "../styled/styled"
import { action, makeObservable, observable, reaction } from "mobx"
import ToolTip from "../styled/tooltip"
import _ from "lodash"

export class NumberObservable {
  number?: number
  displayValue?: string
  errorMessage?: string
  isDisabled: boolean = false

  /**
   * This property is used to keep track who entered the value.
   * - Was it user who typed the value into the text field. In this case value will be true
   * - Or was the value set via calculate totals method
   *
   * Followings are few Scenario where logic is applied based on who entered the text.
   * 1) Tax amount - we allow users to set tax amount. When tax amount is set by user, the IsUpdatedByOnChange property is set to true
   *    Normally tax is calculated based on the tax rate selected drop down. But If IsUpdatedByOnChange = true we simply use the value entered by user.
   *    Furthermore, The tax rate value is set to [Manual] indicating tax is entered manually.
   *    But when user changes the tax by selecting the tax rate, IsUpdatedByOnChange is set to false and tax rate is used to calculate tax.
   *
   **/
  isUpdatedByOnChanged: boolean = false

  constructor(number?: number) {
    makeObservable(this, {
      number: observable,
      displayValue: observable,
      isDisabled: observable,
      errorMessage: observable,
      enableField: action,
    })

    //this.displayValue = ""
    this.number = number
    this.displayValue = number?.toString()
  }

  setValue(value?: number, rounding?: number, resetIsUpdatedByOnChange: boolean = true) {
    if (rounding) {
      this.number = _.round(value, rounding)
    } else {
      this.number = value
    }

    // We don't want to set isUpdatedByOnChanged to false if user manually typed in Tax amount input box.
    if (resetIsUpdatedByOnChange) {
      this.isUpdatedByOnChanged = false
    }

    this.displayValue = this.number?.toString()
  }

  clearAndDisableField() {
    this.isDisabled = true
    this.number = undefined
    this.displayValue = undefined
    this.isUpdatedByOnChanged = false
  }

  enableField() {
    this.isDisabled = false
  }
}

interface Props {
  name: string
  error: any
  placeholder?: string
  label?: string
  value: NumberObservable
  errorKey?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  addMargin?: boolean
  cols?: number
  tooltip?: string
}

const InputGroupNumber = ({ name, error, placeholder = "Optional", label, value, errorKey, tooltip }: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>
        {label}
        {tooltip && (
          <ToolTip text={tooltip} id={"Id"}>
            <i className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}
      </Label>
      <TextInput
        type="number"
        name={name}
        id={name}
        placeholder={placeholder}
        disabled={value.isDisabled}
        value={value ? value.number : undefined}
        onChange={action((e) => (value!.number = parseFloat(e.target.value)))}
      />
      {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(InputGroupNumber)
