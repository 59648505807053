import React, { useEffect, useState } from "react"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import Accordian from "library/styled/accordian"
import { Form, FormGroup, Label } from "library/styled/styled"
import { InputGroupButton } from "library/styled/input-group"
import { WarningAlert } from "library/styled/alerts"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import TableDynamic from "library/styled/table"
import { AddNoteInput, Note } from "components/shared/notes/03_sharedModel/note"
import { NoteCardModel } from "components/shared/notes/01_noteCard/noteCardModel"
import { useModalState } from "utils/modalStates"
import { useAddNote, useNote, useNoteType, useRemoveNote } from "components/shared/notes/services/noteCardService"
import { handlePopupError, handleServerError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { observer } from "mobx-react"
import EditNoteModal from "components/shared/notes/02_editNote/editNote"
import GeneralErrors from "library/generalErrors/generalErrors"
import DateTimePicker from "library/observables/dateTimePicker"
import InputGroupSelect from "library/observables/inputGroupSelect"
import { NoteType } from "components/shared/notes/03_sharedModel/noteType"

interface Props {
  entityReference?: string
  entityTypeReference: string
  onNoteAdded?: () => void
}

const AddNoteTemplate = ({ entityReference, entityTypeReference, onNoteAdded }: Props) => {
  const [model] = useState(() => new NoteCardModel())
  const { data: noteTypes } = useNoteType(model.enableQuery)
  const { mutate: addNote, isLoading } = useAddNote()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (noteTypes) model.setNoteType(noteTypes[0])
  }, [noteTypes])

  useEffect(() => {
    if (entityReference) model.setEnableQuery()
  }, [entityReference])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const input: AddNoteInput = {
      entityTypeReference: entityTypeReference,
      entityReference: entityReference ?? "",
      description: model.description.text ?? "",
      isVisibleToClient: true,
      noteTypeReference: model.noteType.selectedOption?.noteTypeReference ?? "",
    }

    addNote(input, {
      onError: (error: any) => {
        model.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.noteQuery)
        successToaster("Note added")
        model.reset()
        if (onNoteAdded) onNoteAdded()
      },
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup cols={6}>
          <div className="flex justify-start space-x-3">
            <div className="mt-3">
              <Label htmlFor="dueDateTime">Note Type</Label>
            </div>
            <div className="w-72">
              <InputGroupSelect<NoteType>
                value={model.noteType}
                id="noteType"
                options={noteTypes}
                optionValue={"noteTypeReference"}
                optionLabel={"name"}
              />
            </div>
          </div>
        </FormGroup>
        <TextAreaInputGroup label="Write a Note" name="description" value={model.description} placeholder="Required" />
        <GeneralErrors errorMessages={model.generalErrors} />
        <InputGroupButton isPrimary={false} isLoading={isLoading} />
      </Form>
    </>
  )
}

export default observer(AddNoteTemplate)
