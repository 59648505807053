import React, { RefObject, useEffect, useRef, useState } from "react"
import { SchedulerModel } from "components/scheduler/schedulerModel"
import { ScheduleComponent, View } from "@syncfusion/ej2-react-schedule"
import {
  DatePickerComponent,
  NavigatedEventArgs,
  PopupObjectArgs,
  PreventableEventArgs,
  RenderDayCellEventArgs,
} from "@syncfusion/ej2-react-calendars"
import { runInAction } from "mobx"
import { addMonths, addWeeks, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from "date-fns"
import addDays from "date-fns/addDays"
import { observer } from "mobx-react"
import { JobCount, JobCountQueryInput, SchedulerResource } from "components/scheduler/05_models/models"
import ReorderResourceModal from "components/scheduler/reorderResources/reorderResourceModal"
import { addClass } from "@syncfusion/ej2-base"
import { useJobCount } from "components/scheduler/06_services/schedulerService"
import { toUtcDateTime } from "common"
import { log } from "util"

interface Props {
  model: SchedulerModel
  scheduleRef: RefObject<ScheduleComponent>
  resources?: SchedulerResource[]
  onSettingClick: () => void
}

const HeaderBarComponent = ({ model, scheduleRef, resources, onSettingClick }: Props) => {
  const datePicker = useRef<DatePickerComponent>(null)
  const { data: jobCount } = useJobCount(model.getJobCountQueryInput())
  const [openReorderModal, setOpenReorderModal] = useState(false)

  const navigateToPreviousDate = () => {
    let d: Date = new Date()
    if (scheduleRef.current?.currentView === "TimelineMonth") {
      d = addMonths(model.selectedDate, -1)
    } else if (scheduleRef.current?.currentView === "TimelineDay") {
      d = addDays(model.selectedDate, -1)
    } else if (scheduleRef.current?.currentView === "TimelineWeek") {
      d = addWeeks(model.selectedDate, -1)
    }
    handleDateChange(d)
  }

  const navigateToNextDate = () => {
    let d: Date = new Date()
    if (scheduleRef.current?.currentView === "TimelineMonth") {
      d = addMonths(model.selectedDate, 1)
    } else if (scheduleRef.current?.currentView === "TimelineDay") {
      d = addDays(model.selectedDate, 1)
    } else if (scheduleRef.current?.currentView === "TimelineWeek") {
      d = addWeeks(model.selectedDate, 1)
    }
    handleDateChange(d)
  }

  const handleDateChange = (date: Date) => {
    model.setSelectedDate(date)
  }

  const setCurrentView = (view: View) => {
    model.setSelectedView(view)
  }

  const isViewActive = (view: View) => {
    return view === model.selectedView
  }

  const onRenderDayCell = (args: RenderDayCellEventArgs) => {
    const count = jobCount?.find((m) => m.dayOfMonth.toString() === args.element?.textContent)

    if (args.element && count)
      args.element.innerHTML = `<span class="e-day">${args.element.textContent}<div class="event-count-badge">${count?.totalJobs}</div></span>`
  }

  const onNavigate = (args: NavigatedEventArgs) => {
    model.setJobCountDate(args.date)
  }

  const onClose = (args: any) => {
    args.cancel = true
  }

  return (
    <>
      <div className="flex bg-blue_grey-100 border-blue_grey-200 px-4 py-1 shadow-lg text-gray-600">
        <div className="space-x-2">
          <button
            onClick={() => model.setSelectedDate(new Date())}
            className="px-4 py-1 text-sm rounded-md hover:bg-blue_grey-200"
          >
            Today
          </button>

          <button onClick={navigateToPreviousDate} className="rounded-full px-3 py-1 hover:bg-blue_grey-200">
            <i className="fas fa-chevron-left fa-xs"></i>
          </button>
          <button onClick={navigateToNextDate} className="rounded-full px-3 py-1 hover:bg-blue_grey-200">
            <i className="fas fa-chevron-right fa-xs"></i>
          </button>
        </div>

        <div className="inline my-auto scheduler-date">
          <DatePickerComponent
            ref={datePicker}
            value={model.selectedDate}
            format={model.selectedView === "TimelineMonth" ? "MMMM y" : "d MMM yyyy"}
            id="timelineDate"
            className="cursor-pointer rounded-lg"
            cssClass="!bg-blue_grey-100 !border-none !focus:outline-none !focus:border-none !rounded-lg scheduler-datepicker-width"
            showClearButton={false}
            allowEdit={false}
            start={model.selectedView === "TimelineMonth" ? "Year" : undefined}
            depth={model.selectedView === "TimelineMonth" ? "Year" : undefined}
            onChange={(args: any) => handleDateChange(args.value)}
            onClick={() => {
              datePicker.current?.show()
            }}
            // renderDayCell={onRenderDayCell}
            //navigated={onNavigate}
          ></DatePickerComponent>
          <button
            className="text-sm cursor-pointer px-2 py-1 rounded-md hover:bg-blue_grey-200"
            onClick={() => datePicker.current?.show()}
          >
            {model.selectedView === "TimelineDay" && <>{format(model.selectedDate, "d MMM yyyy")}</>}
            {model.selectedView === "TimelineMonth" && <>{format(model.selectedDate, "MMMM y")}</>}
            {model.selectedView === "TimelineWeek" && (
              <>{format(startOfWeek(model.selectedDate), "d MMM") + " - " + format(endOfWeek(model.selectedDate), "d MMM , y")}</>
            )}
            <i className="far fa-calendar pl-2"></i>
          </button>
        </div>

        <div className="border-l border-blue_grey-300 mx-2 my-1"></div>

        <div className="space-x-2 text-sm my-auto">
          <button
            onClick={() => setCurrentView("TimelineDay")}
            className={`px-4 py-1 rounded-md hover:bg-blue_grey-200 ${
              isViewActive("TimelineDay") ? "text-primary-900" : "text-gray-600"
            }`}
          >
            <i className="far fa-calendar-day pr-2"></i>
            Day
          </button>
          <button
            onClick={() => setCurrentView("TimelineWeek")}
            className={`px-4 py-1 rounded-md hover:bg-blue_grey-200 ${
              isViewActive("TimelineWeek") ? "text-primary-900" : "text-gray-600"
            }`}
          >
            <i className="far fa-calendar-week pr-2"></i>
            Week
          </button>
          <button
            onClick={() => setCurrentView("TimelineMonth")}
            className={`px-4 py-1 rounded-md hover:bg-blue_grey-200 ${
              isViewActive("TimelineMonth") ? "text-primary-900" : "text-gray-600"
            }`}
          >
            <i className="far fa-calendar-range pr-2"></i>
            Month
          </button>
        </div>

        <div className="my-auto ml-auto text-sm">
          <button onClick={() => setOpenReorderModal(true)} className="px-4 py-1 rounded-md hover:bg-blue_grey-200">
            Reorder
          </button>

          <button onClick={() => model.setOpenUnassignedJobs()} className="px-4 py-1 rounded-md hover:bg-blue_grey-200">
            {model.openUnassignedJobs ? "Hide" : "Assign Jobs"}
          </button>

          <button onClick={onSettingClick} className="px-2 py-1 rounded-md hover:bg-blue_grey-200">
            <i className="far fa-cog"></i>
          </button>
        </div>
      </div>

      <ReorderResourceModal
        isOpen={openReorderModal}
        onClose={() => setOpenReorderModal(false)}
        resources={resources}
        onChange={() => null}
      />
    </>
  )
}
export default observer(HeaderBarComponent)
