import { useMutation, useQuery } from "react-query"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { common } from "common"
import {
  JobCountQueryInput,
  JobCounts,
  LeaveMetadata,
  MoveJobInput,
  MoveSchedulerJobInput,
  SchedulerEventQueryInput,
  SchedulerEvents,
  SchedulerResources,
  UnassignedJobQueryInput,
  UnassignedJobs,
  UpdateResourceOrderInput,
} from "components/scheduler/05_models/models"
import { RemoveInvoiceInput } from "components/invoices/05_sharedModel/InputModels"
import { mutation } from "shared/graphql/mutations"
import { leaveStatus } from "constants/status"

function useSchedulerResource() {
  return useQuery(keys.schedulerResourceQuery, fetchSchedulerResources, {
    select: (data: Response<SchedulerResources>) => data.data.data.schedulerResources,
  })
}

function fetchSchedulerResources() {
  return graphQLClient.fetch(query.schedulerResourceQuery, null, true, common.peopleApiUrl, keys.schedulerResourceQuery)
}

function useSchedulerEvent(input: SchedulerEventQueryInput) {
  return useQuery([keys.schedulerEventQuery, input], () => fetchSchedulerEvents(input), {
    select: (data: Response<SchedulerEvents>) => {
      return data.data.data.schedulerEvents.map((m, idx) => {
        return {
          Id: idx + 1,
          Subject: m.eventType === "Job" ? m.jobMetadata?.jobNumber : m.leaveMetadata?.leaveTypeName,
          StartTime: new Date(m.startDateTimeUtc),
          EndTime: new Date(m.endDateTimeUtc),
          ResourceId: m.resourceReferences,
          Color: m.eventType === "Job" ? m.jobMetadata?.stateColor : getLeaveColor(m.leaveMetadata),
          ExtendedProps: m,
          IsReadonly: m.eventType !== "Job",
        }
      })
    },
  })
}

function getLeaveColor(leave?: LeaveMetadata) {
  if (leave?.leaveStatusReference == leaveStatus.pending.leaveStatusReference) return "#d1d1d1"
  else if (leave?.leaveStatusReference == leaveStatus.approved.leaveStatusReference) return "#00c799"
  else return "#ff4d4d"
}

function fetchSchedulerEvents(input: SchedulerEventQueryInput) {
  return graphQLClient.fetch(query.schedulerEventQuery, input, true, common.calendarApiUrl, keys.schedulerEventQuery)
}

function useUnassignedJobs(input: UnassignedJobQueryInput) {
  return useQuery([keys.unassignedJobsQuery, input], () => fetchUnassignedJobs(input), {
    select: (data: Response<UnassignedJobs>) => data.data.data.unassignedStaffJobs.edges.map((m) => m.node),
  })
}

function fetchUnassignedJobs(input: UnassignedJobQueryInput) {
  return graphQLClient.fetch(query.unassignedJobsQuery, input, true, common.legacyGraphQlUrl, keys.unassignedJobsQuery)
}

function useJobCount(input: JobCountQueryInput) {
  return useQuery([keys.unassignedJobsQuery, input], () => fetchJobCount(input), {
    select: (data: Response<JobCounts>) => data.data.data.jobCount,
  })
}

function fetchJobCount(input: JobCountQueryInput) {
  return graphQLClient.fetch(query.JobCountQuery, input, true, common.legacyGraphQlUrl, keys.jobCountQuery)
}

function moveSchedulerJob(input: MoveSchedulerJobInput) {
  return graphQLClient.mutation(mutation.moveSchedulerJob, input, "MoveSchedulerJob", common.legacyGraphQlUrl)
}

function useMoveSchedulerJob() {
  return useMutation(moveSchedulerJob)
}

function moveJob(input: MoveJobInput) {
  return graphQLClient.mutation(mutation.moveJob, input, "MoveJob", common.legacyGraphQlUrl)
}

function useMoveJob() {
  return useMutation(moveJob)
}

function reorderResources(input: UpdateResourceOrderInput) {
  return graphQLClient.mutation(
    mutation.updateSchedulerResourceDisplayOrder,
    input,
    "UpdateSchedulerResourceDisplayOrder",
    common.peopleApiUrl
  )
}

function useReorderResource() {
  return useMutation(reorderResources)
}

export {
  useSchedulerResource,
  useSchedulerEvent,
  useUnassignedJobs,
  useMoveSchedulerJob,
  useMoveJob,
  useReorderResource,
  useJobCount,
}
