import React, { useEffect, useState } from "react"
import { ButtonSecondary, Card, CardBody, CardHeader, Form } from "library/styled/styled"
import {
  useGenerateReportLink,
  useGetOrAddInvoiceV2,
  useInvoiceDetail,
  useUpdateInvoice,
  useUpdateInvoiceStatus
} from "../services/invoiceService"
import { observer } from "mobx-react"
import DatePicker from "library/observables/datePickers"
import ClientAddressSelection from "library/clientAddressSelection/clientAddressSelection"
import StaffSelection from "library/components/staffSelection"
import addOrEditInvoicePageModel from "components/invoices/01_addOrEditInvoice/addOrEditInvoicePageModel"
import InvoiceLineItems from "../06_sharedComponent/invoiceLineItems"
import { GenerateReportLinkInput, UpdateInvoiceInput, UpdateInvoiceStatusInput } from "../05_sharedModel/InputModels"
import { handlePopupError, handleServerError } from "common"
import CurrencySelection from "library/currencySelection/currencySelection"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import InvoiceOrganisationDetail from "../06_sharedComponent/invoiceOrganisationDetail"
import OrganisationLogoDisplay from "../../file/imageUpload/organisationLogoImage"
import { entityTypes } from "constants/entityTypes"
import { keys } from "constants/keys"
import InvoiceNumber from "../06_sharedComponent/invoiceNumber"
import { useMetadata } from "context/metadata-context"
import { invoiceStatus } from "constants/invoiceStatus"
import SendEmailPage from "components/invoices/sendEmail/sendEmailModal"
import { successToaster } from "library/styled/toasters"
import ClientSelectionComponent from "library/components/clientSelectionComponent"
import DocumentCard from "components/shared/documents/01_documentCard/documentCard"
import { runInAction } from "mobx"
import ReminderCard from "components/shared/reminders/01_reminderCard/reminderCard"
import NoteCard from "components/shared/notes/01_noteCard/noteCard"
import { useQueryClient } from "react-query"
import Accordian from "library/styled/accordian"
import InputGroupCheckbox from "library/observables/inputGroupCheckbox"
import { useTemplate } from "components/setting/template/templateSettingService"
import InvoiceStatusSelection from "components/invoices/06_sharedComponent/invoiceStatusSelection"
import { invoiceEmailConfig } from "constants/emailConfiguration"
import MenuButtonComponent from "library/dropdownMenu/menuButtonComponent"
import { Template } from "components/setting/template/05_sharedModel/template"
import ToolTip from "../../../library/styled/tooltip"
import AddOrEditInvoiceButtonDropdown from "components/invoices/06_sharedComponent/addInvoiceButtonDropdown"
import { reportType } from "constants/reportType"
import ViewInvoicePdfModal from "components/invoices/03_invoiceDetail/viewInvoicePdf/viewInvoicePdfModal"
import { useLoading } from "context/loading-context"
import JobSelection from "library/jobSelection/jobSelection"
import { useInvoiceReminder } from "components/setting/invoice/invoiceSettingService"

const AddOrEditInvoicePage: React.FC = () => {
  // Page Properties
  const [invoicePageModel] = useState(() => new addOrEditInvoicePageModel(true, true))
  const navigate = useNavigate()
  const params = useParams()
  // React Query Section
  const queryClient = useQueryClient()
  const { data: invoice } = useInvoiceDetail(invoicePageModel.isEditMode, params.invoiceReference)
  const { data: getOrAddInvoice } = useGetOrAddInvoiceV2(invoicePageModel.isAddMode)
  const { data: templates } = useTemplate(true) // query templates when adding and editing invoice.
  const { mutate: updateInvoiceMutation, isLoading } = useUpdateInvoice()
  const { mutate: updateInvoiceStatusMutation } = useUpdateInvoiceStatus()
  const { organisation } = useMetadata()
  const { mutate: generateLink } = useGenerateReportLink()
  const { setLoadingPage } = useLoading()
  const { data: invoiceReminders } = useInvoiceReminder()
  const [activeReminderCount, setActiveReminderCount] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const jobReference = searchParams.get("jobReference")
  const clientReference = searchParams.get("clientReference")
  const clientName = searchParams.get("clientName")

  useEffect(() => {
    if (params.invoiceReference) {
      invoicePageModel.setIsEditMode(true)
      invoicePageModel.setIsAddMode(false)
    } else {
      invoicePageModel.setIsAddMode(true)
      invoicePageModel.setIsEditMode(false)
    }
  }, [params])

  useEffect(() => {
    if (clientReference || clientName) {
      runInAction(() => {
        invoicePageModel.clientSelection.clientReference = clientReference ?? undefined
        invoicePageModel.clientSelection.clientName = clientName ?? undefined
      })
    }
  }, [])

  useEffect(() => {
    if (invoicePageModel.isEditMode) {
      if (invoice) invoicePageModel.setInvoice(invoice)
    }
  }, [invoice])

  useEffect(() => {
    if (getOrAddInvoice && invoicePageModel.isAddMode) {
      runInAction(() => {
        invoicePageModel.invoiceReference.text = getOrAddInvoice?.getOrAddInvoice.invoiceReference
        invoicePageModel.concurrencyToken.text = getOrAddInvoice.getOrAddInvoice.concurrencyToken
      })
    }
  }, [getOrAddInvoice])

  useEffect(() => {
    if (invoiceReminders) {
      const activeReminders = invoiceReminders.filter((m) => m.isEnabled === true)
      setActiveReminderCount(activeReminders.length)
    }
  }, [invoiceReminders])

  useEffect(() => {
    if (templates) invoicePageModel.setTemplates(templates, invoicePageModel.isAddMode)
  }, [templates, invoicePageModel.isAddMode])

  const handleSubmit = async (approve: boolean, sendEmail: boolean, preview: boolean) => {
    const input: UpdateInvoiceInput = invoicePageModel.getUpdateInvoiceInput(invoice)
    updateInvoiceMutation(input, {
      onSuccess: (response) => {
        invoicePageModel.setInvoiceNumber(response?.data.data.updateInvoice.invoiceNumber)
        invoicePageModel.setConcurrencyToken(response?.data.data.updateInvoice.concurrencyToken)
        // if user clicked on approve invoice then approve
        // the invoice and send email to client.
        if (approve) approveInvoice(sendEmail)
        else if (preview) previewInvoice()
        else if (sendEmail) invoicePageModel.setOpenSendEmailModal(true)
        else handleSuccess()
      },
      onError: (error: any) => {
        invoicePageModel.handleApiErrors(error)
        handleServerError(error.response)
      }
    })
  }

  const approveInvoice = (sendEmail: boolean) => {
    let updateInvoiceStatus: UpdateInvoiceStatusInput = {
      invoiceReference: invoicePageModel.invoiceReference.text,
      concurrencyToken: invoicePageModel.concurrencyToken.text,
      invoiceStatusReference: invoiceStatus.approved.reference
    }
    updateInvoiceStatusMutation(updateInvoiceStatus, {
      onSuccess: (response) => {
        invoicePageModel.setConcurrencyToken(response?.data.data.updateInvoiceStatus.concurrencyToken)
        if (sendEmail) invoicePageModel.setOpenSendEmailModal(true)
        else handleSuccess()
      },
      onError: (error: any) => {
        handlePopupError(error.response)
      }
    })
  }

  const previewInvoice = () => {
    setLoadingPage(true)
    // If its add Invoice page then navigate user to edit Invoice page first
    if (invoicePageModel.isAddMode) navigate(`/invoices/edit/${invoicePageModel.invoiceReference.text}`)

    // If we already have a report link just open the pdf modal
    // This can happen if user is opening the preview more than once
    if (invoicePageModel.pdfModal.reportLink) {
      setLoadingPage(false)
      invoicePageModel.setPdfModal(true, invoicePageModel.pdfModal.reportLink)
    }
    // generate report link and open viewInvoicePdf Modal
    else getReportLink()
  }

  const getReportLink = () => {
    const input: GenerateReportLinkInput = {
      reportReference: reportType.invoice.reference,
      reportParameters: [
        {
          normalizedName: "ir",
          value: invoicePageModel.invoiceReference.text ?? ""
        }
      ]
    }
    generateLink(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (response) => {
        if (response) invoicePageModel.setPdfModal(true, response.data.data.generateReportLink.reportUrl)
      },
      onSettled: () => setLoadingPage(false)
    })
  }

  function invalidateQueries() {
    void queryClient.invalidateQueries([keys.invoiceQuery])
    // we are invalidating getOrAddInvoice query after we create an invoice each time.
    // NOTE: The query will not refresh on invalidating as we are passing refetchActive: false
    // It will only load when visiting create screen after invalidating or cache is cleared.
    void queryClient.invalidateQueries(keys.getOrAddInvoice, { refetchActive: false })
  }

  function handleSuccess() {
    if (invoicePageModel.isAddMode)
      successToaster(
        `Invoice has been created.`,
        "",
        `View ${invoicePageModel.invoiceNumber.text ?? ""}`,
        `invoices/${invoicePageModel.invoiceReference.text}?useModal=false`
      )
    else
      successToaster(
        `Invoice has been updated.`,
        "",
        `View ${invoicePageModel.invoiceNumber.text ?? ""}`,
        `invoices/${invoicePageModel.invoiceReference.text}?useModal=false`
      )

    invalidateQueries()
    navigate("/invoices")
  }

  const handleSendEmail = () => {
    // close pdf modal
    invoicePageModel.setPdfModal(false, invoicePageModel.pdfModal.reportLink)
    invoicePageModel.setOpenSendEmailModal(true)
  }

  return (
    <>
      <div className="space-y-2.5 py-3 pb-24 h-full flex-1 overflow-y-auto min-screen-width relative">
        <Card width={"max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl"}>
          <CardHeader title={invoicePageModel.isAddMode ? "Create Invoice" : "Edit Invoice"}
                      hasButton={false}></CardHeader>
          <CardBody>
            <Form name={"Invoice Detail Section"}>
              <OrganisationLogoDisplay></OrganisationLogoDisplay>
              <InvoiceOrganisationDetail></InvoiceOrganisationDetail>

              <ClientSelectionComponent
                name="client"
                value={invoicePageModel.clientSelection}
                isRequired={true}
                showAddClientLink={true}
              ></ClientSelectionComponent>

              <div className={"col-span-3"}>
                <div className={"grid grid-cols-2 gap-x-4"}>
                  <div>
                    <InvoiceNumber
                      value={invoicePageModel.invoiceNumber}
                      isEditMode={invoicePageModel.isEditMode}
                    ></InvoiceNumber>
                  </div>
                  <div>
                    <TextInputGroup
                      cols={3}
                      label="Reference"
                      id="additionalReference"
                      value={invoicePageModel.additionalReference}
                      maxLength={250}
                    />
                  </div>
                </div>
              </div>

              <ClientAddressSelection
                addressSelectionObservable={invoicePageModel.addressSelection}
                clientSelectionObservable={invoicePageModel.clientSelection}
                isAdding={invoicePageModel.isAddMode}
              ></ClientAddressSelection>

              <div className={"col-span-3"}>
                <div className={"grid grid-cols-2 gap-x-4"}>
                  <div>
                    <DatePicker
                      label={"Invoice Date"}
                      name={"invoiceDate"}
                      value={invoicePageModel.invoiceDateTime}
                      placeholder={"Required"}
                      isRequired={true}
                    ></DatePicker>
                  </div>
                  <div>
                    <DatePicker
                      label={"Due Date"}
                      showDueInDays={true}
                      name={"dueDate"}
                      value={invoicePageModel.dueDateTime}
                      customLabel={
                        invoicePageModel.dueDateTime.date &&
                        activeReminderCount > 0 && (
                          <ToolTip
                            className={"w-50"}
                            overlay={
                              <>
                                <span>{activeReminderCount} reminders will be generated for this invoice.</span>
                                <p></p>
                                <span>
                                  <Link to="/settings/invoice" target="_blank" className="underline">
                                    Click here
                                  </Link>{" "}
                                  to manage invoice reminders.
                                </span>
                              </>
                            }
                          >
                            <span
                              className="font-light text-xs ml-2">({activeReminderCount} Reminders will be generated.)</span>
                          </ToolTip>
                        )
                      }
                      placeholder={"Optional"}
                    ></DatePicker>
                  </div>
                </div>
              </div>

              <div className={"col-span-3"}>
                <div className={"grid grid-cols-2 gap-x-4"}>
                  <div>
                    <CurrencySelection
                      id={"currency"}
                      label={"Currency"}
                      value={invoicePageModel.currencySelection}
                      placeholder={"Required"}
                      isClearable={false}
                    ></CurrencySelection>
                  </div>
                  <div>
                    <StaffSelection
                      label="Sale Staff"
                      name="saleStaffMember"
                      value={invoicePageModel.staffSelection}
                    ></StaffSelection>
                  </div>
                </div>
              </div>

              <div className="col-span-3">
                <div className={"grid grid-cols-2 gap-x-4"}>
                  <div>
                    <JobSelection
                      label="Job"
                      name="job"
                      isClearable={true}
                      jobReference={jobReference ?? undefined}
                      clientSelectionObservable={invoicePageModel.clientSelection}
                      value={invoicePageModel.jobSelection}
                      addressSelectionObservable={invoicePageModel.addressSelection}
                      placeholder={"Search by Job Number"}
                    />
                  </div>

                  {invoicePageModel.isEditMode && (
                    <>
                      <div>
                        <InvoiceStatusSelection
                          value={invoicePageModel.invoiceStatus}
                          invoiceReference={invoicePageModel.invoiceReference.text}
                          concurrencyToken={invoicePageModel.concurrencyToken.text}
                          onConcurrencyTokenChange={(token) => invoicePageModel.setConcurrencyToken(token)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>

            <div className={"px-6 pb-6"}>
              <InvoiceLineItems
                lineItemComponentModel={invoicePageModel.lineItemModel}
                localeCode={`${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`}
                currencySelection={invoicePageModel.currencySelection}
                orgCurrency={organisation?.currency.currencyCode}
              ></InvoiceLineItems>
            </div>
          </CardBody>
        </Card>

        <Accordian
          width="max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl"
          title="Invoice Preferences"
          showLink={false}
          openByDefault={true}
        >
          <Form>
            <TextAreaInputGroup
              rows={12}
              label="Message For Client"
              name="description"
              toolTip="Enter supplementary information to be displayed on the client's invoice. Utilize this space to provide essential details or convey professional messages tailored to your clients."
              value={invoicePageModel.description}
              component={
                invoicePageModel.messageForClientTemplates.length > 0 ? (
                  <>
                    <MenuButtonComponent<Template>
                      button={<div className="text-sm text-primary-800 hover:text-primary-900">Choose Message
                        Template</div>}
                      options={invoicePageModel.messageForClientTemplates}
                      optionLabel="name"
                      onSelect={(option) => {
                        runInAction(() => {
                          invoicePageModel.description.text = option.descriptionTemplate
                        })
                      }}
                    />{" "}
                    <ToolTip
                      overlay={
                        <span>
                          <Link to="/settings/template" target="_blank" className="underline">
                            Click here
                          </Link>{" "}
                          to manage custom message templates
                        </span>
                      }
                    >
                      <i className="far fa-circle-info text-gray-700 ml-2"></i>
                    </ToolTip>
                  </>
                ) : undefined
              }
              maxLength={1500}
              showTemplateTooltip={false}
              cols={3}
            />

            <TextAreaInputGroup
              label="Payment Instructions For Client"
              name="paymentTerms"
              toolTip="Provide your preferred payment method, bank account information, or any specific instructions to guide your clients on completing the transaction process efficiently."
              value={invoicePageModel.paymentTerms}
              component={
                invoicePageModel.paymentInstructionTemplates.length > 0 ? (
                  <>
                    <MenuButtonComponent<Template>
                      button={<div className="text-sm text-primary-800 hover:text-primary-900">Choose Payment
                        Template</div>}
                      options={invoicePageModel.paymentInstructionTemplates}
                      optionLabel="name"
                      onSelect={(option) => {
                        runInAction(() => {
                          invoicePageModel.paymentTerms.text = option.descriptionTemplate
                        })
                      }}
                    />
                    <ToolTip
                      overlay={
                        <span>
                          <Link to="/settings/template" target="_blank" className="underline">
                            Click here
                          </Link>{" "}
                          to manage custom message templates
                        </span>
                      }
                    >
                      <i className="far fa-circle-info text-gray-700 ml-2"></i>
                    </ToolTip>
                  </>
                ) : undefined
              }
              rows={12}
              maxLength={1500}
              showTemplateTooltip={false}
              cols={3}
            />

            <InputGroupCheckbox
              label={"Disable Automatic Reminders"}
              name={"isReminderDisabled"}
              value={invoicePageModel.isReminderDisabled}
              cols={6}
              tooltipOverlay={
                <span>
                  Toggle this checkbox to disable invoice reminders. For further customization{" "}
                  <Link to="/settings/invoice" target="_blank" className="underline">
                    click here
                  </Link>{" "}
                  to configure reminder preferences and schedules.
                </span>
              }
            ></InputGroupCheckbox>

            {invoicePageModel.isEditMode && (
              <>
                <InputGroupCheckbox label="Mark As Sent To Client" name="isSent" value={invoicePageModel.isSent}
                                    cols={6} />

                <InputGroupCheckbox label="Mark As Disputed" name="isDisputed" value={invoicePageModel.isDisputed}
                                    cols={6} />
              </>
            )}
          </Form>
        </Accordian>

        <div className="relative z-10">
          <DocumentCard
            entityReference={invoicePageModel.invoiceReference}
            entityTypeReference={entityTypes.invoice.entityTypeReference}
            width="max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl"
          />
        </div>

        <div className="relative z-5">
          <ReminderCard
            entityReference={invoicePageModel.invoiceReference}
            entityTypeReference={entityTypes.invoice.entityTypeReference}
            width="max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl"
          />
        </div>

        <NoteCard
          entityReference={invoicePageModel.invoiceReference}
          entityTypeReference={entityTypes.invoice.entityTypeReference}
          width="max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl"
        />

        <div className={`px-6 mx-auto max-w-[64rem] xl13:max-w-[70rem] xl14:max-w-[74rem] xl15:max-w-7xl`}>
          <div className="flex flex-row justify-end space-x-6 py-6">
            <ButtonSecondary disabled={isLoading} onClick={() => navigate("/invoices")}>
              Cancel
            </ButtonSecondary>

            <AddOrEditInvoiceButtonDropdown
              onSubmit={handleSubmit}
              isLoading={isLoading}
              isAddMode={invoicePageModel.isAddMode}
              isDraftInvoice={invoicePageModel.invoiceStatus.invoiceStatusReference === invoiceStatus.draft.reference}
            />
          </div>
        </div>
      </div>

      <SendEmailPage
        title="Email Invoice"
        senderEntityTypeReference={entityTypes.invoice.entityTypeReference}
        senderEntityReference={invoicePageModel.invoiceReference.text ?? ""}
        recipientEntityTypeReference={entityTypes.client.entityTypeReference}
        recipientEntityReference={invoicePageModel.clientSelection.clientReference ?? ""}
        onEmailSent={() => handleSuccess()}
        isOpen={invoicePageModel.openSendEmailModal}
        onClose={() => handleSuccess()}
        emailConfiguration={invoiceEmailConfig}
      ></SendEmailPage>

      <ViewInvoicePdfModal
        isOpen={invoicePageModel.pdfModal.isOpen}
        onClose={() => {
          if (invoicePageModel.isAddMode) handleSuccess()
          else if (invoicePageModel.isEditMode) invoicePageModel.setPdfModal(false, invoicePageModel.pdfModal.reportLink)
        }}
        reportLink={invoicePageModel.pdfModal.reportLink}
        invoiceReference={invoicePageModel.invoiceReference.text ?? ""}
        concurrencyToken={invoicePageModel.concurrencyToken.text ?? ""}
        isEditMode={invoicePageModel.isEditMode}
        onSendEmail={handleSendEmail}
        canApproveInvoice={
          invoicePageModel.invoiceStatus.invoiceStatusReference === invoiceStatus.draft.reference ||
          invoicePageModel.invoiceStatus.invoiceStatusReference === invoiceStatus.submitted.reference
        }
      />
    </>
  )
}

export default observer(AddOrEditInvoicePage)
