import { makeAutoObservable, runInAction } from "mobx"

export class MultiSelectObservable<TItem> {
  selectedItems: TItem[]

  constructor() {
    makeAutoObservable(this)
    this.selectedItems = []
  }

  any() {
    return this.selectedItems.length > 0
  }

  addItem(item: TItem) {
    runInAction(() => {
      this.selectedItems = [...this.selectedItems, item]
    })
  }

  addItems(items: TItem[]) {
    runInAction(() => {
      this.selectedItems = [...items]
    })
  }
}
