import React from "react"
import Accordian from "library/styled/accordian"
import { Form } from "library/styled/styled"
import StyledDropzone from "library/styled/dropzone"
import { InputGroupButton } from "library/styled/input-group"
import { useModalState } from "utils/modalStates"
import { observer } from "mobx-react"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import UploadFileModal from "components/shared/documents/02_uploadFile/uploadFile"
import LinkExistingFileModal from "components/shared/documents/03_linkExistingFile/linkExistingFile"
import DocumentListTemplate from "components/shared/documents/01_documentCard/documentListTemplate"

interface Props {
  entityReference: TextObservable
  entityTypeReference: string
  width?: string
}

const DocumentCard = ({ entityReference, entityTypeReference, width }: Props) => {
  const { addModal, setAddModal, editModal, setEditModal } = useModalState<File>()

  return (
    <>
      <Accordian width={width} title="Documents" showLink={false}>
        <Form>
          <StyledDropzone onFileDropped={(file) => setEditModal({ item: file, isOpen: true })} />
          <InputGroupButton type="button" isPrimary={false} buttonText="Link Existing File" onClick={() => setAddModal(true)} />
        </Form>
        <DocumentListTemplate
          entityTypeReference={entityTypeReference}
          entityReference={entityReference.text}
          isSelected={true}
        />
      </Accordian>

      <LinkExistingFileModal
        isOpen={addModal}
        onClose={() => setAddModal(false)}
        entityReference={entityReference.text}
        entityTypeReference={entityTypeReference}
      />

      <UploadFileModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        file={editModal.item}
        entityReference={entityReference.text}
        entityTypeReference={entityTypeReference}
      />
    </>
  )
}

export default observer(DocumentCard)
