import { KeyValuePair } from "models/shared/common"

export const timeSlots: KeyValuePair[] = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
]

export const timeLabelIntervals: KeyValuePair[] = [
  {
    label: "15 Mins",
    value: 15,
  },
  {
    label: "30 Mins",
    value: 30,
  },
  {
    label: "45 Mins",
    value: 45,
  },
  {
    label: "1 Hour",
    value: 60,
  },
]

export const dayHours: KeyValuePair[] = [
  {
    label: "12 AM",
    value: "00:00",
  },
  {
    label: "1 AM",
    value: "01:00",
  },
  {
    label: "2 AM",
    value: "02:00",
  },
  {
    label: "3 AM",
    value: "03:00",
  },
  {
    label: "4 AM",
    value: "04:00",
  },
  {
    label: "5 AM",
    value: "05:00",
  },
  {
    label: "6 AM",
    value: "06:00",
  },
  {
    label: "7 AM",
    value: "07:00",
  },
  {
    label: "8 AM",
    value: "08:00",
  },
  {
    label: "9 AM",
    value: "09:00",
  },
  {
    label: "10 AM",
    value: "10:00",
  },
  {
    label: "11 AM",
    value: "11:00",
  },
  {
    label: "12 PM",
    value: "12:00",
  },
  {
    label: "1 PM",
    value: "13:00",
  },
  {
    label: "2 PM",
    value: "14:00",
  },
  {
    label: "3 PM",
    value: "15:00",
  },
  {
    label: "4 PM",
    value: "16:00",
  },
  {
    label: "5 PM",
    value: "17:00",
  },
  {
    label: "6 PM",
    value: "18:00",
  },
  {
    label: "7 PM",
    value: "19:00",
  },
  {
    label: "8 PM",
    value: "20:00",
  },
  {
    label: "9 PM",
    value: "21:00",
  },
  {
    label: "10 PM",
    value: "22:00",
  },
  {
    label: "11 PM",
    value: "23:00",
  },
  {
    label: "12 Midnight",
    value: "24:00",
  },
]

export const dayOfWeekOptions: KeyValuePair[] = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
]
