import React from "react"
import { observer } from "mobx-react"
import TextInputGroup, { TextObservable } from "library/textInputGroup/textInputGroup"
import { A, Form } from "library/styled/styled"
import { isProvided } from "utils/extensions"

interface Props {
  onSave: (recipientName: string, recipientEmailAddress: string) => void
  onClose: () => void
}

const AddEmailRecipient = (props: Props) => {
  const recipientName: TextObservable = new TextObservable()
  const recipientEmailAddress: TextObservable = new TextObservable()

  function save() {
    if (isProvided(recipientName.text) && isProvided(recipientEmailAddress.text)) {
      props.onSave(recipientName.text ?? "", recipientEmailAddress.text ?? "")
    }
  }

  return (
    <>
      <div className="p-6 grid grid-cols-1 gap-y-6">
        <TextInputGroup
          cols={6}
          id={"RecipientName"}
          label="Recipient Name"
          value={recipientName}
          isRequired={true}
          maxLength={50}
        />
        <TextInputGroup
          cols={6}
          isRequired={true}
          maxLength={256}
          isEmail={true}
          id={"RecipientEmailAddress"}
          label="Recipient Email Address"
          value={recipientEmailAddress}
        />
        <A onClick={props.onClose}>Close</A>
        <A onClick={save}>Save</A>
      </div>
    </>
  )
}

export default observer(AddEmailRecipient)
