import React, { useState, useRef, useEffect } from "react"
import { DlGroup, Dt } from "library/styled/styled"

interface Props {
  label: string
  value?: string
}

const TextAreaDynamicView = ({ label, value }: Props) => {
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      setTextAreaHeight(textAreaRef.current.scrollHeight)
      textAreaRef.current.style.overflowY = "hidden"
    }
  }, [value])

  return (
    <DlGroup cols={2}>
      <Dt>{label}</Dt>
      <textarea
        ref={textAreaRef}
        value={value}
        style={{ height: textAreaHeight, backgroundColor: "transparent", resize: "none" }}
        className="mt-1 block w-full sm:text-sm p-0 border-none focus:outline-none focus:shadow-none focus:ring-transparent"
        readOnly={true}
      />
    </DlGroup>
  )
}

export default TextAreaDynamicView
