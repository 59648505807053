import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import {
  AddPaymentMethodInput,
  RemovePaymentMethodInput,
  PaymentMethods,
  UpdatePaymentMethodInput,
} from "models/finance/paymentMethod"

function usePaymentMethod(enabled: boolean,includeAll: boolean) {
  return useQuery(keys.paymentMethodQuery, fetchPaymentMethods, {
    enabled: enabled,
    select: (data: Response<PaymentMethods>) => 
    includeAll ? data.data.data.paymentMethods : data.data.data.paymentMethods.filter(m => !m.isDisabled),
  })
}

function fetchPaymentMethods() {
  return graphQLClient.fetch(query.paymentMethodQuery, null, true, common.accountingApiUrl, keys.paymentMethodQuery)
}

function addPaymentMethod(input: AddPaymentMethodInput) {
  return graphQLClient.mutation(mutation.addPaymentMethod, input, "AddPaymentMethod", common.accountingApiUrl)
}

function useAddPaymentMethod() {
  return useMutation(addPaymentMethod)
}

function updatePaymentMethod(input: UpdatePaymentMethodInput) {
  return graphQLClient.mutation(mutation.updatePaymentMethod, input, "UpdatePaymentMethod", common.accountingApiUrl)
}

function useUpdatePaymentMethod() {
  return useMutation(updatePaymentMethod)
}

function removePaymentMethod(input: RemovePaymentMethodInput) {
  return graphQLClient.mutation(mutation.removePaymentMethod, input, "RemovePaymentMethod", common.accountingApiUrl)
}

function useRemovePaymentMethod() {
  return useMutation(removePaymentMethod)
}

export { usePaymentMethod, useAddPaymentMethod, useUpdatePaymentMethod, useRemovePaymentMethod }
