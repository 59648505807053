import React, { Fragment, useEffect, useState } from "react"
import { jobColumns } from "constants/columns"
import { jobSortType } from "constants/types"
import { useJob, useJobState } from "hooks/job/job"
import DataGrid from "library/styled/datagrid"
import { JobQueryInput } from "models/job/job"
import { Column } from "models/shared/column"
import useLocalStorageState from "utils/localStorage"

function Jobs() {
  const [columns, setColumns] = useLocalStorageState("jobColumns", jobColumns)
  let input: JobQueryInput = {
    first: 10,
    after: "",
    jobReference: "",
    jobNumber: "",
    startDateTimeUtc: "",
    endDateTimeUtc: "",
    stateReferences: [],
    clientReferences: [],
    staffReferences: [],
    organisationCategoryReferences: [],
    sortReference: jobSortType.createdDateTime,
    address: "",
    unAssigned: "false",
    insertedByStaffReference: "",
  }
  const { jobs, fetchNextPage, hasNextPage } = useJob(input)
  const { data: states } = useJobState()
  const [viewDetail, setViewDetail] = useState(false)
  const [statusColor, setStatusColor] = useState<{ [key: string]: string }>()

  useEffect(() => {
    if (states) {
      let colors: { [key: string]: string } = {}
      states.forEach((state) => {
        colors[state.stateReference] = state.color
      })
      setStatusColor(colors)
    }
  }, [states])

  const hanldeViewDetail = () => {
    setViewDetail(true)
  }

  const handleColumnChange = (res: Column[]) => {
    setColumns(res)
  }

  return (
    <Fragment>
      <div className="grid grid-cols-5">
        <div className={`${viewDetail ? "col-span-3" : "col-span-5"}`}>
          <DataGrid
            title="Jobs"
            buttonText="Create Job"
            data={jobs}
            columns={columns}
            dataKey="jobReference"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onRowClick={hanldeViewDetail}
            onColumnChange={handleColumnChange}
            statusColor={statusColor}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default Jobs
