import React, { useEffect, useState } from "react"
import { entityTypes } from "constants/entityTypes"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import SelectInputGroup from "library/selectInputGroup/selectInputGroup"
import ClientSelectionComponent from "library/components/clientSelectionComponent"
import StaffSelection from "library/components/staffSelection"
import FileSelection from "library/FileSelection/fileSelection"
import { InputGroupButton } from "library/styled/input-group"
import { useQueryClient } from "react-query"
import { observer } from "mobx-react"
import ContractorSelection from "library/contractorSelection/contractorSelection"
import { LinkExistingFileModel } from "components/shared/documents/03_linkExistingFile/linkExistingFileModel"
import { FileSource } from "components/shared/documents/04_sharedModel/models"
import JobSelection from "library/jobSelection/jobSelection"
import { handleServerError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import GeneralErrors from "library/generalErrors/generalErrors"
import { useLinkFile } from "components/shared/documents/services/documentCardService"

interface Props {
  isOpen: boolean
  onClose: () => void
  entityReference?: string
  entityTypeReference: string
}

const LinkExistingFileModal = ({ isOpen, onClose, entityReference, entityTypeReference }: Props) => {
  const [model] = useState(() => new LinkExistingFileModel())
  const { mutate: linkFile, isLoading: isSaving } = useLinkFile()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen && entityTypeReference) model.reset(entityTypeReference)
  }, [isOpen, entityTypeReference])

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()

    const input = {
      fileReference: model.file.fileReference,
      entityTypeReference: entityTypeReference,
      entityReference: entityReference ?? "",
    }
    linkFile(input, {
      onError: (error: any) => {
        model.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.fileQuery)
        onClose()
        successToaster("File linked")
      },
    })
  }

  return (
    <>
      <Modal title="Link Existing File" isOpen={isOpen} onClose={onClose} width="max-w-lg">
        <Form onSubmit={handleSubmit}>
          <SelectInputGroup<FileSource>
            label="Select Source"
            id="fileSource"
            options={model.fileSources}
            value={model.source}
            optionLabel="name"
            optionValue={"entityTypeReference"}
            isInModal={true}
            onChange={() => model.resetEntitySelection()}
            cols={6}
          />

          {model.source.selectedOption?.entityTypeReference === entityTypes.client.entityTypeReference && (
            <ClientSelectionComponent
              label="Client Search"
              name="clientSearch"
              isClearable={true}
              value={model.client}
              onChange={() => model.setFileEntityReference()}
              cols={6}
            />
          )}

          {model.source.selectedOption?.entityTypeReference === entityTypes.staff.entityTypeReference && (
            <StaffSelection
              label="Staff Search"
              name="staffSearch"
              isInModal={true}
              value={model.staff}
              onChange={() => model.setFileEntityReference()}
              cols={6}
            />
          )}

          {model.source.selectedOption?.entityTypeReference === entityTypes.contractor.entityTypeReference && (
            <ContractorSelection
              label="Contractor Search"
              name="contractorSearch"
              isClearable={true}
              value={model.contractor}
              onChange={() => model.setFileEntityReference()}
              cols={6}
            />
          )}

          {model.source.selectedOption?.entityTypeReference === entityTypes.job.entityTypeReference && (
            <JobSelection
              label="Job Search"
              name="jobSearch"
              isClearable={true}
              value={model.job}
              onChange={() => model.setFileEntityReference()}
              cols={6}
            />
          )}

          <FileSelection label="File Name" value={model.file} name="fileSearch" linkExistingFileModel={model} cols={6} />

          <GeneralErrors errorMessages={model.generalErrors} />
          <InputGroupButton isLoading={isSaving} />
        </Form>
      </Modal>
    </>
  )
}

export default observer(LinkExistingFileModal)
