import _ from "lodash"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { useFeature } from "context/feature-context"
import { DndModal } from "library/styled/dndModal"
import { InputGroupButton, InputGroupCheckbox } from "library/styled/input-group"
import { Column } from "models/shared/column"
import { EnabledFeature } from "models/identity/features"

interface Props {
  isOpen: boolean
  onClose: () => void
  columns: Column[]
  onChange: (cols: Column[]) => void
}

const ClientListSettings = ({ isOpen, onClose, columns, onChange }: Props) => {
  const [temp, setTemp] = useState<Column[]>(_.cloneDeep(columns))

  const { enabledFeatures } = useFeature()

  useEffect(() => {
    setTemp(_.cloneDeep(columns))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  // a little function to help us with reordering the result
  const reorder = (list: Column[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(temp, result.source.index, result.destination.index)
    setTemp(items)
  }

  const handleColumnChange = (res: Column) => {
    const result = Array.from(temp)
    var col = result.find((m) => m.id === res.id)
    if (col !== undefined) col.isEnabled = !res.isEnabled
    setTemp(result)
  }

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()
    onChange(temp)
    onClose()
  }

  return (
    <>
      <DndModal title="Column Setting" isOpen={isOpen} onClose={onClose}>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <form onSubmit={handleSubmit}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="vertical-list">
                {(provided) => (
                  <ul
                    role="presentation"
                    className="divide-y divide-gray-200"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {temp.map(
                      (column, index) =>
                        enabledFeatures?.[column.featureName as keyof EnabledFeature] && (
                          <Draggable draggableId={column.id} index={index} key={column.id}>
                            {(provided, snapshot) => (
                              <li
                                key={column.name}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className="bg-white"
                                style={{
                                  border: snapshot.isDragging ? "1px solid rgb(29 81 166)" : "",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div className="block hover:bg-gray-50 hover:cursor-move">
                                  <div className="flex items-center p-2">
                                    <div className="min-w-0 flex-1 flex items-center">
                                      <div className="min-w-0 flex-1 px-4">
                                        <div>
                                          <div className="text-sm text-gray-700">
                                            <InputGroupCheckbox
                                              name={column.name}
                                              label={column.name}
                                              value={column.isEnabled}
                                              onChange={() => handleColumnChange(column)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {" "}
                                        <i className="flex-shrink-0 mr-5 text-gray-400 fas fa-grip-lines" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        )
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

            <div className="p-6">
              <InputGroupButton />
            </div>
          </form>
        </div>
      </DndModal>
    </>
  )
}

export default ClientListSettings
