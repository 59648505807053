import React, { useEffect, useState } from "react"
import { useAddPayment } from "components/payment/services/paymentService"
import { usePaymentMethod } from "hooks/finance/paymentMethod"
import { AddPaymentModel } from "components/payment/01_addPayment/addPaymentModel"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import DatePicker from "library/observables/datePickers"
import SelectInputGroup from "library/selectInputGroup/selectInputGroup"
import { PaymentMethod } from "models/finance/paymentMethod"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { useMetadata } from "context/metadata-context"
import { AddPaymentInput } from "components/payment/05_sharedModel/paymentModel"
import { handleServerError } from "common"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import { observer } from "mobx-react"
import NumberInputGroup from "library/numberInputGroup/numberInputGroup"
import AddPaymentButtonDropdown from "components/payment/06_sharedComponent/addPaymentButtonDropdown"
import SendEmailPage from "components/invoices/sendEmail/sendEmailModal"
import { entityTypes } from "constants/entityTypes"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { invoicePaymentEmailConfig } from "constants/emailConfiguration"

interface Props {
  entityTypeReference: string
  entityReference: string
  isOpen: boolean
  onClose: () => void
  invoiceCurrencyCode: string
  concurrencyToken: string
  clientReference: string
}

const AddPaymentModal = ({
  entityReference,
  entityTypeReference,
  isOpen,
  onClose,
  invoiceCurrencyCode,
  concurrencyToken,
  clientReference,
}: Props) => {
  const [model] = useState(() => new AddPaymentModel())
  const { mutate: addPayment, isLoading } = useAddPayment()
  const { data: paymentMethods } = usePaymentMethod(model.enableQuery, false)
  const { organisation } = useMetadata()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen) {
      model.setEnableQuery(true)
      model.resetForm()
    }
  }, [isOpen])

  useEffect(() => {
    if (paymentMethods) model.setPaymentMethod(paymentMethods[0])
  }, [paymentMethods])

  const handleSubmit = (sendEmail: boolean) => {
    const input: AddPaymentInput = {
      paymentReference: "",
      paymentNumber: "",
      entityReference: entityReference,
      entityTypeReference: entityTypeReference,
      paymentMethodReference: model.paymentMethod.selectedOption?.paymentMethodReference ?? "",
      currencyRate: invoiceCurrencyCode === organisation?.currency.currencyCode ? 1 : model.currencyRate.number,
      paymentDateTime: model.paymentDate.getUtcDateTime(),
      amount: model.amountPaid.number ?? null,
      description: model.description.text ?? "",
      concurrencyToken: concurrencyToken,
    }

    addPayment(input, {
      onError: (error: any) => {
        handleServerError(error.response)
        model.handleApiErrors(error)
      },
      onSuccess: (response) => {
        model.setPaymentReference(response?.data.data.addPayment.paymentReference)
        void queryClient.invalidateQueries([keys.paymentQuery, { entityReference, entityTypeReference }])
        void queryClient.invalidateQueries([keys.invoiceQuery])

        if (sendEmail) model.setOpenEmailModal(true)
        else successToaster("Payment Added")
        onClose()
      },
    })
  }

  const handleEmailSent = () => {
    model.setOpenEmailModal(false)
    void queryClient.invalidateQueries([keys.emailsQuery, { entityReference, entityTypeReference }])
    successToaster("Email sent")
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Add Payment"
        width="max-w-md"
        footer={true}
        saveButton={<AddPaymentButtonDropdown onSubmit={handleSubmit} isLoading={isLoading} />}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <DatePicker label="Date Paid" value={model.paymentDate} name="paidDate" cols={6} placeholder="Required" />
          <SelectInputGroup<PaymentMethod>
            id="paymentMethod"
            label="Payment Method"
            value={model.paymentMethod}
            options={paymentMethods}
            optionValue={"paymentMethodReference"}
            optionLabel={"name"}
            isInModal={true}
            cols={6}
          />
          {invoiceCurrencyCode !== organisation?.currency.currencyCode && (
            <NumberInputGroup id="currencyRate" label="Currency Rate" value={model.currencyRate} cols={6} />
          )}
          <NumberInputGroup id="amountPaid" label="Amount Paid" value={model.amountPaid} cols={6} />
          <TextAreaInputGroup name="description" label="Description" value={model.description} />
        </Form>
      </Modal>

      <SendEmailPage
        title="Email Receipt"
        senderEntityTypeReference={entityTypes.payment.entityTypeReference}
        senderEntityReference={model.paymentReference}
        recipientEntityTypeReference={entityTypes.client.entityTypeReference}
        recipientEntityReference={clientReference}
        onEmailSent={handleEmailSent}
        onClose={() => model.setOpenEmailModal(false)}
        isOpen={model.openEmailModal}
        emailConfiguration={invoicePaymentEmailConfig}
      ></SendEmailPage>
    </>
  )
}

export default observer(AddPaymentModal)
