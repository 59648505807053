import React from "react"
import { BooleanView, DescriptionView, TextView } from "library/styled/description-list"
import { Modal } from "library/styled/modal"
import { Dl } from "library/styled/styled"
import { TaskStatus } from "../../../Task/04_sharedModel/taskModel"

interface Props {
  isOpen: boolean
  onClose: () => void
  taskStatus?: TaskStatus
}

const ViewTaskStatus = ({ isOpen, onClose, taskStatus }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Task Status Details" footer={false}>
    <Dl>
      <TextView label="Name" value={taskStatus?.name} />
      <TextView label="Color" value={taskStatus?.color} />
      <TextView label="Display Order" value={taskStatus?.displayOrder} />
      <BooleanView label="Disabled" value={taskStatus?.isDisabled} />
      <DescriptionView value={taskStatus?.description} />
    </Dl>
  </Modal>
)

export default ViewTaskStatus
