export const fileType: any = {
  ".tiff": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".pptx": {
    icon: "fal fa-file-powerpoint fa-2x",
    style: {
      color: "#D04423",
    },
  },
  ".xls": {
    icon: "fal fa-file-spreadsheet fa-2x",
    style: {
      color: "#1D6F42",
    },
  },
  ".ppt": {
    icon: "fal fa-file-powerpoint fa-2x",
    style: {
      color: "#D04423",
    },
  },
  ".ai": {
    icon: "fal fa-file-video fa-2x",
    style: {
      color: "#9a00de",
    },
  },
  ".docx": {
    icon: "fal fa-file-word fa-2x",
    style: {
      color: "#0078d7",
    },
  },
  ".gif": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".jpeg": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".xlsx": {
    icon: "fal fa-file-spreadsheet fa-2x",
    style: {
      color: "#1D6F42",
    },
  },
  ".tif": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".png": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".jpg": {
    icon: "fal fa-file-image fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".txt": {
    icon: "fal fa-file-alt fa-2x",
    style: {},
  },
  ".ods": {
    icon: "fal fa-file fa-2x",
    style: {},
  },
  ".pdf": {
    icon: "fal fa-file-pdf fa-2x",
    style: {
      color: "#ff0000",
    },
  },
  ".doc": {
    icon: "fal fa-file-word fa-2x",
    style: {
      color: "#0078d7",
    },
  },
  ".mp3": {
    icon: "fal fa-file-music fa-2x",
    style: {
      color: "#0078d7",
    },
  },
  ".mp4": {
    icon: "fal fa-file-video fa-2x",
    style: {
      color: "#e79627",
    },
  },
  ".odt": {
    icon: "fal fa-file fa-2x",
    style: {},
  },
}
