import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { AddContactInput, ContactQueryInput, Contacts, RemoveContactInput, UpdateContactInput } from "models/people/contact"
import { PhoneNumberQueryInput, PhoneNumbers } from "models/people/phoneNumber"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { AddAddressInput, Addresses, AddressQueryInput, RemoveAddressInput, UpdateAddressInput } from "models/people/address"

//#region contacts
function useContact(input: ContactQueryInput, enabled = false) {
  return useQuery([keys.contactQuery, input], () => fetchContacts(input), {
    select: (data: Response<Contacts>) => data.data.data.contacts.edges.map((m) => m.node),
    enabled: enabled,
  })
}

function fetchContacts(input: ContactQueryInput) {
  return graphQLClient.fetch(query.contactQuery, input, true, common.peopleApiUrl, keys.contactQuery)
}

function addContact(input: AddContactInput) {
  return graphQLClient.mutation(mutation.addContact, input, "AddContact", common.peopleApiUrl)
}

function useAddContact() {
  return useMutation(addContact)
}

function updateContact(input: UpdateContactInput) {
  return graphQLClient.mutation(mutation.updateContact, input, "UpdateContact", common.peopleApiUrl)
}

function useUpdateContact() {
  return useMutation(updateContact)
}

function removeContact(input: RemoveContactInput) {
  return graphQLClient.mutation(mutation.removeContact, input, "RemoveContact", common.peopleApiUrl)
}

function useRemoveContact() {
  return useMutation(removeContact)
}
//#endregion

//#region phone Number
function usePhoneNumber(input: PhoneNumberQueryInput, enabled = false) {
  return useQuery([keys.phoneNumberQuery, input], () => fetchPhoneNumbers(input), {
    select: (data: Response<PhoneNumbers>) => data?.data.data.phoneNumbers.edges.map((m) => m.node),
    enabled: enabled,
  })
}

function fetchPhoneNumbers(input: PhoneNumberQueryInput) {
  return graphQLClient.fetch(query.phoneNumberQuery, input, true, common.peopleApiUrl, keys.phoneNumberQuery)
}
//#endregion

//#region address
function useAddress(input: AddressQueryInput, enabled = false) {
  return useQuery([keys.addressQuery, input], () => fetchAddresses(input), {
    select: (data: Response<Addresses>) => data.data.data.addresses.edges.map((m) => m.node),
    enabled: enabled,
  })
}

function fetchAddresses(input: AddressQueryInput) {
  return graphQLClient.fetch(query.addressQuery, input, true, common.peopleApiUrl, keys.addressQuery)
}

function addAddress(input: AddAddressInput) {
  return graphQLClient.mutation(mutation.addAddress, input, "AddAddress", common.peopleApiUrl)
}

function useAddAddress() {
  return useMutation(addAddress)
}

function updateAddress(input: UpdateAddressInput) {
  return graphQLClient.mutation(mutation.updateAddress, input, "UpdateAddress", common.peopleApiUrl)
}

function useUpdateAddress() {
  return useMutation(updateAddress)
}

function removeAddress(input: RemoveAddressInput) {
  return graphQLClient.mutation(mutation.removeAddress, input, "RemoveAddress", common.peopleApiUrl)
}

function useRemoveAddress() {
  return useMutation(removeAddress)
}
//#endregion

export {
  useContact,
  useAddContact,
  useUpdateContact,
  useRemoveContact,
  usePhoneNumber,
  useAddress,
  useAddAddress,
  useUpdateAddress,
  useRemoveAddress,
}
