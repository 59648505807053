import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select, { GroupBase, OptionsOrGroups, SingleValue } from "react-select"
import { dropdownVariant } from "library/styled/dropdown"
import React from "react"
import { observer } from "mobx-react"
import { action, makeObservable, observable, runInAction } from "mobx"
import { KeyValuePair } from "models/shared/common"
import ToolTip from "library/styled/tooltip"

export class SelectObservable<T = any> {
  selectedOption?: T
  errorMessage?: string

  constructor(selectedOption?: T) {
    makeObservable(this, {
      selectedOption: observable,
      errorMessage: observable,
      setValue: action,
    })
    this.selectedOption = selectedOption
  }

  setValue(option: T) {
    this.selectedOption = option
  }
}

interface Props<T> {
  id: string
  label?: string
  onChange?: (item: SingleValue<T>) => void
  cols?: number
  options?: OptionsOrGroups<T, GroupBase<T>> | undefined
  value: SelectObservable<T>
  optionValue: keyof T
  optionLabel: keyof T
  placeholder?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  toolTip?: string
}

const SelectInputGroup = <T extends KeyValuePair>({
  id,
  label,
  onChange,
  cols = 3,
  options = [],
  value,
  optionValue,
  optionLabel,
  placeholder = "Optional",
  isInModal = false,
  isSearchable = true,
  isClearable = false,
  isDisabled = false,
  toolTip,
}: Props<T>) => {
  return (
    <FormGroup cols={cols}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {toolTip && (
            <ToolTip text={toolTip} id={"textInputGroupToolTip"}>
              <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
      )}
      <Select
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={id}
        id={id}
        value={value?.selectedOption ? value.selectedOption : null}
        getOptionLabel={(option: T) => option[optionLabel]}
        getOptionValue={(option: T) => option[optionValue]}
        onChange={(e) => {
          runInAction(() => {
            if (value) {
              value.selectedOption = e ?? undefined
            }
            if (onChange) {
              onChange(e)
            }
          })
        }}
        placeholder={placeholder}
        className="ring-primary-100"
        styles={dropdownVariant.input}
        menuPosition={isInModal ? "fixed" : undefined}
        isDisabled={isDisabled}
        menuPlacement={"bottom"}
        menuPortalTarget={document.body}
      ></Select>
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(SelectInputGroup)
