import React from "react"
import { observer } from "mobx-react"
import { CurrencySelectionObservable } from "./currencySelection"

interface Props {
  name: string
  value?: CurrencySelectionObservable
}

const CurrencyCodeLabel = (props: Props) => (
  <>
    {props.name} ({props.value?.currencyCode && props.value?.currencyCode})
  </>
)
export default observer(CurrencyCodeLabel)
