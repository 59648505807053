import React, { MutableRefObject, RefObject, useEffect, useState } from "react"
import SidePanel from "library/styled/sidePanel"
import AddEventModal from "components/scheduler/AddEventModal"
import { useAddressSetting } from "hooks/metdata/metadata"
import { SchedulerModel } from "components/scheduler/schedulerModel"
import SelectInputGroup from "library/selectInputGroup/selectInputGroup"
import { useMetadata } from "context/metadata-context"
import { KeyValuePair } from "models/shared/common"
import { dayHours, dayOfWeekOptions, timeLabelIntervals, timeSlots } from "constants/schedulerSetting"
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule"
import InputGroupCheckbox from "library/observables/inputGroupCheckbox"

interface Props {
  model: SchedulerModel
  scheduleRef: RefObject<ScheduleComponent>
}

const SchedulerSetting = ({ model, scheduleRef }: Props) => {
  const { organisation } = useMetadata()
  const { data: addressSettings } = useAddressSetting()
  const [jobTitles, setJobTitles] = useState<KeyValuePair[]>([
    { label: "Job ID + Job Status", value: "JobNumber" },
    { label: "Description", value: "Description" },
  ])

  useEffect(() => {
    if (addressSettings && organisation) {
      let titles: KeyValuePair[] = []
      let filteredSettings = addressSettings.filter((m) => m.countryCode === organisation.country.isoCode2)
      filteredSettings.forEach((m) => {
        titles.push({ label: "Address - " + m.displayName, value: m.addressComponent })
      })

      setJobTitles([...jobTitles, ...titles])
    }
  }, [addressSettings, organisation])
  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <SelectInputGroup<KeyValuePair>
          label="Job Title"
          value={model.jobTitle}
          id="jobTitle"
          options={jobTitles}
          optionValue={"value"}
          optionLabel={"label"}
          onChange={() => scheduleRef.current?.refreshEvents()}
          toolTip="Choose the information you'd like to be displayed as Job Title"
        />

        <SelectInputGroup<KeyValuePair>
          label="Time Slot"
          value={model.timeSlot}
          id="timeSlot"
          options={timeSlots}
          optionValue={"value"}
          optionLabel={"label"}
          toolTip="The frequency for displaying time slots."
        />

        <SelectInputGroup<KeyValuePair>
          label="Time Label Interval"
          value={model.timeLabelInterval}
          id="timeLabelInterval"
          options={timeLabelIntervals}
          optionValue={"value"}
          optionLabel={"label"}
          toolTip="The frequency that the time slots should be labelled with text."
        />

        <SelectInputGroup<KeyValuePair>
          label="Working Day Start"
          value={model.workingDayStart}
          id="workingDayStart"
          options={dayHours}
          optionValue={"value"}
          optionLabel={"label"}
          toolTip="Determines the first time slot that will be displayed for each day."
        />

        <SelectInputGroup<KeyValuePair>
          label="Working Day End"
          value={model.workingDayEnd}
          id="workingDayEnd"
          options={dayHours}
          optionValue={"value"}
          optionLabel={"label"}
          toolTip="Determines the last time slot that will be displayed for each day."
        />

        <SelectInputGroup<KeyValuePair>
          label="First Day of Week"
          value={model.firstDayOfWeek}
          id="firstDayOfWeek"
          options={dayOfWeekOptions}
          optionValue={"value"}
          optionLabel={"label"}
        />

        <div className="col-span-3">
          <InputGroupCheckbox
            label="Show All Events"
            value={model.rowAutoHeight}
            name="showMoreEventLink"
            tooltip="Show events in a popup if there are more than one event on the same day in the monthly view."
          />
        </div>
      </div>
    </>
  )
}

export default SchedulerSetting
