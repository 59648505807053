export const planTypes = {
  Trial: {
    id: 100,
    planTypeReference: "6864B3E8-F40D-45E9-8615-CC7CCB3CF4C7",
    name: "Trial",
  },
  Paid: {
    id: 200,
    planTypeReference: "F25252EC-9863-4AF9-A1C9-DF8129851CB4",
    name: "Paid",
  },
  Private: {
    id: 300,
    planTypeReference: "0FE568BD-AAC9-436D-A23C-3397625C2612",
    name: "Private",
  },
  Free: {
    id: 400,
    planTypeReference: "55492EDA-6F92-445A-A69E-8FFB939FFAC5",
    name: "Free",
  },
}
