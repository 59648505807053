import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useAttachments, useEmailTemplate, useSendEmail } from "components/invoices/services/invoiceService"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import sendEmailModel from "components/invoices/sendEmail/sendEmailModel"
import { emailAttachmentType } from "constants/emailAttachmentType"
import { emailConfiguration } from "constants/emailConfiguration"
import { SendInvoiceInput, Attachment } from "components/invoices/05_sharedModel/InputModels"
import { handlePopupError, handleServerError } from "common"
import { Form, FormGroup, Label } from "library/styled/styled"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import InputGroupCheckbox, { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { useUserDetail } from "hooks/identity/user"
import { emailRecipientType } from "constants/emailRecipientType"
import { fileType } from "constants/fileType"
import EmailRecipientSelection from "components/invoices/sendEmail/emailRecipientSelection"
import { Modal } from "library/styled/modal"
import { runInAction } from "mobx"
import GeneralErrors from "library/generalErrors/generalErrors"
import { useFiles } from "components/shared/documents/services/documentCardService"
import { EmailAttachment } from "components/invoices/05_sharedModel/invoiceModel"
import { useTemplate } from "components/setting/template/templateSettingService"
import MenuButtonComponent from "library/dropdownMenu/menuButtonComponent"
import { Template } from "components/setting/template/05_sharedModel/template"
import { isFilePreviewAvailable, isProvided } from "utils/extensions"
import ToolTip from "library/styled/tooltip"
import { Link } from "react-router-dom"
import { File } from "models/file/file"
import { DownloadLinkInput } from "components/file/fileModelGql"
import { useDownloadFileLink } from "components/file/fileService"
import { PreviewModal } from "components/shared/documents/04_sharedModel/models"
import PreviewFileModal from "components/file/previewFile/previewFileModal"

/**
 * known Issues
 * - When pressing tab inside popover - it closes the popup instead of cycling through elements
 * - Modal footer is touching the browser end. We need to add a little padding or margin
 * - Modal does not support loading icon when save button is clicked.
 * - Currently, this component only supports sending invoice to client. We cannot send overdue invoices,overdue statements, etc
 *   and is not designed to send quotes purchase orders etc. We have to extend this by passing in email configurations.
 * - Also description template are not supported in current release.
 * */

interface Props {
  /**
   * The entity type of recipient i.e who the email should go to. Client, Supplier etc
   * */
  recipientEntityTypeReference: string

  /**
   * The reference of the entity type i.e client reference if email is going to client else supplier reference etc.
   */
  recipientEntityReference: string

  /**
   * Which entity is sending the email? Invoice, Jobs, Quotes etc
   */
  senderEntityTypeReference: string

  /**
   * The reference of Invoice, Job, Quote etc
   */
  senderEntityReference: string

  /**
   * Type of email that should be sent. for example, invoice email, invoice payment receipt email etc.
   * We can easily expand this to more email configs.
   */
  emailConfiguration: emailConfiguration

  isOpen: boolean
  onEmailSent: () => void
  onClose: () => void
  title: string
}

const SendEmailModal = (props: Props) => {
  const [enabled, setEnabled] = useState(false)
  const [emailModel] = useState(() => new sendEmailModel())
  const { mutate: sendEmailMutation, isLoading } = useSendEmail()
  const { data: user } = useUserDetail()
  const { data: templates } = useTemplate(enabled, {
    emailConfigurationReference: props.emailConfiguration.emailConfigReference,
    includeDisabledTemplates: "false",
  })
  const [selectedTemplate, setSelectedTemplate] = useState("")
  const { data: emailTemplate, refetch } = useEmailTemplate(
    isProvided(selectedTemplate) && (props.senderEntityReference ? enabled : false),
    {
      entityReference: props.senderEntityReference,
      entityTypeReference: props.senderEntityTypeReference,
      templateReference: selectedTemplate,
      emailConfigurationReference: props.emailConfiguration.emailConfigReference,
    }
  )
  let attachments: Attachment[] = []

  const { data: files } = useFiles(
    {
      entityTypeReference: props.senderEntityTypeReference,
      entityReference: props.senderEntityReference,
    },
    enabled
  )
  const { mutate: downloadFileLink } = useDownloadFileLink()
  const [previewModal, setPreviewModal] = useState<PreviewModal>({
    file: undefined,
    fileUrl: undefined,
    isOpen: false,
  })

  useEffect(() => {
    if (props.isOpen) {
      emailModel.reset()
      setEnabled(true)
      if (templates) {
        const defaultTemplate = templates.find((m) => m.isDefault)
        if (defaultTemplate) {
          if (selectedTemplate === defaultTemplate.templateReference)
            refetch().then(() =>
              runInAction(() => {
                emailModel.emailSubject.text = emailTemplate?.emailSubject
                emailModel.emailBody.text = emailTemplate?.emailBody
              })
            )
          setSelectedTemplate(defaultTemplate.templateReference)
        }
      }
    }
  }, [props.isOpen])

  useEffect(() => {
    if (templates) {
      const defaultTemplate = templates.find((m) => m.isDefault)
      if (defaultTemplate) setSelectedTemplate(defaultTemplate.templateReference)
    }
  }, [templates])

  useEffect(() => {
    if (emailTemplate) {
      runInAction(() => {
        emailModel.emailSubject.text = emailTemplate.emailSubject
        emailModel.emailBody.text = emailTemplate.emailBody
      })
    }
  }, [emailTemplate])

  function sendEmailRequest() {
    // clear general errors when trying to send email.
    emailModel.clearErrors()

    // check before adding attribute because if error occurs
    // send email request will be called twice or more.
    if (emailModel.attributes.length <= 1) {
      emailModel.attributes.push({
        normalizedName: props.emailConfiguration.primaryAttributeName,
        value: props.senderEntityReference,
      })
    }

    const input: SendInvoiceInput = {
      entityReference: props.senderEntityReference,
      entityTypeReference: props.senderEntityTypeReference,
      subject: emailModel.emailSubject.text,
      body: emailModel.emailBody.text,
      recipients: emailModel.getRecipients(),
      attributes: emailModel.attributes,
      emailConfigurationReference: props.emailConfiguration.emailConfigReference,
      attachments: attachments,
    }

    if (emailModel.sendMeACopy.value) {
      input.recipients!.push({
        name: `${user?.givenName} ${user?.familyName}`,
        emailAddress: user?.emailAddress,
        recipientTypeReference: emailRecipientType.bcc.reference,
      })
    }

    sendEmailMutation(input, {
      onSuccess: (e) => {
        props.onEmailSent()
      },
      onError: (error: any) => {
        emailModel.handleApiErrors(error)
        handleServerError(error.response)
        attachments = []
      },
    })
  }

  const onAttachmentFileSelect = (isChecked: boolean, attachment: EmailAttachment) => {
    if (isChecked) {
      attachments.push({
        attachmentTypeReference: emailAttachmentType.file.reference,
        entityReference: attachment.fileReference,
        entityName: attachment.fileName,
      })
    } else {
      attachments.splice(
        attachments.findIndex((attachmentData) => attachmentData.entityReference === attachment.fileReference),
        1
      )
    }
  }

  const handleTemplateSelect = (template: Template) => {
    setSelectedTemplate(template.templateReference)
  }

  const getDownloadLink = (file: File, preview: boolean) => {
    const input: DownloadLinkInput = {
      fileReference: file.fileReference,
    }

    downloadFileLink(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (response) => {
        if (response) {
          if (preview) setPreviewModal({ file: file, fileUrl: response.data.data.downloadLink.url, isOpen: true })
          else downloadFile(response.data.data.downloadLink.url, file.fileName)
        }
      },
    })
  }

  const downloadFile = (url: string, fileName: string) => {
    const link = document.createElement("a")
    link.href = url
    link.download = fileName
    link.click()
  }

  return (
    <>
      <Modal
        title={props.title}
        isOpen={props.isOpen}
        onClose={props.onClose}
        footer={true}
        buttonText={"Send Email"}
        action={() => sendEmailRequest()}
        isButtonLoading={isLoading}
        clickOutside={false}
      >
        <Form fixHeight={true}>
          <EmailRecipientSelection
            recipientEntityTypeReference={props.recipientEntityTypeReference}
            recipientEntityReference={props.recipientEntityReference}
            recipients={emailModel.recipients}
            errorMessage={emailModel.recipientErrorMessage}
          ></EmailRecipientSelection>

          <TextInputGroup
            isRequired={true}
            cols={12}
            id="emailSubject"
            label="Email Subject"
            value={emailModel.emailSubject}
            component={
              templates && templates.length > 0 ? (
                <>
                  <MenuButtonComponent<Template>
                    button={<div className="text-sm text-primary-800 hover:text-primary-900">Choose Email Template</div>}
                    options={templates}
                    optionLabel="name"
                    onSelect={handleTemplateSelect}
                  />
                  <ToolTip
                    overlay={
                      <span>
                        <Link to="/settings/template" target="_blank" className="underline">
                          Click here
                        </Link>{" "}
                        to manage custom email template
                      </span>
                    }
                  >
                    <i className="far fa-circle-info text-gray-700 ml-2"></i>
                  </ToolTip>
                </>
              ) : undefined
            }
          />
          <TextAreaInputGroup
            isRequired={true}
            maxLength={1500}
            cols={6}
            rows={10}
            name={"Email Body"}
            label="Email Body"
            value={emailModel.emailBody}
          />
          <InputGroupCheckbox name={"SendMeACopy"} label={"Send me a copy"} value={emailModel.sendMeACopy}></InputGroupCheckbox>

          {files && files.length > 0 && (
            <>
              <Label className={"col-span-6"}>
                Email Attachments
                <p className={"text-xs col-span-6 pt-1 font-normal"}>
                  Please choose the invoice documents that you would like to attach to the email.
                </p>
              </Label>

              <div className={"col-span-6"}>
                <table className="min-w-full divide-y divide-gray-900">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">File</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4">Include In Attachment</th>
                      <th className="w-24 px-2"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-500 bg-white">
                    {files.map((file) => (
                      <tr key={file.fileReference}>
                        <td className="whitespace-nowrap text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              {fileType[file.extension.toLowerCase()] === undefined ? (
                                <i className="fal fa-file fa-2x text-gray-500"></i>
                              ) : (
                                <i
                                  className={`${fileType[file.extension.toLowerCase()]?.icon}`}
                                  style={{ color: fileType[file.extension.toLowerCase()]?.style?.color }}
                                ></i>
                              )}
                            </div>
                            <div className="mb-2">
                              <div className="font-normal">{file.fileName}</div>
                              <div className="text-gray-500">{file.description}</div>
                            </div>
                          </div>
                        </td>
                        <td className={"whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"}>
                          <InputGroupCheckbox
                            name={""}
                            label={""}
                            onChange={(isChecked) => {
                              onAttachmentFileSelect(isChecked, file)
                            }}
                            value={new BooleanObservable()}
                          ></InputGroupCheckbox>
                        </td>
                        <td className="flex justify-between px-2 pt-2">
                          {isFilePreviewAvailable(file.extension) ? (
                            <ToolTip text="View" showArrow={false} delay={0.6}>
                              <button type="button" onClick={() => getDownloadLink(file, true)}>
                                <i className="far fa-eye fa-sm text-primary-700 hover:bg-primary-50 rounded-md px-2 py-4"></i>
                              </button>
                            </ToolTip>
                          ) : (
                            <ToolTip
                              text="Preview is not available for this file type. You can download it instead."
                              showArrow={false}
                              delay={0.6}
                            >
                              <button type="button">
                                <i className="far fa-eye fa-sm text-gray-500 rounded-md px-2 py-4"></i>
                              </button>
                            </ToolTip>
                          )}

                          <ToolTip text="Download" showArrow={false} delay={0.6}>
                            <button type="button" onClick={() => getDownloadLink(file, false)}>
                              <i className="far fa-download fa-sm text-teal-700 hover:bg-teal-50 rounded-md px-2 py-4"></i>
                            </button>
                          </ToolTip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <GeneralErrors errorMessages={emailModel.generalErrors}></GeneralErrors>
        </Form>
      </Modal>

      <PreviewFileModal
        isOpen={previewModal.isOpen}
        onClose={() => setPreviewModal({ ...previewModal, isOpen: false })}
        file={previewModal.file}
        fileUrl={previewModal.fileUrl}
      />
    </>
  )
}

export default observer(SendEmailModal)
