import React, { useEffect, useState } from "react"
import { CurrencyFormatLabel } from "library/styled/description-list"
import InvoicePayments from "components/payment/04_paymentList/invoicePayments"
import ToolTip from "library/styled/tooltip"
import { useMetadata } from "context/metadata-context"
import { useExchangeRate } from "hooks/finance/currency"
import { ExchangeRate } from "models/finance/currency"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"

interface Props {
  invoice?: Invoice
}

const InvoiceOverviewTotals = ({ invoice }: Props) => {
  const { organisation } = useMetadata()
  const { data: exchangeRates } = useExchangeRate()
  const [rate, setRate] = useState<ExchangeRate>()
  const [locale, setLocale] = useState<string>()

  useEffect(() => {
    if (exchangeRates && invoice) {
      const currencyRate = exchangeRates.find((m) => m.currencyCode === invoice.currencyCode)
      setRate(currencyRate)
    }    
  }, [exchangeRates, invoice])

  useEffect(() => {
    if (organisation?.locale) setLocale(`${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`)
  }, [organisation])

  return (
    <>
      <div className="mt-6 float-right text-sm w-92 flex flex-col space-y-2 justify-end">
        <div className="flex justify-between">
          <span>
            Sub Total
            {invoice?.totalDiscountAmount && (
              <span className="text-xs pl-1">
                (Includes <CurrencyFormatLabel amount={invoice.totalDiscountAmount} localeCode={locale} /> Discount)
              </span>
            )}
          </span>
          <span>
            <CurrencyFormatLabel amount={invoice?.subTotalAmount} localeCode={locale} />
          </span>
        </div>
        <div className="flex justify-between">
          <span>
            Total Tax <span className="text-xs pl-1">({invoice?.taxTypeName})</span>
          </span>
          <span>
            <CurrencyFormatLabel amount={invoice?.totalTaxAmount} localeCode={locale} />
          </span>
        </div>
        <div className="border border-gray-200"></div>
        <div className="flex justify-between font-medium">
          <span>Total</span>
          <span>
            <CurrencyFormatLabel amount={invoice?.totalAmount} currencyCode={invoice?.currencyCode} localeCode={locale} />
          </span>
        </div>

        <InvoicePayments
          invoiceReference={invoice?.invoiceReference ?? ""}
          clientReference={invoice?.client.clientReference ?? ""}
        />

        <div className="border border-gray-200"></div>

        <div className="flex justify-between font-medium">
          <span>Amount Due</span>
          <span>
            <CurrencyFormatLabel amount={invoice?.amountDue} currencyCode={invoice?.currencyCode} localeCode={locale} />
          </span>
        </div>

        {organisation?.currency.currencyCode !== invoice?.currencyCode && (
          <>
            <div className="border border-gray-200"></div>
            {(invoice?.totalPaidAmount === null || invoice?.totalPaidAmount === 0) && (
              <div className="flex justify-end">
                <span>
                  <ToolTip text={`1 ${rate?.baseCurrencyCode} = ${rate?.rate} ${rate?.currencyCode}`}>
                    <i className="far fa-info-circle"></i>
                  </ToolTip>{" "}
                  If paid at today's exchange rate you'd get{" "}
                  <CurrencyFormatLabel
                    amount={getTotalInBaseCurrency(invoice?.amountDue, rate?.rate)}
                    currencyCode={rate?.baseCurrencyCode}
                    localeCode={locale}
                  />
                </span>
              </div>
            )}
            {invoice?.totalPaidAmount !== undefined && invoice.totalPaidAmount > 0 && invoice.amountDue > 0 && (
              <>
                <div className="flex justify-end">
                  <span>
                    <ToolTip text={`1 ${rate?.baseCurrencyCode} = ${rate?.rate} ${rate?.currencyCode}`}>
                      <i className="far fa-info-circle"></i>
                    </ToolTip>{" "}
                    If remainder paid at today's rate you'd get{" "}
                    <CurrencyFormatLabel
                      amount={getTotalInBaseCurrency(invoice?.amountDue, rate?.rate)}
                      currencyCode={rate?.baseCurrencyCode}
                      localeCode={locale}
                    />
                  </span>
                </div>
                <div className="flex justify-end">
                  <span>
                    Paid so far{" "}
                    <CurrencyFormatLabel
                      amount={getTotalInBaseCurrency(invoice?.totalPaidAmount, rate?.rate)}
                      currencyCode={rate?.baseCurrencyCode}
                      localeCode={locale}
                    />
                  </span>
                </div>
              </>
            )}
            {invoice?.amountDue === 0 && (
              <div className="flex justify-end">
                <span>
                  Paid{" "}
                  <CurrencyFormatLabel
                    amount={getTotalInBaseCurrency(invoice?.totalPaidAmount, rate?.rate)}
                    currencyCode={rate?.baseCurrencyCode}
                    localeCode={locale}
                  />
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default InvoiceOverviewTotals

function getTotalInBaseCurrency(total?: number, exchangeRate?: number) {
  if (total && exchangeRate) return total / exchangeRate
}
