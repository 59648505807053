import React, { useEffect, useState } from "react"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { classNames } from "common"
import { useFeature } from "context/feature-context"
import { useMetadata } from "context/metadata-context"
import { SuspenseLoading } from "library/styled/spinners"
import TemplateSettingPage from "components/setting/template/templateSettingPage"
import TaskSetting from "./task/taskSetting"

const StaffSetting = React.lazy(() => import("./staff/staffSetting"))
const JobSetting = React.lazy(() => import("./job/jobSetting"))
const ClientSetting = React.lazy(() => import("./client/clientSetting"))
const Roles = React.lazy(() => import("./role&Permission/roles/roles"))
const ContractorSetting = React.lazy(() => import("./contractor/contractorSetting"))
const OrganisationSettings = React.lazy(() => import("./organisation/organisationSettings"))
const TimesheetSetting = React.lazy(() => import("./timesheet/timesheetSetting"))
const LeaveSetting = React.lazy(() => import("./leave/leaveSetting"))
const InquirySetting = React.lazy(() => import("./inquiry/inquirySetting"))
const PartnerOrganisations = React.lazy(() => import("./partner/partners"))
const InvoiceSettingView = React.lazy(() => import("components/setting/invoice/invoiceSettingPage"))

export default function Settings() {
  const { enabledFeatures, enabledPermissions } = useFeature()
  const { organisation } = useMetadata()
  const [subNavigation, setSubNavigation] = useState([
    {
      name: "Organisation",
      description: "",
      href: "",
      icon: "building",
      current: true,
      component: <OrganisationSettings />,
      isVisible: true
    },
    {
      name: "Invoice",
      description: "",
      href: "invoice",
      icon: "file-invoice-dollar",
      current: false,
      component: <InvoiceSettingView />,
      isVisible: true
    },
    {
      name: "Template",
      description: "",
      href: "template",
      icon: "envelope-open-text",
      current: false,
      component: <TemplateSettingPage />,
      isVisible: true
    },
    {
      name: "Job",
      description: "",
      href: "job",
      icon: "clipboard-check",
      current: false,
      component: <JobSetting />,
      isVisible: enabledFeatures?.job && enabledPermissions?.job_Setting
    },
    {
      name: "Task",
      description: "",
      href: "task",
      icon: "list-check",
      current: false,
      component: <TaskSetting />,
      isVisible: enabledFeatures?.organisation
    },
    {
      name: "Client",
      description: "",
      href: "client",
      icon: "users",
      current: false,
      component: <ClientSetting />,
      isVisible: enabledFeatures?.client && enabledPermissions?.client_Setting
    },
    {
      name: "Staff",
      description: "",
      href: "staff",
      icon: "user",
      current: false,
      component: <StaffSetting />,
      isVisible: enabledFeatures?.staff && enabledPermissions?.staff_Setting
    },
    {
      name: "Contractor",
      description: "",
      href: "contractor",
      icon: "user-helmet-safety",
      current: false,
      component: <ContractorSetting />,
      isVisible: enabledFeatures?.contractor && enabledPermissions?.contractor_Setting
    },
    {
      name: "Timesheet",
      description: "",
      href: "timesheet",
      icon: "clock",
      current: false,
      component: <TimesheetSetting />,
      isVisible: enabledFeatures?.timesheet && enabledPermissions?.timesheet_Setting
    },
    {
      name: "Leave",
      description: "",
      href: "leave",
      icon: "island-tropical",
      current: false,
      component: <LeaveSetting />,
      isVisible: enabledFeatures?.leave && enabledPermissions?.leave_Setting
    },
    {
      name: "Inquiry",
      description: "",
      href: "inquiry",
      icon: "question-circle",
      current: false,
      component: <InquirySetting />,
      isVisible: enabledFeatures?.inquiry && enabledPermissions?.inquiry_Settings
    },
    {
      name: "Role & Permissions",
      description: "",
      href: "role",
      icon: "lock",
      current: false,
      component: <Roles />,
      isVisible: enabledPermissions?.role_Read
    },
    {
      name: "Partners",
      description: "",
      href: "partnerOrganisations",
      icon: "handshake",
      current: false,
      component: <PartnerOrganisations />,
      isVisible: organisation?.isPartner
    }
  ])

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    openSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  function openSettings() {
    const nav = [...subNavigation]
    nav.forEach((item) => (item.current = false))

    if (params.subSetting === undefined || params.subSetting === "organisation") {
      let menu = nav.find((item) => item.href === "")
      if (menu !== undefined) menu.current = true
    } else if (nav.some((m) => m.href === params.subSetting)) {
      let menu = nav.find((item) => item.href === params.subSetting)
      if (menu !== undefined) menu.current = true
    } else {
      navigate("/organisation")
    }
    setSubNavigation(nav)
  }

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          <main className="flex-1 flex overflow-hidden">
            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="flex-1 flex overflow-hidden">
                <nav aria-label="Sections" className="w-60 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col">
                  <div className="flex-shrink-0 h-12 px-4 border-b border-blue-gray-200 flex items-center">
                    <p className="text-lg font-medium text-blue-gray-900">Settings</p>
                  </div>
                  <div className="flex-1 sub-menu-height overflow-y-auto">
                    {subNavigation.map(
                      (item) =>
                        item.isVisible && (
                          <NavLink
                            to={item.href}
                            key={item.name}
                            className={classNames(
                              item.current ? "bg-blue-100 bg-opacity-50" : "hover:bg-blue-50 hover:bg-opacity-50",
                              "flex p-4 border-b border-blue-gray-200 cursor-pointer"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <i className={`fal fa-${item.icon} h-5 w-6 text-gray-600 my-auto`} aria-hidden="true"></i>
                            <div className="ml-1 text-sm">
                              <p className="font-medium text-blue-gray-900">{item.name}</p>
                              <p className="mt-1 text-blue-gray-500">{item.description}</p>
                            </div>
                          </NavLink>
                        )
                    )}
                  </div>
                </nav>

                {subNavigation.map(
                  (item) =>
                    item.current &&
                    item.isVisible && (
                      <div className="flex-1 overflow-y-auto pt-4" key={item.name}>
                        <React.Suspense fallback={<SuspenseLoading />}>{item.component}</React.Suspense>
                      </div>
                    )
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
