import { action, makeObservable, observable, runInAction } from "mobx"
import { DatePickerObservable } from "library/observables/datePickers"
import { TextObservable } from "library/observables/inputGroupText"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import { JobSelectionObservable } from "library/jobSelection/jobSelection"
import { Task, TaskCategory, TaskEntityType, TaskReminder, TaskStatus } from "../04_sharedModel/taskModel"
import {
  AddTaskInput,
  StaffMemberInput,
  TaskCategoryInput,
  TaskReminderInput,
  UpdateTaskInput
} from "../04_sharedModel/inputModels"
import { SelectObservable } from "../../../library/selectInputGroup/selectInputGroup"
import { StaffName } from "../../../models/people/staffName"
import { MultiSelectObservable } from "../../../library/multiSelectInputGroup/multiSelectInputGroup"
import { entityTypes } from "../../../constants/entityTypes"
import { allStaffMember } from "../../../constants/StaffMember"
import { isEmpty, isProvided, isSame } from "../../../utils/extensions"
import { TaskReminderObservable } from "../04_sharedModel/TaskReminderObservable"
import { v4 } from "uuid"
import { Category } from "../../../models/organisation/category"

export default class addOrEditTaskPageModel {
  taskReference: TextObservable
  entityTypeReference: TextObservable
  entityReference: TextObservable
  taskStatusReference: TextObservable
  name: TextObservable
  description: TextObservable
  dueDateTimeUtc: DatePickerObservable
  startDateTimeUtc: DatePickerObservable
  endDateTimeUtc: DatePickerObservable
  assignToAllStaffMembers: BooleanObservable

  clientSelection: ClientSelectionObservable
  jobSelection: JobSelectionObservable
  taskStatus: SelectObservable<TaskStatus>
  taskEntityType: SelectObservable<TaskEntityType>
  staffMembers: MultiSelectObservable<StaffName>
  taskCategory: MultiSelectObservable<Category>
  taskReminders: TaskReminderObservable[]
  concurrencyToken: TextObservable

  isAddMode: boolean
  isEditMode: boolean

  constructor() {
    makeObservable(this, {
      handleApiErrors: action,
      isAddMode: observable,
      setIsAddMode: action,
      setTask: action,
      isEditMode: observable,
      setIsEditMode: action,
      setConcurrencyToken: action
    })

    this.taskReference = new TextObservable()
    this.entityTypeReference = new TextObservable()
    this.entityReference = new TextObservable()
    this.taskStatusReference = new TextObservable()
    this.name = new TextObservable()
    this.description = new TextObservable()
    this.dueDateTimeUtc = new DatePickerObservable()
    this.startDateTimeUtc = new DatePickerObservable()
    this.endDateTimeUtc = new DatePickerObservable()
    this.assignToAllStaffMembers = new BooleanObservable()
    this.clientSelection = new ClientSelectionObservable()
    this.jobSelection = new JobSelectionObservable()
    this.taskStatus = new SelectObservable<TaskStatus>
    this.taskEntityType = new SelectObservable<TaskEntityType>
    this.staffMembers = new MultiSelectObservable<StaffName>
    this.taskCategory = new MultiSelectObservable<Category>
    this.taskReminders = [new TaskReminderObservable(), new TaskReminderObservable(), new TaskReminderObservable()]

    this.concurrencyToken = new TextObservable()
    this.isAddMode = false
    this.isEditMode = false
  }

  getUpdateTaskInput(): UpdateTaskInput {
    const assignToAllStaffMembers = this.staffMembers
      .selectedItems
      .some(value => value.staffReference === allStaffMember.staffReference)

    return {
      taskReference: this.taskReference.text,
      taskStatusReference: this.taskStatus.selectedOption?.taskStatusReference,
      concurrencyToken: this.concurrencyToken.text,
      name: this.name.text,
      description: this.description.text,
      dueDateTimeUtc: this.dueDateTimeUtc.getUtcDateTime(),
      startDateTimeUtc: this.startDateTimeUtc.getUtcDateTime(),
      endDateTimeUtc: this.endDateTimeUtc.getUtcDateTime(),
      assignToAllStaffMembers: assignToAllStaffMembers,
      staffMembers: this.getStaffMemberInput(),
      taskReminders: this.getTaskReminderInputs(),
      taskCategory: this.getTaskCategoryInput()
    }
  }

  getAddTaskInput(): AddTaskInput {
    let entityTypeReference = this.taskEntityType.selectedOption?.entityTypeReference
    let entityReference = undefined
    runInAction(() => {
      this.taskReference.text = v4().toUpperCase()
    })

    if (this.taskEntityType.selectedOption?.isClientEntity) {
      entityReference = this.clientSelection.clientReference
    }

    if (this.taskEntityType.selectedOption?.isJobEntity) {
      entityReference = this.jobSelection.jobReference
    }

    if (this.taskEntityType.selectedOption?.isGeneralEntity) {
      entityReference = this.taskReference.text
    }

    const assignToAllStaffMembers = this.staffMembers
      .selectedItems
      .some(value => value.staffReference === allStaffMember.staffReference)

    return {
      taskReference: this.taskReference.text,
      entityTypeReference: entityTypeReference,
      entityReference: entityReference,
      taskStatusReference: this.taskStatus.selectedOption?.taskStatusReference,
      name: this.name.text,
      description: this.description.text,
      dueDateTimeUtc: this.dueDateTimeUtc.getUtcDateTime(),
      startDateTimeUtc: this.startDateTimeUtc.getUtcDateTime(),
      endDateTimeUtc: this.endDateTimeUtc.getUtcDateTime(),
      assignToAllStaffMembers: assignToAllStaffMembers,
      staffMembers: this.getStaffMemberInput(),
      taskReminders: this.getTaskReminderInputs(),
      taskCategory: this.getTaskCategoryInput()
    }
  }

  setIsAddMode(isAddMode: boolean) {
    this.isAddMode = isAddMode
  }

  setIsEditMode(isAddMode: boolean) {
    this.isEditMode = isAddMode
  }

  setDefaultTaskStatus(taskStatusList?: TaskStatus[]) {
    runInAction(() => {
      if (this.isAddMode && taskStatusList && taskStatusList.length > 0 && !this.taskStatus.selectedOption?.taskStatusReference) {
        this.taskStatus.setValue(taskStatusList[0])
      }
    })
  }

  setDefaultTaskType(taskTypeList?: TaskEntityType[]) {
    // Sets default task entity type
    runInAction(() => {
      if (this.isAddMode && taskTypeList && taskTypeList.length > 0 && !this.taskEntityType.selectedOption?.entityTypeReference) {
        this.taskEntityType.setValue(taskTypeList[0])
      }
    })
  }

  getTaskReminderInputs(): TaskReminderInput[] {
    return this.taskReminders
      .filter(value => value.reminderDateTimeUtc.date != undefined)
      .map(value => {
        return {
          reminderDateTimeUtc: value.reminderDateTimeUtc.getUtcDateTime(),
          taskReminderReference: value.taskReminderReference
        }
      })
  }

  getStaffMemberInput(): StaffMemberInput[] {
    return this.staffMembers
      .selectedItems.filter(value => value.staffReference != allStaffMember.staffReference)
      .map(value => {
        return {
          staffReference: value.staffReference,
          taskStaffReference: value.reference ?? v4().toUpperCase()
        }
      })
  }

  getTaskCategoryInput(): TaskCategoryInput[] {
    return this.taskCategory
      .selectedItems
      .map(value => {
        return {
          taskCategoryReference: value.categoryReference
        }
      })
  }

  setTask(task: Task) {

    runInAction(() => {
      this.taskReference.text = task.taskReference
      this.entityTypeReference.text = task.entityTypeReference
      this.entityReference.text = task.entityReference
      this.concurrencyToken.text = task.concurrencyToken
      this.name.text = task.name
      this.description.text = task.description
      this.dueDateTimeUtc.setDate(task.dueDateUtc)
      this.startDateTimeUtc.setDate(task.startDateTimeUtc)
      this.endDateTimeUtc.setDate(task.endDateTimeUtc)

      if (task.assignToAllStaffMembers) {
        this.staffMembers.selectedItems = [allStaffMember]
      }

      this.taskStatus.setValue({
        taskStatusReference: task.taskStatusReference ?? "",
        name: task.taskStatusName ?? ""
      })

      this.taskEntityType.setValue(new TaskEntityType(task.entityTypeReference ?? "", task.entityTypeName ?? ""))

      if (task.staffMembers && task.staffMembers.length > 0) {
        this.staffMembers.selectedItems = task.staffMembers.map(value => {
          return {
            reference: value.taskStaffReference,
            staffReference: value.staffReference,
            fullName: value.name,
            profileImageUrl: value.profileImageUrl
          }
        })
      }

      if (task.taskCategory && task.taskCategory.length > 0) {
        this.taskCategory.selectedItems = task.taskCategory.map(value => {
          return {
            categoryReference: value.taskCategoryReference!,
            name: value.name!,
            entityType: {
              entityTypeReference: entityTypes.task.entityTypeReference,
              name: entityTypes.task.name
            }
          }
        })
      }

      if (task.entityTypeReference === entityTypes.client.entityTypeReference) {
        this.clientSelection.clientReference = task.entityReference
        this.clientSelection.clientName = task.entityName
      }

      if (task.entityTypeReference === entityTypes.job.entityTypeReference) {
        console.log("Job", task.entityName)
        this.jobSelection.jobReference = task.entityReference
        this.jobSelection.jobNumber = task.entityName
      }

      if (task.taskReminders) {
        for (let i = 0; i < task.taskReminders.length; i++) {
          const taskReminder = task.taskReminders[i]
          const selectedTaskReminder = this.taskReminders[i]
          selectedTaskReminder.reminderDateTimeUtc.setDate(taskReminder.reminderDateTimeUtc)
          selectedTaskReminder.taskReminderReference = taskReminder.taskReminderReference
          selectedTaskReminder.isDefault = false
        }
      }
    })
  }

  setConcurrencyToken(token?: string) {
    runInAction(() => {
      this.concurrencyToken.text = token
    })
  }

  handleApiErrors(errorResponse: any) {
    runInAction(() => {
      errorResponse.response.data.messages.forEach((v: any) => {
        if (isSame(v.property, "taskReference")) {
          this.taskReference.errorMessage = v.description
        }

        if (isSame(v.property, "entityTypeReference")) {
          this.entityTypeReference.errorMessage = v.description
        }

        if (isSame(v.property, "entityReference")) {
          this.entityReference.errorMessage = v.description
        }

        if (isSame(v.property, "taskStatusReference")) {
          this.taskStatusReference.errorMessage = v.description
        }

        if (isSame(v.property, "name")) {
          this.name.errorMessage = v.description
        }

        if (isSame(v.property, "description")) {
          this.description.errorMessage = v.description
        }

        if (isSame(v.property, "dueDateTimeUtc")) {
          this.dueDateTimeUtc.errorMessage = v.description
        }

        if (isSame(v.property, "startDateTimeUtc")) {
          this.startDateTimeUtc.errorMessage = v.description
        }

        if (isSame(v.property, "endDateTimeUtc")) {
          this.endDateTimeUtc.errorMessage = v.description
        }

        if (isSame(v.property, "endDateTimeUtc")) {
          this.endDateTimeUtc.errorMessage = v.description
        }

        if (isSame(v.property, "staffMembers")) {
          this.staffMembers.errorMessage = v.description
        }

        if (isSame(v.property, "entityReference") &&
          this.taskEntityType.selectedOption?.entityTypeReference === entityTypes.client.entityTypeReference) {
          this.clientSelection.errorMessage = v.description
        }

        if (isSame(v.property, "entityReference") &&
          this.taskEntityType.selectedOption?.entityTypeReference === entityTypes.job.entityTypeReference) {
          this.jobSelection.errorMessage = v.description
        }
      })
    })
  }
}
