import React from "react"
import ToolTip from "library/styled/tooltip"

interface Props {
  label: string
  name: string
  tooltip: string
  children: React.ReactNode
  tooltipOverlay?: React.ReactNode | (() => React.ReactNode)
}

const CheckBox = ({ children, label, name, tooltip = "", tooltipOverlay }: Props) => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">{children}</div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
        {(tooltip || tooltipOverlay) && (
          <ToolTip text={tooltip} id={name} overlay={tooltipOverlay}>
            <i className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}
      </div>
    </div>
  )
}

export default CheckBox
