import React from "react"
import { ThreeDots } from "react-loader-spinner"
import { primary } from "styles/colors"

const LoadingSpinner = () => {
  return (
    <>
      <svg className="h-5 w-5 fa-spin text-primary-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M256 32C256 14.33 270.3 0 288 0C429.4 0 544 114.6 544 256C544 302.6 531.5 346.4 509.7 384C500.9 399.3 481.3 404.6 465.1 395.7C450.7 386.9 445.5 367.3 454.3 351.1C470.6 323.8 480 291 480 255.1C480 149.1 394 63.1 288 63.1C270.3 63.1 256 49.67 256 31.1V32z"
        />
        <path
          fill="currentColor"
          fillOpacity={0.4}
          d="M287.1 64C181.1 64 95.1 149.1 95.1 256C95.1 362 181.1 448 287.1 448C358.1 448 419.3 410.5 452.9 354.4L453 354.5C446.1 369.4 451.5 387.3 465.1 395.7C481.3 404.6 500.9 399.3 509.7 384C509.9 383.7 510.1 383.4 510.2 383.1C466.1 460.1 383.1 512 288 512C146.6 512 32 397.4 32 256C32 114.6 146.6 0 288 0C270.3 0 256 14.33 256 32C256 49.67 270.3 64 288 64H287.1z"
        />
      </svg>
    </>
  )
}

const SuspenseLoading = () => (
  <div className="flex justify-center mt-[40vh]">
    <svg className="h-8 w-8 fa-spin text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M288 32C288 49.67 273.7 64 256 64C238.3 64 224 49.67 224 32C224 14.33 238.3 0 256 0C273.7 0 288 14.33 288 32zM288 480C288 497.7 273.7 512 256 512C238.3 512 224 497.7 224 480C224 462.3 238.3 448 256 448C273.7 448 288 462.3 288 480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288C462.3 288 448 273.7 448 256C448 238.3 462.3 224 480 224zM32 288C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224C49.67 224 64 238.3 64 256C64 273.7 49.67 288 32 288zM74.98 391.8C87.48 379.3 107.7 379.3 120.2 391.8C132.7 404.3 132.7 424.5 120.2 437C107.7 449.5 87.48 449.5 74.98 437C62.48 424.5 62.48 404.3 74.98 391.8zM391.8 437C379.3 424.5 379.3 404.3 391.8 391.8C404.3 379.3 424.5 379.3 437 391.8C449.5 404.3 449.5 424.5 437 437C424.5 449.5 404.3 449.5 391.8 437zM120.2 74.98C132.7 87.48 132.7 107.7 120.2 120.2C107.7 132.7 87.48 132.7 74.98 120.2C62.48 107.7 62.48 87.48 74.98 74.98C87.48 62.49 107.7 62.49 120.2 74.98z"
      />
    </svg>
  </div>
)

interface Props {
  left?: string
}

const ThreeDotsSpinner = ({ left = "54%" }: Props) => (
  <ThreeDots
    height="30"
    width="30"
    radius="3"
    color={primary}
    ariaLabel="three-dots-loading"
    visible={true}
    wrapperStyle={{ position: "absolute", left: left, top: "45%", width: "40px", height: "30px" }}
  />
)

export { LoadingSpinner, SuspenseLoading, ThreeDotsSpinner }
