import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleMutationError } from "common"
import { keys } from "constants/keys"
import { useUpdateCategory } from "hooks/organisation/category"
import { InputGroupButton, InputGroupText, InputGroupTextarea } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { Category, UpdateCategoryInput } from "models/organisation/category"

interface Props {
  isOpen: boolean
  onClose: () => void
  category?: Category
}

const EditCategoryModal = ({ isOpen, onClose, category }: Props) => {
  const { mutate: updateCategory, isLoading } = useUpdateCategory()
  const [error, setError] = useState({})
  const queryClient = useQueryClient()

  useEffect(() => {
    setError({})
  }, [isOpen])

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()
    const { name, description } = event.currentTarget.elements

    const input: UpdateCategoryInput = {
      categoryReference: category?.categoryReference,
      name: name.value,
      description: description.value,
    }

    updateCategory(input, {
      onError: (error: any) => handleMutationError(error.response, setError),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.categoryQuery)
        successToaster("Category updated")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal title="Edit Category" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <InputGroupText label="Name" name="name" placeholder="Required" value={category?.name} error={error} />
          <InputGroupTextarea label="Description" name="description" value={category?.description} error={error} />
          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}

export default EditCategoryModal
