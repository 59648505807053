import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import ToolTip from "library/styled/tooltip"
import Select, { OptionProps } from "react-select"
import { useSearchJob } from "hooks/job/job"
import { debounce } from "lodash"
import { ThreeDots } from "react-loader-spinner"
import { requiredValidation } from "utils/validation"
import { Address, JobQueryInput } from "models/job/job"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import { isEmpty, isProvided } from "utils/extensions"
import { AddressObservable } from "library/clientAddressSelection/clientAddressSelection"
import { jobSortType } from "constants/types"

export class JobSelectionObservable {
  jobReference?: string
  jobNumber?: string
  description?: string
  errorMessage?: string

  constructor(jobReference?: string, jobNumber?: string, description?: string) {
    makeObservable(this, {
      jobReference: observable,
      jobNumber: observable,
      description: observable,
      errorMessage: observable
    })

    this.jobReference = jobReference
    this.jobNumber = jobNumber
    this.description = description
  }
}

export class JobKeyValue {
  jobReference?: string
  jobNumber?: string
  description?: string
  address?: Address
  clientName?: string

  constructor(jobReference?: string, jobNumber?: string, description?: string, address?: Address, clientName?: string) {
    this.jobReference = jobReference
    this.jobNumber = jobNumber
    this.description = description
    this.address = address
    this.clientName = clientName
  }
}

interface Props {
  value: JobSelectionObservable
  tooltip?: string
  label?: string
  name: string
  isClearable?: boolean
  type?: "filter" | "input"
  isInModal?: boolean
  placeholder?: string
  isRequired?: boolean
  onChange?: () => void
  cols?: number
  clientSelectionObservable?: ClientSelectionObservable
  jobReference?: string
  addressSelectionObservable?: AddressObservable,
  isDisabled?: boolean,
  clearJobIfClientIsChanged?: boolean
}

const JobSelection = ({
                        value,
                        tooltip,
                        label = "Job",
                        name,
                        isClearable = true,
                        type = "input",
                        isInModal = false,
                        placeholder = "Search by job number",
                        isRequired = false,
                        onChange,
                        cols = 3,
                        clientSelectionObservable,
                        jobReference,
                        addressSelectionObservable,
                        isDisabled = false,
                        clearJobIfClientIsChanged = true // default is true for invoice feature where
                        // client is cleared when job is also cleared. This should not run for task feature.
                      }: Props) => {
  const [input, setInput] = useState<JobQueryInput>({
    first: 20,
    jobNumber: ""
  })
  const { data, isLoading } = useSearchJob(input)

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput({ ...input, jobNumber: searchQuery.trim() })
    }, 1000)
  ).current

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  const [jobInput, setJobInput] = useState<JobQueryInput>({
    first: 500,
    sortReference: jobSortType.createdDateTime,
    includeJobsWithoutClients: "true",
    clientReferences: [],
    jobReference: "",
    jobNumber: ""
  })
  const { data: jobs } = useSearchJob(jobInput)

  const debouncedJobSearch = useRef(
    debounce((includeJobsWithoutClients: string, clientReferences: [string], jobReference: string, jobNumber: string) => {
      setJobInput({
        ...jobInput,
        includeJobsWithoutClients: includeJobsWithoutClients,
        clientReferences: clientReferences,
        jobReference: jobReference,
        jobNumber: jobNumber
      })
    }, 1000)
  ).current

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
      debouncedJobSearch.cancel()
    }
  }, [debouncedSearch, debouncedJobSearch])

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value?.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.jobReference, label, isRequired)
    })
  }

  //search job for invoice job
  const handleSearchInput = (jobNumber: string) => {
    if (jobNumber) {
      debouncedJobSearch("true", [], "", jobNumber)
    }
  }
  useEffect(() => {
    if (isProvided(jobReference)) {
      debouncedJobSearch("false", [], jobReference, "")
    }
  }, [])

  useEffect(() => {
    // If jobReference found in URL auto select job.
    if (jobs) {
      const filteredJob = jobs?.find((job) => job.jobReference === jobReference)
      if (filteredJob) {
        runInAction(() => {
          value.jobNumber = filteredJob.jobNumber
          value.jobReference = filteredJob.jobReference
        })
        if (filteredJob.address) addressSelectionObservable?.setAddress(filteredJob.address)
      }
    }
  }, [jobs])

  useEffect(() => {
    // This use effect will clear the job selected when client selection is changed.
    // If job was populated via url then client
    if (!jobReference && clearJobIfClientIsChanged) {
      if (isProvided(clientSelectionObservable?.clientReference)) {
        debouncedJobSearch("true", [clientSelectionObservable?.clientReference ?? ""], "", "")
      }
      if (isEmpty(clientSelectionObservable?.clientReference)) {
        runInAction(() => {
          value.jobReference = undefined
          value.jobNumber = undefined
        })
        debouncedJobSearch("true", [], "", "")
      }
    }
  }, [clientSelectionObservable?.clientReference])

  const CustomOption = (props: OptionProps<JobKeyValue>) => {
    const { innerProps, innerRef, getStyles } = props
    return (
      <div ref={innerRef} {...innerProps} style={getStyles("option", props) as CSSProperties}
           className="overflow-hidden">
        <div className="mr-2">{props.data.jobNumber} {props.data.clientName ? " | " + props.data.clientName : ""} </div>
        {name === "job" && <span className="text-xs truncate-td-2">{props.data.description}</span>}
      </div>
    )
  }

  return (
    <>
      <FormGroup cols={cols}>
        <Label htmlFor={label}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          name={name}
          id={name}
          inputId={name}
          isMulti={false}
          options={name === "job" ? jobs : data}
          value={value?.jobReference ? value : null}
          getOptionLabel={(option: JobKeyValue) => option.jobNumber ?? ""}
          getOptionValue={(option: JobKeyValue) => option.jobReference ?? ""}
          placeholder={placeholder}
          isClearable={true}
          isLoading={isLoading}
          isDisabled={isDisabled}
          onChange={(e) => {
            runInAction(() => {
              value.jobNumber = e?.jobNumber
              value.jobReference = e?.jobReference
              validate(true)
              if (onChange) onChange()
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          onInputChange={name === "job" ? handleSearchInput : handleInput}
          styles={type === "filter" ? filterStyle : inputStyle}
          className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
          menuPosition={isInModal ? "fixed" : undefined}
          components={{ LoadingIndicator, Option: CustomOption }}
          noOptionsMessage={() => <h2>No Job Found</h2>}
          filterOption={() => true}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      radius="3"
      color="rgb(180, 183, 170,1)"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ marginRight: "6px" }}
      visible={true}
    />
  )
}

export default observer(JobSelection)
