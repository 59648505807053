// /** @jsx jsx */
// import { jsx } from "@emotion/core"

import React from "react"
import { FullPageErrorFallback, FullPageLoading } from "library/styled/styled"
import { useEnabledFeature, useEnabledPermission } from "hooks/identity/user"
import { EnabledFeature } from "models/identity/features"
import { EnabledPermission } from "models/identity/permissions"

interface DefaultContextValue {
  enabledFeatures: EnabledFeature | undefined
  enabledPermissions: EnabledPermission | undefined
}

let defaultValue: DefaultContextValue = {
  enabledFeatures: undefined,
  enabledPermissions: undefined,
}

const FeatureContext = React.createContext(defaultValue)
FeatureContext.displayName = "FeatureContext"

type Props = {
  children: React.ReactNode
}

function FeatureProvider(props: Props) {
  const { data: enabledFeatures, isLoading, isError, isSuccess, error, status } = useEnabledFeature()

  const { data: enabledPermissions, isLoading: isLoadingPermissions } = useEnabledPermission()

  const value = React.useMemo(() => ({ enabledFeatures, enabledPermissions }), [enabledFeatures, enabledPermissions])

  if (isLoading) {
    return <FullPageLoading />
  }
  if (isLoadingPermissions) {
    return <FullPageLoading />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <FeatureContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useFeature() {
  const context = React.useContext(FeatureContext)
  if (context === undefined) {
    throw new Error(`useFeature must be used within a FeatureProvider`)
  }
  return context
}

export { FeatureProvider, useFeature }
