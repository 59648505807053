import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { common } from "common"
import { getUserOrganisation } from "hooks/identity/user"
import { localStorageService } from "../services/local-storage"
import { Response } from "models/shared/response"
import { getUserSession, isNotRunningTest, isRunningTest } from "utils/extensions"

export const graphQLClient = {
  //#region fetch
  async fetch(query: string, variables: any, useOrganisationHeader: boolean, url: string, operationName: string) {
    const user = await getUserSession()
    let apiUrl = isRunningTest() ? "" : url

    if (isNotRunningTest() && (user === null || user === undefined)) {
      console.log("User not found. Please login again")
      // Log out if we don't have a valid user.
      // This will mostly happen when user logs out of angularjs and reactjs is also logged out.
      // In this case local storage will be cleared.
      window.location.reload()
      return
    }

    let data = {
      query: query,
      variables: variables,
      operationName: operationName
    }

    let req: AxiosRequestConfig<any> = {
      method: "POST",
      url: apiUrl + "/" + operationName,
      headers: {
        Authorization: user?.token_type + " " + user?.access_token,
        "Content-Type": "application/json",
        "X-Correlation-Id": "correlation-id-xxxx",
        "x-http-400-unsuccessful-request": true,
        "X-Organisation": false
      },
      data: data
    }

    // only add organisation header when it's not set by the caller.
    if (isNotRunningTest() && useOrganisationHeader) {
      const selectOrganisationId = await getUserOrganisation()
      // if we can't find the organisation id in local storage cancel request.
      if (selectOrganisationId === null || selectOrganisationId === undefined) {
        console.warn("Cannot find organisation id")
        return
      }
      if (req.headers !== undefined) req.headers["X-Organisation"] = selectOrganisationId
    }

    const response: any = await axios(req)
    return response
  },
  //#endregion

  //#region mutation
  async mutation<T = any>(query: string, input: any, operationName: string, url: string) {
    const user = await getUserSession()
    let apiUrl = isRunningTest() ? "" : url

    if (user === null || user === undefined) {
      console.log("User not found. Please login again")
      return
    }

    let data = {
      query: query,
      variables: {
        input: input
      },
      operationName: operationName
    }

    let req: AxiosRequestConfig = {
      method: "POST",
      url: apiUrl + "/" + operationName,
      headers: {
        Authorization: user.token_type + " " + user.access_token,
        "Content-Type": "application/json",
        "X-Correlation-Id": "correlation-id-xxxx",
        "x-http-400-unsuccessful-request": true,
        "X-Organisation": false
      },
      data: data
    }

    const selectOrganisationId = localStorageService.get(common.localStorageActiveOrganisationKey)
    if (req.headers !== undefined) req.headers["X-Organisation"] = selectOrganisationId

    const response: Response<T> = await axios(req)
    return response
  },

  async mutation2<TResponse>(
    query: string,
    input: any,
    operationName: string,
    url: string
  ): Promise<AxiosResponse<TResponse> | null> {
    const user = await getUserSession()

    if (user === null || user === undefined) {
      console.log("User not found. Please login again")
      return null
    }

    let data = {
      query: query,
      variables: {
        input: input
      },
      operationName: operationName
    }

    let req: AxiosRequestConfig = {
      method: "POST",
      url: url + "/" + operationName,
      headers: {
        Authorization: user.token_type + " " + user.access_token,
        "Content-Type": "application/json",
        "X-Correlation-Id": "correlation-id-xxxx",
        "x-http-400-unsuccessful-request": true,
        "X-Organisation": false
      },
      data: data
    }

    const selectOrganisationId = localStorageService.get(common.localStorageActiveOrganisationKey)
    if (req.headers !== undefined) req.headers["X-Organisation"] = selectOrganisationId

    const response = await axios.post<TResponse>(req.url!, data, req)
    return response
  },
  async mutation3(query: string, variables: any, useOrganisationHeader: boolean, url: string, operationName: string) {
    const user = await getUserSession()

    if (user === null || user === undefined) {
      console.log("User not found. Please login again")
      return
    }

    let data = {
      query: query,
      variables: {
        input: variables
      },
      operationName: operationName
    }

    let req: AxiosRequestConfig<any> = {
      method: "POST",
      url: url + "/" + operationName,
      headers: {
        Authorization: user.token_type + " " + user.access_token,
        "Content-Type": "application/json",
        "X-Correlation-Id": "correlation-id-xxxx",
        "x-http-400-unsuccessful-request": true,
        "X-Organisation": false
      },
      data: data
    }

    // only add organisation header when it's not set by the caller.
    if (useOrganisationHeader) {
      const selectOrganisationId = await getUserOrganisation()
      // if we can't find the organisation id in local storage cancel request.
      if (selectOrganisationId === null || selectOrganisationId === undefined) {
        console.warn("Cannot find organisation id")
        return
      }
      if (req.headers !== undefined) req.headers["X-Organisation"] = selectOrganisationId
    }
    const response: any = await axios(req)
    return response
  }
  //#endregion
}
