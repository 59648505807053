import React, { Fragment } from "react"
import { toLocaleDate, toLocaleDateTime, toLocaleShortDate } from "common"
import { TBody, Td, Th, THead } from "library/styled/styled"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { HamburgerMenuTable } from "library/dropdownMenu/hamburgerMenu"
import ListLoader from "loading/contentLoader"

interface Props {
  columns: Column[]
  data: any
  onRowClick?: (item: any) => void
  dataKey: string
  statusColor?: any[]
  editAction?: ((item: any) => void) | null
  deleteAction?: ((item: any) => void) | null
  // pass options as [] to hide menu options
  options?: any[]
  id?: string
  fixHeight?: boolean
  noItemFoundMessage?: string
  isLoading?: boolean
  loadingWidth?: number
  columnLoadingWidths?: number[]
}

const TableDynamic = ({
                        columns,
                        data,
                        onRowClick = function() {
                        },
                        dataKey,
                        statusColor,
                        editAction = null,
                        deleteAction = null,
                        options,
                        id = "",
                        fixHeight = false,
                        noItemFoundMessage = "There are no results to show at this time.",
                        isLoading = false,
                        loadingWidth = 670,
                        columnLoadingWidths = []
                      }: Props) => {
  const menuItems = [
    { name: "Edit", icon: "pen-circle", action: editAction },
    { name: "Delete", icon: "circle-trash", action: deleteAction }
  ]

  const customisedOptions = options === undefined ? menuItems : options

  return (
    <Fragment>
      <div className="tbl-header">
        <table id={`table-${dataKey}`} cellPadding="0" cellSpacing="0" border={0} className="table-fixed">
          <THead>
            <tr>
              {columns.map((column) => (
                <Th width={column.width} key={column.name}>
                  {column.name}
                </Th>
              ))}
              {customisedOptions?.length > 0 && (
                <th className="w-12 py-3.5 pl-4 pr-3 sm:pl-6 text-left text-sm text-gray-700"></th>
              )}
            </tr>
          </THead>
        </table>
      </div>

      <ListLoader isLoading={isLoading} height={140} columnWidths={columnLoadingWidths} width={loadingWidth} />
      <div className={fixHeight ? "overflow-y-auto tab-content-table-max-height" : ""}>
        <table cellPadding="0" cellSpacing="0" border={0} className="table-fixed">
          <TBody>
            {data?.length === 0 && (
              <tr>
                <Td className="text-center">{noItemFoundMessage}</Td>
              </tr>
            )}
            {data?.map((item: any, idx: number) => (
              <tr
                className={`cursor-pointer hover:bg-blue_grey-100 ${idx % 2 === 0 ? undefined : "bg-blue_grey-50"} ${
                  idx === data.length - 1 ? "rounded-b-md" : ""
                }`}
                key={item[dataKey]}
                onMouseOver={() => {
                  let rowStyle = document.getElementById(`${id}${dataKey}${idx}`)
                  if (rowStyle !== null) rowStyle.style.visibility = "visible"
                }}
                onMouseOut={() => {
                  let rowStyle = document.getElementById(`${id}${dataKey}${idx}`)
                  if (rowStyle !== null) rowStyle.style.visibility = "hidden"
                }}
              >
                {columns.map((column) => (
                  <Fragment key={column.id}>
                    {column.innerKey ? (
                      item[column.key] ? (
                        <Fragment>
                          {column.columnTypeId === ColumnType.Text.Id && (
                            <Td width={column.width} onClick={() => onRowClick(item)}>
                              {item[column.key][column.innerKey]}
                            </Td>
                          )}
                          {column.columnTypeId === ColumnType.Email.Id && (
                            <Td width={column.width} onClick={() => onRowClick(item)}>
                              {item[column.key][column.innerKey]}
                            </Td>
                          )}
                          {column.columnTypeId === ColumnType.DateTime.Id && (
                            <Td width={column.width} onClick={() => onRowClick(item)}>
                              {toLocaleDateTime(item[column.key][column.innerKey])}
                            </Td>
                          )}
                          {column.columnTypeId === ColumnType.ListOfImages.Id && (
                            <Td>
                              <div className="flex overflow-hidden truncate -space-x-1">
                                {item[column.key].map((subItem: any) => (
                                  <span key={subItem.name} className="inline-flex items-center">
                                    <img
                                      alt=""
                                      src={subItem.profileImageUrl}
                                      className="h-7 w-7 rounded-full inline-block ring-2 ring-white"
                                    />
                                  </span>
                                ))}
                              </div>
                            </Td>
                          )}
                          {column.columnTypeId === ColumnType.ListOfDates.Id && (
                            <Td width={column.width} onClick={() => onRowClick(item)}>
                              {item[column.key].map((subItem: any, idx: number) => (
                                <span key={idx}>
                                  {idx < 3 && (
                                    <span>
                                      {idx > 0 && <span className="px-2">|</span>}
                                      {toLocaleShortDate(subItem.startDateTimeUtc)}
                                    </span>
                                  )}
                                  {idx === 3 && <span className="text-xs pl-2">...</span>}
                                </span>
                              ))}
                            </Td>
                          )}
                        </Fragment>
                      ) : (
                        <Td></Td>
                      )
                    ) : (
                      <Fragment>
                        {column.columnTypeId === ColumnType.LabelId.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            <div className="flex items-center">
                              <div className="rounded-lg bg-secondary-100">
                                <div className="text-primary-900 font-medium text-xs px-2 py-1">{item[column.key]}</div>
                              </div>
                            </div>
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Text.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {item[column.key]}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Description.Id && (
                          <Td truncate={false} width={column.width} onClick={() => onRowClick(item)}>
                            {item[column.key]}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Email.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {item[column.key]}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Date.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {toLocaleDate(item[column.key])}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.DateTime.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {toLocaleDateTime(item[column.key])}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.LabelType.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            <div className="flex items-center">
                              <div className={`rounded-full ${column.className} bg-opacity-20`}>
                                <div className="text-xs px-2 py-1">{item[column.key]}</div>
                              </div>
                            </div>
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Status.Id && (
                          <Td>
                            <div className="flex items-center">
                              <div
                                className={`rounded-full hover:shadow-lg`}
                                style={
                                  column.statusKey !== undefined
                                    ? {
                                      backgroundColor: `${statusColor?.[item[column.statusKey]]}40`
                                    }
                                    : undefined
                                }
                              >
                                <div className="text-xs px-3 py-1 text-gray-900">{item[column.key]}</div>
                              </div>
                            </div>
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.profileName.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            <div className="flex items-center">
                              <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                              <div className="ml-2">{item.givenName + " " + item.familyName}</div>
                            </div>
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.StaffName.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            <div className="flex items-center">
                              <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                              <div className="ml-2">{item.staffName}</div>
                            </div>
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Address.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {item["route"]}&nbsp;
                            {item["city"]}&nbsp;
                            {item["regionName"]}&nbsp;
                            {item["postalCode"]}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.Boolean.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {item[column.key] ? "Yes" : "No"}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.BooleanInReverse.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {item[column.key] ? "No" : "Yes"}
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.InvoiceReminder.Id && (
                          <Td width={column.width} truncate={false} onClick={() => onRowClick(item)}>
                            Send reminder when invoice
                            is {column.keys ? item[column.keys[0]] + " " + item[column.keys[1]] : ""}{" "}
                            days
                          </Td>
                        )}
                        {column.columnTypeId === ColumnType.FileSize.Id && (
                          <Td width={column.width} onClick={() => onRowClick(item)}>
                            {(item[column.key] / 1000000).toString().substring(0, 4)} Mb
                          </Td>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                ))}
                {customisedOptions?.length > 0 && (
                  <td
                    className="w-12 px-3 text-sm text-gray-500 break-words align-middle relative text-right font-medium">
                    <div id={`${id}${dataKey}${idx}`} className="invisible">
                      <HamburgerMenuTable options={customisedOptions} portal={fixHeight} listItem={item} />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </TBody>
        </table>
      </div>
    </Fragment>
  )
}

export default TableDynamic
