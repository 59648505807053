import { graphQLClient } from "../../shared/graphql/graphQLClient"
import { common } from "../../common"
import { keys } from "../../constants/keys"
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { Response } from "../../models/shared/response"
import { itemQuery } from "./itemGql"
import { ItemSearches, ItemSearchInput } from "./itemModelGql"
import { AddOrUpdateItemInput, ItemQueryInput, RemoveItemInput, UpdateItemResponse } from "./04_itemSharedModel/inputModels"
import { Items } from "./04_itemSharedModel/itemModel"
import { ReactQueryParams } from "models/shared/common"
import { query } from "shared/graphql/queries"
import { mutation } from "shared/graphql/mutations"

type GetOrAddItem = {
  getOrAddItem: {
    itemReference: string
  }
}

function useItemSearch(input: ItemSearchInput) {
  return useQuery([keys.itemSearchQuery, input], () => itemSearchQuery(input), {
    select: (data: Response<ItemSearches>) => data.data.data.itemSearch,
  })
}

function itemSearchQuery(input: ItemSearchInput) {
  return graphQLClient.fetch(itemQuery.itemSearchQuery, input, true, common.accountingApiUrl, keys.itemSearchQuery)
}

function useTaxes(input: ItemSearchInput) {
  return useQuery([keys.taxQuery], () => itemSearchQuery(input), {
    select: (data: Response<ItemSearches>) => data.data.data.itemSearch,
  })
}

//#region getAllItems
function useItem(input: ItemQueryInput) {
  const queryClient = useQueryClient()
  return useInfiniteQuery([keys.itemQuery, input], fetchItems, {
    getNextPageParam: (lastPage: Response<Items>) => {
      if (lastPage?.data.data.items.pageInfo.hasNextPage) {
        return lastPage?.data.data.items.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Items>>) => {
      for (const item of data.pages.flatMap((m) => m.data.data.items.edges)) {
        // we have to set cache in the same way we will get the data from server
        let axiosResponse: Response<Items> = {
          config: {},
          headers: {},
          status: 200,
          statusText: "",
          data: {
            data: {
              items: {
                edges: [item],
                pageInfo: { endCursor: "", hasNextPage: false, hasPreviousPage: false, startCursor: "" },
              },
            },
          },
        }
        queryClient.setQueryData([keys.itemDetailQuery, { itemReference: item.node.itemReference }], axiosResponse)
      }

      return data
    },
  })
}

const fetchItems = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.itemQuery, input, true, common.accountingApiUrl, keys.itemQuery)
}
//#endregion

//#region itemDetails
function fetchItemDetails(itemReference?: string) {
  return graphQLClient.fetch(query.itemQuery, { itemReference }, true, common.accountingApiUrl, keys.itemQuery)
}

function useItemDetail(enabled: boolean, itemReference?: string) {
  return useQuery([keys.itemDetailQuery, { itemReference }], () => fetchItemDetails(itemReference), {
    enabled: enabled,
    select: (response: Response<Items>) => {
      return response.data.data.items.edges.map((m) => m.node)[0]
    },
  })
}
//#endregion

//#region getItemReference
function getOrAddItem() {
  return graphQLClient.mutation3(mutation.getOrAddItem, {}, true, common.accountingApiUrl, "GetOrAddItem")
}

function useGetOrAddItem(enabled: boolean) {
  return useQuery(keys.getOrAddItem, getOrAddItem, {
    enabled: enabled,
    cacheTime: 0,
    select: (response: Response<GetOrAddItem>) => response.data.data,
  })
}
//#endregion

//#region addOrUpdateItem
function updateItem(input: AddOrUpdateItemInput) {
  return graphQLClient.mutation<UpdateItemResponse>(mutation.updateItem, input, "UpdateItem", common.accountingApiUrl)
}

function useUpdateItem() {
  return useMutation(updateItem)
}

//#endregion

//#region removeItem
function removeItem(input: RemoveItemInput) {
  return graphQLClient.mutation(mutation.removeItem, input, "RemoveItem", common.accountingApiUrl)
}

function useRemoveItem() {
  return useMutation(removeItem)
}
//#endregion

export { useItemSearch, useItem, useItemDetail, useGetOrAddItem, useUpdateItem, useRemoveItem }
