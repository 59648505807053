import { TextObservable } from "library/observables/inputGroupText"
import { action, makeObservable, observable, runInAction } from "mobx"
import { SelectOptionObservable } from "library/observables/inputGroupDropdown"
import { Client, UpdateClientInput } from "models/people/client"
import { isSame } from "utils/extensions"
import PrimaryContactObservable from "../02_primaryContact/primaryContactModal"
import { AddressObservable } from "components/shared/address/addressComponent"
import { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { AddressType } from "models/people/peopleTypes"
import { GeneralError } from "models/shared/common"

export default class addClientPageModel {
  clientNumber: TextObservable
  clientReference: TextObservable
  clientTypeReference: TextObservable
  clientTypeName: TextObservable
  businessName: TextObservable
  description: TextObservable
  website: TextObservable
  businessEmailAddress: TextObservable
  businessPhoneNumber: TextObservable
  clientManager: SelectOptionObservable
  primaryContact: PrimaryContactObservable
  address: AddressObservable
  addressType: SelectObservable<AddressType>
  generalErrors: GeneralError

  constructor() {
    makeObservable(this, {
      generalErrors: observable,
      clearAddressErrors: action,
    })
    this.clientNumber = new TextObservable()
    this.clientReference = new TextObservable()
    this.clientTypeReference = new TextObservable()
    this.clientTypeName = new TextObservable()
    this.businessName = new TextObservable()
    this.description = new TextObservable()
    this.website = new TextObservable()
    this.businessEmailAddress = new TextObservable()
    this.businessPhoneNumber = new TextObservable()
    this.clientManager = new SelectOptionObservable()
    this.primaryContact = new PrimaryContactObservable()
    this.address = new AddressObservable()
    this.addressType = new SelectObservable<AddressType>()
    this.generalErrors = {}
  }

  getClientInput() {
    const input: UpdateClientInput = {
      clientReference: this.clientReference.text,
      clientTypeReference: this.clientTypeReference.text,
      businessName: this.businessName.text,
      description: this.description.text,
      website: this.website.text,
      businessEmailAddress: this.businessEmailAddress.text,
      businessPhoneNumber: this.businessPhoneNumber.text,
      clientManagerReference: this.clientManager.reference,
      timezone: "",
    }
    if (this.primaryContact.givenName) {
      input.primaryContact = {
        emailAddress: this.primaryContact.emailAddress.text,
        givenName: this.primaryContact.givenName.text,
        familyName: this.primaryContact.familyName.text,
        dateOfBirth: this.primaryContact.dateOfBirth.text,
        phoneNumber: this.primaryContact.phoneNumber.text,
        phoneNumberTypeReference: this.primaryContact.PhoneNumberType.reference,
      }
    }
    return input
  }

  setClient(client: Client) {
    runInAction(() => {
      this.clientNumber.text = client.clientNumber
      this.clientReference.text = client.clientReference
      this.clientTypeReference.text = client.clientTypeReference
      this.clientTypeName.text = client.clientTypeName
      this.businessName.text = client.businessName
      this.description.text = client.description
      this.website.text = client.website
      this.businessEmailAddress.text = client.businessEmailAddress
      this.businessPhoneNumber.text = client.businessPhoneNumber
      this.clientManager.reference = client.clientManager?.managerReference
      this.primaryContact.setPrimaryContact(client.primaryContact)
    })
  }

  clear() {
    this.clientNumber = new TextObservable()
    this.clientReference = new TextObservable()
    this.clientTypeReference = new TextObservable()
    this.clientTypeName = new TextObservable()
    this.businessName = new TextObservable()
    this.description = new TextObservable()
    this.website = new TextObservable()
    this.businessEmailAddress = new TextObservable()
    this.businessPhoneNumber = new TextObservable()
    this.clientManager = new SelectOptionObservable()
    this.primaryContact = new PrimaryContactObservable()
  }

  // Method to handle API error messages
  handleApiErrors(errorResponse: any) {
    runInAction(() => {
      // Loop through the error messages
      errorResponse.response?.data.messages?.forEach((v: any) => {
        if (isSame(v.property, "primaryContact.givenName")) {
          this.primaryContact.givenName.errorMessage = v.description
        } else if (isSame(v.property, "primaryContact.emailAddress")) {
          this.primaryContact.emailAddress.errorMessage = v.description
        } else if (isSame(v.property, "primaryContact.phoneNumber")) {
          this.primaryContact.phoneNumber.errorMessage = v.description
        } else if (isSame(v.property, "primaryContact.phoneNumberTypeReference")) {
          this.primaryContact.PhoneNumberType.errorMessage = v.description
        } else if (isSame(v.property, "businessName")) {
          this.businessName.errorMessage = v.description
        } else if (isSame(v.property, "businessEmailAddress")) {
          this.businessEmailAddress.errorMessage = v.description
        } else this.generalErrors[v.property] = v.description
      })
    })
  }

  handleAddressApiErrors(errorResponse: any) {
    runInAction(() => {
      errorResponse.response?.data.messages?.forEach((v: any) => {
        if (isSame(v.property, "addressTypeReference")) {
          this.addressType.errorMessage = v.description
        } else if (isSame(v.property, "route")) {
          this.address.route.errorMessage = v.description
        } else if (isSame(v.property, "city")) {
          this.address.city.errorMessage = v.description
        } else if (isSame(v.property, "regionCode") || isSame(v.property, "regionName")) {
          this.address.region.errorMessage = v.description
        } else if (isSame(v.property, "postalCode")) {
          this.address.postCode.errorMessage = v.description
        } else if (isSame(v.property, "countryCode") || isSame(v.property, "countryName")) {
          this.address.country.errorMessage = v.description
        } else this.generalErrors[v.property] = v.description
      })
    })
  }

  clearAddressErrors() {
    this.address.clearErrors()
    this.addressType.errorMessage = undefined
  }
}
