import React, { useEffect, useState } from "react"
import { Modal } from "library/styled/modal"
import { ThreeDotsSpinner } from "library/styled/spinners"
import { GenerateReportLinkInput } from "../../invoices/05_sharedModel/InputModels"
import { reportType } from "../../../constants/reportType"
import { handlePopupError } from "../../../common"
import { useGenerateReportLink } from "../../invoices/services/invoiceService"

interface Props {
  isOpen: boolean
  onClose: () => void
  paymentReference: string
}

const PaymentPdfModal = (prop: Props) => {

  const [reportLink, setReportLink] = useState("")
  const [loading, setLoading] = useState(true)
  const { mutate: generateLink } = useGenerateReportLink()

  useEffect(() => {
    if (prop.isOpen) {
      setLoading(true)

      const input: GenerateReportLinkInput = {
        reportReference: reportType.payment.reference,
        reportParameters: [
          {
            normalizedName: "pr",
            value: prop.paymentReference ?? ""
          }
        ]
      }
      generateLink(input, {
        onError: (error: any) => handlePopupError(error.response),
        onSuccess: (response) => {
          if (response) {
            setReportLink(response.data.data.generateReportLink.reportUrl)
          }
        },
        onSettled: () => setLoading(false)
      })
    }
  }, [prop.isOpen])

  return (
    <>
      <Modal
        title="Preview Receipt"
        isOpen={prop.isOpen}
        onClose={prop.onClose}
        footer={true}
        width="max-w-[850px]"
        clickOutside={false}
        marginTop="mt-4"
      >
        {loading && <ThreeDotsSpinner left="50%" />}
        {reportLink &&
          <iframe
            src={reportLink}
            style={{ height: "80vh", width: "100%", border: "none" }}
            title="Invoice Pdf"
            onLoad={() => setLoading(false)}
          />
        }
      </Modal>
    </>
  )
}

export default PaymentPdfModal
