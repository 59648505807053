import React from "react"
import { CreatedView, DateTimeView, TagView, TextView } from "library/styled/description-list"
import { Modal } from "library/styled/modal"
import { Dl } from "library/styled/styled"
import TextAreaDynamicView from "library/styled/textAreaDynamic"
import { Email } from "components/invoices/05_sharedModel/emailModel"

interface Props {
  isOpen: boolean
  onClose: () => void
  email?: Email
}

const ViewEmailDetailModal = ({ isOpen, onClose, email }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Invoice Email Details" footer={false}>
    <Dl>
      <TextView label="Configuration Name" value={email?.emailConfigurationName} />
      <TextView label="Subject" value={email?.subject} cols={2} />
      <TextAreaDynamicView label="Body" value={email?.body} />
      <TagView label="Recipients" list={email?.recipients} optionLabel="name" optionValue="emailAddress" />
      <TextView label="Status" value={email?.emailDeliveryStatusName} />
      {email?.emailDeliveryStatusReference === "EF3BF7EB-F330-4112-B708-E6767FFA2D56" && (
        <TextView label="Failed Reason" value={email?.errorMessage} />
      )}
      <DateTimeView label="Email Opened On" value={email?.emailOpenDateTimeUtc} />
      <CreatedView label="Created By" createdBy={email?.insertedBy} createdDateTimeUtc={email?.insertedDateTimeUtc} />
    </Dl>
  </Modal>
)

export default ViewEmailDetailModal
