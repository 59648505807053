import { useMutation, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import {
  AddReminderInput,
  ReminderQueryInput,
  Reminders,
  RemoveReminderInput,
  UpdateReminderInput,
} from "../03_sharedModel/reminder"

//#region reminders
const fetchReminders = (input: ReminderQueryInput) => {
  return graphQLClient.fetch(query.reminderQuery, input, true, common.reminderApiUrl, keys.reminderQuery)
}

function useReminder(input: ReminderQueryInput, enabled: boolean) {
  return useQuery([keys.reminderQuery, input], () => fetchReminders(input), {
    enabled: enabled,
    select: (data: Response<Reminders>) => data.data.data.reminders.edges.map((m) => m.node),
  })
}

function addReminder(input: AddReminderInput) {
  return graphQLClient.mutation(mutation.addReminder, input, "AddReminder", common.reminderApiUrl)
}

function useAddReminder() {
  return useMutation(addReminder)
}

function updateReminder(input: UpdateReminderInput) {
  return graphQLClient.mutation(mutation.updateReminder, input, "UpdateReminder", common.reminderApiUrl)
}

function useUpdateReminder() {
  return useMutation(updateReminder)
}

function removeReminder(input: RemoveReminderInput) {
  return graphQLClient.mutation(mutation.removeReminder, input, "RemoveReminder", common.reminderApiUrl)
}

function useRemoveReminder() {
  return useMutation(removeReminder)
}
//#endregion

export { useReminder, useAddReminder, useUpdateReminder, useRemoveReminder }
