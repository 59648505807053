import React from "react"
import { Switch } from "@headlessui/react"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { action } from "mobx"
import { observer } from "mobx-react"

interface Props {
  isEnabled: BooleanObservable
}

const ToggleSwitchInput = ({ isEnabled }: Props) => {
  return (
    <Switch
      name="toggle"
      as="button"
      checked={isEnabled.value}
      onChange={action((e) => {
        isEnabled.value = !isEnabled.value
      })}
      className={`${isEnabled.value ? "bg-secondary-100" : "bg-gray-200"}
                          relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${isEnabled.value ? "translate-x-[20px]" : "translate-x-0"}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  )
}

export default observer(ToggleSwitchInput)
