import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { observer } from "mobx-react"
import { action, runInAction } from "mobx"
import { handleServerError } from "common"
import { Modal } from "library/styled/modal"
import { Form, FormGroup, Label, TextArea } from "library/styled/styled"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import addOrEditItemPageModel from "./addOrEditItemPageModel"
import { Item } from "../04_itemSharedModel/itemModel"
import { useGetOrAddItem, useUpdateItem } from "../itemService"
import { AddOrUpdateItemInput } from "../04_itemSharedModel/inputModels"
import { successToaster } from "library/styled/toasters"
import { keys } from "constants/keys"
import NumberInputGroup from "library/numberInputGroup/numberInputGroup"
import InputGroupDropdown from "../../../library/observables/inputGroupDropdown"
import { itemTypes } from "../../../constants/itemTypes"
import ItemTaxSelection from "library/components/itemTaxSelection"
import TextAreaInputGroup from "../../../library/textAreaInputGroup/textAreaInputGroup"

interface Props {
  isOpen: boolean
  onClose: () => void
  item?: Item
  isEditMode: boolean
  onItemCreated?: (item: AddOrUpdateItemInput) => void
}

const AddOrEditItemModal = ({ isOpen, onClose, item, isEditMode, onItemCreated }: Props) => {
  const [itemPageModel] = useState(() => new addOrEditItemPageModel())
  const queryClient = useQueryClient()
  //when editing item we should not call useGetOrAddItem. It is only called when creating an item.
  const { data: getOrAddItem } = useGetOrAddItem(!isEditMode && isOpen)
  const { mutate: updateItemMutation, isLoading } = useUpdateItem()

  useEffect(() => {
    if (isOpen && isEditMode && item) {
      itemPageModel.setItem(item)
    }
    // Initialise data when popup is opened for add Item.
    if (isOpen && !isEditMode) itemPageModel.clear()
  }, [isOpen, isEditMode])

  useEffect(() => {
    if (getOrAddItem) {
      runInAction(() => {
        itemPageModel.itemReference.text = getOrAddItem?.getOrAddItem.itemReference
      })
    }
  }, [getOrAddItem])

  const handleSubmit = () => {
    const input: AddOrUpdateItemInput = itemPageModel.getUpdateItemInput()
    updateItemMutation(input, {
      onError: (error: any) => {
        itemPageModel.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {        
        if (onItemCreated) {
          onItemCreated(input)
        }
        void queryClient.invalidateQueries(keys.itemQuery)
        void queryClient.invalidateQueries(keys.getOrAddItem, { refetchActive: false })
        successToaster(`Item ${isEditMode ? "updated" : "added"}`)
        itemPageModel.clear()
        onClose()
      },
    })
  }

  return (
    <>
      <Modal
        title={`${isEditMode ? "Edit" : "Create"} Item`}
        isOpen={isOpen}
        onClose={onClose}
        footer={true}
        action={handleSubmit}
        isButtonLoading={isLoading}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextInputGroup id={"name"} label="Name" value={itemPageModel.name} isRequired={true} />
          <InputGroupDropdown
            label={"Item Type"}
            name="ItemType"
            options={itemTypes}
            value={itemPageModel.itemTypeSelection}
            isInModal={true}
            isSearchable={false}
            isClearable={false}
            isDisabled={isEditMode ?? false}
          />
          <TextInputGroup id={"code"} label="Code" value={itemPageModel.itemCode} isRequired={true} />
          <TextAreaInputGroup
            name={"DescriptionTextArea"}
            label={"Description"}
            isRequired={false}
            value={itemPageModel.description}
            rows={6}
          />
          <FormGroup cols={6}>
            <Label>Sale Information</Label>
          </FormGroup>
          <NumberInputGroup
            id="salePrice"
            label="Sale Price"
            value={itemPageModel.salePrice}
            isRequired={true}
            maxValue={2147483647}
          />
          <ItemTaxSelection taxRateObservable={itemPageModel.taxRateSelection} type="input" name="TaxRate" label="Tax Rate" isEditMode={isEditMode} />
        </Form>
      </Modal>
    </>
  )
}

export default observer(AddOrEditItemModal)
