export const localStorageService = {
  set(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  },

  get(key: string) {
    var value = localStorage.getItem(key)
    if (value !== null) return JSON.parse(value)
    return null
  },

  clear() {
    localStorage.clear()
  },

  remove(key: string) {
    localStorage.removeItem(key)
  },
}
