import { makeObservable, observable } from "mobx"
import { DatePickerObservable } from "../../../library/observables/datePickers"
import { v4 } from "uuid"

export class TaskReminderObservable {
  public taskReminderReference?: string
  public isDefault?: boolean
  public reminderDateTimeUtc: DatePickerObservable
  public errorMessage?: string

  constructor() {
    makeObservable(this, {
      taskReminderReference: observable,
      reminderDateTimeUtc: observable,
      errorMessage: observable
    })

    this.isDefault = true
    this.taskReminderReference = v4()
    this.reminderDateTimeUtc = new DatePickerObservable()
  }
}