import { DatePickerObservable } from "library/observables/datePickers"
import { action, makeObservable, observable, reaction } from "mobx"
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns"
import {
  AddEventModal,
  EventDetail,
  JobCountQueryInput,
  SchedulerEventQueryInput,
  UnassignedJobQueryInput,
} from "components/scheduler/05_models/models"
import { View } from "@syncfusion/ej2-react-schedule"
import { toUtcDateTime } from "common"
import { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { KeyValuePair } from "models/shared/common"
import { dayHours, dayOfWeekOptions, timeLabelIntervals, timeSlots } from "constants/schedulerSetting"
import { SchedulerSettings } from "components/scheduler/schedulerView"
import { localStorageService } from "shared/services/local-storage"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"

export class SchedulerModel {
  selectedDate: Date = new Date()
  selectedView: View = "TimelineDay"
  addEventModal: AddEventModal = {
    isOpen: false,
  }
  openUnassignedJobs: boolean = false
  jobCountDate: Date = new Date()

  jobTitle = new SelectObservable<KeyValuePair>({ label: "Job ID + Job Status", value: "JobNumber" })
  timeSlot = new SelectObservable<KeyValuePair>(timeSlots[1])
  timeLabelInterval = new SelectObservable<KeyValuePair>(timeLabelIntervals[3])
  workingDayStart = new SelectObservable<KeyValuePair>(dayHours[0])
  workingDayEnd = new SelectObservable<KeyValuePair>(dayHours[24])
  firstDayOfWeek = new SelectObservable<KeyValuePair>(dayOfWeekOptions[1])
  rowAutoHeight = new BooleanObservable(false)

  constructor() {
    const settings = localStorageService.get("schedulerSettings")
    if (settings) {
      this.selectedDate = new Date(settings.selectedDate)
      this.selectedView = settings.selectedView
      this.openUnassignedJobs = settings.openUnassignedJobs
      this.jobCountDate = new Date(settings.jobCountDate)
      this.jobTitle.setValue(settings.jobTitle)
      this.timeSlot.setValue(settings.timeSlot)
      this.timeLabelInterval.setValue(settings.timeLabelInterval)
      this.workingDayStart.setValue(settings.workingDayStart)
      this.workingDayEnd.setValue(settings.workingDayEnd)
      this.firstDayOfWeek.setValue(settings.firstDayOfWeek)
      this.rowAutoHeight.value = settings.showMoreEventLink
    }
    makeObservable(this, {
      addEventModal: observable,
      setAddEventModal: action,
      selectedDate: observable,
      setSelectedDate: action,
      selectedView: observable,
      setSelectedView: action,
      openUnassignedJobs: observable,
      setOpenUnassignedJobs: action,
      jobCountDate: observable,
      setJobCountDate: action,
      reset: action,
    })

    // React to changes in class variables and update local storage
    reaction(
      () => ({
        selectedDate: this.selectedDate,
        selectedView: this.selectedView,
        openUnassignedJobs: this.openUnassignedJobs,
        jobCountDate: this.jobCountDate,
        jobTitle: this.jobTitle.selectedOption,
        timeSlot: this.timeSlot.selectedOption,
        timeLabelInterval: this.timeLabelInterval.selectedOption,
        workingDayStart: this.workingDayStart.selectedOption,
        workingDayEnd: this.workingDayEnd.selectedOption,
        showMoreEventLink: this.rowAutoHeight.value,
      }),
      (data) => {
        localStorage.setItem("schedulerSettings", JSON.stringify(data))
      }
    )
  }

  reset(settings: SchedulerSettings) {
    this.selectedDate = new Date(settings.selectedDate)
    this.selectedView = settings.selectedView
  }

  getEventQueryInput() {
    const input: SchedulerEventQueryInput = {
      resourceReference: "",
      clientReference: "",
      categoryReferences: [],
      stateReferences: [],
      startDateTimeUtc: this.getStartDate(),
      endDateTimeUtc: this.getEndDate(),
    }
    return input
  }

  getStartDate() {
    let date: Date
    if (this.selectedView === "TimelineDay") {
      date = startOfDay(this.selectedDate)
    } else if (this.selectedView === "TimelineWeek") {
      date = startOfWeek(this.selectedDate)
    } else {
      date = startOfMonth(this.selectedDate)
    }

    return toUtcDateTime(date)
  }

  getEndDate() {
    let date: Date
    if (this.selectedView === "TimelineDay") {
      date = endOfDay(this.selectedDate)
    } else if (this.selectedView === "TimelineWeek") {
      date = endOfWeek(this.selectedDate)
    } else {
      date = endOfMonth(this.selectedDate)
    }

    return toUtcDateTime(date)
  }

  getUnassignedJobsQueryInput() {
    const input: UnassignedJobQueryInput = {
      first: 100,
      after: "",
      startDateTimeUtc: this.getStartDate(),
      endDateTimeUtc: this.getEndDate(),
    }
    return input
  }

  getJobCountQueryInput() {
    const input: JobCountQueryInput = {
      startDateTimeUtc: toUtcDateTime(startOfMonth(this.jobCountDate)),
      endDateTimeUtc: toUtcDateTime(endOfMonth(this.jobCountDate)),
    }
    return input
  }

  setAddEventModal(isOpen: boolean, event?: EventDetail) {
    this.addEventModal.isOpen = isOpen
    this.addEventModal.event = event
  }

  setSelectedDate(date: Date) {
    this.selectedDate = date
  }

  setSelectedView(view: View) {
    this.selectedView = view
  }

  setOpenUnassignedJobs() {
    this.openUnassignedJobs = !this.openUnassignedJobs
  }

  setJobCountDate(date?: Date) {
    if (date) this.jobCountDate = date
  }
}
