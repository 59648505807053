import React, { useEffect, useState } from "react"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import { useMetadata } from "context/metadata-context"
import { TBody, Td, Th, THead } from "library/styled/styled"
import { CurrencyFormatLabel } from "library/styled/description-list"
import InvoiceOverviewTotals from "components/invoices/03_invoiceDetail/overview/invoiceOverviewTotals"

interface Props {
  currencyCode?: string
  invoice?: Invoice
}

const LineItemsTableComponent = ({ invoice }: Props) => {
  const { organisation } = useMetadata()
  const [locale, setLocale] = useState<string>()

  useEffect(() => {
    if (organisation?.locale) setLocale(`${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`)
  }, [organisation])

  return (
    <>
      <table className="mt-2 ring-1 ring-black ring-opacity-10 table-fixed">
        <THead>
          <tr>
            <Th className="w-60">Item</Th>
            <Th>Quantity</Th>
            <Th>Unit Price</Th>
            <Th>Discount</Th>
            <Th>Total</Th>
          </tr>
        </THead>
        <TBody>
          {invoice?.lineItemGroups
            .flatMap((m) => m.lineItems)
            .map((item, idx) => (
              <tr key={item.lineItemReference} className={`${idx % 2 === 0 ? undefined : "bg-blue_grey-50"}`}>
                <td className="truncate-td-3 pl-4 text-sm text-gray-500 sm:pl-6 break-words align-middle my-2">
                  {item.description}
                </td>
                <Td>{item.quantity}</Td>
                <Td truncate={false}>
                  <CurrencyFormatLabel amount={item.unitSalePrice} localeCode={locale} />
                </Td>
                <Td truncate={false}>
                  <CurrencyFormatLabel amount={item.totalDiscountAmount} localeCode={locale} />
                </Td>
                <Td truncate={false}>
                  <CurrencyFormatLabel amount={item.totalAmount} localeCode={locale} />
                </Td>
              </tr>
            ))}
        </TBody>
      </table>

      <InvoiceOverviewTotals invoice={invoice} />
    </>
  )
}

export default LineItemsTableComponent
