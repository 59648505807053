import { common, redirectKey, versionNumber } from "../common"
import { RedirectToken, UserSessionStored } from "../models/shared/userSession"
import Crypto from "crypto-js"
import { localStorageService } from "../shared/services/local-storage"

const extensionsSupported: string[] = [
  ".jpeg",
  ".png",
  ".gif",
  ".tiff",
  ".bmp",
  ".webm",
  ".mp4",
  ".3gp",
  ".mov",
  ".avi",
  ".mpegps",
  ".wmv",
  ".flv",
  ".txt",
  ".css",
  ".html",
  ".php",
  ".c",
  ".cpp",
  ".h",
  ".hpp",
  ".js",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".pdf",
  ".pages",
  ".ai",
  ".psd",
  ".tiff",
  ".dxf",
  ".svg",
  ".eps",
  ".ps",
  ".ttf",
  ".xps",
  ".zip",
  ".rar"
]

function isEmpty(str1?: string | null): boolean {
  if (str1 === undefined || str1 === null) return true
  if (str1.length <= 0) return true
  return false
}

function isProvided(str1?: string | null): boolean {
  if (str1 === undefined || str1 === null) return false
  if (str1.length <= 0) return false
  return true
}

function isSame(str1?: string | null, str2?: string | null): boolean {
  if (str1 === undefined || str1 === null) return false
  if (str2 === undefined || str2 === null) return false
  return str1.toLowerCase() === str2.toLowerCase()
}

function isContains(str1?: string | null, str2?: string | null): boolean {
  if (str1 === undefined || str1 === null) return false
  if (str2 === undefined || str2 === null) return false
  return str1.toLowerCase().includes(str2.toLowerCase())
}

function toCurrency(amount?: number, currencyCode?: string, localeCode?: string) {
  if (amount === null || undefined) amount = 0
  let label = ""
  if (currencyCode) label = currencyCode + " "

  return label + new Intl.NumberFormat(localeCode, { minimumFractionDigits: 2 }).format(amount ?? 0)
}

// This method checks if any properties of the mobx model has validation error
function hasValidationError(errors: (string | undefined)[]): boolean {
  return errors.some((m) => m !== undefined && m.length > 0)
}

function getFormattedDisplayValue(displayValue: string, alwaysShowDecimals: boolean, decimalsLimit?: number) {
  if (isEmpty(displayValue)) return ""

  let newValue = displayValue
  let numberValue = Number(displayValue)

  // Split the string value so that we can find number of decimal places entered by user.
  const decimalArray = (displayValue ?? "").toString().split(".")

  // If user has not provided any decimal places then always round to 2 decimal places
  if (decimalArray.length === 1 && alwaysShowDecimals) {
    newValue = numberValue.toFixed(2) ?? ""
  }

  // If only 1 decimal is provided by user we round to two decimals
  if (decimalArray.length === 2 && decimalArray[1].length === 1 && alwaysShowDecimals) {
    newValue = numberValue.toFixed(2) ?? ""
  }

  // finally if more than 4 decimals are provided to round to 4
  if (decimalArray.length === 2 && decimalsLimit && decimalArray[1].length > (decimalsLimit ?? 0)) {
    newValue = numberValue.toFixed(decimalsLimit) ?? ""
  }

  return newValue
}

function isNotRunningTest(): boolean {
  return !isSame(process.env.JEST_WORKER_ID, "1")
}

function isRunningTest(): boolean {
  return isSame(process.env.JEST_WORKER_ID, "1")
}

const showDashIfEmpty = (value: string | number | undefined) => {
  if (value) return value
  return "-"
}

function getTotalInBaseCurrency(total?: number, exchangeRate?: number) {
  if (total && exchangeRate) return total / exchangeRate
}

function isFilePreviewAvailable(extension: string) {
  return extensionsSupported.includes(extension.toLowerCase())
}

function getPreviousPath() {
  return localStorage.getItem(common.PreviousPathStorageKey)
}

function storeCurrentPath() {
  const path = window.location.pathname + window.location.search + window.location.hash
  localStorage.setItem(common.PreviousPathStorageKey, path)
}

function loadAngularJsOrganisation() {
  const searchParams = new URLSearchParams(document.location.search)

  // If user is redirected from angularjs url will contain the 't' parameter
  const param = searchParams.get("t")
  if (param) {
    const decodedBase64 = atob(param)
    // Decrypt
    const bytes = Crypto.AES.decrypt(decodedBase64, redirectKey)
    const decryptedData: RedirectToken = JSON.parse(bytes.toString(Crypto.enc.Utf8))
    localStorageService.set(common.localStorageActiveOrganisationKey, decryptedData.organisationId)
  }
}

function monitorVersionChange() {
  // when ever user refreshes the page if the version in local storage and in common file do not match.
  // we have to first clear the local storage and add common version into the storage.
  // And log the user out of the application.
  const appVersion = localStorageService.get(common.AppVersionCacheKey)
  const activeOrganisationId = localStorageService.get(common.localStorageActiveOrganisationKey)
  //Make sure version is the same as we have in common file.
  if (!isSame(versionNumber, appVersion)) {
    // Clear storage, this will log user out also
    localStorageService.clear()
    // Add new version to storage
    localStorageService.set(common.AppVersionCacheKey, versionNumber)
    localStorageService.set(common.localStorageActiveOrganisationKey, activeOrganisationId)
  }
}

async function getUserSession() {
  let userSession: UserSessionStored
  userSession = localStorageService.get(common.identityServerStorageKey)
  return userSession
}

function clearLogoutData() {
  // don't remove user session this will get deleted by oidc manager
  const userSession = localStorage.getItem(common.identityServerStorageKey)
  localStorageService.clear()
  localStorageService.set(common.identityServerStorageKey, userSession)
}

function safeSubString(str: string, end: number) {
  if (str.length > end) {
    return str.substring(0, end) + ".."
  }

  return str
}

export {
  isEmpty,
  isProvided,
  isSame,
  isContains,
  toCurrency,
  getFormattedDisplayValue,
  isNotRunningTest,
  isRunningTest,
  hasValidationError,
  showDashIfEmpty,
  getTotalInBaseCurrency,
  isFilePreviewAvailable,
  getPreviousPath,
  storeCurrentPath,
  loadAngularJsOrganisation,
  monitorVersionChange,
  getUserSession,
  clearLogoutData,
  safeSubString
}
