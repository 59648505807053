import React from "react"
import DropdownMenu from "library/styled/dropdownMenu"
import { TemplateMergeField } from "components/setting/invoice/05_sharedModel/templateMergeField"
import { observer } from "mobx-react"
import { AddOrUpdateTemplateModel } from "components/setting/template/01_addOrEditTemplate/addOrEditTemplateModel"
import { runInAction } from "mobx"
import { placeholderInputTypes } from "components/setting/template/05_sharedModel/placeholderInputTypes"

interface Props {
  templateModel: AddOrUpdateTemplateModel
  id: string
}

const InsertMergeFieldsDropdownMenu = ({ templateModel, id }: Props) => {
  const onMergeFieldClicked = (field: TemplateMergeField) => {
    insertPlaceholder(field)
  }

  function insertPlaceholder(field: TemplateMergeField) {
    // Get the input element with the specified id and cast it to an HTMLInputElement
    const element = document.getElementById(id) as HTMLInputElement
    // Get the current cursor position of the input element, or set it to 0 if the element is not found
    const position = element?.selectionStart ?? 0
    // If the input element has the id "emailSubject", update the email subject placeholder value
    if (id === placeholderInputTypes.subject) {
      runInAction(() => {
        // Set the new placeholder value for the email subject
        templateModel.emailTemplateSubject.text = setPlaceholderValue(
          templateModel.emailTemplateSubject.text ?? "",
          field,
          position
        )
      })
    }
    // Otherwise, update the email body placeholder value
    else if (id === placeholderInputTypes.body) {
      runInAction(() => {
        // Set the new placeholder value for the email body
        templateModel.emailTemplateBody.text = setPlaceholderValue(templateModel.emailTemplateBody.text ?? "", field, position)
      })
    } else if (id === placeholderInputTypes.description) {
      runInAction(() => {
        // Set the new placeholder value for the description
        templateModel.descriptionTemplate.text = setPlaceholderValue(
          templateModel.descriptionTemplate.text ?? "",
          field,
          position
        )
      })
    } else {
      runInAction(() => {
        // Set the new placeholder value for the sms
        templateModel.smsTemplateMessage.text = setPlaceholderValue(templateModel.smsTemplateMessage.text ?? "", field, position)
      })
    }
    // Calculate the new cursor position after inserting the placeholder value
    const textLength = field.normalizedName.length + position
    // Set the selection range of the input element to the new cursor position
    element.setSelectionRange(textLength + 1, textLength + 1)
    // Focus on the input element to make the cursor visible
    element.focus()
  }

  const setPlaceholderValue = (value: string, field: TemplateMergeField, position: number) => {
    let textToInsert = field.normalizedName
    let textBeforeCursorPosition = value.substring(0, position)
    let textAfterCursorPosition = value.substring(position)
    return textBeforeCursorPosition + textToInsert + textAfterCursorPosition
  }

  return (
    <>
      <DropdownMenu
        onClick={onMergeFieldClicked}
        options={templateModel.templateType.selectedOption?.mergeFields}
        menuButton={
          <div id="insertPlaceholder" className="block text-xs font-normal underline text-gray-700 my-auto">
            Insert Placeholders
          </div>
        }
      />
    </>
  )
}

export default observer(InsertMergeFieldsDropdownMenu)
