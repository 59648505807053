import React, { useEffect, useState } from "react"
import { Modal } from "library/styled/modal"
import { ThreeDotsSpinner } from "library/styled/spinners"
import { useNavigate } from "react-router-dom"
import { UpdateInvoiceStatusInput } from "components/invoices/05_sharedModel/InputModels"
import { invoiceStatus } from "constants/invoiceStatus"
import { handlePopupError } from "common"
import { useUpdateInvoiceStatus } from "components/invoices/services/invoiceService"

interface Props {
  isOpen: boolean
  onClose: () => void
  reportLink: string
  invoiceReference: string
  concurrencyToken: string
  isEditMode?: boolean
  // only draft, submitted invoices can be approved
  canApproveInvoice: boolean
  onSendEmail: () => void
}

const ViewInvoicePdfModal = ({
                               isOpen,
                               onClose,
                               reportLink,
                               invoiceReference,
                               concurrencyToken,
                               isEditMode,
                               canApproveInvoice,
                               onSendEmail
                             }: Props) => {
  const [loading, setLoading] = useState(true)
  const { mutate: updateInvoiceStatus } = useUpdateInvoiceStatus()
  const navigate = useNavigate()
  useEffect(() => {
    if (isOpen) setLoading(true)
  }, [isOpen])

  const approveAndSendEmail = () => {
    let input: UpdateInvoiceStatusInput = {
      invoiceReference: invoiceReference,
      concurrencyToken: concurrencyToken,
      invoiceStatusReference: invoiceStatus.approved.reference
    }
    updateInvoiceStatus(input, {
      onSuccess: () => {
        onSendEmail()
      },
      onError: (error: any) => {
        handlePopupError(error.response)
      }
    })
  }

  return (
    <>
      <Modal
        title="Invoice Pdf"
        isOpen={isOpen}
        onClose={onClose}
        footer={true}
        width="max-w-[850px]"
        clickOutside={false}
        marginTop="mt-4"
        onEdit={
          isEditMode
            ? undefined
            : () => {
              navigate(`/invoices/edit/${invoiceReference}`)
            }
        }
        buttonText={canApproveInvoice ? "Approve & Email" : "Email"}
        action={() => (canApproveInvoice ? approveAndSendEmail() : onSendEmail())}
      >
        {loading && <ThreeDotsSpinner left="50%" />}
        {reportLink && <iframe
          src={reportLink}
          style={{ height: "80vh", width: "100%", border: "none" }}
          title="Invoice Pdf"
          onLoad={() => setLoading(false)}
        />}

      </Modal>
    </>
  )
}

export default ViewInvoicePdfModal
