import TextInputGroup from "library/textInputGroup/textInputGroup"
import InputGroupDropdown, { SelectOptionObservable } from "library/observables/inputGroupDropdown"
import React from "react"
import { StaffQueryInput } from "models/people/staff"
import { staffStates } from "constants/people"
import { useStaff } from "hooks/people/staff"
import { usePhoneNumberType } from "hooks/people/type"
import PrimaryContactObservable from "./primaryContactModal"

interface Props {
  primaryContactInput: PrimaryContactObservable
  clientManager?: SelectOptionObservable
  primaryPhoneNumber?: SelectOptionObservable
}
const ClientPrimaryContact = ({ primaryContactInput, clientManager, primaryPhoneNumber }: Props) => {
  const { data: phoneNumberTypes } = usePhoneNumberType()

  return (
    <>
      <div>
        <TextInputGroup id={"firstName"} label="First Name" value={primaryContactInput.givenName} isRequired={true} />
      </div>
      <div>
        <TextInputGroup id={"lastName"} label="Last Name" value={primaryContactInput.familyName} />
      </div>
      <div>
        <TextInputGroup id={"emailAddress"} label="Email Address" value={primaryContactInput.emailAddress} />
      </div>
      <div></div>
      <div>
        <TextInputGroup id={"phoneNumber"} label="Phone Number" value={primaryContactInput.phoneNumber} />
      </div>
      <div>
        <InputGroupDropdown
          label={"Phone Number Type"}
          name="phoneNumberType"
          options={phoneNumberTypes?.map((phoneNumber) => {
            return { reference: phoneNumber.phoneNumberTypeReference, name: phoneNumber.name }
          })}
          value={primaryPhoneNumber}
          isInModal={true}
          isSearchable={false}
          isClearable={false}
        />
      </div>
    </>
  )
}
export default ClientPrimaryContact
