import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { common } from "common"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import {
  GenerateReportLinkInput,
  GenerateReportLinkResponse,
  InvoiceQueryInput,
  RemoveInvoiceInput,
  SendInvoiceInput,
  UpdateInvoiceInput,
  UpdateInvoiceResponse,
  UpdateInvoiceStatusInput,
  UpdateInvoiceStatusResponse,
} from "../05_sharedModel/InputModels"
import { keys } from "constants/keys"
import {
  AttachmentInput,
  ClientEmailAddresses,
  ClientEmailAddressQueryInput,
  EmailAttachments,
  Invoices,
  InvoiceStatues,
} from "../05_sharedModel/invoiceModel"
import { query } from "shared/graphql/queries"
import { ReactQueryParams } from "models/shared/common"
import { Response } from "models/shared/response"
import { EmailQueryInput, Emails, EmailTemplateQueryInput, EmailTemplates } from "components/invoices/05_sharedModel/emailModel"

type GetOrAddInvoice = {
  getOrAddInvoice: {
    invoiceReference: string
    concurrencyToken: string
  }
}

function useInvoice(input: InvoiceQueryInput) {
  const queryClient = useQueryClient()
  return useInfiniteQuery([keys.invoiceQuery, input], fetchInvoices, {
    getNextPageParam: (lastPage: Response<Invoices>) => {
      if (lastPage?.data.data.invoices.pageInfo.hasNextPage) {
        return lastPage?.data.data.invoices.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Invoices>>) => {
      for (const invoice of data.pages.flatMap((m) => m.data.data.invoices.edges)) {
        // we have to set cache in the same way we will get the data from server
        let axiosResponse: Response<Invoices> = {
          config: {},
          headers: {},
          status: 200,
          statusText: "",
          data: {
            data: {
              invoices: {
                edges: [invoice],
                pageInfo: { endCursor: "", hasNextPage: false, hasPreviousPage: false, startCursor: "" },
              },
            },
          },
        }
        queryClient.setQueryData([keys.invoiceDetailQuery, { invoiceReference: invoice.node.invoiceReference }], axiosResponse)
      }

      return data
    },
  })
}

const fetchInvoices = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.invoiceQuery, input, true, common.accountingApiUrl, keys.invoiceQuery)
}

function fetchInvoiceDetails(invoiceReference?: string) {
  return graphQLClient.fetch(query.invoiceQuery, { invoiceReference }, true, common.accountingApiUrl, keys.invoiceQuery)
}

function useInvoiceDetail(enabled: boolean, invoiceReference?: string) {
  return useQuery([keys.invoiceDetailQuery, { invoiceReference }], () => fetchInvoiceDetails(invoiceReference), {
    enabled: enabled,
    select: (response: Response<Invoices>) => {
      return response.data.data.invoices.edges.map((m) => m.node)[0]
    },
  })
}
//#endregion

function getOrAddInvoice() {
  return graphQLClient.mutation3(mutation.getOrAddInvoice, {}, true, common.accountingApiUrl, "GetOrAddInvoice")
}

function useGetOrAddInvoiceV2(enabled: boolean) {
  return useQuery(keys.getOrAddInvoice, getOrAddInvoice, {
    enabled: enabled,
    select: (response: Response<GetOrAddInvoice>) => response.data.data,
  })
}

function updateInvoice(input: UpdateInvoiceInput) {
  return graphQLClient.mutation<UpdateInvoiceResponse>(mutation.updateInvoice, input, "UpdateInvoice", common.accountingApiUrl)
}

function updateInvoiceStatus(input: UpdateInvoiceStatusInput) {
  return graphQLClient.mutation<UpdateInvoiceStatusResponse>(
    mutation.updateInvoiceStatus,
    input,
    "UpdateInvoiceStatus",
    common.accountingApiUrl
  )
}

function sendEmail(input: SendInvoiceInput) {
  return graphQLClient.mutation(mutation.sendEmail, input, "SendEmail", common.organisationApiUrl)
}

function useUpdateInvoice() {
  return useMutation(updateInvoice)
}

function useUpdateInvoiceStatus() {
  return useMutation(updateInvoiceStatus)
}

function useSendEmail() {
  return useMutation(sendEmail)
}

function useInvoiceStatus() {
  return useQuery(keys.invoiceStatusQuery, fetchInvoiceStatuses, {
    select: (data: Response<InvoiceStatues>) => data.data.data.invoiceStatues,
  })
}
function useClientEmailAddress(input: ClientEmailAddressQueryInput) {
  return useQuery([keys.clientEmailAddressQuery, input], () => fetchClientEmailAddress(input), {
    select: (data: Response<ClientEmailAddresses>) => data.data.data.clientEmailAddress,
  })
}

function useAttachments(input: AttachmentInput, enabled: boolean) {
  return useQuery([keys.emailAttachmentQuery, input], () => fetchAttachments(input), {
    enabled: enabled,
    select: (data: Response<EmailAttachments>) => data.data.data.files.edges.map((value) => value.node),
  })
}

function fetchInvoiceStatuses() {
  return graphQLClient.fetch(query.invoiceStatusQuery, null, true, common.accountingApiUrl, keys.invoiceStatusQuery)
}

function fetchClientEmailAddress(input: ClientEmailAddressQueryInput) {
  return graphQLClient.fetch(query.clientEmailAddressQuery, input, true, common.peopleApiUrl, keys.clientEmailAddressQuery)
}

function fetchAttachments(input: AttachmentInput) {
  return graphQLClient.fetch(query.emailAttachmentQuery, input, true, common.fileApiUrl, keys.emailAttachmentQuery)
}

function fetchEmails(input: EmailQueryInput) {
  return graphQLClient.fetch(query.emailsQuery, input, true, common.organisationApiUrl, keys.emailsQuery)
}

function useEmail(enabled: boolean, input: EmailQueryInput) {
  return useQuery([keys.emailsQuery, input], () => fetchEmails(input), {
    enabled: enabled,
    select: (response: Response<Emails>) => response.data.data.emails,
  })
}

function removeInvoice(input: RemoveInvoiceInput) {
  return graphQLClient.mutation(mutation.removeInvoice, input, "RemoveInvoice", common.accountingApiUrl)
}

function useRemoveInvoice() {
  return useMutation(removeInvoice)
}

function generateReportLink(input: GenerateReportLinkInput) {
  return graphQLClient.mutation<GenerateReportLinkResponse>(
    mutation.generateReportLink,
    input,
    "GenerateReportLink",
    common.organisationApiUrl
  )
}

function useGenerateReportLink() {
  return useMutation(generateReportLink)
}

function fetchEmailTemplates(input: EmailTemplateQueryInput) {
  return graphQLClient.fetch(query.emailTemplateQuery, input, true, common.organisationApiUrl, keys.emailTemplateQuery)
}

function useEmailTemplate(enabled: boolean, input: EmailTemplateQueryInput) {
  return useQuery([keys.emailsQuery, input], () => fetchEmailTemplates(input), {
    enabled: enabled,
    select: (response: Response<EmailTemplates>) => response.data.data.emailTemplate,
    cacheTime: 0,
  })
}

export {
  useInvoice,
  useInvoiceDetail,
  useUpdateInvoice,
  useInvoiceStatus,
  useGetOrAddInvoiceV2,
  useUpdateInvoiceStatus,
  useSendEmail,
  useClientEmailAddress,
  useAttachments,
  useEmail,
  useRemoveInvoice,
  useGenerateReportLink,
  useEmailTemplate,
}
