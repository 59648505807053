import { action, makeObservable, observable } from "mobx"
import { Item, Items } from "../04_itemSharedModel/itemModel"
import { InfiniteData } from "react-query"
import { Response } from "models/shared/response"
import { ItemQueryInput } from "../04_itemSharedModel/inputModels"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { SelectOptionObservable } from "library/observables/inputGroupDropdown"

export class ItemListModel {
  itemList: Item[] = []
  itemNameSearch: TextObservable
  itemType: SelectOptionObservable
  includeArchived: BooleanObservable
  constructor() {
    makeObservable(this, {
      itemList: observable,
      setItemList: action,
    })
    this.itemNameSearch = new TextObservable("")
    this.itemType = new SelectOptionObservable()
    this.includeArchived = new BooleanObservable()
  }

  getFilter() {
    const obj: ItemQueryInput = {
      first: 10,
      after: "",
      itemCode: "",
      itemReference: "",
      itemTypeReference: this.itemType.reference ?? "",
      categoryReference: "",
      barcodeNumber: "",
      storageReference: "",
      adjustmentReference: "",
      supplierItemCode: "",
      includeArchived: this.includeArchived.value ? "true" : "false",
      supplierReference: "",
      search: this.itemNameSearch.text ?? "",
    }
    return obj
  }

  setItemList(data: InfiniteData<Response<Items>>) {
    let tempList: Item[] = []
    data.pages.forEach((page) => {
      const pageData = page.data.data.items.edges.map((item) => item.node)
      tempList = tempList.concat(pageData)
    })
    this.itemList = tempList
  }
}
