import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { TemplateMergeFieldQueryInput, TemplateMergeFields } from "components/setting/invoice/05_sharedModel/templateMergeField"
import {
  AddInvoiceReminderInput,
  InvoiceReminders,
  RemoveInvoiceReminderInput,
  UpdateInvoiceReminderInput,
} from "components/setting/invoice/05_sharedModel/invoiceReminder"

function useInvoiceReminder() {
  return useQuery(keys.invoiceReminderQuery, fetchInvoiceReminders, {
    select: (data: Response<InvoiceReminders>) => data.data.data.invoiceReminders,
  })
}

function fetchInvoiceReminders() {
  return graphQLClient.fetch(query.invoiceReminderQuery, null, true, common.accountingApiUrl, keys.invoiceReminderQuery)
}

function addInvoiceReminder(input: AddInvoiceReminderInput) {
  return graphQLClient.mutation(mutation.addInvoiceReminder, input, "AddInvoiceReminder", common.accountingApiUrl)
}

function useAddInvoiceReminder() {
  return useMutation(addInvoiceReminder)
}

function updateInvoiceReminder(input: UpdateInvoiceReminderInput) {
  return graphQLClient.mutation(mutation.updateInvoiceReminder, input, "UpdateInvoiceReminder", common.accountingApiUrl)
}

function useUpdateInvoiceReminder() {
  return useMutation(updateInvoiceReminder)
}

function removeInvoiceReminder(input: RemoveInvoiceReminderInput) {
  return graphQLClient.mutation(mutation.removeInvoiceReminder, input, "RemoveInvoiceReminder", common.accountingApiUrl)
}

function useRemoveInvoiceReminder() {
  return useMutation(removeInvoiceReminder)
}

function useTemplateMergeField(input: TemplateMergeFieldQueryInput) {
  return useQuery([keys.templateMergeFieldQuery, input], () => fetchTemplateMergeFields(input), {
    select: (data: Response<TemplateMergeFields>) => data.data.data.templateMergeFields,
  })
}

function fetchTemplateMergeFields(input: TemplateMergeFieldQueryInput) {
  return graphQLClient.fetch(query.templateMergeFieldQuery, input, true, common.organisationApiUrl, keys.templateMergeFieldQuery)
}

export { useInvoiceReminder, useAddInvoiceReminder, useUpdateInvoiceReminder, useRemoveInvoiceReminder, useTemplateMergeField }
