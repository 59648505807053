import React, { useEffect } from "react"
import ClientSelectionComponent from "library/components/clientSelectionComponent"
import { TaskListModel } from "./taskListModel"
import { useTaskStatusQuery } from "../services/taskService"
import { TaskStatus } from "../04_sharedModel/taskModel"
import InputGroupSelect from "library/observables/inputGroupSelect"
import InputGroupDropdown from "library/observables/inputGroupDropdown"
import { taskSortTypes } from "constants/taskSortType"
import { useFilteredCategory } from "../../../hooks/organisation/category"
import { entityTypes } from "../../../constants/entityTypes"
import { Category } from "../../../models/organisation/category"
import MultiSelectInputGroup from "../../../library/multiSelectInputGroup/multiSelectInputGroup"
import { runInAction } from "mobx"
import useLocalStorageState from "../../../utils/localStorage"
import { isProvided } from "../../../utils/extensions"

interface Props {
  taskListModel: TaskListModel
}

const TaskListFilters = ({ taskListModel }: Props) => {
  const { data: taskStatus } = useTaskStatusQuery()
  const { data: category } = useFilteredCategory(entityTypes.task.entityTypeReference)
  const [columns, setColumns] = useLocalStorageState<TaskStatus>("taskFilters", { taskStatusReference: "", name: "" })

  useEffect(() => {
    if (columns && isProvided(columns.taskStatusReference)) {
      taskListModel.taskStatus.selectedOption = {
        taskStatusReference: columns.taskStatusReference,
        name: columns.name
      }
    }
  }, [])

  return (
    <>
      <div className="flex row-auto grid grid-cols-5 gap-4">
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <ClientSelectionComponent
            label=""
            name="taskClient"
            value={taskListModel.client}
            type="filter"
            placeholder="Filter by Client Name | Phone | Email"
            onChange={() => {
              runInAction(() => {
                // Clear job selection when job filter is populated
                taskListModel.jobSelection.jobReference = ""
                taskListModel.jobSelection.jobNumber = ""
              })
            }}
          />
        </div>

        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <MultiSelectInputGroup<Category>
            id={"TaskCategory"}
            options={category}
            value={taskListModel.taskCategory}
            isClearable={true}
            isDisabled={false}
            placeholder={"Filter By Category"}
            isSearchable={true}
            optionValue={"categoryReference"}
            optionLabel={"name"}
            type={"filter"}
          />
        </div>

        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <MultiSelectInputGroup<TaskStatus>
            id={"TaskStatus"}
            options={taskStatus}
            value={taskListModel.taskStatusList}
            isClearable={true}
            isDisabled={false}
            placeholder={"Filter By Status"}
            isSearchable={true}
            optionValue={"taskStatusReference"}
            optionLabel={"name"}
            type={"filter"}
          />
        </div>

        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <InputGroupDropdown
            name="TaskSortType"
            options={taskSortTypes}
            value={taskListModel.sortType}
            isClearable={true}
            type="filter"
            placeholder="Sort Order"
          />
        </div>
      </div>
    </>
  )
}

export default TaskListFilters
