import { Column, DataGridColumn } from "models/shared/column"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"

export const ColumnType = {
  Text: {
    Id: 100,
    Name: "Text"
  },
  Phone: {
    Id: 200,
    Name: "Phone Number"
  },
  Email: {
    Id: 300,
    Name: "Email Address"
  },
  Description: {
    Id: 400,
    Name: "Description"
  },
  Date: {
    Id: 500,
    Name: "Date"
  },
  DateTime: {
    Id: 600,
    Name: "DateTime"
  },
  ListOfDates: {
    Id: 700,
    Name: "List of Dates"
  },
  ListOfImages: {
    Id: 800,
    Name: "List of Images"
  },
  LabelId: {
    Id: 900,
    Name: "Label Id"
  },
  LabelType: {
    Id: 1000,
    Name: "Type Label"
  },
  Status: {
    Id: 1200,
    Name: "Status"
  },
  profileName: {
    Id: 1300,
    Name: "Profile Name"
  },
  StaffName: {
    Id: 1400,
    Name: "Staff Name"
  },
  Address: {
    Id: 1500,
    Name: "Address"
  },
  Boolean: {
    Id: 1600,
    Name: "Boolean"
  },
  BooleanInReverse: {
    Id: 1700,
    Name: "Reverse Boolean"
  },
  InvoiceReminder: {
    Id: 1800,
    Name: "Invoice Reminder"
  },
  CurrencyLabel: {
    Id: 1900,
    Name: "Currency Label(INR 3,000.00)"
  },
  CurrencyFormat: {
    Id: 2000,
    Name: "Currency Format(3,000.00)"
  },
  FileSize: {
    Id: 2100,
    Name: "File Size in Mb"
  },
  Tags: {
    Id: 2200,
    Name: "List of Tags"
  },
  FileNameIcon: {
    Id: 2300,
    Name: "File name with icon"
  },
  TaskEntity: {
    Id: 2400,
    Name: "Type"
  },
  StaffMemberList: {
    Id: 2500,
    Name: "StaffMemberList"
  },
  EditableStatus: {
    Id: 2600,
    Name: "Status"
  }
}

export const clientColumns: Column[] = [
  {
    id: "1",
    name: "Id",
    isEnabled: true,
    key: "clientNumber",
    order: 1,
    columnTypeId: ColumnType.LabelId.Id,
    featureName: "client"
  },
  {
    id: "2",
    name: "Name",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "givenName",
    order: 2,
    columnTypeId: ColumnType.Text.Id,
    featureName: "client"
  },
  {
    id: "3",
    name: "Manager",
    isEnabled: false,
    key: "clientManager",
    innerKey: "givenName",
    order: 3,
    columnTypeId: ColumnType.Text.Id,
    featureName: "client_ClientManager"
  },
  {
    id: "4",
    name: "Email",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "emailAddress",
    order: 4,
    columnTypeId: ColumnType.Email.Id,
    featureName: "client"
  },
  {
    id: "5",
    name: "Phone",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "phoneNumber",
    order: 5,
    columnTypeId: ColumnType.Text.Id,
    featureName: "client"
  },
  {
    id: "6",
    name: "Created",
    isEnabled: false,
    key: "insertedDateTimeUtc",
    order: 6,
    columnTypeId: ColumnType.Date.Id,
    featureName: "client"
  },
  {
    id: "7",
    name: "Type",
    isEnabled: true,
    key: "clientTypeName",
    order: 7,
    columnTypeId: ColumnType.LabelType.Id,
    className: "bg-accent_green-900",
    featureName: "client"
  },
  {
    id: "8",
    name: "Created By",
    isEnabled: false,
    key: "insertedBy",
    order: 8,
    columnTypeId: ColumnType.Text.Id,
    featureName: "client"
  },
  {
    id: "9",
    name: "Status",
    isEnabled: false,
    key: "clientStateName",
    order: 9,
    columnTypeId: ColumnType.LabelType.Id,
    className: "bg-primary-900",
    featureName: "client"
  }
]

export const jobColumns: Column[] = [
  {
    id: "1",
    name: "Id",
    isEnabled: true,
    key: "jobNumber",
    order: 1,
    columnTypeId: ColumnType.LabelId.Id,
    featureName: "job"
  },
  {
    id: "2",
    name: "Description",
    isEnabled: true,
    key: "description",
    order: 2,
    columnTypeId: ColumnType.Text.Id,
    featureName: "job"
  },
  {
    id: "3",
    name: "Start Time",
    isEnabled: true,
    key: "schedule",
    innerKey: "startDateTimeUtc",
    order: 3,
    columnTypeId: ColumnType.DateTime.Id,
    featureName: "job"
  },
  {
    id: "4",
    name: "End Time",
    isEnabled: true,
    key: "schedule",
    innerKey: "endDateTimeUtc",
    order: 5,
    columnTypeId: ColumnType.DateTime.Id,
    featureName: "job"
  },
  {
    id: "5",
    name: "Status",
    isEnabled: true,
    key: "jobStateName",
    statusKey: "jobStateReference",
    order: 5,
    columnTypeId: ColumnType.Status.Id,
    featureName: "job"
  },
  {
    id: "6",
    name: "Client",
    isEnabled: true,
    key: "clientName",
    order: 6,
    columnTypeId: ColumnType.Text.Id,
    featureName: "job_Client"
  },
  {
    id: "7",
    name: "Created",
    isEnabled: true,
    key: "insertedDateTimeUtc",
    order: 7,
    columnTypeId: ColumnType.Date.Id,
    featureName: "job"
  },
  {
    id: "8",
    name: "Created By",
    isEnabled: true,
    key: "insertedBy",
    order: 8,
    columnTypeId: ColumnType.Text.Id,
    featureName: "job"
  },
  {
    id: "9",
    name: "Assigned To",
    isEnabled: true,
    key: "staffs",
    innerKey: "staffReference",
    order: 9,
    columnTypeId: ColumnType.ListOfImages.Id,
    featureName: "job_StaffMembers"
  }
]

export const staffColumns: Column[] = [
  {
    id: "1",
    name: "Id",
    isEnabled: true,
    key: "staffNumber",
    order: 1,
    columnTypeId: ColumnType.LabelId.Id,
    featureName: "staff"
  },
  {
    id: "2",
    name: "Name",
    isEnabled: true,
    key: "staffName",
    order: 2,
    columnTypeId: ColumnType.profileName.Id,
    featureName: "staff"
  },
  {
    id: "3",
    name: "Email",
    isEnabled: true,
    key: "emailAddress",
    order: 3,
    columnTypeId: ColumnType.Email.Id,
    featureName: "staff"
  },
  {
    id: "4",
    name: "Phone",
    isEnabled: true,
    key: "primaryPhone",
    innerKey: "number",
    order: 4,
    columnTypeId: ColumnType.Text.Id,
    featureName: "staff"
  },
  {
    id: "5",
    name: "Date of Birth",
    isEnabled: true,
    key: "dateOfBirth",
    order: 5,
    columnTypeId: ColumnType.Date.Id,
    featureName: "staff"
  },
  {
    id: "6",
    name: "Date of Hire",
    isEnabled: true,
    key: "dateOfHire",
    order: 6,
    columnTypeId: ColumnType.Date.Id,
    featureName: "staff"
  },
  {
    id: "7",
    name: "Created",
    isEnabled: true,
    key: "insertedDateTimeUtc",
    order: 7,
    columnTypeId: ColumnType.Date.Id,
    featureName: "staff"
  },
  {
    id: "8",
    name: "Created By",
    isEnabled: true,
    key: "insertedBy",
    order: 8,
    columnTypeId: ColumnType.Text.Id,
    featureName: "staff"
  }
]

export const contractorColumns: Column[] = [
  {
    id: "1",
    name: "Id",
    isEnabled: true,
    key: "contractorNumber",
    order: 1,
    columnTypeId: ColumnType.LabelId.Id,
    featureName: "contractor"
  },
  {
    id: "2",
    name: "Name",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "givenName",
    order: 2,
    columnTypeId: ColumnType.Text.Id,
    featureName: "contractor"
  },
  {
    id: "3",
    name: "Business",
    isEnabled: true,
    key: "businessName",
    order: 3,
    columnTypeId: ColumnType.Text.Id,
    featureName: "contractor"
  },
  {
    id: "4",
    name: "Email",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "emailAddress",
    order: 4,
    columnTypeId: ColumnType.Email.Id,
    featureName: "contractor"
  },
  {
    id: "5",
    name: "Phone",
    isEnabled: true,
    key: "primaryContact",
    innerKey: "phoneNumber",
    order: 5,
    columnTypeId: ColumnType.Text.Id,
    featureName: "contractor"
  },
  {
    id: "6",
    name: "Created",
    isEnabled: false,
    key: "insertedDateTimeUtc",
    order: 6,
    columnTypeId: ColumnType.Date.Id,
    featureName: "contractor"
  },
  {
    id: "7",
    name: "Created By",
    isEnabled: false,
    key: "insertedBy",
    order: 7,
    columnTypeId: ColumnType.Text.Id,
    featureName: "contractor"
  }
]

export const leaveColumns = [
  {
    id: "1",
    name: "Staff Member",
    isEnabled: true,
    key: "staffName",
    order: 1,
    isStaffName: true,
    featureName: "leave"
  },
  {
    id: "2",
    name: "Type",
    isEnabled: true,
    key: "leaveTypeName",
    order: 2,
    isText: true,
    featureName: "leave"
  },
  {
    id: "3",
    name: "Date",
    isEnabled: true,
    key: "leaveEntries",
    innerKey: "startDateTimeUtc",
    order: 3,
    isListOfDates: true,
    featureName: "leave"
  },
  {
    id: "4",
    name: "Hours",
    isEnabled: true,
    key: "totalDurationLabel",
    order: 4,
    isText: true,
    featureName: "leave"
  },
  {
    id: "5",
    name: "Submitted",
    isEnabled: true,
    key: "insertedDateTimeUtc",
    order: 7,
    isDate: true,
    featureName: "leave"
  },
  {
    id: "6",
    name: "Submitted By",
    isEnabled: true,
    key: "insertedBy",
    order: 8,
    isText: true,
    featureName: "leave"
  },
  {
    id: "7",
    name: "Status",
    isEnabled: true,
    key: "leaveStatusName",
    statusKey: "leaveStatusReference",
    order: 9,
    isStatus: true,
    featureName: "leave"
  }
]
