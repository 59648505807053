import React, { useEffect, useState } from "react"
import { SelectingEventArgs, TabComponent } from "@syncfusion/ej2-react-navigations"
import { useNavigate } from "react-router-dom"
import { successToaster } from "library/styled/toasters"
import { MenuOption } from "library/dropdownMenu/hamburgerMenu"
import { HamburgerMenu } from "library/dropdownMenu/hamburgerMenu"
import { WarningAlert } from "library/styled/alerts"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { useQueryClient } from "react-query"
import { useModalState } from "utils/modalStates"
import ToolTip from "library/styled/tooltip"
import { useRemoveTask, useTaskDetailQuery } from "../services/taskService"
import { RemoveTaskInput } from "../04_sharedModel/inputModels"
import { Task } from "../04_sharedModel/taskModel"
import TaskOverviewTab from "./taskOverviewTab"
import AddOrEditTaskPage from "../01_addOrEditTask/addOrEditTaskPage"
import SidePanel from "../../../library/styled/sidePanel"

interface Props {
  taskReference?: string
  isOpenInModal?: boolean
  onDelete?: () => void
}

const TaskDetailPage = ({ taskReference, onDelete }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: task } = useTaskDetailQuery(enabled, taskReference)
  const [selectedTab, setSelectedTab] = useState(0)
  const { alert, setAlert } = useModalState<Task>()
  const { mutate: removeTask, isLoading: isDeleting } = useRemoveTask()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { viewModal: addTaskViewModal, setViewModal: setAddTaskModal } = useModalState<Task>()


  useEffect(() => {
    if (taskReference) {
      setEnabled(true)
    }
  }, [taskReference])

  const handleDelete = () => {
    const input: RemoveTaskInput = {
      taskReference: task?.taskReference,
      concurrencyToken: task?.concurrencyToken
    }

    removeTask(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.taskQuery])
        successToaster(`Task deleted`)
        setAlert({ ...alert, isOpen: false })
        if (onDelete) onDelete()
      }
    })
  }

  const menuOptions: MenuOption[] = [
    {
      name: "Delete",
      iconClass: "far fa-trash text-red-800",
      action: () => setAlert({ item: task, isOpen: true }),
      disabled: false
    }
  ]

  return (
    <>
      <TabComponent
        id="defaultTab"
        selectedItem={0}
        selecting={(args: SelectingEventArgs) => setSelectedTab(args.selectingIndex)}
        animation={{ next: { effect: "None" }, previous: { effect: "None" } }}
      >
        <div className="e-tab-header pl-3">
          <div>Overview</div>
        </div>
        <div className={`absolute top-1 right-20`}>
          <ToolTip text="Edit Task" delay={0.5} placement="bottom" showArrow={false}>
            <button
              className="px-2 py-0.5 rounded-md text-sky-700 hover:bg-sky-100"
              onClick={() => {
                setAddTaskModal({ item: task, isOpen: true })
              }}
            >
              <i className="far fa-pen-to-square h-4 text-sm"></i>
            </button>
          </ToolTip>
        </div>

        <div className="absolute top-2 right-6">
          <HamburgerMenu options={menuOptions} />
        </div>

        <div className="e-content">
          <div>
            <TaskOverviewTab task={task} />
          </div>
        </div>
      </TabComponent>

      <WarningAlert
        title="Task"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={value => handleDelete()}
        isLoading={isDeleting}
      />

      <SidePanel
        title={`Edit Task`}
        open={addTaskViewModal.isOpen}
        onClose={() => {
          setAddTaskModal({ ...addTaskViewModal, isOpen: false })
          navigate("/tasks")
        }}
        body={
          <AddOrEditTaskPage
            taskReference={addTaskViewModal.item?.taskReference}
            closeSidePanel={() => setAddTaskModal({ ...addTaskViewModal, isOpen: false })}
          />
        }
        type="detailPanel"
      />
    </>
  )
}

export default TaskDetailPage
