import { InfiniteData, useInfiniteQuery, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Contractor, ContractorQueryInput, Contractors } from "models/people/contractor"
import { ReactQueryParams } from "models/shared/common"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { ClientQueryInput, Clients } from "models/people/client"
import { ClientKeyValue } from "library/components/clientSelectionMultiComponent"
import { ContractorKeyValue } from "library/contractorSelection/contractorSelection"

function useContractor(input: ContractorQueryInput) {
  const result = useInfiniteQuery([keys.contractorQuery, input], fetchContractors, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.contractors.pageInfo.hasNextPage) {
        return lastPage?.data.data.contractors.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Contractors>>) => setContractorData(data),
  })
  return { ...result, contractors: contractors }
}

const fetchContractors = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.contractorQuery, input, true, common.peopleApiUrl, keys.contractorQuery)
}

let contractors: Contractor[] = []
function setContractorData(data: InfiniteData<Response<Contractors>>) {
  contractors = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.contractors.edges.map((item) => item.node)
    contractors = contractors.concat(pageData)
  })
}

function useSearchContractor(input: ContractorQueryInput) {
  return useQuery([keys.contractorSearchQuery, input], () => searchContractors(input), {
    select: (data: Response<Contractors>) =>
      data.data.data.contractors.edges.map(
        (m) =>
          new ContractorKeyValue(
            m.node.contractorReference,
            m.node.primaryContact?.givenName + " " + m.node.primaryContact?.familyName,
            m.node.primaryContact?.emailAddress
          )
      ),
  })
}

function searchContractors(input: ContractorQueryInput) {
  return graphQLClient.fetch(query.contractorQuery, input, true, common.peopleApiUrl, keys.contractorQuery)
}

export { useContractor, useSearchContractor }
