export interface FileAccesses {
  [key: string]: FileAccess
}

export interface FileAccess {
  reference: string
  name: string
}

export const fileAccess: FileAccesses = {
  onlyMe: {
    reference: "7CA5DC2A-A928-4090-BCA4-3D5091DF1E0B",
    name: "Only With Me",
  },
  allStaff: {
    reference: "CA4DB29D-F885-4A3D-93C8-83E90559378D",
    name: "All Staff",
  },
  private: {
    reference: "7CA5DC2A-A928-4090-BCA4-3D5091DF1E0B",
    name: "Selected Staff",
  },
}

export const fileAccessList: FileAccess[] = [fileAccess.onlyMe, fileAccess.allStaff, fileAccess.private]

export const filePermission = {
  read: {
    id: "A622809E-3892-494D-89BE-2177BC9D79D8",
    name: "Read",
    description: "Allows users to download the file and read",
  },
  update: {
    id: "36FAEF06-FE24-44F3-86DD-ECFDE9F45BC0",
    name: "Update",
    description: "Allows users to updated the file content and details",
  },
  delete: {
    id: "F68BECF6-2E2D-4A36-BB04-B2754BF79B71",
    name: "Delete",
    description: "Allows users to delete the file",
  },
  all: {
    id: "2A4457E6-775A-4CA3-9566-6B7300AC7A14",
    name: "All",
    description: "Grant all permissions listed below. Also allows users to change the permission of the file.",
  },
}
