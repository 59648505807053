export const emailAttachmentType = {
  report: {
    reference: "CBBDD4BD-66DF-49BE-BC74-C7C9F896959B",
    name: "Report",
  },
  file: {
    reference: "D3F9D6BB-E780-42AD-9228-D3588BF77FE0",
    name: "File",
  },
}
