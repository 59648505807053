import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { ErrorInline, Form, FormGroup } from "library/styled/styled"
import { GeneralError } from "models/shared/common"

interface Props {
  errorMessages: GeneralError
  cols?: number
}

/**
 * General error is used to display form validation errors that otherwise cannot be display inline.
 * This component should be placed closer to the save button
 * @param props
 * @constructor
 */
const GeneralErrors = ({ errorMessages, cols = 6 }: Props) => {
  return (
    <>
      <FormGroup cols={cols}>
        {Object.keys(errorMessages).map((key) => (
          <ErrorInline key={key}>{errorMessages[key]}</ErrorInline>
        ))}
      </FormGroup>
    </>
  )
}

export default observer(GeneralErrors)
