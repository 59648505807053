import React, { useState } from "react"
import { Form, FormGroup, Label } from "library/styled/styled"
import { InputGroupButton } from "library/styled/input-group"
import { AddReminderInput } from "components/shared/reminders/03_sharedModel/reminder"
import { ReminderCardModel } from "components/shared/reminders/01_reminderCard/reminderCardModel"
import { useAddReminder } from "components/shared/reminders/services/reminderCardService"
import { handleServerError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import DateTimePicker from "library/observables/dateTimePicker"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { observer } from "mobx-react"
import GeneralErrors from "library/generalErrors/generalErrors"

interface Props {
  entityReference?: string
  entityTypeReference: string
  onReminderAdded?: () => void
}

const AddReminderTemplate = ({ entityReference, entityTypeReference, onReminderAdded }: Props) => {
  const [model] = useState(() => new ReminderCardModel())
  const { mutate: addReminder, isLoading } = useAddReminder()
  const queryClient = useQueryClient()

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const input: AddReminderInput = {
      dueDateTimeUtc: model.dueDateTime.getUtcDateTime(),
      entityTypeReference: entityTypeReference,
      entityReference: entityReference ?? "",
      description: model.description.text ?? "",
    }

    addReminder(input, {
      onError: (error: any) => {
        model.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.reminderQuery)
        successToaster("Reminder added")
        model.reset()
        if (onReminderAdded) onReminderAdded()
      },
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup cols={6}>
          <div className="flex justify-start space-x-3">
            <div className="mt-3">
              <Label htmlFor="dueDateTime">Remind me on</Label>
            </div>
            <div className="w-72">
              <DateTimePicker value={model.dueDateTime} name="dueDateTime" />
            </div>
          </div>
        </FormGroup>

        <TextAreaInputGroup name="description" value={model.description} placeholder="Remind me about..." />
        <GeneralErrors errorMessages={model.generalErrors} />
        <InputGroupButton isPrimary={false} isLoading={isLoading} />
      </Form>
    </>
  )
}

export default observer(AddReminderTemplate)
