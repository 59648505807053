import React, { useEffect, useState } from "react"
import { handlePopupError, handleServerError } from "common"
import { useGetOrAddDraftClient, useUpdateClient } from "hooks/people/client"
import { Form, FormGroup, Label } from "library/styled/styled"
import { Modal } from "library/styled/modal"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import addClientPageModel from "./addClientPageModel"
import { UpdateClientInput } from "models/people/client"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { runInAction } from "mobx"
import { clientTypes } from "constants/people"
import { useMetadata } from "context/metadata-context"
import { useAddAddress } from "hooks/people/people"
import ClientPrimaryContact from "../02_primaryContact/primaryContact"
import { AddAddressInput } from "models/people/address"
import AddressComponent from "components/shared/address/addressComponent"
import GeneralErrors from "library/generalErrors/generalErrors"

interface Props {
  isOpen: boolean
  onClose: () => void
  onClientCreated?: (client: UpdateClientInput) => void
}
const AddClientPage = ({ isOpen, onClose, onClientCreated }: Props) => {
  const [clientPageModel] = useState(() => new addClientPageModel())
  const queryClient = useQueryClient()
  const [addClientType, setAddClientType] = useState({
    clientTypeReference: clientTypes.individual.clientTypeReference,
    clientTypeName: clientTypes.individual.name,
  })
  const { mutate: getOrAddDraftClient } = useGetOrAddDraftClient()
  const { organisation } = useMetadata()
  const { mutate: updateClientMutation, isLoading } = useUpdateClient()
  const { mutate: addAddress } = useAddAddress()
  const [client, setClient] = useState({
    clientReference: "",
    clientNumber: "",
  })

  const handleClientTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddClientType({
      clientTypeReference: event.target.value,
      clientTypeName: event.target.dataset.name ?? "",
    })
  }

  useEffect(() => {
    if (isOpen) {
      clientPageModel.clear()
      clientPageModel.address.reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      if (addClientType) {
        runInAction(() => {
          clientPageModel.clientTypeReference.text = addClientType.clientTypeReference
          clientPageModel.clientTypeName.text = addClientType.clientTypeName
        })
      }
    }
  }, [addClientType, isOpen])

  useEffect(() => {
    if (isOpen) {
      getOrAddDraftClient(undefined, {
        onError: (error: any) => handlePopupError(error.response),
        onSuccess: (response) => {
          setClient({
            clientReference: response?.data.data.getOrAddDraftClient.clientReference,
            clientNumber: response?.data.data.getOrAddDraftClient.clientNumber,
          })
        },
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (client) {
      runInAction(() => {
        clientPageModel.clientReference.text = client.clientReference
        clientPageModel.clientNumber.text = client.clientNumber
      })
    }
  }, [client])

  const handleSubmit = async () => {
    const input: UpdateClientInput = clientPageModel.getClientInput()
    input.timezone = organisation?.timezone.name

    const addressInput: AddAddressInput = {
      addressReference: "",
      addressTypeReference: clientPageModel.addressType.selectedOption?.addressTypeReference ?? "",
      businessEntityReference: client.clientReference,
      route: clientPageModel.address.route.text ?? "",
      regionName: clientPageModel.address.region.selectedOption?.name ?? "",
      regionCode: clientPageModel.address.region.selectedOption?.regionCode ?? "",
      city: clientPageModel.address.city.text ?? "",
      countryCode: clientPageModel.address.country.selectedOption?.countryCode ?? "",
      countryName: clientPageModel.address.country.selectedOption?.name ?? "",
      postalCode: clientPageModel.address.postCode.text ?? "",
      latitude: clientPageModel.address.lat,
      longitude: clientPageModel.address.lng,
      isPrimary: true,
    }

    updateClientMutation(input, {
      onError: (error: any) => {
        clientPageModel.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        if (addressInput.route || addressInput.city) {
          addAddress(addressInput, {
            onError: (error: any) => {
              clientPageModel.handleAddressApiErrors(error)
              handleServerError(error.response)
            },
            onSuccess: () => {
              void queryClient.invalidateQueries(keys.addressQuery)
              clientPageModel.clearAddressErrors()
              handleSuccess(input)
            },
          })
        } else {
          handleSuccess(input)
        }
      },
    })
  }

  const handleSuccess = (input: UpdateClientInput) => {
    if (onClientCreated) {
      onClientCreated(input)
    }
    void queryClient.invalidateQueries(keys.clientQuery)
    void queryClient.invalidateQueries(keys.getOrAddDraftClient, { refetchActive: false })
    successToaster(`Client added`)
    clientPageModel.clear()
    onClose()
  }
  return (
    <>
      <Modal
        width={"max-w-4xl"}
        title={"Add Client"}
        isOpen={isOpen}
        onClose={onClose}
        footer={true}
        action={handleSubmit}
        isButtonLoading={isLoading}
      >
        <Form className="!gap-y-2" onSubmit={(e) => e.preventDefault()}>
          <FormGroup cols={6}>
            <Label htmlFor="clientType">Client Type</Label>
            <div className="inline-flex justify-start space-x-4 text-sm my-2">
              <div className="flex items-center">
                <input
                  id="individual"
                  name="clientType"
                  type="radio"
                  data-name={clientTypes.individual.name}
                  value={clientTypes.individual.clientTypeReference}
                  className="h-3 w-3"
                  checked={addClientType.clientTypeReference === clientTypes.individual.clientTypeReference}
                  onChange={handleClientTypeChange}
                />
                <label htmlFor="individual" className="ml-2 block">
                  Individual
                </label>
              </div>

              <div className="flex items-center">
                <input
                  id="business"
                  name="clientType"
                  type="radio"
                  data-name={clientTypes.business.name}
                  value={clientTypes.business.clientTypeReference}
                  className="h-3 w-3"
                  checked={addClientType.clientTypeReference === clientTypes.business.clientTypeReference}
                  onChange={handleClientTypeChange}
                />
                <label htmlFor="business" className="ml-2 block">
                  Business
                </label>
              </div>
            </div>
          </FormGroup>

          {addClientType.clientTypeReference === clientTypes.business.clientTypeReference && (
            <div className="col-span-6">
              <div className={"grid grid-cols-2 gap-4"}>
                <div>
                  <TextInputGroup
                    id={"businessName"}
                    label="Business Name"
                    value={clientPageModel.businessName}
                    isRequired={true}
                  />
                </div>
                <div>
                  <TextInputGroup id={"businessWebsite"} label="Business Website" value={clientPageModel.website} />
                </div>
                <div>
                  <TextInputGroup id={"businessEmail"} label="Business Email" value={clientPageModel.businessEmailAddress} />
                </div>
              </div>
            </div>
          )}
          <div className="col-span-6">
            <Label htmlFor="primaryContact" className="mb-2 mt-2">
              Primary Contact
            </Label>
            <div className={"grid grid-cols-2 gap-4"}>
              <ClientPrimaryContact
                primaryContactInput={clientPageModel.primaryContact}
                clientManager={clientPageModel.clientManager}
                primaryPhoneNumber={clientPageModel.primaryContact.PhoneNumberType}
              />
            </div>
          </div>
        </Form>
        <Form className="!gap-y-3 pb-6" addVerticalPadding={false}>
          <FormGroup cols={6}>
            <Label>Address</Label>
          </FormGroup>
          <AddressComponent
            address={clientPageModel.address}
            addressType={clientPageModel.addressType}
            countryCode={organisation?.country.isoCode2 ?? ""}
          />
          <GeneralErrors errorMessages={clientPageModel.generalErrors}></GeneralErrors>
        </Form>
      </Modal>
    </>
  )
}

export default AddClientPage
