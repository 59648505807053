import React from "react"
import TemplateListPage from "components/setting/template/04_templateList/templateListPage"

function TemplateSettingPage() {
  return (
    <>
      <div className="pb-10 space-y-2.5">
        <TemplateListPage />
      </div>
    </>
  )
}

export default TemplateSettingPage
