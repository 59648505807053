export class TaxType {
  taxTypeReference: string
  taxTypeName: string

  constructor(taxTypeReference: string, taxTypeName: string) {
    this.taxTypeReference = taxTypeReference
    this.taxTypeName = taxTypeName
  }
}

export const taxTypeExclusive = new TaxType("264E6CDA-E2F3-404C-A466-5AF58602F52B", "Exclusive Tax")
export const taxTypeInclusive = new TaxType("F0125830-288E-457A-B796-6DA890DDB24D", "Inclusive Tax")
export const taxTypeNoTax = new TaxType("239BEB78-1568-4EE0-8D5D-42F0C0A000F7", "No Tax")
export const taxTypes: TaxType[] = [taxTypeExclusive, taxTypeInclusive, taxTypeNoTax]
