import React from "react"
import { toast, ToastContentProps } from "react-toastify"
import { ErrorResponse } from "models/shared/response"
import { Link } from "react-router-dom"

function errorToaster(response: ErrorResponse) {
  const ErrorTemplate = ({ closeToast }: ToastContentProps<{}>) => (
    <div className="flex">
      <div className="flex-shrink-0 pt-1">
        <i className="fas fa-times-circle text-red-400 fa-lg"></i>
      </div>
      <div className="ml-3">
        <h3 className="leading-5 text-red-700">{response.summary}</h3>
        <div className="mt-2 leading-5 text-red-700">
          <ul className="list-disc pl-5 text-sm">
            {response.messages?.map((item) => (
              <li key={item.description} className="mt-1">
                {item.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            onClick={closeToast}
            className="inline-flex rounded-md p-1.5 text-red-800 hover:text-red-800 hover:bg-red-100 focus:outline-none transition ease-in-out duration-150"
            aria-label="Dismiss"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )

  toast.error(ErrorTemplate, {
    autoClose: false,
    position: "top-center",
    theme: "colored",
    icon: false,
    closeButton: false,
  })
}

function successToaster(title: string, description?: string, linkText?: string, link?: any) {
  const SuccessTemplate = ({ closeToast }: ToastContentProps<{}>) => (
    <div className="flex">
      <div className="flex-shrink-0">
        <i className="fas fa-check-circle text-green-400 fa-lg"></i>
      </div>
      <div className="ml-3 text-green-700">
        <h3 className="">{title} {linkText && <Link to={link} className="underline text-sm">{`${linkText}`}</Link>}</h3>
        {description && <h3 className="pt-2 leading-4 text-green-700 text-sm">{description}</h3>}
        
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            className="inline-flex rounded-md p-1.5 text-green-800 hover:text-green-800 hover:bg-green-100 focus:outline-none transition ease-in-out duration-150"
            aria-label="Dismiss"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )

  toast.success(SuccessTemplate, {
    autoClose: 5000,
    position: "top-center",
    theme: "colored",
    icon: false,
    closeButton: false,
    hideProgressBar: true,
  })
}

export { errorToaster, successToaster }
