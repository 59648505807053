import { useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { planTypes } from "constants/planTypes"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import { Industries } from "models/metadata/industry"
import { Timezones } from "models/metadata/timezone"
import { Plans } from "models/metadata/plan"
import { FreePeriods } from "models/metadata/freePeriod"
import { DiscountSchemes } from "models/metadata/discountScheme"
import { AddressSettings } from "models/metadata/addressSetting"
import { GoogleAddressTypes } from "models/metadata/googleAddressType"
import { Regions } from "models/metadata/region"
import { Countries } from "models/metadata/country"
import { Currencies } from "models/metadata/currency"

function useIndustry() {
  return useQuery(keys.industryQuery, fetchIndustries, {
    select: (response: Response<Industries>) => response.data.data.industries,
  })
}

function fetchIndustries() {
  return graphQLClient.fetch(query.industryQuery, null, false, common.metadataApiUrl, keys.industryQuery)
}

function useTimezone() {
  return useQuery(keys.timezoneQuery, fetchTimezones, {
    select: (response: Response<Timezones>) => response.data.data.timezones,
  })
}

function fetchTimezones() {
  return graphQLClient.fetch(query.timezoneQuery, null, false, common.metadataApiUrl, keys.timezoneQuery)
}

function usePlan(enabled = false) {
  return useQuery(keys.plansQuery, fetchPlans, {
    select: (response: Response<Plans>) => response.data.data.plans,
    enabled: enabled,
  })
}

function fetchPlans() {
  const input = {
    planTypeReference: planTypes.Trial.planTypeReference,
  }
  return graphQLClient.fetch(query.plansQuery, input, true, common.metadataApiUrl, keys.plansQuery)
}

function useFreePeriod(enabled = false) {
  return useQuery(keys.freePeriodQuery, fetchFreePeriods, {
    select: (response: Response<FreePeriods>) => response.data.data.freePeriods,
    enabled: enabled,
  })
}

function fetchFreePeriods() {
  return graphQLClient.fetch(query.freePeriodQuery, null, true, common.metadataApiUrl, keys.freePeriodQuery)
}

function useDiscountScheme(enabled = false) {
  return useQuery(keys.discountSchemeQuery, fetchDiscountSchemes, {
    select: (response: Response<DiscountSchemes>) => response.data.data.discountSchemes,
    enabled: enabled,
  })
}

function fetchDiscountSchemes() {
  return graphQLClient.fetch(query.discountSchemeQuery, null, true, common.metadataApiUrl, keys.discountSchemeQuery)
}

function useAddressSetting() {
  return useQuery(keys.addressSettingQuery, fetchAddressSetting, {
    select: (response: Response<AddressSettings>) => response.data.data.addressSettings,
  })
}

function fetchAddressSetting() {
  return graphQLClient.fetch(query.addressSettingQuery, null, true, common.metadataApiUrl, keys.addressSettingQuery)
}

function useGoogleAddressType() {
  return useQuery(keys.googleAddressTypesQuery, fetchGoogleAddressTypes, {
    select: (response: Response<GoogleAddressTypes>) => response.data.data.googleAddressTypes,
  })
}

function fetchGoogleAddressTypes() {
  return graphQLClient.fetch(query.googleAddressTypesQuery, null, true, common.metadataApiUrl, keys.googleAddressTypesQuery)
}

function useRegion() {
  return useQuery(keys.regionQuery, fetchRegions, {
    select: (response: Response<Regions>) => response.data.data.regions,
  })
}

function fetchRegions() {
  return graphQLClient.fetch(query.regionQuery, null, false, common.metadataApiUrl, keys.regionQuery)
}

function useCountry() {
  return useQuery(keys.countryQuery, fetchCountries, {
    select: (response: Response<Countries>) => response.data.data.countries,
  })
}

function fetchCountries() {
  return graphQLClient.fetch(query.countryQuery, null, false, common.metadataApiUrl, keys.countryQuery)
}

function useMetaCurrency() {
  return useQuery(keys.metaCurrencyQuery, fetchMeteCurrencies, {
    select: (response: Response<Currencies>) => response.data.data.currencies,
  })
}

function fetchMeteCurrencies() {
  return graphQLClient.fetch(query.metaCurrencyQuery, null, false, common.metadataApiUrl, keys.currencyQuery)
}

export {
  useIndustry,
  useTimezone,
  usePlan,
  useFreePeriod,
  useDiscountScheme,
  useAddressSetting,
  useGoogleAddressType,
  useRegion,
  useCountry,
  useMetaCurrency,
}
