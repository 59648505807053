import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import { Button, ButtonSecondary } from "library/styled/styled"

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  footer?: boolean
  buttonText?: string
  action?: () => void
  isButtonLoading?: boolean
  isButtonDisabled?: boolean
  isEditButtonDisabled?: boolean
  width?: string
  info?: string
  clickOutside?: boolean
  onEdit?: () => void
  marginTop?: string
  saveButton?: JSX.Element,
  editButtonText?: string
}

const Modal = ({
                 isOpen,
                 onClose,
                 title,
                 children,
                 footer = false,
                 buttonText = "Save",
                 action,
                 isButtonLoading = false,
                 isButtonDisabled = false,
                 isEditButtonDisabled = false,
                 width = "max-w-3xl",
                 info,
                 clickOutside = true,
                 onEdit,
                 marginTop = "mt-10",
                 saveButton,
                 editButtonText = "Edit"
               }: Props) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={clickOutside ? onClose : () => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className={`flex min-h-full items-start justify-center p-4 text-center ${marginTop}`}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full ${width} transform rounded-md bg-white text-left align-middle shadow-xl transition-all absolute`}
                  style={width === "max-w-3xl" ? { left: "33%" } : undefined}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 px-6 py-4 border-b border-gray-200"
                  >
                    {title}

                    <button
                      type="button"
                      className="inline-flex rounded-md border border-transparent px-2 py-1.5 text-sm hover:bg-gray-200 float-right"
                      onClick={onClose}
                    >
                      <i className="fas fa-xmark-large"></i>
                    </button>
                    {info && <div className="text-xs text-gray-500 font-normal">{info}</div>}
                  </Dialog.Title>

                  {children}

                  {(footer || onEdit || action) && (
                    <div className="flex flex-row justify-end px-6 py-3 space-x-6 border-t border-gray-200">
                      <ButtonSecondary onClick={onClose}>Close</ButtonSecondary>
                      {onEdit !== undefined &&
                        <Button onClick={onEdit} disabled={isEditButtonDisabled}>{editButtonText}</Button>}
                      {saveButton && saveButton}
                      {action && (
                        <Button onClick={action} disabled={isButtonLoading || isButtonDisabled}>
                          {buttonText}
                          {isButtonLoading && <i className="fas fa-spinner-third fa-spin text-white ml-2"></i>}
                        </Button>
                      )}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export { Modal }
