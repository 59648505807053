import React from "react"

const AddEventModal = () => {
  return (
    <>
      <div>--------- Job Form -----------</div>
    </>
  )
}

export default AddEventModal
