import { action, makeObservable, observable } from "mobx"
import { InfiniteData } from "react-query"
import { Response } from "models/shared/response"
import { SelectObservable } from "library/models/selectObservable"
import { StaffSelectionObservable } from "library/components/staffSelection"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import { Task, Tasks, TaskStatus } from "../04_sharedModel/taskModel"
import { TaskQueryInput } from "../04_sharedModel/inputModels"
import { entityTypes } from "../../../constants/entityTypes"
import { DatePickerObservable } from "../../../library/observables/datePickers"
import { SelectOptionObservable } from "../../../library/observables/inputGroupDropdown"
import { newestFirstTaskType } from "../../../constants/taskSortType"
import { JobSelectionObservable } from "../../../library/jobSelection/jobSelection"
import { MultiSelectObservable } from "../../../library/multiSelectInputGroup/multiSelectInputGroup"
import { Category } from "../../../models/organisation/category"

export class TaskListModel {
  taskList: Task[] = []
  staffMember: StaffSelectionObservable
  client: ClientSelectionObservable
  taskStatus: SelectObservable<TaskStatus>
  dueDateTime: DatePickerObservable
  sortType: SelectOptionObservable
  jobSelection: JobSelectionObservable
  taskCategory: MultiSelectObservable<Category>
  taskStatusList: MultiSelectObservable<TaskStatus>

  constructor() {
    makeObservable(this, {
      taskList: observable,
      setTaskList: action
    })
    this.staffMember = new StaffSelectionObservable()
    this.client = new ClientSelectionObservable()
    this.taskStatus = new SelectObservable<TaskStatus>()
    this.dueDateTime = new DatePickerObservable()
    this.jobSelection = new JobSelectionObservable()
    this.sortType = new SelectOptionObservable(newestFirstTaskType.reference, newestFirstTaskType.name)
    this.taskCategory = new MultiSelectObservable<Category>()
    this.taskStatusList = new MultiSelectObservable<TaskStatus>()
  }

  getFilter() {
    let entityTypeReference = ""
    let entityReference = ""

    if (this.client.clientReference) {
      entityTypeReference = entityTypes.client.entityTypeReference
      entityReference = this.client.clientReference
    }

    if (this.jobSelection.jobReference) {
      entityTypeReference = entityTypes.job.entityTypeReference
      entityReference = this.jobSelection.jobReference
    }

    const obj: TaskQueryInput = {
      first: 10,
      after: "",
      taskReference: "",
      entityTypeReference: entityTypeReference,
      entityReference: entityReference,
      taskStatusReference: this.taskStatus?.selectedOption?.taskStatusReference,
      staffReference: this.staffMember.staffReference,
      dueDateTimeUtc: "",
      sortReference: this.sortType.reference,
      categoryReferences: this.taskCategory.selectedItems.map(value => value.categoryReference),
      taskStatusReferences: this.taskStatusList.selectedItems.map(value => value.taskStatusReference)
    }
    return obj
  }

  setTaskList(data: InfiniteData<Response<Tasks>>) {
    let tempList: Task[] = []
    data.pages.forEach((page) => {
      const pageData = page.data.data.tasks.edges.map((item) => item.node)
      tempList = tempList.concat(pageData)
    })
    this.taskList = tempList
  }
}
