import React, { Fragment } from "react"
import Accordian from "library/styled/accordian"
import { useModalState } from "utils/modalStates"
import TableDynamic from "library/styled/table"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { useQueryClient } from "react-query"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { WarningAlert } from "library/styled/alerts"
import { useRemoveTemplate, useTemplate } from "components/setting/template/templateSettingService"
import { RemoveTemplateInput, Template, TemplateQueryInput } from "components/setting/template/05_sharedModel/template"
import AddOrEditTemplateModal from "components/setting/template/01_addOrEditTemplate/addOrEditTemplateModal"
import ViewTemplateModal from "components/setting/template/03_viewTemplate/viewTemplateModal"
import { AxiosError } from "axios"

const TemplateListPage = () => {
  const { addModal, setAddModal, viewModal, setViewModal, editModal, setEditModal, alert, setAlert } = useModalState<Template>()
  const queryInput: TemplateQueryInput = {
    includeDisabledTemplates: "true",
  }
  const { data: templates } = useTemplate(true, queryInput)
  const { mutate: removeTemplate, isLoading: isDeleting } = useRemoveTemplate()
  const queryClient = useQueryClient()

  const handleDelete = (template: Template) => {
    const input: RemoveTemplateInput = {
      templateReference: template.templateReference,
    }

    removeTemplate(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (res) => {
        void queryClient.invalidateQueries(keys.templateQuery)
        successToaster("Template deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false }),
    })
  }

  const onEdit = () => {
    setViewModal({ ...viewModal, isOpen: false })
    setEditModal({ item: viewModal.item, isOpen: true })
  }

  const options = [
    {
      name: "Edit",
      icon: "pen-circle",
      action: (item: Template) => setEditModal({ isOpen: true, item: item }),
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: Template) => setAlert({ isOpen: true, item: item }),
    },
  ]

  return (
    <Fragment>
      <Accordian title="Templates" action={() => setAddModal(true)} openByDefault={true} linkText="Add Template">
        <TableDynamic
          columns={cols}
          data={templates}
          dataKey="templateReference"
          options={options}
          onRowClick={(item) => setViewModal({ isOpen: true, item: item })}
        />
      </Accordian>

      <AddOrEditTemplateModal isOpen={addModal} onClose={() => setAddModal(false)} />

      <AddOrEditTemplateModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        template={editModal.item}
        isEditMode={true}
      />

      <ViewTemplateModal
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        template={viewModal.item}
        onEdit={onEdit}
      />

      <WarningAlert
        title="Template"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />
    </Fragment>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Name",
    key: "name",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "2",
    name: "Type",
    key: "templateTypeName",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "3",
    name: "Default",
    key: "isDefault",
    columnTypeId: ColumnType.Boolean.Id,
  },
]

export default TemplateListPage
