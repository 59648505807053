import React from "react"
import { BooleanView, DescriptionView } from "library/styled/description-list"
import { Dl } from "library/styled/styled"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"

interface Props {
  invoice?: Invoice
  isOpenInModal?: boolean
}

const InvoicePreferencesDetail = ({ invoice, isOpenInModal = false }: Props) => {
  return (
    <div className={`overflow-y-auto ${isOpenInModal ? "invoice-side-detail-height" : "side-detail-height"}`}>
      <Dl addExtraPadding={false}>
        <DescriptionView label="Message for Client" value={invoice?.description} />
        <DescriptionView label="Payment Instructions for Client" value={invoice?.paymentTerms} />
        <BooleanView label="Automatic Reminders" value={invoice?.isReminderEnabled} />
        <BooleanView label="Sent to Client" value={invoice?.isSent} />
        <BooleanView label="Disputed" value={invoice?.isDisputed} />
      </Dl>
    </div>
  )
}

export default InvoicePreferencesDetail
