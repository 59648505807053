import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import ToolTip from "library/styled/tooltip"
import Select from "react-select"
import { debounce } from "lodash"
import { ThreeDots } from "react-loader-spinner"
import { requiredValidation } from "utils/validation"
import { FileKeyValue, FileSelectionObservable } from "library/FileSelection/models"
import { LinkExistingFileModel } from "components/shared/documents/03_linkExistingFile/linkExistingFileModel"
import { useSearchFile } from "components/shared/documents/services/documentCardService"

interface Props {
  value: FileSelectionObservable
  tooltip?: string
  label?: string
  name: string
  isClearable?: boolean
  type?: "filter" | "input"
  isInModal?: boolean
  placeholder?: string
  isRequired?: boolean
  cols?: number
  linkExistingFileModel: LinkExistingFileModel
}

const FileSelection = ({
  value,
  tooltip,
  label = "File",
  name,
  isClearable = true,
  type = "input",
  isInModal = false,
  placeholder = "Search by file name",
  isRequired = false,
  linkExistingFileModel,
  cols = 3,
}: Props) => {
  const [input, setInput] = useState("")
  const { data, isLoading } = useSearchFile(linkExistingFileModel.getSearchFileInput())

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput(searchQuery.trim())
    }, 1000)
  ).current

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value?.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.fileReference, label, isRequired)
    })
  }

  return (
    <>
      <FormGroup cols={cols}>
        <Label htmlFor={label}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          name={name}
          id={name}
          isMulti={false}
          options={data}
          value={value?.fileReference ? value : null}
          getOptionLabel={(option: FileKeyValue) => option.fileName ?? ""}
          getOptionValue={(option: FileKeyValue) => option.fileReference ?? ""}
          placeholder={placeholder}
          isClearable={true}
          isLoading={isLoading}
          onChange={(e) => {
            runInAction(() => {
              value.fileName = e?.fileName
              value.fileReference = e?.fileReference
              validate(true)
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          onInputChange={handleInput}
          styles={type === "filter" ? filterStyle : inputStyle}
          className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
          menuPosition={isInModal ? "fixed" : undefined}
          components={{ LoadingIndicator }}
          noOptionsMessage={() => <h2>No File Found</h2>}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      radius="3"
      color="#CBD5E1"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ marginRight: "6px" }}
      visible={true}
    />
  )
}

export default observer(FileSelection)
