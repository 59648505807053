export const keys = {
  localizationQuery: "localizationQuery",
  industryQuery: "IndustryQuery",
  timezoneQuery: "TimezoneQuery",
  featureSettingQuery: "FeatureSettingQuery",
  categoryQuery: "GetCategories",
  customFieldDefinitionQuery: "GetCustomFieldDefinition",
  jobStateQuery: "StateQuery",
  phoneNumberTypeQuery: "PhoneNumberTypeQuery",
  addressTypeQuery: "AddressTypeQuery",
  contactTypeQuery: "ContactTypeQuery",
  timeEntryTypeQuery: "TimeEntryTypeQuery",
  leaveTypeQuery: "LeaveTypeQuery",
  inquiryStateQuery: "InquiryStateQuery",
  inquirySourceQuery: "InquirySourceQuery",
  roleQuery: "RoleQuery",
  rolePermissionQuery: "RolePermissionQuery",
  partnerOrganisationQuery: "PartnerOrganisationQuery",
  partnerOrganisationInvoiceQuery: "PartnerOrganisationInvoiceQuery",
  partnerOrganisationUpcomingInvoiceQuery: "PartnerOrganisationUpcomingInvoiceQuery",
  plansQuery: "PlansQuery",
  freePeriodQuery: "FreePeriodQuery",
  discountSchemeQuery: "DiscountSchemeQuery",
  reviewLinkQuery: "ReviewLinkQuery",
  featureSettingAttributeValueQuery: "FeatureSettingAttributeValueQuery",
  clientQuery: "ClientQuery",
  itemSearchQuery: "ItemSearchQuery",
  contactQuery: "ContactQuery",
  phoneNumberQuery: "PhoneNumberQuery",
  jobQuery: "JobQuery",
  staffQuery: "StaffQuery",
  enabledFeatureQuery: "EnabledFeatureQuery",
  enabledPermissionQuery: "EnabledPermissionQuery",
  contractorQuery: "ContractorQuery",
  leaveQuery: "LeaveQuery",
  staffNameQuery: "StaffNameQuery",
  noteQuery: "NoteQuery",
  reminderQuery: "ReminderQuery",
  addressSettingQuery: "AddressSettingQuery",
  googleAddressTypesQuery: "GoogleAddressTypeQuery",
  regionQuery: "RegionQuery",
  countryQuery: "CountryQuery",
  addressQuery: "AddressQuery",
  fileQuery: "FileQuery",
  customFieldQuery: "GetCustomFields",
  unreadNotificationQuery: "UnreadNotificationQuery",
  notifications: "notifications",
  reminderNotifications: "reminderNotifications",
  customFieldOptionQuery: "GetCustomFieldOption",
  organisationDetailQuery: "GetOrganisation",
  getOrAddInvoice: "GetOrAddInvoice",
  imageQuery: "GetImages",
  userOrganisationQuery: "UserOrganisationQuery",
  userDetailQuery: "UserDetailQuery",
  sequenceNumberQuery: "SequenceNumberQuery",
  taxIdentificationQuery: "TaxIdentificationQuery",
  taxQuery: "TaxQuery",
  currencyQuery: "CurrencyQuery",
  metaCurrencyQuery: "MetaCurrencyQuery",
  paymentMethodQuery: "PaymentMethodQuery",
  exchangeRateQuery: "ExchangeRateQuery",
  invoiceReminderQuery: "InvoiceReminderQuery",
  templateMergeFieldQuery: "TemplateMergeFieldQuery",
  templateTypeQuery: "TemplateTypeQuery",
  templateQuery: "TemplateQuery",
  invoiceQuery: "InvoiceQuery",
  invoiceStatusQuery: "InvoiceStatusQuery",
  clientEmailAddressQuery: "ClientEmailAddressQuery",
  emailAttachmentQuery: "EmailAttachmentQuery",
  documentsCardQuery: "DocumentsCardQuery",
  fileSearchQuery: "FileSearchQuery",
  clientSearchQuery: "ClientSearchQuery",
  contractorSearchQuery: "ContractorSearchQuery",
  reportBrandingQuery: "ReportBrandingQuery",
  noteTypeQuery: "NoteTypeQuery",
  invoiceDetailQuery: "InvoiceDetailQueryKey",
  emailTemplateQuery: "EmailTemplateQuery",
  emailsQuery: "EmailsQuery",
  paymentQuery: "PaymentQuery",
  itemQuery: "ItemQuery",
  itemDetailQuery: "ItemDetailQueryKey",
  getOrAddItem: "GetOrAddItem",
  getOrAddDraftClient: "GetOrAddDraftClient",
  fileDetailQuery: "FileDetailQueryKey",
  schedulerResourceQuery: "SchedulerResourceQuery",
  schedulerEventQuery: "SchedulerEventsQuery",
  unassignedJobsQuery: "UnassignedStaffJobQuery",
  jobCountQuery: "JobCountQuery",
  taskQuery: "TaskQuery",
  taskDetailQuery: "ItemDetailQueryKey",
  taskStatusQuery: "TaskStatusQuery"
}
