import React from "react"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import Accordian from "library/styled/accordian"
import { observer } from "mobx-react"
import NoteListTemplate from "components/shared/notes/01_noteCard/noteListTemplate"
import AddNoteTemplate from "components/shared/notes/01_noteCard/addNoteTemplate"

interface Props {
  entityReference: TextObservable
  entityTypeReference: string
  width?: string
}

const NoteCard = ({ entityReference, entityTypeReference, width }: Props) => {
  return (
    <>
      <Accordian width={width} title="Notes" showLink={false}>
        <AddNoteTemplate entityTypeReference={entityTypeReference} entityReference={entityReference.text} />
        <NoteListTemplate entityTypeReference={entityTypeReference} entityReference={entityReference.text} isSelected={true} />
      </Accordian>
    </>
  )
}

export default observer(NoteCard)
