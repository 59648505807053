import React from "react"
import AddNoteTemplate from "components/shared/notes/01_noteCard/addNoteTemplate"
import { Modal } from "library/styled/modal"

interface Props {
  isOpen: boolean
  onClose: () => void
  entityTypeReference: string
  entityReference?: string
}

const AddNoteModal = ({ isOpen, onClose, entityReference, entityTypeReference }: Props) => {
  return (
    <Modal title="Add Note" isOpen={isOpen} onClose={onClose}>
      <AddNoteTemplate entityReference={entityReference} entityTypeReference={entityTypeReference} onNoteAdded={onClose} />
    </Modal>
  )
}

export default AddNoteModal
