import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleMutationError } from "common"
import { keys } from "constants/keys"
import {
  InputGroupButton, InputGroupCheckbox,
  InputGroupColor,
  InputGroupNumber,
  InputGroupText,
  InputGroupTextarea
} from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { useUpdateTaskStatus } from "../../../Task/services/taskService"
import { UpdateTaskStatusInput } from "../../../Task/04_sharedModel/inputModels"
import { TaskStatus } from "../../../Task/04_sharedModel/taskModel"

interface Props {
  isOpen: boolean
  onClose: () => void
  taskStatus?: TaskStatus
}

const EditTaskStatus = ({ isOpen, onClose, taskStatus }: Props) => {
  const { mutate: updateTaskStatus, isLoading } = useUpdateTaskStatus()
  const [error, setError] = useState({})
  const queryClient = useQueryClient()

  useEffect(() => {
    setError({})
  }, [isOpen])

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()
    const { name, description, color, displayOrder, isDisabled } = event.currentTarget.elements
    const input: UpdateTaskStatusInput = {
      taskStatusReference: taskStatus?.taskStatusReference,
      name: name.value,
      color: color.value,
      displayOrder: parseInt(displayOrder.value),
      description: description.value,
      isDisabled: isDisabled.checked
    }

    updateTaskStatus(input, {
      onError: (error: any) => handleMutationError(error.response, setError),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.taskStatusQuery)
        successToaster("Task Status updated")
        onClose()
      }
    })
  }

  return (
    <>
      <Modal title="Edit Task Status" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <InputGroupText label="Name" name="name" placeholder="Required" value={taskStatus?.name} error={error} />
          <InputGroupColor label="Color" name="color" value={taskStatus?.color} error={error} />
          <InputGroupCheckbox name="isDisabled" label="Disable" value={taskStatus?.isDisabled} cols={3}
                              margin={false} />
          <InputGroupNumber label="Display Order" name="displayOrder" value={taskStatus?.displayOrder} error={error} />
          <InputGroupTextarea label="Description" name="description" value={taskStatus?.description} error={error} />
          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}

export default EditTaskStatus
