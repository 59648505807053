import React from "react"
import { observer } from "mobx-react"
import Select from "react-select"
import { action, makeObservable, observable, runInAction } from "mobx"
import { Label } from "../styled/styled"
import { isProvided } from "../../utils/extensions"
import useRenderCounter from "../../utils/useRenderCounter"
import { taxTypeExclusive, taxTypeInclusive, taxTypeNoTax, taxTypes } from "../../constants/taxTypes"

export class TaxTypeObservable {
  taxTypeReference?: string
  taxTypeName?: string
  errorMessage?: string

  constructor(taxTypeReference?: string, taxTypeName?: string) {
    makeObservable(this, {
      taxTypeReference: observable,
      taxTypeName: observable,
      errorMessage: observable,
    })
    this.taxTypeReference = taxTypeReference
    this.taxTypeName = taxTypeName
  }

  isExclusive() {
    if (!this.taxTypeReference) return false
    return this.taxTypeReference === taxTypeExclusive.taxTypeReference
  }

  isInclusive() {
    if (!this.taxTypeReference) return false
    return this.taxTypeReference === taxTypeInclusive.taxTypeReference
  }

  isNoTax() {
    if (!this.taxTypeReference) return false
    return this.taxTypeReference === taxTypeNoTax.taxTypeReference
  }
}

interface Props {
  id?: string
  taxType: TaxTypeObservable
  onBlur?: () => void
  onChange?: () => void
}

const inputStyle = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    fontSize: 14,
    borderRadius: 0,
    shadow: 0,
    marginTop: 0,
    borderColor: state.isFocused ? "rgb(243 244 246)" : "rgb(243 244 246)",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(243 244 246)" : "rgb(243 244 246)",
    },
    borderWidth: 2,
    boxShadow: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    // Removes blue square border around typing indicator
    "input:focus": {
      boxShadow: "none",
    },
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14,
    width: 200,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#6b7280",
  }),
}

const TaxTypeSelection = (props: Props) => {
  function getDefaultValue() {
    if (isProvided(props.taxType.taxTypeReference)) {
      runInAction(() => {
        if (props.onChange) {
          props.onChange()
        }
      })
      return {
        taxTypeReference: props.taxType.taxTypeReference,
        taxTypeName: props.taxType.taxTypeName,
      }
    }

    const taxType = taxTypes[0]

    runInAction(() => {
      props.taxType.taxTypeReference = taxType.taxTypeReference
      props.taxType.taxTypeName = taxType.taxTypeName
    })
    return taxType
  }

  return (
    <>
      <div className={""}>
        <div className={"pl-1"}>
          <Label className={"pt-2.5"}></Label>
        </div>

        <div>
          <Select
            classNamePrefix={props.id}
            options={taxTypes}
            inputId={props.id}
            getOptionLabel={(m) => m.taxTypeName ?? ""}
            getOptionValue={(m) => m.taxTypeName ?? ""}
            value={getDefaultValue()}
            styles={inputStyle}
            isClearable={false}
            isSearchable={true}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            onBlur={action((event) => {
              if (props.onBlur) props.onBlur()
            })}
            onChange={(e) => {
              runInAction(() => {
                props.taxType.taxTypeName = e!.taxTypeName
                props.taxType.taxTypeReference = e!.taxTypeReference

                if (props.onChange) props.onChange()
              })
            }}
            menuPosition={"fixed"}
          />
        </div>
      </div>
    </>
  )
}

export default observer(TaxTypeSelection)
