import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import ToolTip from "library/styled/tooltip"
import Select, { OptionProps } from "react-select"
import { useSearchContractor } from "hooks/people/contractor"
import { debounce } from "lodash"
import { ThreeDots } from "react-loader-spinner"
import { requiredValidation } from "utils/validation"
import { ContractorQueryInput } from "models/people/contractor"
import { contractorStates } from "constants/people"

export class ContractorSelectionObservable {
  contractorReference?: string
  contractorName?: string
  emailAddress?: string
  errorMessage?: string

  constructor(contractorReference?: string, contractorName?: string, emailAddress?: string) {
    makeObservable(this, {
      contractorReference: observable,
      contractorName: observable,
      errorMessage: observable,
    })

    this.contractorReference = contractorReference
    this.contractorName = contractorName
    this.emailAddress = emailAddress
  }
}

export class ContractorKeyValue {
  contractorReference?: string
  contractorName?: string
  emailAddress?: string

  constructor(contractorReference?: string, name?: string, emailAddress?: string) {
    this.contractorReference = contractorReference
    this.contractorName = name
    this.emailAddress = emailAddress
  }
}

interface Props {
  value: ContractorSelectionObservable
  tooltip?: string
  label?: string
  name: string
  isClearable?: boolean
  type?: "filter" | "input"
  isInModal?: boolean
  placeholder?: string
  isRequired?: boolean
  onChange?: () => void
  cols?: number
}

const ContractorSelectionComponent = ({
  value,
  tooltip,
  label = "Contractor",
  name,
  isClearable = true,
  type = "input",
  isInModal = false,
  placeholder = "Search by contractor's name or email",
  isRequired = false,
  onChange,
  cols = 3,
}: Props) => {
  const [input, setInput] = useState<ContractorQueryInput>({
    first: 100,
    searchTerm: "",
    contractorStateReferences: [contractorStates.active.contractorStateReference],
  })
  const { data, isLoading } = useSearchContractor(input)

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput({ ...input, searchTerm: searchQuery.trim() })
    }, 1000)
  ).current

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value?.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.contractorReference, label, isRequired)
    })
  }

  return (
    <>
      <FormGroup cols={cols}>
        <Label htmlFor={label}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          name={name}
          id={name}
          isMulti={false}
          options={data}
          value={value?.contractorReference ? value : null}
          getOptionLabel={(option: ContractorKeyValue) => option.contractorName ?? ""}
          getOptionValue={(option: ContractorKeyValue) => option.contractorReference ?? ""}
          placeholder={placeholder}
          isClearable={true}
          isLoading={isLoading}
          onChange={(e) => {
            runInAction(() => {
              value.contractorName = e?.contractorName
              value.contractorReference = e?.contractorReference
              value.emailAddress = e?.emailAddress
              validate(true)
              if (onChange) onChange()
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          onInputChange={handleInput}
          styles={type === "filter" ? filterStyle : inputStyle}
          className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
          menuPosition={isInModal ? "fixed" : undefined}
          components={{ LoadingIndicator, Option: CustomOption }}
          noOptionsMessage={() => <h2>No Contractor Found</h2>}
          filterOption={() => true}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <ThreeDots
      height="30"
      width="30"
      radius="3"
      color="rgb(180, 183, 170,1)"
      ariaLabel="three-dots-loading"
      wrapperStyle={{ marginRight: "6px" }}
      visible={true}
    />
  )
}

const CustomOption = (props: OptionProps<ContractorKeyValue>) => {
  const { innerProps, innerRef, getStyles } = props
  return (
    <div ref={innerRef} {...innerProps} style={getStyles("option", props) as CSSProperties} className="overflow-hidden">
      <div className="mr-2">{props.data.contractorName}</div>
      <span className="text-xs">{props.data.emailAddress}</span>
    </div>
  )
}

export default observer(ContractorSelectionComponent)
