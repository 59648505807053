import React, { useEffect, useState } from "react"
import { useUpdatePayment } from "components/payment/services/paymentService"
import { usePaymentMethod } from "hooks/finance/paymentMethod"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import DatePicker from "library/observables/datePickers"
import SelectInputGroup from "library/selectInputGroup/selectInputGroup"
import { PaymentMethod } from "models/finance/paymentMethod"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { Payment, UpdatePaymentInput } from "components/payment/05_sharedModel/paymentModel"
import { handleServerError } from "common"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import { observer } from "mobx-react"
import NumberInputGroup from "library/numberInputGroup/numberInputGroup"
import { EditPaymentModel } from "components/payment/02_editPayment/editPaymentModel"
import { entityTypes } from "constants/entityTypes"
import AddPaymentButtonDropdown from "components/payment/06_sharedComponent/addPaymentButtonDropdown"
import SendEmailPage from "components/invoices/sendEmail/sendEmailModal"
import { invoicePaymentEmailConfig } from "constants/emailConfiguration"

interface Props {
  payment?: Payment
  isOpen: boolean
  onClose: () => void
}

const EditPaymentModal = ({ payment, isOpen, onClose }: Props) => {
  const [model] = useState(() => new EditPaymentModel())
  const { mutate: updatePayment, isLoading } = useUpdatePayment()
  const { data: paymentMethods } = usePaymentMethod(model.enableQuery, true)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen && payment) {
      model.setEnableQuery(true)
      model.resetForm(payment)
    }
  }, [isOpen, payment])

  useEffect(() => {
    if (paymentMethods && payment) {
      const paymentMethodUsed = paymentMethods.find((m) => m.paymentMethodReference === payment.paymentMethodReference)
      if (paymentMethodUsed) model.setPaymentMethod(paymentMethodUsed)
    }
  }, [paymentMethods, payment])

  const handleSubmit = () => {
    const input: UpdatePaymentInput = {
      paymentReference: payment?.paymentReference,
      paymentNumber: payment?.paymentNumber,
      paymentMethodReference: model.paymentMethod.selectedOption?.paymentMethodReference ?? "",
      paymentDateTimeUtc: model.paymentDate.getUtcDateTime(),
      description: model.description.text ?? "",
    }

    updatePayment(input, {
      onError: (error: any) => {
        handleServerError(error.response)
        model.handleApiErrors(error)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([
          keys.paymentQuery,
          { entityReference: payment?.invoice?.invoiceReference, entityTypeReference: entityTypes.invoice.entityTypeReference },
        ])
        successToaster("Payment Updated")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={`Edit Payment : ${payment?.paymentNumber}`}
        width="max-w-md"
        footer={true}
        action={handleSubmit}
        buttonText="Update"
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <DatePicker label="Date Paid" value={model.paymentDate} name="paidDate" cols={6} placeholder="Required" />
          <SelectInputGroup<PaymentMethod>
            id="paymentMethod"
            label="Payment Method"
            value={model.paymentMethod}
            options={paymentMethods?.filter((m) => !m.isDisabled)}
            optionValue={"paymentMethodReference"}
            optionLabel={"name"}
            isInModal={true}
            cols={6}
          />
          <NumberInputGroup id="amountPaid" label="Amount Paid" value={model.amountPaid} cols={6} disabled={true} />
          <TextAreaInputGroup name="description" label="Description" value={model.description} />
        </Form>
      </Modal>
    </>
  )
}

export default observer(EditPaymentModal)
