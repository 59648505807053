import React from "react"
import { Modal } from "library/styled/modal"
import AddReminderTemplate from "components/shared/reminders/01_reminderCard/addReminderTemplate"

interface Props {
  isOpen: boolean
  onClose: () => void
  entityTypeReference: string
  entityReference?: string
}

const AddReminderModal = ({ isOpen, onClose, entityReference, entityTypeReference }: Props) => {
  return (
    <Modal title="Add Reminder" isOpen={isOpen} onClose={onClose}>
      <AddReminderTemplate
        entityReference={entityReference}
        entityTypeReference={entityTypeReference}
        onReminderAdded={onClose}
      />
    </Modal>
  )
}

export default AddReminderModal
