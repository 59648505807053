import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import React from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { KeyValuePair } from "models/shared/common"
import ToolTip from "../styled/tooltip"

export class MultiSelectObservable<T = any> {
  selectedItems: T[]
  errorMessage?: string

  constructor(selectedItems?: T[]) {
    makeObservable(this, {
      selectedItems: observable,
      errorMessage: observable
    })
    this.selectedItems = selectedItems ?? []
  }
}

interface Props<T> {
  id: string
  label?: string
  onChange?: (item: MultiValue<T>) => void
  cols?: number
  options?: OptionsOrGroups<T, GroupBase<T>> | undefined
  value: MultiSelectObservable<T>
  optionValue: keyof T
  optionLabel: keyof T
  placeholder?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  toolTip?: string
  toolTipComponent?: any
  type?: "filter" | "input"
}

const MultiSelectInputGroup = <T extends KeyValuePair>({
                                                         id,
                                                         label,
                                                         onChange,
                                                         cols = 3,
                                                         options = [],
                                                         value,
                                                         optionValue,
                                                         optionLabel,
                                                         placeholder = "Optional",
                                                         isInModal = false,
                                                         isSearchable = true,
                                                         isClearable = false,
                                                         isDisabled = false,
                                                         toolTip,
                                                         toolTipComponent,
                                                         type
                                                       }: Props<T>) => {
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>
        {label}

        {toolTip && (
          <ToolTip text={toolTip} id={"textInputGroupToolTip"}>
            <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}

        {toolTipComponent && <>{toolTipComponent}</>}
      </Label>
      <Select
        isMulti={true}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={id}
        id={id}
        value={value.selectedItems}
        getOptionLabel={(option: T) => option[optionLabel]}
        getOptionValue={(option: T) => option[optionValue]}
        onChange={(e) => {
          runInAction(() => {
            if (value) {
              value.selectedItems = [...e]
            }
            if (onChange) {
              onChange(e)
            }
          })
        }}
        placeholder={placeholder}
        menuPosition={isInModal ? "fixed" : undefined}
        isDisabled={isDisabled}
        menuPlacement={"bottom"}
        menuPortalTarget={document.body}
        styles={type === "filter" ? filterStyle : inputStyle}
        className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
      ></Select>
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(MultiSelectInputGroup)
