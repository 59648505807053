import React, { useEffect } from "react"
import { runInAction } from "mobx"
import { observer } from "mobx-react"
import TextInputGroup, { TextObservable } from "../../../library/textInputGroup/textInputGroup"
import { useSequenceNumber } from "hooks/finance/sequenceNumber"
import { NumberSequenceTypes } from "constants/numberSequenceTypes"
import { isSame } from "utils/extensions"
import ToolTip from "../../../library/styled/tooltip"
import { Link } from "react-router-dom"

interface Props {
  value: TextObservable
  isEditMode: boolean
}

const InvoiceNumber = ({ value, isEditMode }: Props) => {
  const { data: sequenceNumbers } = useSequenceNumber()

  useEffect(() => {
    if (sequenceNumbers && !value.text && !isEditMode) {
      // find invoice number sequence number type
      const sequenceNumber = sequenceNumbers.find((value) =>
        isSame(value.sequenceNumberTypeReference, NumberSequenceTypes.invoice.reference)
      )
      if (sequenceNumber) {
        const invoiceNumber = sequenceNumber.nextSequenceNumber
          .toString()
          .padStart(sequenceNumber.numberFormat.length, sequenceNumber.numberFormat[0])

        runInAction(() => {
          value.text = `${sequenceNumber.prefix}${invoiceNumber}`
        })
      }
    }
    // eslint-disable-next-line
  }, [sequenceNumbers])

  return (
    <>
      <TextInputGroup
        label="Invoice Number"
        id="invoiceNumber"
        toolTipComponent={
          <>
            <ToolTip
              className={"w-60"}
              overlay={
                <>
                  <span>Enter an invoice number or leave the field blank for automatic generation.</span>
                  <p></p>
                  <span>
                    <Link to="/settings/invoice" target="_blank" className="underline">
                      Click here
                    </Link>{" "}
                    to edit invoice number settings.
                  </span>
                </>
              }
            >
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          </>
        }
        value={value}
        isRequired={false}
        maxLength={20}
      />
    </>
  )
}

export default observer(InvoiceNumber)
