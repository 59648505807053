import React, { Fragment, useEffect, useRef, useState } from "react"
import TextInputGroup, { TextObservable } from "library/textInputGroup/textInputGroup"
import SelectInputGroup, { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { Region } from "models/metadata/region"
import { action, makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-react"
import { useAddressSetting, useCountry, useGoogleAddressType, useRegion } from "hooks/metdata/metadata"
import { useAddressType } from "hooks/people/type"
import { AddressSetting } from "models/metadata/addressSetting"
import { GoogleAddressType } from "models/metadata/googleAddressType"
import { AddressComponentModel } from "components/shared/address/addressComponentModel"
import Conditional from "library/styled/conditional"
import SearchLocationInput from "library/components/googleAddressSearch"
import { Country } from "models/metadata/country"
import PlaceResult = google.maps.places.PlaceResult
import { SingleValue } from "react-select"
import { AddressType } from "models/people/peopleTypes"

export class AddressObservable {
  route: TextObservable
  city: TextObservable
  region: SelectObservable<Region>
  postCode: TextObservable
  country: SelectObservable<Country>
  lat: number = 0
  lng: number = 0

  constructor() {
    makeObservable(this, {
      lat: observable,
      lng: observable,
      reset: action,
      clearErrors: action
    })
    this.route = new TextObservable("")
    this.city = new TextObservable("")
    this.region = new SelectObservable<Region>()
    this.postCode = new TextObservable("")
    this.country = new SelectObservable<Country>()
  }

  reset() {
    this.route.text = ""
    this.city.text = ""
    this.postCode.text = ""
    this.lat = 0
    this.lng = 0
    this.region.selectedOption = undefined
  }

  clearErrors() {
    this.route.errorMessage = undefined
    this.city.errorMessage = undefined
    this.postCode.errorMessage = undefined
    this.region.errorMessage = undefined
    this.country.errorMessage = undefined
  }
}

interface Props {
  address: AddressObservable
  addressType: SelectObservable<AddressType>
  countryCode: string
}

const AddressComponent = ({ address, addressType, countryCode }: Props) => {
  const [model] = useState(() => new AddressComponentModel())
  const { data: addressSettingList } = useAddressSetting()
  const { data: googleAddressTypeList } = useGoogleAddressType()
  const { data: countries } = useCountry()
  const { data: regionList } = useRegion()
  const { data: addressTypes } = useAddressType()

  const [country, setCountry] = useState(countryCode)
  const clearSearch = useRef<any>(null)

  useEffect(() => {
    if (country && countries) {
      const countrySelected = countries.find((m) => m.countryCode === country)
      if (countrySelected) address.country.setValue(countrySelected)
    }
  }, [country, countries])

  useEffect(() => {
    if (addressSettingList && country) model.setAddressSettings(addressSettingList, country)
  }, [addressSettingList, country])

  useEffect(() => {
    if (regionList && country) model.setRegions(regionList, country)
  }, [regionList, country])

  useEffect(() => {
    if (googleAddressTypeList && country) model.setGoogleAddressType(googleAddressTypeList, country)
  }, [googleAddressTypeList, country])

  useEffect(() => {
    if (addressTypes) addressType.setValue(addressTypes[0])
  }, [addressTypes])

  const handleAddressChange = (searchResult: PlaceResult) => {
    if (searchResult.address_components) {
      address.reset()
      const googleAddressKeys = Object.keys(model.googleAddressType)

      googleAddressKeys.forEach((key) => {
        const addressTypes = model.googleAddressType[key as keyof GoogleAddressType]
        if (typeof addressTypes !== "string") {
          addressTypes.forEach((item) => {
            const ac = searchResult.address_components?.find((m) => m.types.includes(item.addressTypeName))
            if (ac) setAddressProp(address, key, item.useShortValue, ac.short_name, ac.long_name)
          })
        }
      })
      runInAction(() => {
        address.lat = searchResult.geometry?.location?.lat() ?? 0
        address.lng = searchResult.geometry?.location?.lng() ?? 0
      })
    }
  }

  const handleCountryChange = (country: SingleValue<Country>) => {
    address.reset()
    clearSearch.current()
    setCountry(country?.countryCode ?? "")
  }

  return (
    <>
      <SearchLocationInput countryCode={country} onAddressChange={handleAddressChange} clearSearch={clearSearch} />

      {model.addressSettings.map((addressSetting) => (
        <Fragment key={addressSetting.addressComponent}>
          {addressSetting.visible && (
            <>
              <Conditional when={addressSetting.addressComponent === "route"}>
                <TextInputGroup
                  label={addressSetting.displayName}
                  value={address.route}
                  id={addressSetting.addressComponent}
                  isRequired={addressSetting.required}
                />
              </Conditional>
              <Conditional when={addressSetting.addressComponent === "city"}>
                <TextInputGroup
                  label={addressSetting.displayName}
                  value={address.city}
                  id={addressSetting.addressComponent}
                  isRequired={addressSetting.required}
                />
              </Conditional>
              <Conditional when={addressSetting.addressComponent === "postCode"}>
                <TextInputGroup
                  label={addressSetting.displayName}
                  value={address.postCode}
                  id={addressSetting.addressComponent}
                  isRequired={addressSetting.required}
                />
              </Conditional>
              <Conditional when={addressSetting.addressComponent === "region"}>
                <SelectInputGroup<Region>
                  label={addressSetting.displayName}
                  options={model.regions}
                  optionValue={"regionCode"}
                  optionLabel={"name"}
                  id="region"
                  value={address.region}
                  placeholder={addressSetting.required ? "Required" : "Optional"}
                  isInModal={true}
                />
              </Conditional>
            </>
          )}
        </Fragment>
      ))}
      <SelectInputGroup<Country>
        label="Country"
        options={countries}
        optionValue={"countryCode"}
        optionLabel={"name"}
        id="country"
        value={address.country}
        placeholder="Required"
        onChange={handleCountryChange}
        isInModal={true}
      />

      <SelectInputGroup<AddressType>
        label="Address Type"
        options={addressTypes}
        optionValue={"addressTypeReference"}
        optionLabel={"name"}
        id="addressType"
        value={addressType}
        placeholder="Required"
        isInModal={true}
      />
    </>
  )
}

export default observer(AddressComponent)

function setAddressProp(address: AddressObservable, key: string, useShortValue: boolean, shortValue: string, longValue: string) {
  switch (key) {
    case "route":
      runInAction(() => {
        address.route.text += getPropValue(useShortValue, shortValue, longValue)
      })
      break
    case "city":
      runInAction(() => {
        address.city.text += getPropValue(useShortValue, shortValue, longValue)
      })
      break
    case "postCode":
      runInAction(() => {
        address.postCode.text += getPropValue(useShortValue, shortValue, longValue)
      })
      break
    case "region": {
      runInAction(() => {
        address.region.selectedOption = {
          regionCode: shortValue,
          name: longValue,
          countryCode: ""
        }
      })
    }
  }
}

function getPropValue(useShortValue: boolean, shortValue: string, longValue: string) {
  if (useShortValue) return shortValue
  return longValue + " "
}
