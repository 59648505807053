export const clientTypes = {
  individual: {
    id: 100,
    clientTypeReference: "29661BA8-8DC2-4AE4-B65C-56764145D991",
    name: "Individual",
  },
  business: {
    id: 200,
    clientTypeReference: "CE05E19F-C7A7-48B7-AC6C-9EB3F6548265",
    name: "Business",
  },
}

export const clientStates = {
  active: {
    id: 100,
    clientStateReference: "092D9BB5-33E2-409B-B4EA-5C6A1899B133",
    name: "Active - Clients",
  },
  archived: {
    id: 200,
    clientStateReference: "FD448B1E-89E4-4011-95EA-2BC98973A182",
    name: "Archived - Clients",
  },
}

export const contractorStates = {
  active: {
    contractorStateReference: "092D9BB5-33E2-409B-B4EA-5C6A1899B133",
    name: "Active - Contractors",
  },
  archived: {
    contractorStateReference: "FD448B1E-89E4-4011-95EA-2BC98973A182",
    name: "Archived - Contractors",
  },
}

export const staffStates = {
  active: {
    id: 100,
    staffStateReference: "092D9BB5-33E2-409B-B4EA-5C6A1899B133",
    name: "Active - Staff Members",
  },
  archived: {
    id: 200,
    staffStateReference: "FD448B1E-89E4-4011-95EA-2BC98973A182",
    name: "Archived - Staff Members",
  },
}

export const noteTypes = {
  general: {
    noteTypeReference: "EC2348EE-7D9A-4C6F-BF9E-1DD8515EF5B6",
    name: "General",
  },
}
