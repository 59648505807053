import React, { useEffect, useState } from "react"
import { AvailableStatusTransition, InvoiceStatus } from "components/invoices/05_sharedModel/invoiceModel"
import { useInvoiceStatus, useUpdateInvoiceStatus } from "components/invoices/services/invoiceService"
import { makeObservable, observable, runInAction } from "mobx"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select, { SingleValue } from "react-select"
import { inputStyle } from "library/styled/dropdown"
import { UpdateInvoiceStatusInput } from "components/invoices/05_sharedModel/InputModels"
import { handlePopupError } from "common"
import { successToaster } from "library/styled/toasters"
import { observer } from "mobx-react"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import { useModalState } from "utils/modalStates"
import { WarningAlert } from "library/styled/alerts"
import { invoiceStatus } from "constants/invoiceStatus"

export class InvoiceStatusObservable {
  invoiceStatusReference: string
  name: string
  errorMessage?: string

  constructor(invoiceStatusReference?: string, name?: string) {
    makeObservable(this, {
      invoiceStatusReference: observable,
      name: observable,
      errorMessage: observable,
    })
    this.invoiceStatusReference = invoiceStatusReference ?? ""
    this.name = name ?? ""
  }
}

interface Props {
  value: InvoiceStatusObservable
  invoiceReference?: string
  concurrencyToken?: string
  onConcurrencyTokenChange: (token?: string) => void
}

const InvoiceStatusSelection = ({ value, invoiceReference, concurrencyToken, onConcurrencyTokenChange }: Props) => {
  const { data } = useInvoiceStatus()
  const [states, setStates] = useState<AvailableStatusTransition[]>([])
  const { alert, setAlert } = useModalState<SingleValue<InvoiceStatusObservable>>()
  const { mutate: updateInvoiceStatusMutation, isLoading } = useUpdateInvoiceStatus()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data && value.invoiceStatusReference) {
      const filteredState = data.find((m) => m.invoiceStatusReference === value.invoiceStatusReference)
      if (filteredState) setStates(filteredState.availableStatusTransitions)
    }
  }, [data, value.invoiceStatusReference])

  const handleChange = (e: SingleValue<InvoiceStatusObservable>) => {
    if (e?.invoiceStatusReference === invoiceStatus.void.reference) setAlert({ item: e, isOpen: true })
    else updateStatus(e)
  }

  const updateStatus = (e: SingleValue<InvoiceStatusObservable>) => {
    let updateInvoiceStatus: UpdateInvoiceStatusInput = {
      invoiceReference: invoiceReference,
      concurrencyToken: concurrencyToken,
      invoiceStatusReference: e?.invoiceStatusReference ?? "",
    }
    updateInvoiceStatusMutation(updateInvoiceStatus, {
      onSuccess: (response) => {
        runInAction(() => {
          value.invoiceStatusReference = e!.invoiceStatusReference
          value.name = e!.name
        })
        onConcurrencyTokenChange(response?.data.data.updateInvoiceStatus.concurrencyToken)
        successToaster("Status Updated")
        void queryClient.invalidateQueries([keys.invoiceQuery])
      },
      onError: (error: any) => {
        handlePopupError(error.response)
      },
      onSettled: () => setAlert({ ...alert, isOpen: false }),
    })
  }

  return (
    <>
      <FormGroup cols={3}>
        <Label>Status</Label>
        <Select
          options={states}
          isSearchable={true}
          isClearable={false}
          id="invoiceStatus"
          classNamePrefix="invoiceStatus"
          value={value}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.invoiceStatusReference}
          placeholder="Required"
          styles={inputStyle}
          onChange={handleChange}
          isLoading={isLoading}
        ></Select>
        {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
      </FormGroup>

      <WarningAlert
        fullTitle="Void Invoice ?"
        description="Confirm voiding of this invoice. This action will result in a permanent void and cannot be undone."
        buttonText="Confirm"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={updateStatus}
        isLoading={isLoading}
      />
    </>
  )
}

export default observer(InvoiceStatusSelection)
