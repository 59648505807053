import React from "react"
import { action, makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-react"
import {
  requiredValidation,
  maxLengthValidation,
  emailAddressValidation,
  phoneNumberValidation
} from "../../utils/validation"
import ToolTip from "../styled/tooltip"
import { ErrorInline, FormGroup, Label, TextInput } from "../styled/styled"

export class TextObservable {
  public text?: string
  public errorMessage?: string

  constructor(text?: string, errorMessage?: string) {
    this.text = text
    this.errorMessage = errorMessage

    makeObservable(this, {
      text: observable,
      errorMessage: observable
    })
  }
}

interface Props {
  id: string
  label?: string
  value: TextObservable
  addMargin?: boolean
  cols?: number
  toolTip?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
  isEmail?: boolean
  isPhoneNumber?: boolean
  maxLength?: number
  toolTipComponent?: any
  tabIndex?: number
  component?: JSX.Element
}

const TextInputGroup = ({
                          id,
                          label,
                          value,
                          addMargin = true,
                          cols = 3,
                          toolTip,
                          onFocus,
                          onBlur,
                          onChange,
                          isRequired,
                          isEmail,
                          isPhoneNumber,
                          maxLength,
                          toolTipComponent,
                          tabIndex,
                          component
                        }: Props) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.text, label, isRequired)
      if (!value.errorMessage) value.errorMessage = maxLengthValidation(value.text, label, maxLength)
      if (!value.errorMessage) value.errorMessage = emailAddressValidation(value.text, label, isEmail)
      if (!value.errorMessage) value.errorMessage = phoneNumberValidation(value.text, isPhoneNumber)
    })
  }

  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>
        {label}

        {toolTip && (
          <ToolTip text={toolTip} id={"textInputGroupToolTip"}>
            <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}

        {toolTipComponent && <>{toolTipComponent}</>}
        {component && <div className="inline float-right">{component}</div>}
      </Label>

      <TextInput
        type="text"
        autoComplete="none"
        data-testid={id}
        id={id}
        placeholder={isRequired ? "Required" : "Optional"}
        value={value.text ?? ""}
        onChange={action((e) => {
          value.text = e.target.value
          validate(true)
          if (onChange) onChange(e)
        })}
        addMargin={addMargin}
        onBlur={(event) => {
          validate()
          if (onBlur) onBlur(event)
        }}
        onFocus={onFocus}
      />
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(TextInputGroup)
