import React from "react"
import { Dialog } from "@headlessui/react"
import { Button } from "library/styled/styled"

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  footer?: boolean
  buttonText?: string
  action?: any
  width?: string
  info?: string
  isLoading?: boolean
}

const DndModal = ({
  isOpen,
  onClose,
  title,
  children,
  footer = false,
  buttonText = "Save",
  action,
  width = "max-w-2xl",
  info,
  isLoading = false,
}: Props) => {
  return (
    <>
      <Dialog as="div" className="relative z-10" open={isOpen} onClose={onClose}>
        <div className="fixed inset-0 bg-black bg-opacity-25" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-start mt-4 justify-center p-4 text-center">
            <Dialog.Panel className={`w-full ${width} rounded-md bg-white text-left align-middle shadow-xl transition-all`}>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 px-6 py-4 border-b border-gray-200">
                {title}

                <button
                  type="button"
                  className="inline-flex rounded-md border border-transparent p-1 text-sm hover:bg-gray-200 float-right"
                  onClick={onClose}
                >
                  <i className="fas fa-xmark-large"></i>
                </button>
                {info && <div className="text-xs text-gray-500 font-normal">{info}</div>}
              </Dialog.Title>

              {children}

              {footer && (
                <div className="flex flex-row justify-end px-6 py-3">
                  <Button onClick={action}>
                    {buttonText}
                    {isLoading && <i className="fas fa-spinner-third fa-spin text-white ml-2"></i>}
                  </Button>
                </div>
              )}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export { DndModal }
