import { useMutation, useQuery } from "react-query"
import { common } from "common"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { keys } from "constants/keys"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import {
  AddPaymentInput,
  AddPaymentResponse,
  PaymentQueryInput,
  Payments,
  RemovePaymentInput,
  UpdatePaymentInput,
} from "components/payment/05_sharedModel/paymentModel"
import { UpdateInvoiceResponse } from "components/invoices/05_sharedModel/InputModels"

function fetchPayments(input: PaymentQueryInput) {
  return graphQLClient.fetch(query.paymentQuery, input, true, common.accountingApiUrl, keys.paymentQuery)
}

function usePayment(enabled: boolean, input: PaymentQueryInput) {
  return useQuery([keys.paymentQuery, input], () => fetchPayments(input), {
    enabled: enabled,
    select: (response: Response<Payments>) => {
      return response.data.data.payments
    },
  })
}

function addPayment(input: AddPaymentInput) {
  return graphQLClient.mutation<AddPaymentResponse>(mutation.addPayment, input, "AddPayment", common.accountingApiUrl)
}

function useAddPayment() {
  return useMutation(addPayment)
}

function updatePayment(input: UpdatePaymentInput) {
  return graphQLClient.mutation<UpdateInvoiceResponse>(mutation.updatePayment, input, "UpdatePayment", common.accountingApiUrl)
}

function useUpdatePayment() {
  return useMutation(updatePayment)
}

function removePayment(input: RemovePaymentInput) {
  return graphQLClient.mutation(mutation.removePayment, input, "RemovePayment", common.accountingApiUrl)
}

function useRemovePayment() {
  return useMutation(removePayment)
}

export { usePayment, useAddPayment, useUpdatePayment, useRemovePayment }
