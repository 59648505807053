import React, { KeyboardEvent, useState } from "react"
import CreatableSelect from "react-select/creatable"
import { dropdownVariant } from "library/styled/dropdown"
import { makeObservable, observable, runInAction } from "mobx"
import { Tag } from "models/file/file"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import { log } from "util"
import { observer } from "mobx-react"
import { v4 } from "uuid"

export class TagsObservable {
  list: Tag[]
  errorMessage?: string

  constructor(list?: Tag[]) {
    makeObservable(this, {
      list: observable,
      errorMessage: observable,
    })
    this.list = list ?? []
  }
}

interface Props {
  id: string
  value: TagsObservable
  label?: string
  cols?: number
}

const TagInputGroup = ({ id, value, label, cols = 3 }: Props) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (inputValue: string) => setInputValue(inputValue)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        runInAction(() => {
          value.list.push({ tagReference: v4().toUpperCase(), name: inputValue })
        })
        setInputValue("")
        event.preventDefault()
        break
      default:
    }
  }
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>{label}</Label>
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isMulti
        name={id}
        id={id}
        menuIsOpen={false}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type something and press enter..."
        value={value.list}
        styles={dropdownVariant.input}
        getOptionLabel={(tag: Tag) => tag.name}
        getOptionValue={(tag: Tag) => tag.tagReference}
        onChange={(e) => {
          runInAction(() => {
            value.list = [...e]
          })
        }}
      />
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(TagInputGroup)
