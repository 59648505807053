import { makeObservable, observable } from "mobx"

export class FileKeyValue {
  fileReference?: string
  fileName?: string

  constructor(fileReference?: string, name?: string) {
    this.fileReference = fileReference
    this.fileName = name
  }
}

export class FileSelectionObservable {
  fileReference?: string
  fileName?: string
  errorMessage?: string

  constructor(fileReference?: string, fileName?: string) {
    makeObservable(this, {
      fileReference: observable,
      fileName: observable,
      errorMessage: observable,
    })

    this.fileReference = fileReference
    this.fileName = fileName
  }
}
