import { Region } from "models/metadata/region"
import { AddressSetting } from "models/metadata/addressSetting"
import { GoogleAddressType } from "models/metadata/googleAddressType"
import { action, makeObservable, observable } from "mobx"

export class AddressComponentModel {
  addressSettings: AddressSetting[]
  regions: Region[]
  googleAddressType: GoogleAddressType
  constructor() {
    makeObservable(this, {
      addressSettings: observable,
      regions: observable,
      googleAddressType: observable,
      setAddressSettings: action,
      setRegions: action,
      setGoogleAddressType: action,
    })

    this.addressSettings = []
    this.regions = []
    this.googleAddressType = { city: [], country: [], countryCode: "", postCode: [], region: [], route: [] }
  }

  setAddressSettings(list: AddressSetting[], countryCode: string) {
    let filteredAddressSetting = list.filter((m) => m.countryCode === countryCode)

    if (filteredAddressSetting.length === 0) filteredAddressSetting = list.filter((m) => m.countryCode === "US")
    filteredAddressSetting = filteredAddressSetting.map((item) => ({
      ...item,
      addressComponent: lowercaseFirstLetter(item.addressComponent),
    }))
    this.addressSettings = filteredAddressSetting
  }

  setGoogleAddressType(list: GoogleAddressType[], countryCode: string) {
    this.googleAddressType = list.filter((m) => m.countryCode === countryCode)[0]
  }

  setRegions(list: Region[], countryCode: string) {
    this.regions = list.filter((m) => m.countryCode === countryCode)
  }
}

function lowercaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}
