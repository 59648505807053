import React, { useState } from "react"
import { ButtonPrimary } from "library/styled/styled"
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu"

interface Props {
  onSubmit: (sendEmail: boolean) => void
  isLoading: boolean
  isEditMode?: boolean
}

interface SaveButtonType {
  name: string
  sendEmail: boolean
}

const AddPaymentButtonDropdown = ({ onSubmit, isLoading, isEditMode }: Props) => {
  const saveButton: SaveButtonType = { name: isEditMode ? "Update" : "Add Payment", sendEmail: false }
  const saveAndEmailButton: SaveButtonType = {
    name: isEditMode ? "Update & Send Receipt" : "Add & Send Receipt ",
    sendEmail: true,
  }
  const saveButtons = [saveButton, saveAndEmailButton]
  const [buttons, setButtons] = useState([saveAndEmailButton])
  const [selectedButton, setSelectedButton] = useState(saveButton)

  const handleButtonChange = (button: SaveButtonType) => {
    setSelectedButton(button)
    onSubmit(button.sendEmail)

    let buttonsTemp = saveButtons.filter((m) => m.name !== button.name)
    setButtons(buttonsTemp)
  }

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <ButtonPrimary
        onClick={() => onSubmit(selectedButton.sendEmail)}
        type="button"
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        disabled={isLoading}
      >
        {selectedButton.name}
        {isLoading && <i className="fas fa-spinner-third fa-spin text-white ml-2 h-4 w-4"></i>}
      </ButtonPrimary>

      <Menu
        menuButton={
          <MenuButton
            className="inline-flex items-center rounded-r-md bg-primary-900 px-2 py-2 text-white"
            style={{ borderLeft: "1px solid white" }}
          >
            {" "}
            <i className="h-5 w-5 far fa-chevron-down" aria-hidden="true" />
          </MenuButton>
        }
        transition
        align="end"
      >
        {buttons.map((item) => (
          <MenuItem key={item.name} className="font-medium" onClick={() => handleButtonChange(item)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default AddPaymentButtonDropdown
