import React, { useState } from "react"
import { ErrorFallback } from "library/styled/styled"
import { ErrorBoundary } from "react-error-boundary"
import { Navigate, Route, Routes } from "react-router-dom"
import Sidebar from "./components/navigation/sidebar"
import Navbar from "./components/navigation/navbar"
import Settings from "components/setting/setting"
import Logout from "./components/angularJs/logout/logout"
import InvoiceListPage from "components/invoices/04_invoiceList/invoiceListPage"
import ItemListPage from "components/items/03_itemList/itemListPage"
import { LoadingContext } from "context/loading-context"
import { ThreeDotsSpinner } from "library/styled/spinners"
import AddOrEditInvoicePage from "components/invoices/01_addOrEditInvoice/addOrEditInvoicePage"
import DocumentListPage from "components/documents/01_documentList/documentListPage"
import Jobs from "components/job/jobList/jobs"
import Scheduler from "components/scheduler/schedulerView"
import TaskListPage from "./components/Task/03_taskList/taskListPage"
import AddOrEditTaskPage from "./components/Task/01_addOrEditTask/addOrEditTaskPage"

function AuthenticatedApp() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)

  function handleSidebar() {
    if (sidebarOpen) {
      setTimeout(() => {
        let sidebar = document.getElementById("sidebar")
        if (sidebar !== null) sidebar.style.display = "flex"
      }, 300)
    } else {
      let sidebar = document.getElementById("sidebar")
      if (sidebar !== null) sidebar.style.display = "none"
    }

    setSidebarOpen(!sidebarOpen)
  }

  return (
    <>
      <div className={sidebarOpen ? "mini-sidebar" : ""}>
        <Sidebar sidebarOpen={sidebarOpen} />
        <Navbar sidebarOpen={sidebarOpen} onSidebarToggled={handleSidebar} />
        <main>
          <div
            className={`page-wrapper bg-gray-100 border-t border-gray-200 overflow-y-auto ${loadingPage ? "opacity-30" : ""}`}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LoadingContext.Provider value={{ loadingPage, setLoadingPage }}>
                <AppRoutes />
              </LoadingContext.Provider>
            </ErrorBoundary>
          </div>
          {loadingPage && <ThreeDotsSpinner />}
        </main>
      </div>
    </>
  )
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="settings" element={<Settings />}>
        <Route path=":subSetting" element={<Settings />} />
      </Route>
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/invoices" element={<InvoiceListPage />}>
        <Route path=":invoiceReference" element={<InvoiceListPage />} />
      </Route>
      <Route path="/invoices/create" element={<AddOrEditInvoicePage />} />
      <Route path="/invoices/create?jobReference&clientReference&clientName" element={<AddOrEditInvoicePage />} />
      <Route path="/invoices/edit/:invoiceReference" element={<AddOrEditInvoicePage />} />
      <Route path="/documents" element={<DocumentListPage />} />
      <Route path="/items" element={<ItemListPage />} />
      
      <Route path="/tasks" element={<TaskListPage />}>
        <Route path=":taskReference" element={<TaskListPage />} />
      </Route>

      <Route path="/tasks/create" element={<AddOrEditTaskPage />} />
      <Route path="/tasks/edit/:taskReference" element={<AddOrEditTaskPage />} />
      <Route path="/scheduler" element={<Scheduler />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="settings" replace />} />
    </Routes>
  )
}

export default AuthenticatedApp
