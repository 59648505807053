import { CheckboxInput, ErrorInline, FormGroup } from "../styled/styled"
import { action, makeAutoObservable } from "mobx"
import { observer } from "mobx-react"
import React, { ChangeEvent } from "react"
import CheckBox from "../styled/checkbox"

export class BooleanObservable {
  value: boolean = false
  errorMessage?: string

  constructor(value?: boolean) {
    makeAutoObservable(this)
    this.value = value ?? false
  }
}

interface CheckBoxProps {
  name: string
  error?: any
  placeholder?: string
  label: string
  value: BooleanObservable
  errorKey?: string
  onChange?: (isChecked: boolean) => void
  margin?: boolean
  cols?: number
  tooltip?: string
  tooltipOverlay?: React.ReactNode | (() => React.ReactNode)
}

const InputGroupCheckbox = ({
  name,
  label,
  value,
  onChange,
  cols = 6,
  margin = true,
  tooltip = "",
  tooltipOverlay,
}: CheckBoxProps) => {
  return (
    <FormGroup cols={cols} className={`${cols === 3 && margin ? "mt-8" : ""}`}>
      <CheckBox label={label} name={name} tooltip={tooltip} tooltipOverlay={tooltipOverlay}>
        <CheckboxInput
          id={name}
          name={name}
          type="checkbox"
          checked={value.value}
          onChange={action((e) => {
            value.value = e.target.checked
            if (onChange) onChange(e.target.checked)
          })}
        />
        {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
      </CheckBox>
    </FormGroup>
  )
}

export default observer(InputGroupCheckbox)
