import React from "react"
import Tooltip from "rc-tooltip"

interface Props {
  id?: string
  text?: string
  placement?: string
  trigger?: string
  showArrow?: boolean
  children?: any
  show?: boolean
  overlay?: (() => React.ReactNode) | React.ReactNode
  className?: string
  delay?: number
}

const ToolTip = ({
  id,
  text = "",
  placement = "top",
  trigger = "hover",
  showArrow = true,
  children,
  show = true,
  overlay,
  className = "max-w-[300px]",
  delay = 0.1,
}: Props) => {
  return show ? (
    <Tooltip
      overlayClassName={className}
      id={id}
      placement={placement}
      trigger={[trigger]}
      overlay={text ? <div className="">{text}</div> : overlay}
      mouseEnterDelay={delay}
      showArrow={showArrow}
    >
      {children}
    </Tooltip>
  ) : (
    children
  )
}

export default ToolTip
