import React, { Fragment, useEffect, useState } from "react"
import useLocalStorageState from "utils/localStorage"
import DataGridNew from "library/styled/datagridNew"
import { observer } from "mobx-react"
import { useModalState } from "utils/modalStates"
import { useNavigate } from "react-router-dom"
import { DocumentListModel } from "components/documents/01_documentList/documentListModel"
import { File } from "models/file/file"
import { documentColumns } from "components/documents/03_sharedModel/documentListColumns"
import { useFiles } from "components/file/fileService"
import DocumentListFilters from "components/documents/01_documentList/documentListFilters"
import LinkExistingFileModal from "components/shared/documents/03_linkExistingFile/linkExistingFile"
import UploadFileModal from "components/shared/documents/02_uploadFile/uploadFile"
import { entityTypes } from "constants/entityTypes"
import { ButtonPrimary } from "library/styled/styled"
import SidePanel from "library/styled/sidePanel"
import DocumentDetailTabs from "../02_documentDetail/documentDetailTabs"

function DocumentListPage() {
  const [columns, setColumns] = useLocalStorageState("documentColumns", documentColumns)
  const [documentListModel] = useState(() => new DocumentListModel())
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useFiles(documentListModel.getFilter())
  const { addModal, setAddModal, editModal, setEditModal, viewModal, setViewModal } = useModalState<File>()

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data) documentListModel.setDocumentList(data)
  }, [data])

  // @ts-ignore
  return (
    <Fragment>
      <div className="px-3 pt-3 space-y-3 xl16:px-6 xl16:pt-6 xl16:space-y-6">
        <div className="">
          <DocumentListFilters documentListModel={documentListModel} />
        </div>

        <div className="">
          <DataGridNew<File>
            title="Documents"
            buttonText="Upload File"
            data={documentListModel.documentList}
            columns={columns}
            dataKey="fileReference"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onRowClick={(item) => setViewModal({ item: item, isOpen: true })}
            onColumnChange={setColumns}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            noItemFoundMessage="No Document found"
            action={() => setEditModal({ ...editModal, isOpen: true })}
            secondaryButton={<ButtonPrimary onClick={() => setAddModal(true)}>Add Existing File</ButtonPrimary>}
          />
        </div>
      </div>

      <LinkExistingFileModal
        isOpen={addModal}
        onClose={() => setAddModal(false)}
        entityReference={entityTypes.document.reference}
        entityTypeReference={entityTypes.document.entityTypeReference}
      />

      <UploadFileModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        file={undefined}
        entityReference={entityTypes.document.reference}
        entityTypeReference={entityTypes.document.entityTypeReference}
      />

      <SidePanel
        title={`${viewModal.item?.fileName}`}
        open={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        body={
          <DocumentDetailTabs
            fileReference={viewModal.item?.fileReference}
            onFileDeleted={() => setViewModal({ ...viewModal, isOpen: false })}
          />
        }
        type="detailPanel"
      />
    </Fragment>
  )
}

export default observer(DocumentListPage)
