import React, { useEffect, useState } from "react"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import TableDynamic from "library/styled/table"
import { useEmail } from "components/invoices/services/invoiceService"
import { useModalState } from "utils/modalStates"
import { Email } from "components/invoices/05_sharedModel/emailModel"
import ViewEmailDetailModal from "components/invoices/03_invoiceDetail/emails/invoiceEmailDetailModal"

interface Props {
  entityReference?: string
  entityTypeReference: string
  // This props controls the noteQuery when set to true the component will load the query
  isSelected: boolean
  isOpenInModal?: boolean
}

const EmailListTemplate = ({ entityReference, entityTypeReference, isSelected, isOpenInModal = false }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: emails, isLoading } = useEmail(enabled, {
    entityReference: entityReference ?? "",
    entityTypeReference: entityTypeReference,
  })
  const { viewModal, setViewModal } = useModalState<Email>()

  useEffect(() => {
    if (entityReference && isSelected) setEnabled(true)
  }, [entityReference, isSelected])

  return (
    <>
      <TableDynamic
        columns={cols}
        data={emails}
        dataKey="emailReference"
        options={[]}
        onRowClick={(item) => setViewModal({ item: item, isOpen: true })}
        isLoading={isOpenInModal ? false : isLoading}
        columnLoadingWidths={[25, 25, 25, 25]}
      />

      <ViewEmailDetailModal
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        email={viewModal.item}
      />
    </>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Subject",
    key: "subject",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "2",
    name: "Body",
    key: "body",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "3",
    name: "Status",
    key: "emailDeliveryStatusName",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "4",
    name: "Opened On",
    key: "emailOpenDateTimeUtc",
    columnTypeId: ColumnType.DateTime.Id,
  },
]

export default EmailListTemplate
