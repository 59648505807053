import * as React from "react"
import {
  ScheduleComponent,
  Inject,
  ViewsDirective,
  ViewDirective,
  EventSettingsModel,
  ResourcesDirective,
  TimelineMonth,
  ResourceDirective,
  TimelineViews,
  CellClickEventArgs,
  SelectEventArgs,
  ResourceDetails,
  Resize,
  ResizeEventArgs,
  DragAndDrop,
  DragEventArgs,
  PopupOpenEventArgs,
  View,
} from "@syncfusion/ej2-react-schedule"
import { useEffect, useRef, useState } from "react"
import { SchedulerModel } from "components/scheduler/schedulerModel"
import {
  useMoveJob,
  useMoveSchedulerJob,
  useSchedulerEvent,
  useSchedulerResource,
  useUnassignedJobs,
} from "components/scheduler/06_services/schedulerService"
import { observer } from "mobx-react"
import SidePanel from "library/styled/sidePanel"
import AddEventModal from "components/scheduler/AddEventModal"
import { MoveJobInput, MoveSchedulerJobInput } from "components/scheduler/05_models/models"
import { handlePopupError, toUtcDateTime } from "common"
import { InfoAlert, SuccessAlert } from "library/styled/alerts"
import {
  DateHeaderTemplate,
  EventTemplate,
  QuickInfoFooterTemplate,
  QuickInfoHeaderTemplate,
  ResourceHeaderTemplate,
} from "components/scheduler/templates/templates"
import UnassignedJobView from "components/scheduler/unassignedJobs/unassignedJobView"
import { successToaster } from "library/styled/toasters"
import HeaderBarComponent from "components/scheduler/headerBarComponent"
import SchedulerSetting from "components/scheduler/settings/schedulerSetting"
import useLocalStorageState from "utils/localStorage"
import useObservableLocalStorage from "utils/observableLocalStorage"

export interface SchedulerSettings {
  selectedDate: Date
  selectedView: View
}

const Scheduler = () => {
  const [model] = useState(() => new SchedulerModel())
  let scheduleRef = useRef<ScheduleComponent>(null)
  const { data: resources } = useSchedulerResource()
  const { data: schedulerEvents, refetch: refetchEvents } = useSchedulerEvent(model.getEventQueryInput())
  const { data: unassignedJobs, refetch: refetchUnassignedJobs } = useUnassignedJobs(model.getUnassignedJobsQueryInput())
  const { mutate: moveJob } = useMoveSchedulerJob()
  const { mutate: updateJobSchedule } = useMoveJob()
  const [infoAlert, setInfoAlert] = useState({
    isOpen: false,
    title: "",
    description: "",
  })
  const [successAlert, setSuccessAlert] = useState(false)
  const [settingModal, setSettingModal] = useState(false)
  const eventSettings: EventSettingsModel = {
    dataSource: schedulerEvents,
    template: (props: any) => EventTemplate(props, model.jobTitle.selectedOption?.value),
    ignoreWhitespace: false,
  }
  const group = { allowGroupEdit: true, resources: ["Resources"] }

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true
  }

  const onSelect = (args: SelectEventArgs) => {
    if (args.requestType === "cellSelect") {
      model.setAddEventModal(true, {
        resourceReference: "",
        startDateTime: new Date(),
        endDateTime: new Date(),
      })
    }
  }

  const handleJobMove = (input: MoveSchedulerJobInput, isExternalEvent: boolean) => {
    setInfoAlert({
      isOpen: true,
      title: isExternalEvent ? "Assigning Job" : "Moving Job",
      description: "Please Wait...",
    })
    moveJob(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void refetchEvents()
        if (isExternalEvent) {
          setSuccessAlert(true)
          void refetchUnassignedJobs()
        } else successToaster("Job moved")
      },
      onSettled: () => setInfoAlert({ ...infoAlert, isOpen: false }),
    })
  }

  const onResize = (args: ResizeEventArgs) => {
    setInfoAlert({
      isOpen: true,
      title: "Updating Job Time",
      description: "Please Wait...",
    })
    const input: MoveJobInput = {
      jobReference: args.data.ExtendedProps.jobMetadata?.jobReference,
      startDateTimeUtc: toUtcDateTime(args.data.StartTime),
      endDateTimeUtc: toUtcDateTime(args.data.EndTime),
    }
    updateJobSchedule(input, {
      onError: (error: any) => {
        handlePopupError(error.response)
        args.cancel = true
      },
      onSuccess: () => {
        successToaster("Job Schedule Updated")
        void refetchEvents()
      },
      onSettled: () => setInfoAlert({ ...infoAlert, isOpen: false }),
    })
  }

  const onDragStop = (event: DragEventArgs) => {
    let input: MoveSchedulerJobInput = {
      jobReference: event.data.ExtendedProps.jobMetadata.jobReference,
      startDateTimeUtc: toUtcDateTime(event.data.StartTime),
      endDateTimeUtc: toUtcDateTime(event.data.EndTime),
      oldResource: null,
      newResource: null,
    }

    if (event.element.dataset.groupIndex) {
      let oldResourceDetails: ResourceDetails | undefined = scheduleRef.current?.getResourcesByIndex(
        parseInt(event.element.dataset.groupIndex)
      )
      input.oldResource = {
        resourceReference: oldResourceDetails?.resourceData.resourceReference,
        isContractor: oldResourceDetails?.resourceData.isContractor,
        isStaffMember: oldResourceDetails?.resourceData.isStaffMember,
      }
    }
    if (event.target) {
      let newResourceDetails: ResourceDetails | undefined = scheduleRef.current?.getResourcesByIndex(
        parseInt(event.target?.dataset.groupIndex ?? "")
      )
      input.newResource = {
        resourceReference: newResourceDetails?.resourceData.resourceReference,
        isContractor: newResourceDetails?.resourceData.isContractor,
        isStaffMember: newResourceDetails?.resourceData.isStaffMember,
      }
    }

    handleJobMove(input, false)
  }

  const onDragStart = (args: DragEventArgs) => {
    if (args.navigation) args.navigation.enable = true
  }

  const onPopupOpen = (args: PopupOpenEventArgs) => {
    if (args.type === "Editor") args.cancel = true
  }

  const onDateClick = (date: Date) => {
    model.setSelectedDate(date)
    model.setSelectedView("TimelineDay")
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-3 drag-wrapper">
        <div className={model.openUnassignedJobs ? "col-span-10" : "col-span-12"}>
          <HeaderBarComponent
            model={model}
            scheduleRef={scheduleRef}
            resources={resources}
            onSettingClick={() => setSettingModal(true)}
          />
          <ScheduleComponent
            id="scheduler"
            ref={scheduleRef}
            width="100%"
            height="calc(100vh - 106px)"
            currentView={model.selectedView}
            selectedDate={model.selectedDate}
            eventSettings={eventSettings}
            group={group}
            resourceHeaderTemplate={ResourceHeaderTemplate}
            cssClass="schedule-cell-dimension"
            eventDragArea="schedule-drag-drop"
            allowMultiCellSelection={true}
            cellClick={onCellClick}
            select={onSelect}
            rowAutoHeight={model.rowAutoHeight.value}
            quickInfoTemplates={{
              header: (props: any) => QuickInfoHeaderTemplate(props, scheduleRef),
              footer: QuickInfoFooterTemplate,
            }}
            allowResizing={true}
            allowDragAndDrop={true}
            resizeStop={onResize}
            dragStop={onDragStop}
            dragStart={onDragStart}
            delayUpdate={true}
            popupOpen={onPopupOpen}
            showHeaderBar={false}
            startHour={model.workingDayStart.selectedOption?.value}
            endHour={model.workingDayEnd.selectedOption?.value}
            timeScale={{
              enable: true,
              interval: model.timeLabelInterval.selectedOption?.value,
              slotCount: model.timeSlot.selectedOption?.value,
            }}
            firstDayOfWeek={model.firstDayOfWeek.selectedOption?.value}
          >
            <ViewsDirective>
              <ViewDirective option="TimelineDay" displayName="Day" />
              <ViewDirective option="TimelineWeek" displayName="Week" />
              <ViewDirective
                option="TimelineMonth"
                displayName="Month"
                dateHeaderTemplate={(props: any) => DateHeaderTemplate(props, onDateClick)}
              />
            </ViewsDirective>
            <ResourcesDirective>
              <ResourceDirective
                field="ResourceId"
                title="Resource"
                name="Resources"
                allowMultiple={true}
                dataSource={resources}
                textField="fullName"
                idField="resourceReference"
              ></ResourceDirective>
            </ResourcesDirective>
            <Inject services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]} />
          </ScheduleComponent>
        </div>

        {model.openUnassignedJobs && (
          <div className="col-span-2">
            <UnassignedJobView unassignedJobs={unassignedJobs} scheduleRef={scheduleRef} onJobMoved={handleJobMove} />
          </div>
        )}
      </div>

      <SidePanel
        title={`Add Job`}
        open={model.addEventModal.isOpen}
        onClose={() => {
          model.setAddEventModal(false)
        }}
        body={<AddEventModal />}
        type="detailPanel"
      />

      <SidePanel
        title={`Settings`}
        open={settingModal}
        onClose={() => {
          setSettingModal(false)
        }}
        body={<SchedulerSetting model={model} scheduleRef={scheduleRef} />}
        type="filterPanel"
      />

      <InfoAlert
        title={infoAlert.title}
        description={infoAlert.description}
        isOpen={infoAlert.isOpen}
        onClose={() => setInfoAlert({ ...infoAlert, isOpen: false })}
      />

      <SuccessAlert
        title="Job Assigned"
        description="Job has been assigned"
        isOpen={successAlert}
        onClose={() => setSuccessAlert(false)}
      />
    </>
  )
}

export default observer(Scheduler)
