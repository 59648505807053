import React from "react"
import { observer } from "mobx-react"
import { ErrorInline } from "../styled/styled"
import { NumberObservable } from "../observables/inputGroupNumber"

interface Props {
  localeCode: string
  id?: string
  value: NumberObservable
  className?: string
}

const CurrencyLabel = (props: Props) => {
  function getCurrency() {
    if (!props.value.number) return "0.00"
    return new Intl.NumberFormat(props.localeCode, { minimumFractionDigits: 2 }).format(props.value.number)
  }

  return (
    <>
      <div data-testid={props.id} className={props.className}>{getCurrency()}</div>
      {props.value.errorMessage && <ErrorInline>{props.value.errorMessage}</ErrorInline>}
    </>
  )
}
export default observer(CurrencyLabel)
