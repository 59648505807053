import { Connection } from "../../../models/shared/response"
import { InvoiceStatus } from "../../invoices/05_sharedModel/invoiceModel"
import { entityTypes } from "../../../constants/entityTypes"

export interface Tasks {
  tasks: Connection<Task>
}

export interface Task {
  taskReference?: string
  entityTypeReference?: string
  entityTypeName?: string
  entityReference?: string
  entityName?: string
  taskStatusReference?: string
  taskStatusName?: string
  taskStatusColor?: string
  name?: string
  description?: string
  dueDateUtc?: string
  startDateTimeUtc?: string
  endDateTimeUtc?: string
  concurrencyToken?: string
  assignToAllStaffMembers?: boolean
  taskReminders?: TaskReminder[]
  staffMembers?: StaffMember[]
  taskCategory?: TaskCategory[]
  updatedBy?: any
  updatedDateTimeUtc?: any
  insertedBy?: string
  insertedDateTimeUtc?: string
}

export interface TaskReminder {
  taskReminderReference: string
  reminderDateTimeUtc: string
}

export interface StaffMember {
  taskStaffReference?: string
  staffReference: string
  name: string
  profileImageUrl: string
}

export interface TaskCategory {
  taskCategoryReference?: string
  name?: string
}

export interface TaskStatuses {
  taskStatus: TaskStatus[]
}

export interface TaskStatus {
  taskStatusReference: string
  name: string
  color?: string
  displayOrder?: string
  description?: string
  isSystemOwned?: boolean
  isDisabled?: boolean
  updatedBy?: string
  updatedDateTimeUtc?: string
  insertedBy?: string
  insertedDateTimeUtc?: string
}

export class TaskEntityType {
  entityTypeReference?: string
  entityTypeName?: string

  constructor(entityTypeReference: string, entityTypeName: string) {
    this.entityTypeReference = entityTypeReference
    this.entityTypeName = entityTypeName
  }

  get isGeneralEntity() {
    return this.entityTypeReference === entityTypes.general.entityTypeReference
  }

  get isJobEntity() {
    return this.entityTypeReference === entityTypes.job.entityTypeReference
  }

  get isClientEntity() {
    return this.entityTypeReference === entityTypes.client.entityTypeReference
  }
}
