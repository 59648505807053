export class ItemType {
  itemTypeReference: string
  itemTypeName: string

  // Reference and name are used by InputGroupDropdown
  reference: string
  name: string

  constructor(itemTypeReference: string, itemTypeName: string) {
    this.itemTypeReference = itemTypeReference
    this.itemTypeName = itemTypeName
    this.reference = itemTypeReference
    this.name = itemTypeName
  }
}

export const itemTypeService = new ItemType("C27E84B5-314F-409C-BB79-CA3D90670D53", "Service")
export const itemTypeProduct = new ItemType("217BD16D-BCB3-4DDD-A0D2-F4FF640A4599", "Product")
export const itemTypes: ItemType[] = [itemTypeService, itemTypeProduct]
