import React, { useEffect, useState } from "react"
import {
  ButtonPrimary,
  ButtonSecondary,
  DlGroup,
  Form,
  FormGroup
} from "library/styled/styled"

import { observer } from "mobx-react"
import { handleServerError } from "common"
import { Link } from "react-router-dom"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import { successToaster } from "library/styled/toasters"
import { useAddTask, useTaskDetailQuery, useTaskStatusQuery, useUpdateTask } from "../services/taskService"
import { AddTaskInput, UpdateTaskInput } from "../04_sharedModel/inputModels"
import { TaskEntityType, TaskStatus } from "../04_sharedModel/taskModel"
import InputGroupSelect from "../../../library/observables/inputGroupSelect"
import TextAreaInputGroup from "../../../library/textAreaInputGroup/textAreaInputGroup"
import { taskEntityTypes } from "../../../constants/taskTypes"
import ClientSelectionComponent from "../../../library/components/clientSelectionComponent"
import Conditional from "../../../library/styled/conditional"
import JobSelection from "../../../library/jobSelection/jobSelection"
import { useStaffName } from "../../../hooks/people/staff"
import MultiSelectInputGroup from "../../../library/multiSelectInputGroup/multiSelectInputGroup"
import { StaffName } from "../../../models/people/staffName"
import { allStaffMember } from "../../../constants/StaffMember"
import DateTimePicker from "../../../library/observables/dateTimePicker"
import Accordian from "../../../library/styled/accordian"
import { DescriptionView } from "../../../library/styled/description-list"
import { keys } from "../../../constants/keys"
import { useQueryClient } from "react-query"
import { useFilteredCategory } from "../../../hooks/organisation/category"
import { entityTypes } from "../../../constants/entityTypes"
import { Category } from "../../../models/organisation/category"
import ToolTip from "../../../library/styled/tooltip"
import addOrEditTaskPageModel from "./addOrEditTaskPageModel"

interface Props {
  taskReference?: string
  setModalTitle?: (title: string) => void
  closeSidePanel?: () => void
}

const AddOrEditTaskPage = ({ taskReference, setModalTitle, closeSidePanel }: Props) => {

  const [taskPageModel] = useState(() => new addOrEditTaskPageModel())
  const queryClient = useQueryClient()

  const { data: task } = useTaskDetailQuery(taskPageModel.isEditMode, taskReference)
  const { mutate: updateTaskMutation, isLoading: updateTaskIsLoading } = useUpdateTask()
  const { mutate: addTaskMutation, isLoading: addTaskIsLoading } = useAddTask()
  const { data: taskStatus, isLoading: taskStatusLoading } = useTaskStatusQuery()
  const { data: staffMembers } = useStaffName(true)
  const { data: category } = useFilteredCategory(entityTypes.task.entityTypeReference)

  useEffect(() => {
    if (taskReference) {
      taskPageModel.setIsEditMode(true)
      taskPageModel.setIsAddMode(false)

      if (setModalTitle)
        setModalTitle("Edit Task")
    } else {
      taskPageModel.setIsAddMode(true)
      taskPageModel.setIsEditMode(false)

      if (setModalTitle)
        setModalTitle("New Task")
    }
  }, [])

  useEffect(() => {
    if (taskPageModel.isEditMode && task) {
      taskPageModel.setTask(task)
    }
  }, [task])

  useEffect(() => {
    taskPageModel.setDefaultTaskStatus(taskStatus)
  }, [taskStatusLoading])

  useEffect(() => {
    taskPageModel.setDefaultTaskType(taskEntityTypes)
  }, [taskEntityTypes])

  useEffect(() => {
    if (staffMembers) {
      const hasAllStaffMember = staffMembers.some(value => value.staffReference === allStaffMember.staffReference)
      if (!hasAllStaffMember) staffMembers.unshift(allStaffMember)
    }
  }, [staffMembers])

  const submitForm = async () => {
    if (taskPageModel.isEditMode) {
      const input: UpdateTaskInput = taskPageModel.getUpdateTaskInput()
      updateTaskMutation(input, {
        onSuccess: (response) => {
          handleSuccess()
        },
        onError: (error: any) => {
          taskPageModel.handleApiErrors(error)
          handleServerError(error.response)
        }
      })
    } else {
      const input: AddTaskInput = taskPageModel.getAddTaskInput()
      addTaskMutation(input, {
        onSuccess: (response) => {
          handleSuccess()
        },
        onError: (error: any) => {
          taskPageModel.handleApiErrors(error)
          handleServerError(error.response)
        }
      })
    }
  }

  function handleSuccess() {

    void queryClient.invalidateQueries([keys.taskQuery])
    void queryClient.invalidateQueries([keys.taskDetailQuery])

    if (taskPageModel.isAddMode)
      successToaster(
        `Task has been created.`,
        "",
        `View ${taskPageModel.name.text}`,
        `tasks/${taskPageModel.taskReference.text}`
      )
    else
      successToaster(
        `Task has been updated.`,
        "",
        `View ${taskPageModel.name.text}`,
        `tasks/${taskPageModel.taskReference.text}`
      )

    if (closeSidePanel) closeSidePanel()
  }

  return (
    <>
      <div className={`overflow-y overflow-x-hidden side-detail-height`}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextInputGroup cols={2} id={"taskName"} label="Task Title" value={taskPageModel.name} isRequired={true}
          />

          <TextAreaInputGroup label="Task Description" name="description" value={taskPageModel.description}
                              placeholder="Optional" />

          <InputGroupSelect<TaskStatus>
            value={taskPageModel.taskStatus}
            id="taskStatus"
            options={taskStatus}
            optionValue={"taskStatusReference"}
            optionLabel={"name"}
            label={"Task Status"}
            toolTipComponent={
              <>
                <ToolTip
                  className={"w-60"}
                  overlay={
                    <span> <Link to="/settings/task" target="_blank" className="underline">Click here</Link>
                      {" "}to add more task status.
                      </span>
                  }>
                  <i className="far fa-circle-info text-gray-700 ml-2"></i>
                </ToolTip>
              </>
            }
          />

          <MultiSelectInputGroup<Category>
            id={"TaskCategory"}
            label={"Task Category"}
            options={category}
            value={taskPageModel.taskCategory}
            isClearable={true}
            isDisabled={false}
            placeholder={"Optional"}
            isSearchable={true}
            optionValue={"categoryReference"}
            optionLabel={"name"}
            toolTipComponent={
              <>
                <ToolTip
                  className={"w-60"}
                  overlay={
                    <span> <Link to="/settings/task" target="_blank" className="underline">Click here</Link>
                      {" "}to add more task category.
                      </span>
                  }>
                  <i className="far fa-circle-info text-gray-700 ml-2"></i>
                </ToolTip>
              </>
            }
          />

          <InputGroupSelect<TaskEntityType>
            value={taskPageModel.taskEntityType}
            id="taskType"
            options={taskEntityTypes}
            optionValue={"entityTypeReference"}
            optionLabel={"entityTypeName"}
            label={"Task Type"}
            isDisabled={taskPageModel.isEditMode}
          />

          <Conditional
            when={taskPageModel.taskEntityType.selectedOption?.isClientEntity}>
            <ClientSelectionComponent
              label="Choose Client"
              name="taskClient"
              value={taskPageModel.clientSelection}
              type="input"
              placeholder="Client is required"
              isDisabled={taskPageModel.isEditMode}
            />
          </Conditional>

          <Conditional
            when={taskPageModel.taskEntityType.selectedOption?.isJobEntity}>
            <JobSelection
              label="Choose Job"
              name="job"
              isClearable={true}
              value={taskPageModel.jobSelection}
              placeholder={"Job is required"}
              type={"input"}
              isDisabled={taskPageModel.isEditMode}
              clearJobIfClientIsChanged={false}
            />
          </Conditional>

          <MultiSelectInputGroup<StaffName>
            id={"TaskStaffMembers"}
            label={"Staff Members"}
            options={staffMembers}
            value={taskPageModel.staffMembers}
            isClearable={true}
            placeholder={"Optional"}
            isSearchable={true}
            optionValue={"staffReference"}
            optionLabel={"fullName"}
            toolTip={"Assigning staff members is essential for setting up task reminders."}
          />

        </Form>

        <Accordian
          width="max-w-4xl"
          title="Task Schedule"
          showLink={false}
          openByDefault={false}
        >
          <Form>
            <DateTimePicker
              label={"Due Date"}
              name={"dueDateTime"}
              value={taskPageModel.dueDateTimeUtc}
              placeholder={"Optional"}
              isRequired={false}
            ></DateTimePicker>

            <DlGroup></DlGroup>

            <DateTimePicker
              label={"Start Date"}
              name={"startDateTime"}
              value={taskPageModel.startDateTimeUtc}
              placeholder={"Optional"}
              isRequired={false}
            ></DateTimePicker>

            <DateTimePicker
              label={"End Date"}
              name={"endDateTime"}
              value={taskPageModel.endDateTimeUtc}
              placeholder={"Optional"}
              isRequired={false}
            ></DateTimePicker>
          </Form>
        </Accordian>

        <Accordian
          width="max-w-4xl"
          title="Task Reminders"
          showLink={false}
          openByDefault={false}
        >
          <Form>
            <FormGroup cols={6}>
              <DescriptionView
                value={"Receive up to three notifications for the following times."}
                label={""}></DescriptionView>
            </FormGroup>

            {taskPageModel.taskReminders.map(value => {
              return <DateTimePicker
                key={value.taskReminderReference}
                label={""}
                name={"reminderDateTime1"}
                value={value.reminderDateTimeUtc}
                placeholder={"Optional"}
                isRequired={false}
                showClearButton={true}
              ></DateTimePicker>
            })}
          </Form>
        </Accordian>

        <div className="px-4 pt-4 sm:px-6 mx-auto max-w-3xl sm:max-w-4xl flex flex-row justify-end space-x-6 py-6 ">
          <ButtonSecondary disabled={addTaskIsLoading} onClick={() => {
            if (closeSidePanel) closeSidePanel()
          }}>
            Cancel
          </ButtonSecondary>

          <ButtonPrimary disabled={addTaskIsLoading || updateTaskIsLoading} onClick={() => submitForm()}>Save
            Task</ButtonPrimary>

        </div>
      </div>
    </>
  )
}

export default observer(AddOrEditTaskPage)
