import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select, { GroupBase, OptionsOrGroups, SingleValue } from "react-select"
import { dropdownVariant } from "library/styled/dropdown"
import React from "react"
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import { SelectObservable } from "library/models/selectObservable"
import { KeyValuePair } from "models/shared/common"
import ToolTip from "library/styled/tooltip"

interface DropdownProps<T> {
  id: string
  label?: string
  onChange?: (item: SingleValue<T>) => void
  cols?: number
  options?: OptionsOrGroups<T, GroupBase<T>> | undefined
  value: SelectObservable<T>
  optionValue: keyof T
  optionLabel: keyof T
  placeholder?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  type?: "filter" | "input" | "grid",
  toolTip?: string,
  toolTipComponent?: any
}

const InputGroupSelect = <T extends KeyValuePair>({
                                                    id,
                                                    label,
                                                    onChange,
                                                    cols = 3,
                                                    options = [],
                                                    value,
                                                    optionValue,
                                                    optionLabel,
                                                    placeholder = "Optional",
                                                    isInModal = false,
                                                    isSearchable = true,
                                                    isClearable = false,
                                                    isDisabled = false,
                                                    type = "input",
                                                    toolTip,
                                                    toolTipComponent
                                                  }: DropdownProps<T>) => {

  function getClassName() {
    if (type === "filter") {
      return "shadow-md w-full"
    }

    if (type === "input") {
      return "ring-primary-100"
    }

    return "ring-primary-100"
  }

  function getStyles() {
    if (type === "filter") {
      return dropdownVariant.filter
    }

    if (type === "input") {
      return dropdownVariant.input
    }

    return dropdownVariant.grid
  }

  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>
        {label}

        {toolTip && (
          <ToolTip text={toolTip} id={"textInputGroupToolTip"}>
            <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}

        {toolTipComponent && <>{toolTipComponent}</>}
      </Label>
      <Select
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={id}
        id={id}
        value={value?.selectedOption ? value.selectedOption : null}
        getOptionLabel={(option: T) => option[optionLabel]}
        getOptionValue={(option: T) => option[optionValue]}
        onChange={(e) => {
          runInAction(() => {
            value.selectedOption = e ?? undefined
            if (onChange) {
              onChange(e)
            }
          })
        }}
        placeholder={placeholder}
        className={getClassName()}
        styles={getStyles()}
        menuPosition={isInModal ? "fixed" : undefined}
        isDisabled={isDisabled}
        menuPlacement={"bottom"}
        menuPortalTarget={document.body}
      ></Select>
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(InputGroupSelect)
