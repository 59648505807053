export const templateTypes = {
  invoiceMessageForClient: {
    id: 100,
    templateTypeReference: "BB670B34-DCAC-48B2-915B-163F08D7F45A",
    name: "Invoice Message for Client Template",
  },
  invoicePaymentInstruction: {
    id: 200,
    templateTypeReference: "38C59613-1E89-4CFD-A1F8-DEE93FF7756D",
    name: "Payment Instructions",
  },
}
