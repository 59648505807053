// /** @jsx jsx */
// import { jsx } from "@emotion/core"

import * as React from "react"
import { FullPageErrorFallback, FullPageLoading } from "library/styled/styled"
import { useOrganisationDetail } from "hooks/organisation/organisation"
import { OrganisationDetail } from "models/organisation/organisationDetail"

interface DefaultContextValue {
  organisation: OrganisationDetail | undefined
}

const defaultValue: DefaultContextValue = {
  organisation: {} as OrganisationDetail,
}

const MetadataContext = React.createContext(defaultValue)
MetadataContext.displayName = "MetadataContext"

type Props = {
  children: React.ReactNode
}

function MetadataProvider(props: Props) {
  const { data: organisation, isLoading, isError, isSuccess, error, status } = useOrganisationDetail()

  const value = React.useMemo(() => ({ organisation }), [organisation])

  if (isLoading) {
    return <FullPageLoading />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <MetadataContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useMetadata() {
  const context = React.useContext(MetadataContext)
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`)
  }
  return context
}

export { MetadataProvider, useMetadata, MetadataContext }
