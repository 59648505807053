import _ from "lodash"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { useFeature } from "context/feature-context"
import { DndModal } from "library/styled/dndModal"
import { InputGroupButton, InputGroupCheckbox } from "library/styled/input-group"
import { DataGridColumn } from "models/shared/column"
import { EnabledFeature } from "models/identity/features"
import { ErrorInline } from "library/styled/styled"
import Conditional from "library/styled/conditional"

interface Props {
  isOpen: boolean
  onClose: () => void
  columns: DataGridColumn[]
  onChange: (cols: DataGridColumn[]) => void
}

const ListColumnSettingModal = ({ isOpen, onClose, columns, onChange }: Props) => {
  const [temp, setTemp] = useState<DataGridColumn[]>(_.cloneDeep(columns))
  const [error, setError] = useState("")
  const { enabledFeatures } = useFeature()

  useEffect(() => {
    setTemp(_.cloneDeep(columns))
    setError("")
  }, [isOpen])

  // a little function to help us with reordering the result
  const reorder = (list: DataGridColumn[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(temp, result.source.index, result.destination.index)
    setTemp(items)
  }

  const handleColumnChange = (res: DataGridColumn) => {
    const result = Array.from(temp)
    var col = result.find((m) => m.id === res.id)
    if (col !== undefined) col.isEnabled = !res.isEnabled
    setTemp(result)
  }

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()

    let enabledColumns = temp.filter((m) => m.isEnabled).length
    if (enabledColumns > 9) {
      setError("Maximum 9 columns can be selected to display. Please unselect few columns and try again.")
      return
    } else if (enabledColumns < 4) {
      setError("Minimum 4 columns needs to be selected to display. Please select few columns and try again.")
      return
    }

    onChange(temp)
    onClose()
  }

  return (
    <>
      <DndModal title="Column Setting" isOpen={isOpen} onClose={onClose}>
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <form onSubmit={handleSubmit}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="vertical-list">
                {(provided) => (
                  <ul
                    role="presentation"
                    className="divide-y divide-gray-200"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {temp.map((column, index) => (
                      <Conditional
                        key={column.id}
                        when={column.featureName === undefined || enabledFeatures?.[column.featureName]}
                      >
                        <Draggable draggableId={column.id} index={index}>
                          {(provided, snapshot) => (
                            <li
                              key={column.name}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="bg-white"
                              style={{
                                border: snapshot.isDragging ? "1px solid rgb(29 81 166)" : "",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div className="block hover:bg-gray-50 hover:cursor-move">
                                <div className="flex items-center p-2">
                                  <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4">
                                      <div>
                                        <div className="text-sm text-gray-700">
                                          <InputGroupCheckbox
                                            name={column.name}
                                            label={column.name}
                                            value={column.isEnabled}
                                            onChange={() => handleColumnChange(column)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {" "}
                                      <i className="flex-shrink-0 mr-5 text-gray-400 fas fa-grip-lines" aria-hidden="true" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                        </Draggable>
                      </Conditional>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

            {error && <ErrorInline className="px-5">{error}</ErrorInline>}
            <div className="px-6 pb-6 pt-3">
              <InputGroupButton />
            </div>
          </form>
        </div>
      </DndModal>
    </>
  )
}

export default ListColumnSettingModal
