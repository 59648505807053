import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { SequenceNumbers, UpdateSequenceNumberInput } from "models/finance/sequenceNumber"

function useSequenceNumber() {
  return useQuery(keys.sequenceNumberQuery, fetchSequenceNumbers, {
    select: (data: Response<SequenceNumbers>) => data.data.data.sequenceNumbers,
    cacheTime: 0,
  })
}

function fetchSequenceNumbers() {
  return graphQLClient.fetch(query.sequenceNumberQuery, null, true, common.accountingApiUrl, keys.sequenceNumberQuery)
}

function updateSequenceNumber(input: UpdateSequenceNumberInput) {
  return graphQLClient.mutation(mutation.updateSequenceNumber, input, "UpdateSequenceNumber", common.accountingApiUrl)
}

function useUpdateSequenceNumber() {
  return useMutation(updateSequenceNumber)
}

export { useSequenceNumber, useUpdateSequenceNumber }
