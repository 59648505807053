import React from "react"
import { NavLink } from "react-router-dom"
import { A, ButtonPrimary, ButtonSecondarySmall } from "library/styled/styled"

interface Props {
  children: React.ReactNode
  isList?: boolean
  title: string
  buttonText: string
  action: any
  showAsLink?: boolean
  hasButton?: boolean
  closeButton?: boolean
  onClose?: () => void
  settingIcon: boolean
  settingAction: () => void
  showNavLink: boolean
  SecondaryButton?: JSX.Element
}

const List = ({
  children,
  isList = false,
  title,
  buttonText = "Add",
  action,
  showAsLink = false,
  hasButton = true,
  closeButton = false,
  onClose,
  settingIcon = false,
  settingAction,
  showNavLink = false,
  SecondaryButton,
}: Props) => {
  return (
    <div className={`mx-auto`}>
      <div className="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 rounded-t-md shadow ring-1 ring-black ring-opacity-5">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          </div>
          {hasButton && (
            <div className="ml-4 mt-2 flex-shrink-0 space-x-4">
              {!showAsLink && !showNavLink && (
                <ButtonPrimary onClick={action}>
                  <i className="fas fa-plus mr-2"></i>
                  {buttonText}
                </ButtonPrimary>
              )}
              {SecondaryButton && SecondaryButton}
              {showNavLink && (
                <NavLink to={action}>
                  <ButtonSecondarySmall>
                    <i className="fas fa-plus mr-2"></i>
                    {buttonText}
                  </ButtonSecondarySmall>
                </NavLink>
              )}
              {showAsLink && <A onClick={action}>{buttonText}</A>}
              {settingIcon && (
                <button
                  onClick={settingAction}
                  type="button"
                  className="ml-4 inline-flex rounded-md border border-transparent p-2 text-sm hover:bg-gray-200 float-right"
                >
                  <i className="fas fa-gear text-gray-400"></i>
                </button>
              )}
            </div>
          )}
          {closeButton && (
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                onClick={onClose}
                type="button"
                className="inline-flex rounded-md border border-transparent p-2 text-sm hover:bg-gray-200 float-right"
              >
                <i className="fas fa-xmark-large text-gray-400"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col shadow ring-1 ring-black ring-opacity-5 rounded-b-md">
        <div className="overflow-x-auto">
          <div className="block min-w-full align-middle">{children}</div>
        </div>
      </div>
    </div>
  )
}

export { List }
