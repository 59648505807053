import { DataGridColumn } from "models/shared/column"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import { ColumnType } from "constants/columns"

export const invoiceColumns: DataGridColumn<Invoice>[] = [
  {
    id: "1",
    name: "Invoice Number",
    isEnabled: true,
    keys: ["invoiceNumber"],
    order: 1,
    columnTypeId: ColumnType.LabelId.Id
  },
  {
    id: "2",
    name: "Client",
    isEnabled: true,
    keys: ["client", "name"],
    order: 2,
    columnTypeId: ColumnType.Text.Id,
    toolTip: {
      tooltipDisplayThreshold: 15,
      maxTooltipCharacters: 350
    }
  },
  {
    id: "3",
    name: "Invoice Date",
    isEnabled: true,
    keys: ["invoiceDateTimeUtc"],
    order: 3,
    columnTypeId: ColumnType.Date.Id
  },
  {
    id: "4",
    name: "Due Date",
    isEnabled: true,
    keys: ["dueDateTimeUtc"],
    order: 4,
    columnTypeId: ColumnType.Date.Id
  },
  {
    id: "5",
    name: "Created On",
    isEnabled: true,
    keys: ["insertedDateTimeUtc"],
    order: 5,
    columnTypeId: ColumnType.Date.Id
  },
  {
    id: "6",
    name: "Status",
    isEnabled: true,
    keys: ["invoiceStatusName"],
    helperKey: "invoiceStatusReference",
    order: 6,
    columnTypeId: ColumnType.Status.Id
  },
  {
    id: "7",
    name: "Due Amount",
    isEnabled: true,
    keys: ["amountDue"],
    order: 7,
    columnTypeId: ColumnType.CurrencyFormat.Id,
    helperKey: "currencyCode",
    className: "currency-col"
  },
  {
    id: "8",
    name: "Paid Amount",
    isEnabled: true,
    keys: ["totalPaidAmount"],
    order: 8,
    columnTypeId: ColumnType.CurrencyFormat.Id,
    helperKey: "currencyCode",
    className: "currency-col"
  },
  {
    id: "9",
    name: "Total Amount",
    isEnabled: true,
    keys: ["totalAmount"],
    order: 9,
    columnTypeId: ColumnType.CurrencyLabel.Id,
    helperKey: "currencyCode",
    className: "currency-col"
  },
  {
    id: "10",
    name: "Message for Client",
    isEnabled: false,
    keys: ["description"],
    order: 10,
    columnTypeId: ColumnType.Description.Id
  },
  {
    id: "11",
    name: "Payment Instruction for Client",
    isEnabled: false,
    keys: ["paymentTerms"],
    order: 11,
    columnTypeId: ColumnType.Description.Id
  },
  {
    id: "12",
    name: "Tax Type",
    isEnabled: false,
    keys: ["taxTypeName"],
    order: 12,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "13",
    name: "Reference",
    isEnabled: false,
    keys: ["additionalReference"],
    order: 13,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "14",
    name: "Discount Amount",
    isEnabled: false,
    keys: ["totalDiscountAmount"],
    order: 14,
    columnTypeId: ColumnType.CurrencyFormat.Id,
    helperKey: "currencyCode",
    className: "currency-col"
  },
  {
    id: "15",
    name: "Tax",
    isEnabled: false,
    keys: ["totalTaxAmount"],
    order: 15,
    columnTypeId: ColumnType.CurrencyFormat.Id,
    helperKey: "currencyCode",
    className: "currency-col"
  },
  {
    id: "16",
    name: "Sent to Client",
    isEnabled: false,
    keys: ["isSent"],
    order: 16,
    columnTypeId: ColumnType.Boolean.Id
  },
  {
    id: "17",
    name: "Disputed",
    isEnabled: false,
    keys: ["isDisputed"],
    order: 17,
    columnTypeId: ColumnType.Boolean.Id
  },
  {
    id: "18",
    name: "Sale Staff",
    isEnabled: false,
    keys: ["saleStaff", "name"],
    order: 18,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "19",
    name: "Currency",
    isEnabled: false,
    keys: ["currencyCode"],
    order: 19,
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "20",
    name: "Created By",
    isEnabled: false,
    keys: ["insertedBy"],
    order: 20,
    columnTypeId: ColumnType.Text.Id
  }
]
