import React, { useEffect, useState } from "react"
import { usePayment, useRemovePayment } from "components/payment/services/paymentService"
import { CurrencyFormatLabel } from "library/styled/description-list"
import { useMetadata } from "context/metadata-context"
import { handlePopupError, toLocaleDate } from "common"
import { entityTypes } from "constants/entityTypes"
import EditPaymentModal from "components/payment/02_editPayment/editPaymentModal"
import { useModalState } from "utils/modalStates"
import { Payment, RemovePaymentInput } from "components/payment/05_sharedModel/paymentModel"
import { WarningAlert } from "library/styled/alerts"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import ViewPaymentModal from "components/payment/03_paymentDetails/viewPaymentModal"
import ToolTip from "../../../library/styled/tooltip"
import PaymentPdfModal from "../03_paymentDetails/paymentPdfModal"

interface Props {
  invoiceReference: string
  clientReference: string
}

const InvoicePayments = ({ invoiceReference, clientReference }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { organisation } = useMetadata()
  const { data: payments } = usePayment(enabled, {
    entityReference: invoiceReference,
    entityTypeReference: entityTypes.invoice.entityTypeReference
  })
  const { mutate: removePayment, isLoading: isDeleting } = useRemovePayment()
  const [locale, setLocale] = useState<string>()
  const { editModal, setEditModal, viewModal, setViewModal, alert, setAlert } = useModalState<Payment>()
  const queryClient = useQueryClient()
  const [paymentPreview, setPaymentPreview] = useState({ isOpen: false, paymentReference: "" })


  useEffect(() => {
    if (invoiceReference) setEnabled(true)
  }, [invoiceReference])

  useEffect(() => {
    if (organisation?.locale) setLocale(`${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`)
  }, [organisation])

  const handleDelete = (payment: Payment) => {
    const input: RemovePaymentInput = {
      paymentReference: payment.paymentReference,
      concurrencyToken: payment.concurrencyToken
    }
    removePayment(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.invoiceQuery])
        void queryClient.invalidateQueries([
          keys.paymentQuery,
          { entityReference: invoiceReference, entityTypeReference: entityTypes.invoice.entityTypeReference }
        ])
        successToaster(`Payment deleted`)
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }


  const onEdit = () => {
    setViewModal({ ...viewModal, isOpen: false })
    setEditModal({ item: viewModal.item, isOpen: true })
  }

  return (
    <>
      {payments !== undefined && payments.length > 0 && <div className="border border-gray-200"></div>}
      {payments?.map((payment) => (
        <div
          key={payment.paymentReference}
          className="flex justify-between"
        >
          <div className="flex items-center">
            <a
              onClick={() => setViewModal({ item: payment, isOpen: true })}
              className="text-primary-800 underline cursor-pointer hover:text-primary-900"
            >
              Payment - {toLocaleDate(payment.paymentDateTimeUtc)}
            </a>
            <ToolTip text="Preview Payment">
              <i
                onClick={() => {
                  setPaymentPreview({ isOpen: true, paymentReference: payment.paymentReference })
                }}
                id={`preview-payment-${payment.paymentReference}`}
                className="cursor-pointer far fa-eye text-teal-700 ml-2 p-1 rounded-full hover:bg-red-50"
              ></i>
            </ToolTip>
            <ToolTip text="Delete Payment">
              <i
                onClick={() => setAlert({ item: payment, isOpen: true })}
                id={`delete-payment-${payment.paymentReference}`}
                className="cursor-pointer far fa-trash text-red-400 ml-2 p-1 rounded-full hover:bg-red-50"
              ></i>
            </ToolTip>
          </div>

          <a
            onClick={() => setViewModal({ item: payment, isOpen: true })}
            className="text-primary-800 underline cursor-pointer hover:text-primary-900"
          >
            <CurrencyFormatLabel amount={payment?.amount} currencyCode={undefined} localeCode={locale} />
          </a>
        </div>
      ))}

      <ViewPaymentModal
        payment={viewModal.item}
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        clientReference={clientReference}
        onEdit={onEdit}
      />
      <EditPaymentModal
        payment={editModal.item}
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
      />

      <WarningAlert
        title="Payment"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />

      <PaymentPdfModal
        isOpen={paymentPreview.isOpen}
        paymentReference={paymentPreview?.paymentReference ?? ""}
        onClose={() => setPaymentPreview({ isOpen: false, paymentReference: "" })}
      />
    </>
  )
}

export default InvoicePayments
