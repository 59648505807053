export const reportType = {
  invoice: {
    reference: "523F4D78-AC19-47BE-BF2D-4A2BF5429C4E",
    name: "Invoice",
  },
  payment: {
    reference: "2D29F5ED-B346-42B6-B8A4-66F78E2F3B7B",
    name: "Payment",
  },
  outstandingStatement: {
    reference: "01302F6E-D526-48CD-A34A-00A344194C3B",
    name: "Outstanding Statement",
  },
  activityStatement: {
    reference: "7924EA63-E118-443D-97C8-5321D3A78FE4",
    name: "Activity Statement",
  },
}
