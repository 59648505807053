export const emailRecipientType = {
  to: {
    reference: "1CC61D55-1063-436D-8412-363AEC71340B",
    name: "To",
  },
  bcc: {
    reference: "BF217687-CA14-43C9-A54E-63F9C752A3F5",
    name: "Bcc",
  },
}
