import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { AddCurrencyInput, RemoveCurrencyInput, Currencies, UpdateCurrencyInput, ExchangeRates } from "models/finance/currency"

function useCurrency() {
  return useQuery(keys.currencyQuery, fetchCurrencies, {
    select: (data: Response<Currencies>) => data.data.data.currencies,
  })
}

function fetchCurrencies() {
  return graphQLClient.fetch(query.currencyQuery, null, true, common.accountingApiUrl, keys.currencyQuery)
}

function addCurrency(input: AddCurrencyInput) {
  return graphQLClient.mutation(mutation.addCurrency, input, "AddCurrency", common.accountingApiUrl)
}

function useAddCurrency() {
  return useMutation(addCurrency)
}

function updateCurrency(input: UpdateCurrencyInput) {
  return graphQLClient.mutation(mutation.updateCurrency, input, "UpdateCurrency", common.accountingApiUrl)
}

function useUpdateCurrency() {
  return useMutation(updateCurrency)
}

function removeCurrency(input: RemoveCurrencyInput) {
  return graphQLClient.mutation(mutation.removeCurrency, input, "RemoveCurrency", common.accountingApiUrl)
}

function useRemoveCurrency() {
  return useMutation(removeCurrency)
}

function useExchangeRate() {
  return useQuery(keys.exchangeRateQuery, fetchExchangeRates, {
    select: (data: Response<ExchangeRates>) => data.data.data.exchangeRates,
  })
}

function fetchExchangeRates() {
  return graphQLClient.fetch(query.exchangeRateQuery, null, true, common.organisationApiUrl, keys.exchangeRateQuery)
}

export { useCurrency, useAddCurrency, useUpdateCurrency, useRemoveCurrency, useExchangeRate }
