import { graphQLClient } from "shared/graphql/graphQLClient"
import { fileGql } from "./fileGql"
import { common } from "common"
import { keys } from "constants/keys"
import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { DownloadLinkInput, DownloadLinkResponse, ImageQueryInput, ImageQueryRoot } from "./fileModelGql"
import { Response } from "models/shared/response"
import { localStorageService } from "shared/services/local-storage"
import axios from "axios"
import { mutation } from "shared/graphql/mutations"
import { FileQueryInput, Files, UpdateFileInput } from "models/file/file"
import { ReactQueryParams } from "models/shared/common"
import { query } from "shared/graphql/queries"
import { getUserSession } from "../../utils/extensions"

function useFiles(input: FileQueryInput) {
  const queryClient = useQueryClient()
  return useInfiniteQuery([keys.fileQuery, input], fetchFiles, {
    getNextPageParam: (lastPage: Response<Files>) => {
      if (lastPage?.data.data.files.pageInfo.hasNextPage) {
        return lastPage?.data.data.files.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Files>>) => {
      for (const file of data.pages.flatMap((m) => m.data.data.files.edges)) {
        // we have to set cache in the same way we will get the data from server
        let axiosResponse: Response<Files> = {
          config: {},
          headers: {},
          status: 200,
          statusText: "",
          data: {
            data: {
              files: {
                edges: [file],
                pageInfo: { endCursor: "", hasNextPage: false, hasPreviousPage: false, startCursor: "" }
              }
            }
          }
        }
        queryClient.setQueryData([keys.fileDetailQuery, { fileReference: file.node.fileReference }], axiosResponse)
      }

      return data
    }
  })
}

const fetchFiles = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.fileQuery, input, true, common.fileApiUrl, keys.fileQuery)
}

function fetchFileDetails(fileReference?: string) {
  return graphQLClient.fetch(query.fileQuery, { fileReference }, true, common.fileApiUrl, keys.fileQuery)
}

function useFileDetail(enabled: boolean, fileReference?: string) {
  return useQuery([keys.fileDetailQuery, { fileReference }], () => fetchFileDetails(fileReference), {
    enabled: enabled,
    select: (response: Response<Files>) => {
      return response.data.data.files.edges.map((m) => m.node)[0]
    }
  })
}

function fetchImages(input: ImageQueryInput) {
  return graphQLClient.fetch(fileGql.imageQuery, input, true, common.fileApiUrl, keys.imageQuery)
}

function useImages(input: ImageQueryInput) {
  return useQuery([keys.imageQuery, input], () => fetchImages(input), {
    select: (response: Response<ImageQueryRoot>) => response.data.data
  })
}

const uploadImage = async (input: any) => {
  const userSession = await getUserSession()
  // when uploading a file we have to send the file details via headers.
  const authorization = userSession?.token_type + " " + userSession?.access_token
  const headers = {
    authorization: authorization,
    "x-organisation": localStorageService.get(common.localStorageActiveOrganisationKey),
    "x-http-400-unsuccessful-request": "true"
  }

  return axios.post(common.fileUploadAPI, input, {
    headers: headers
  })
}

const useUploadImage = () => {
  return useMutation(uploadImage)
}

function downloadLink(input: DownloadLinkInput) {
  return graphQLClient.mutation<DownloadLinkResponse>(mutation.downloadLink, input, "DownloadLink", common.fileApiUrl)
}

function useDownloadFileLink() {
  return useMutation(downloadLink)
}

function updateFile(input: UpdateFileInput) {
  return graphQLClient.mutation(mutation.updateFile, input, "UpdateFile", common.fileApiUrl)
}

function useUpdateFile() {
  return useMutation(updateFile)
}

export { useFiles, useFileDetail, useImages, useUploadImage, useDownloadFileLink, useUpdateFile }
