import * as React from "react"
import { Internationalization } from "@syncfusion/ej2-base"
import { toLocaleDateTime } from "common"
import { AddressView } from "library/styled/description-list"
import { Dd } from "library/styled/styled"
import { format, isSameDay } from "date-fns"
import { RefObject } from "react"
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule"
import { isProvided } from "utils/extensions"

const instance = new Internationalization()

const DateHeaderTemplate = (props: any, onDateClick: (date: Date) => void) => {
  const getDateHeaderText = (value: Date) => {
    return instance.formatDate(value, { skeleton: "Ed" })
  }

  return (
    <div onClick={() => onDateClick(props.date)} className="text-center hover:underline cursor-pointer">
      {getDateHeaderText(props.date)}
    </div>
  )
}

const ResourceHeaderTemplate = (props: any) => {
  return (
    <div className="template-wrap flex items-center">
      <div>
        <img className="inline-block h-8 w-8 rounded-full" src={props.resourceData.profileImageUrl} alt="" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{props.resourceData.fullName}</p>
      </div>
    </div>
  )
}

const UnassignedJobTemplate = (props: any) => {
  return (
    <div className="w-full flex items-center justify-between px-2 py-3 hover:bg-gray-100">
      <div className="flex flex-col truncate">
        <div className="flex items-center space-x-3">
          <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs leading-4 font-medium bg-blue-300 rounded">
            {props.jobNumber}
          </span>
          <h3 className="text-blue-800 text-xm leading-5 truncate">
            {getDateFormat(props.startDateTimeUtc, props.endDateTimeUtc)}
          </h3>
        </div>
        <p className="text-gray-700 text-xs font-medium leading-5 truncate">
          {props.address !== null &&
            props.address?.route &&
            `${props.address?.route} ${props.address?.city} ${props.address?.regionName} ${props.address?.postalCode} ${props.address?.countryName}`}
        </p>
        <div className="text-gray-400 text-xs leading-5 truncate">{props.description}</div>
      </div>
    </div>
  )
}

function getDateFormat(startDateStr: string, endDateStr: string) {
  const start = new Date(startDateStr)
  const end = new Date(endDateStr)

  if (isSameDay(start, end)) {
    return `${format(start, "d MMM")} (${format(start, "h:mm aaa")} - ${format(end, "h:mm aaa")})`
  }

  return `${format(start, "d")} - ${format(end, "d MMM")} (${format(start, "h:mm aaa")} - ${format(end, "h:mm aaa")})`
}

const EventTemplate = (props: any, title: string) => {
  return (
    <div
      className="inset-0 flex flex-col h-full rounded-[3px] px-2 py-1.5 text-xs leading-5"
      style={{
        border: `2px solid ${props.Color}`,
        background: props.Color + 15,
      }}
    >
      <p className="order-1 font-medium text-gray-700 pl-1" style={{ borderLeft: `2px solid ${props.Color}` }}>
        {getEventTitle(props, title)}
      </p>
    </div>
  )
}

const getEventTitle = (props: any, title: string) => {
  if (props.ExtendedProps.eventType === "Job") {
    if (title === "JobNumber") return `${props.Subject} - ${props.ExtendedProps?.jobMetadata.stateName}`
    else if (title === "Description") return getTitle(props.ExtendedProps?.jobMetadata?.description, props)
    else if (title === "Route") return getTitle(props.ExtendedProps?.jobMetadata?.addressRoute, props)
    else if (title === "City") return getTitle(props.ExtendedProps?.jobMetadata?.addressCity, props)
    else if (title === "Region") return getTitle(props.ExtendedProps?.jobMetadata?.addressRegionName, props)
    else if (title === "PostCode") return getTitle(props.ExtendedProps?.jobMetadata?.addressPostalCode, props)
  } else return `${props.Subject} - ${props.ExtendedProps?.leaveMetadata.leaveStatusName}`
}

const getTitle = (value: string, props: any) => {
  if (isProvided(value)) return value
  return `${props.Subject} - ${props.ExtendedProps?.jobMetadata.stateName}`
}

const QuickInfoHeaderTemplate = (props: any, scheduleRef: RefObject<ScheduleComponent>) => {
  return (
    <>
      <div className="bg-blue_grey-100">
        <div className="flex flex-row justify-between p-3">
          <div className="bg-blue-200 text-primary-900 font-medium px-2 py-2 rounded-md">{props.Subject}</div>

          <button onClick={() => scheduleRef.current?.closeQuickInfoPopup()} className="px-2 py-1 rounded-md hover:bg-gray-200 ">
            <i className="far fa-times text-gray-500 fa-lg"></i>
          </button>
        </div>
        <div className="text-sm px-3 py-1">
          <i className="far fa-business-time fa-lg text-gray-600"></i> {toLocaleDateTime(props.StartTime)} -{" "}
          {toLocaleDateTime(props.EndTime)}
        </div>

        <div className="flex items-center justify-between py-1 px-3">
          <div className="text-sm text-primary-800">
            <a className="update-job hover:text-primary-900 cursor-pointer">View</a>
            <a className="update-job pl-1 hover:text-primary-900 cursor-pointer">| Edit</a>
            <a className="update-job pl-1 hover:text-primary-900 cursor-pointer">| Activity</a>
          </div>
          <div className="ml-2 flex-shrink-0 flex">
            {props.ExtendedProps.eventType === "Job" ? (
              <div
                id="state"
                className="inline-flex border-2 border-solid border-transparent text-xs cursor-pointer text-gray-900 rounded-full px-2.5 py-0.5"
                style={{ background: props.Color + 30 }}
              >
                {props.ExtendedProps.jobMetadata.stateName}
              </div>
            ) : (
              <div
                id="state"
                className="inline-flex border-2 border-solid border-transparent text-xs cursor-pointer text-gray-900 rounded-full px-2.5 py-0.5"
                style={{ background: props.Color + 30 }}
              >
                {props.ExtendedProps.leaveMetadata.leaveStatusName}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
const QuickInfoFooterTemplate = (props: any) => {
  return (
    <>
      <div className="p-3 flex">
        <div className="flex-shrink-0 pt-1.5">
          <i className="far fa-user fa-lg" aria-hidden="true"></i>
        </div>
        <div className="ml-2">
          <div className="text-xs text-gray-400">Created By</div>
          <div className="text-xs text-gray-500">
            {props.ExtendedProps.insertedBy} | {toLocaleDateTime(props.ExtendedProps.insertedDateTimeUtc)}
          </div>
        </div>
      </div>
    </>
  )
}

export {
  DateHeaderTemplate,
  ResourceHeaderTemplate,
  UnassignedJobTemplate,
  EventTemplate,
  QuickInfoFooterTemplate,
  QuickInfoHeaderTemplate,
}
