import React, { useEffect, useState } from "react"
import { TabComponent } from "@syncfusion/ej2-react-navigations"
import ItemOverview from "./overview/itemOverview"
import { useItemDetail, useRemoveItem } from "../itemService"
import { handlePopupError } from "common"
import { useQueryClient } from "react-query"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { WarningAlert } from "library/styled/alerts"
import { useModalState } from "utils/modalStates"
import { Item } from "../04_itemSharedModel/itemModel"
import { RemoveItemInput } from "../04_itemSharedModel/inputModels"
import { HamburgerMenu, MenuOption } from "library/dropdownMenu/hamburgerMenu"
import AddOrEditItemPage from "../01_addOrEditItem/addOrEditItemPage"

interface Props {
  itemReference?: string
}

const ItemDetailTabs = ({ itemReference }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: item } = useItemDetail(enabled, itemReference)
  const { mutate: removeItem, isLoading: isDeleting } = useRemoveItem()
  const { editModal: editItemModal, setEditModal: setEditItemModal, alert, setAlert } = useModalState<Item>()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (itemReference) {
      setEnabled(true)
    }
  }, [itemReference])

  const handleDelete = () => {
    const input: RemoveItemInput = {
      itemReference: item?.itemReference ?? ""
    }
    removeItem(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.itemQuery])
        successToaster(`Item ${item?.name} deleted`)
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }

  const menuOptions: MenuOption[] = [
    {
      name: "Delete",
      iconClass: "far fa-trash text-red-600",
      action: () => setAlert({ item: item, isOpen: true })
    }
  ]

  return (
    <>
      <AddOrEditItemPage
        isOpen={editItemModal.isOpen}
        onClose={() => setEditItemModal({ ...editItemModal, isOpen: false })}
        item={editItemModal.item}
        isEditMode={true}
      />

      <TabComponent id="defaultTab" selectedItem={0}
                    animation={{ next: { effect: "None" }, previous: { effect: "None" } }}>
        <div className="e-tab-header">
          <div className={"pl-3"}> Overview</div>
        </div>
        <div className="e-content">
          <div>
            <ItemOverview item={item} />
          </div>
        </div>
      </TabComponent>

      <div className="absolute top-1 right-20">
        <button
          className="px-2 py-0.5 rounded-md text-sky-700 hover:bg-sky-100"
          onClick={() => setEditItemModal({ item: item, isOpen: true })}
        >
          <i className="far fa-pen-to-square h-4"></i>
        </button>
      </div>

      <div className="absolute top-2 right-6">
        <HamburgerMenu options={menuOptions} />
      </div>

      <WarningAlert
        title="Item"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />
    </>
  )
}

export default ItemDetailTabs
