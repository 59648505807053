import { InfiniteData, useInfiniteQuery, useMutation, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Client, ClientQueryInput, Clients, UpdateClientInput } from "models/people/client"
import { ReactQueryParams } from "models/shared/common"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { ClientKeyValue } from "../../library/components/clientSelectionMultiComponent"

//#region Clients
function useClient(input: ClientQueryInput) {
  const result = useInfiniteQuery([keys.clientQuery, input], fetchClients, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.clients.pageInfo.hasNextPage) {
        return lastPage?.data.data.clients.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Clients>>) => setClientData(data),
  })
  return { ...result, clients: clients }
}

const fetchClients = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.clientQuery, input, true, common.peopleApiUrl, keys.clientQuery)
}

let clients: Client[] = []
function setClientData(data: InfiniteData<Response<Clients>>) {
  clients = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.clients.edges.map((item) => item.node)
    clients = clients.concat(pageData)
  })
}

function useSearchClient(input: ClientQueryInput) {
  return useQuery([keys.clientSearchQuery, input], () => searchClients(input), {
    select: (data: Response<Clients>) =>
      data.data.data.clients.edges.map(
        (m) =>
          new ClientKeyValue(
            m.node.clientReference,
            m.node.primaryContact?.givenName + " " + m.node.primaryContact?.familyName,
            m.node.primaryContact?.emailAddress
          )
      ),
  })
}

function searchClients(input: ClientQueryInput) {
  return graphQLClient.fetch(query.clientQuery, input, true, common.peopleApiUrl, keys.clientQuery)
}
//#endregion

//#region getOrAddDraftClient
function getOrAddDraftClient() {
  return graphQLClient.mutation(mutation.getOrAddDraftClient, {}, "GetOrAddDraftClient", common.peopleApiUrl)
}

function useGetOrAddDraftClient() {
  return useMutation(getOrAddDraftClient)
}
//#endregion

//#region update
function updateClient(input: UpdateClientInput) {
  return graphQLClient.mutation(mutation.updateClient, input, "UpdateClient", common.peopleApiUrl)
}

function useUpdateClient() {
  return useMutation(updateClient)
}
//#endregion

export { useClient, useGetOrAddDraftClient, useUpdateClient, useSearchClient }
