import React from "react"

interface Props {
  when: boolean | undefined
  children: React.ReactNode
}

const Conditional = ({ when, children }: Props) => {
  return <>{when && children}</>
}
export default Conditional
