import { DataGridColumn } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { File } from "models/file/file"

export const documentColumns: DataGridColumn<File>[] = [
  {
    id: "1",
    name: "Name",
    isEnabled: true,
    keys: ["fileName"],
    order: 1,
    columnTypeId: ColumnType.FileNameIcon.Id,
    toolTip: {
      tooltipDisplayThreshold: 15,
      maxTooltipCharacters: 350
    }
  },
  {
    id: "2",
    name: "Description",
    isEnabled: true,
    keys: ["description"],
    order: 2,
    columnTypeId: ColumnType.Description.Id,
    toolTip: {
      tooltipDisplayThreshold: 15,
      maxTooltipCharacters: 350
    }
  },
  {
    id: "3",
    name: "Size",
    isEnabled: true,
    keys: ["sizeInBytes"],
    order: 3,
    columnTypeId: ColumnType.FileSize.Id
  },
  {
    id: "4",
    name: "Tags",
    isEnabled: true,
    keys: ["tags"],
    helperKey: "name",
    order: 4,
    columnTypeId: ColumnType.Tags.Id
  },
  {
    id: "5",
    name: "Uploaded on",
    isEnabled: true,
    keys: ["insertedDateTimeUtc"],
    order: 5,
    columnTypeId: ColumnType.DateTime.Id
  },
  {
    id: "6",
    name: "Uploaded By",
    isEnabled: true,
    keys: ["insertedBy"],
    order: 6,
    columnTypeId: ColumnType.Text.Id
  }
]
