import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import "./site.css"
import "rc-tooltip/assets/bootstrap.css"
import { registerLicense } from "@syncfusion/ej2-base"
import { QueryClient, QueryClientProvider } from "react-query"
import { FullPageLoading } from "library/styled/styled"
import { BrowserRouter as Router } from "react-router-dom"
import App from "app"
import { ToastContainer } from "react-toastify"
import { ReactQueryDevtools } from "react-query/devtools"
import { applicationInsights, common, handlePopupError } from "common"
import { AuthProvider, AuthProviderProps } from "react-oidc-context"
import { User, WebStorageStateStore } from "oidc-client-ts"
import { getPreviousPath } from "utils/extensions"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { createBrowserHistory } from "history"

registerLicense("Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfcnRQQmRZVkVyW0Y=")

const browserHistory = createBrowserHistory()
var reactPlugin = new ReactPlugin()
var appInsights = new ApplicationInsights({
  config: {
    connectionString: applicationInsights,
    extensions: [reactPlugin],
    // disableXhr: false,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})
appInsights.loadAppInsights()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error: any) => handlePopupError(error.response),
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry(failureCount, error: any) {
        if (error.status === 404) return false
        else return failureCount < 2
      },
      staleTime: 30 * 60 * 1000, //30 minutes
      cacheTime: 30 * 60 * 1000,
    },
  },
})

const onSigninCallback = (_user: User | void): void => {
  const previousPath = getPreviousPath()
  console.log(`index.tsx -> onSigninCallback -> previousPath: ${previousPath}`)

  window.history.replaceState({}, document.title, window.location.pathname)

  if (previousPath) {
    window.location.replace(previousPath)
  }
}

const oidcConfig: AuthProviderProps = {
  authority: common.identityServerAuthorityUrl,
  client_id: "js_oidc",
  redirect_uri: window.location.origin,
  onSigninCallback: onSigninCallback,
  response_type: "code",
  scope: "graphql-api offline_access"
}

ReactDOM.render(
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <React.Suspense fallback={<FullPageLoading />}>
      <AuthProvider
        {...oidcConfig}
        userStore={
          new WebStorageStateStore({
            store: globalThis?.window?.localStorage,
          })
        }
      >
        <Router>
          <App />
          <ToastContainer />
        </Router>
      </AuthProvider>
    </React.Suspense>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
)
