import React from "react"
import { Disclosure, Transition } from "@headlessui/react"
import { ButtonSecondarySmall } from "library/styled/styled"

interface Props {
  title: string
  openByDefault?: boolean
  action?: () => void
  linkText?: string
  showLink?: boolean
  children: React.ReactNode
  showEditIcon?: boolean
  width?: string
}

const Accordian = ({
  title,
  openByDefault = false,
  action,
  linkText = "Create New",
  showLink = true,
  children,
  showEditIcon = false,
  width,
}: Props) => {
  return (
    <div className={`px-4 pt-4 sm:px-6 mx-auto ${width ?? "max-w-3xl sm:max-w-4xl"}`}>
      <Disclosure as="div" defaultOpen={openByDefault}>
        {({ open }) => (
          <>
            <div
              className={`bg-white px-4 py-2 border-b border-gray-200 sm:px-6 shadow ring-1 ring-black ring-opacity-5 w-full ${
                open ? "rounded-t-md" : "rounded-md"
              }`}
            >
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <Disclosure.Button className={`ml-4 mt-2 inline-flex w-[calc(100%-250px)]`}>
                  <div className="p-2">
                    <i className={`fas fa-chevron-${open ? "up" : "down"}`} />
                  </div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 my-auto pl-2">{title}</h3>
                </Disclosure.Button>

                <div className="mt-2 flex">
                  {showLink && open && (
                    <ButtonSecondarySmall onClick={action}>
                      {showEditIcon && <i className="fas fa-pencil mr-2"></i>}
                      {!showEditIcon && <i className="fas fa-plus mr-2"></i>}
                      {linkText}
                    </ButtonSecondarySmall>
                  )}
                </div>
              </div>
            </div>

            <Transition
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              unmount={false}
            >
              <Disclosure.Panel unmount={false} className="flex flex-col">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-b-md">{children}</div>
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Accordian
