import React, { useState } from "react"
import FilterInputText from "library/observables/filterInputs"
import { ItemListModel } from "./itemListModel"
import SidePanel from "library/styled/sidePanel"
import ItemListAdvanceFilters from "./itemListAdvanceFilters"
import InputGroupDropdown from "library/observables/inputGroupDropdown"
import { itemTypes } from "constants/itemTypes"

interface Props {
  itemListModel: ItemListModel
  onFilterDataUpdate?: () => void
}

const ItemListFilters = ({ itemListModel, onFilterDataUpdate }: Props) => {
  const [sidePanel, setSidePanel] = useState(false)
  return (
    <>
      <div className="flex row-auto grid grid-cols-5 gap-4">
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <FilterInputText name="Name" value={itemListModel.itemNameSearch} placeholder="Filter by Item Name" />
        </div>
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <InputGroupDropdown
            name="ItemType"
            options={itemTypes}
            value={itemListModel.itemType}
            isSearchable={true}
            isClearable={true}
            type="filter"
            placeholder="Filter by Item Type"
          />
        </div>
      </div>

      {/*<button*/}
      {/*  onClick={() => setSidePanel(true)}*/}
      {/*  type="button"*/}
      {/*  className="filter-btn bg-white text-gray-400 shadow-2xl focus:outline-none hover:bg-gray-200 hover:text-gray-500"*/}
      {/*>*/}
      {/*  <i className="fal fa-filter text-gray-500 ml-3" aria-hidden="true"></i>{" "}*/}
      {/*  <span className="text-sm text-gray-500">More Filters</span>*/}
      {/*</button>*/}

      {/*<SidePanel*/}
      {/*  title="Item Filters"*/}
      {/*  open={sidePanel}*/}
      {/*  onClose={() => setSidePanel(false)}*/}
      {/*  body={<ItemListAdvanceFilters itemListModel={itemListModel} />}*/}
      {/*  type="filterPanel"*/}
      {/*/>*/}
    </>
  )
}

export default ItemListFilters
