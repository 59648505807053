import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { ErrorInline } from "library/styled/styled"
import { ItemSearch, ItemSearchTax } from "../../items/itemModelGql"
import { debounce } from "lodash"
import { useItemSearch } from "../../items/itemService"
import Select, { MenuListProps, components } from "react-select"
import { isEmpty, isProvided } from "../../../utils/extensions"
import AddOrEditItemPage from "../../items/01_addOrEditItem/addOrEditItemPage"
import { useTax } from "hooks/finance/tax"
import { AddOrUpdateItemInput } from "components/items/04_itemSharedModel/inputModels"

export class ItemSearchObservable {
  itemReference?: string
  itemCode?: string
  itemName?: string
  itemDescription?: string
  itemSalePrice?: number
  saleTax?: ItemSearchTax
  purchaseTax?: ItemSearchTax
  errorMessage?: string

  constructor(itemReference?: string, itemName?: string) {
    makeObservable(this, {
      itemName: observable,
      itemReference: observable,
      errorMessage: observable,
    })
    this.itemReference = itemReference
    this.itemName = itemName
  }
}

interface Props {
  id?: string
  itemSearchObservable: ItemSearchObservable
  onChange?: (value: ItemSearch) => void
  onBlur?: () => void
}

const inputStyle2 = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    fontSize: 14,
    borderRadius: 0,
    //shadow: 0,
    borderColor: state.isFocused ? "#ffffff" : "#ffffff",
    "&:hover": {
      borderColor: state.isFocused ? "#c43a3a" : "#bd1770",
    },
    border: 1,
    // The following disables all border colors on element
    boxShadow: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    // Removes blue square border around typing indicator
    "input:focus": {
      boxShadow: "none",
    },
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14,
    width: 400,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#6b7280",
  }),
}

const LineItemSelection = (props: Props) => {
  const [input, setInput] = useState({ searchTerm: "", includeTax: true, storageReference: "" })
  const { data, isLoading, refetch } = useItemSearch(input)
  const [showAddItem, setShowAddItem] = useState(false)
  const [saleTax, setSaleTax] = useState(Object)
  const { data: taxes } = useTax()

  const handleInput = (inputValue: string) => debouncedSearch(inputValue)

  const debouncedSearch = useRef(
    debounce((searchQuery: string) => {
      setInput({ searchTerm: searchQuery.trim(), includeTax: true, storageReference: "" })
    }, 1000)
  ).current

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (saleTax) {
      runInAction(() => {
        props.itemSearchObservable.saleTax = saleTax
      })
    }
  }, [saleTax])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  function getValue(): ItemSearch | undefined {
    if (isEmpty(props.itemSearchObservable.itemReference)) return undefined

    return {
      itemReference: props.itemSearchObservable.itemReference,
      itemCode: props.itemSearchObservable.itemCode,
      itemDescription: props.itemSearchObservable.itemDescription,
      itemName: props.itemSearchObservable.itemName,
      purchaseTax: undefined,
      saleTax: undefined,
      salePrice: props.itemSearchObservable.itemSalePrice,
    }
  }

  function onCreateNewItem() {
    setShowAddItem(true)
  }

  //Called when a new item is successfully created.
  const onItemCreated = (item: AddOrUpdateItemInput) => {
    let filteredTax = taxes?.find((m) => m.taxReference === item?.sale?.taxReference)
    setSaleTax({
      effectiveRate: filteredTax?.effectiveRate,
      name: filteredTax?.name,
      taxReference: filteredTax?.taxReference,
    })
    runInAction(() => {
      props.itemSearchObservable.itemReference = item.itemReference
      props.itemSearchObservable.itemCode = item.itemCode
      props.itemSearchObservable.itemDescription = item.description
      props.itemSearchObservable.itemSalePrice = item?.sale?.unitPrice
      props.itemSearchObservable.itemName = item.name
      props.itemSearchObservable.saleTax = undefined
      props.itemSearchObservable.purchaseTax = undefined
    })
    if (props.onChange) {
      props.onChange(item as ItemSearch)
    }
  }

  const MenuList = (props: MenuListProps<any, false, any>) => {
    return (
      <components.MenuList {...props} className="!pb-0">
        {props.children}
        <div onClick={onCreateNewItem} className={"pl-3 pt-2 pb-2 sticky bottom-0 bg-white"}>
          {" "}
          <i className="fa-regular fa-plus text-primary-800"></i>{" "}
          <span className={"hover:underline active:text-blue-400 hover:cursor-pointer text-primary-800"}>Create New Item</span>
        </div>
      </components.MenuList>
    )
  }

  return (
    <>
      <AddOrEditItemPage
        isOpen={showAddItem}
        isEditMode={false}
        onItemCreated={onItemCreated}
        onClose={() => {
          refetch()
          setShowAddItem(false)
        }}
      />
      <Select
        classNamePrefix={props.id}
        isMulti={false}
        inputId={"lineItemSelection"}
        options={data}
        styles={inputStyle2}
        menuPlacement={"top"}
        value={getValue()}
        getOptionLabel={(option) => option.itemName ?? ""}
        getOptionValue={(option) => option.itemReference ?? ""}
        placeholder="Items"
        isClearable={true}
        isLoading={isLoading}
        onBlur={(event) => {
          runInAction(() => {
            if (props.onBlur) {
              props.onBlur()
            }
          })
        }}
        onChange={(e) => {
          runInAction(() => {
            props.itemSearchObservable.itemReference = e?.itemReference
            props.itemSearchObservable.itemCode = e?.itemCode
            props.itemSearchObservable.itemDescription = e?.itemDescription
            props.itemSearchObservable.itemSalePrice = e?.salePrice
            props.itemSearchObservable.itemName = e?.itemName
            props.itemSearchObservable.saleTax = e?.saleTax
            props.itemSearchObservable.purchaseTax = e?.purchaseTax
            if (props.onChange) {
              props.onChange(e as ItemSearch)
            }
            if (isProvided(props.itemSearchObservable.errorMessage)) props.itemSearchObservable.errorMessage = undefined
          })
        }}
        onInputChange={handleInput}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, MenuList }}
        noOptionsMessage={() => <h2>No Item Found</h2>}
        menuPosition={"fixed"}
      />
      {props.itemSearchObservable.errorMessage && (
        <ErrorInline data-testid={`${props.id}-error-label`}>{props.itemSearchObservable.errorMessage}</ErrorInline>
      )}
    </>
  )
}

export default observer(LineItemSelection)
