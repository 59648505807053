import React from "react"
import { CreatedView, DescriptionView, TagView, TextView } from "library/styled/description-list"
import { Dl, DlGroup } from "library/styled/styled"
import { File, StaffPermission } from "models/file/file"
import { fileAccess } from "constants/fileAccess"

interface Props {
  file?: File
}

const DocumentOverview = ({ file }: Props) => {
  return (
    <div className="overflow-y-auto side-detail-height">
      <Dl addExtraPadding={false}>
        <TextView label="Name" value={file?.fileName} />
        <TextView label="Size" value={file?.sizeInBytes ? `${(file?.sizeInBytes / 1000000).toString().substring(0, 4)} Mb` : 0} />
        <TagView label="Tags" optionLabel="name" optionValue="tagReference" list={file?.tags} />
        <DescriptionView value={file?.description} />
        <TextView
          cols={6}
          label="Shared With"
          value={getAccessLevelName(file?.accessLevel.accessLevelReference, file?.staffPermissions)}
        />
        <CreatedView createdBy={file?.insertedBy} createdDateTimeUtc={file?.insertedDateTimeUtc} />
        <CreatedView label="Updated By" createdBy={file?.updatedBy} createdDateTimeUtc={file?.updatedDateTimeUtc} />
      </Dl>
    </div>
  )
}

export default DocumentOverview

function getAccessLevelName(reference?: string, permissions?: StaffPermission[]) {
  if (reference === fileAccess.allStaff.reference) return fileAccess.allStaff.name
  if (permissions && permissions.length > 0) return fileAccess.private.name
  return fileAccess.onlyMe.name
}
