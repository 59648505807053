import React, { Fragment, useEffect, useState } from "react"
import { Modal } from "library/styled/modal"
import { EditFileModel } from "components/file/editFile/editFileModel"
import { Form, FormGroup, Table, TBody, Td, Th, THead } from "library/styled/styled"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import { useFileDetail, useUpdateFile } from "components/file/fileService"
import TagInputGroup from "library/tagInputGroup/tagInputGroup"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import SelectInputGroup from "library/selectInputGroup/selectInputGroup"
import { fileAccess, FileAccess, fileAccessList, filePermission } from "constants/fileAccess"
import ToggleSwitch from "library/styled/switch"
import ReactPaginate from "react-paginate"
import { useStaffName } from "hooks/people/staff"
import { StaffName } from "models/people/staffName"
import { observer } from "mobx-react"
import ToggleSwitchInput from "library/toggleSwitchInput/toggleSwitchInput"
import { handleServerError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"

interface Props {
  isOpen: boolean
  onClose: () => void
  fileReference?: string
}

const EditFileModal = ({ isOpen, onClose, fileReference }: Props) => {
  const [model] = useState(() => new EditFileModel())
  const { data: file } = useFileDetail(model.enableQuery, fileReference)
  const { data: staffMembers } = useStaffName(true)
  const { mutate: updateFile, isLoading } = useUpdateFile()
  const queryClient = useQueryClient()

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<StaffName[]>()
  const [pageCount, setPageCount] = useState(0)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 5

  useEffect(() => {
    if (fileReference && isOpen) model.setEnableQuery(true)
  }, [fileReference, isOpen])

  useEffect(() => {
    if (file && isOpen) model.setFileData(file)
    if (file && staffMembers && isOpen) model.setStaffFilePermissions(staffMembers, file.staffPermissions)
  }, [file, staffMembers, isOpen])

  useEffect(() => {
    if (staffMembers) {
      const endOffset = itemOffset + itemsPerPage
      setCurrentItems(staffMembers?.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(staffMembers.length / itemsPerPage))
    }
  }, [itemOffset, itemsPerPage, staffMembers])

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % (staffMembers?.length ?? 1)
    setItemOffset(newOffset)
  }

  const handleSubmit = () => {
    const input = model.getUpdateFileInput(file)

    updateFile(input, {
      onError: (error: any) => {
        model.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.fileQuery)
        successToaster("File updated")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Edit File"
        footer={true}
        action={handleSubmit}
        width="max-w-4xl"
        isButtonLoading={isLoading}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextInputGroup id="fileName" label="Name" value={model.fileName} />

          <TagInputGroup label="Tags" id="tags" value={model.tags} />

          <TextAreaInputGroup label="Description" name="description" value={model.description} />

          <SelectInputGroup<FileAccess>
            label="Shared With"
            id="fileAccess"
            options={fileAccessList}
            optionLabel={"name"}
            optionValue={"name"}
            value={model.fileAccess}
          />
        </Form>

        {model.fileAccess.selectedOption?.name === fileAccess.private.name && (
          <>
            <Table className="table-fixed">
              <THead>
                <tr>
                  <Th className="w-1/3">Name</Th>
                  <Th className="w-1/6">Read</Th>
                  <Th className="w-1/6">Update</Th>
                  <Th className="w-1/6">Delete</Th>
                </tr>
              </THead>
              <TBody>
                {currentItems?.map((item, idx) => (
                  <tr className={`${idx % 2 === 0 ? undefined : "bg-gray-50"}`} key={item.staffReference}>
                    <Td className="w-1/3">
                      <div className="">
                        <img alt="img" src={item.profileImageUrl} className="h-5 w-5 rounded-full inline mr-2"></img>
                        {item.fullName}
                      </div>
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitchInput isEnabled={model.staffPermissions[item.staffReference].read} />
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitchInput isEnabled={model.staffPermissions[item.staffReference].update} />
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitchInput isEnabled={model.staffPermissions[item.staffReference].delete} />
                    </Td>
                  </tr>
                ))}
              </TBody>
            </Table>
            <div className="flex justify-center py-2">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default observer(EditFileModal)
