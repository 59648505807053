import { useMutation, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import {
  AddNoteInput,
  NoteQueryInput,
  Notes,
  RemoveNoteInput,
  UpdateNoteInput,
} from "components/shared/notes/03_sharedModel/note"
import {
  AddNoteTypeInput,
  NoteTypes,
  RemoveNoteTypeInput,
  UpdateNoteTypeInput,
} from "components/shared/notes/03_sharedModel/noteType"

//#region notes
const fetchNotes = (input: NoteQueryInput) => {
  return graphQLClient.fetch(query.noteQuery, input, true, common.organisationApiUrl, keys.noteQuery)
}

function useNote(input: NoteQueryInput, enabled: boolean) {
  return useQuery([keys.noteQuery, input], () => fetchNotes(input), {
    enabled: enabled,
    select: (data: Response<Notes>) => data.data.data.notes.edges.map((m) => m.node),
  })
}

function addNote(input: AddNoteInput) {
  return graphQLClient.mutation(mutation.addNote, input, "AddNote", common.organisationApiUrl)
}

function useAddNote() {
  return useMutation(addNote)
}

function updateNote(input: UpdateNoteInput) {
  return graphQLClient.mutation(mutation.updateNote, input, "UpdateNote", common.organisationApiUrl)
}

function useUpdateNote() {
  return useMutation(updateNote)
}

function removeNote(input: RemoveNoteInput) {
  return graphQLClient.mutation(mutation.removeNote, input, "RemoveNote", common.organisationApiUrl)
}

function useRemoveNote() {
  return useMutation(removeNote)
}

const fetchNoteTypes = () => {
  return graphQLClient.fetch(query.noteTypeQuery, null, true, common.organisationApiUrl, keys.noteTypeQuery)
}

function useNoteType(enabled: boolean, includeAll?: boolean) {
  return useQuery(keys.noteTypeQuery, fetchNoteTypes, {
    enabled: enabled,
    select: (data: Response<NoteTypes>) => {
      if (includeAll) return data.data.data.noteTypes
      return data.data.data.noteTypes.filter((m) => !m.isDisabled)
    },
  })
}

function addNoteType(input: AddNoteTypeInput) {
  return graphQLClient.mutation(mutation.addNoteType, input, "AddNoteType", common.organisationApiUrl)
}

function useAddNoteType() {
  return useMutation(addNoteType)
}

function updateNoteType(input: UpdateNoteTypeInput) {
  return graphQLClient.mutation(mutation.updateNoteType, input, "UpdateNoteType", common.organisationApiUrl)
}

function useUpdateNoteType() {
  return useMutation(updateNoteType)
}

function removeNoteType(input: RemoveNoteTypeInput) {
  return graphQLClient.mutation(mutation.removeNoteType, input, "RemoveNoteType", common.organisationApiUrl)
}

function useRemoveNoteType() {
  return useMutation(removeNoteType)
}
//#endregion

export { useNote, useAddNote, useUpdateNote, useRemoveNote, useNoteType, useAddNoteType, useUpdateNoteType, useRemoveNoteType }
