import React, { useEffect, useState } from "react"
import { AddressView, BooleanView, CreatedView, DateView, DescriptionView, TextView } from "library/styled/description-list"
import { Dl, DlGroup, Dt } from "library/styled/styled"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import LineItemsTableComponent from "components/invoices/03_invoiceDetail/overview/lineItemsTableComponent"
import { InvoiceOverviewModel } from "components/invoices/03_invoiceDetail/overview/invoiceOverviewModel"
import InvoiceStatusSelection from "components/invoices/06_sharedComponent/invoiceStatusSelection"
import { common } from "common"

interface Props {
  invoice?: Invoice
  isOpenInModal?: boolean
}

const InvoiceOverview = ({ invoice, isOpenInModal = false }: Props) => {
  const [model] = useState(() => new InvoiceOverviewModel())

  useEffect(() => {
    if (invoice) model.setInvoiceStatus(invoice)
  }, [invoice])

  return (
    <div className={`overflow-y-auto ${isOpenInModal ? "invoice-side-detail-height" : "side-detail-height"}`}>
      <Dl addExtraPadding={false}>
        <TextView label="Client" value={invoice?.client.name} link={invoice?.client?.clientReference && common.angularJsLinks.clientDetails + invoice?.client?.clientReference} />

        <DlGroup className="w-1/2">
          <InvoiceStatusSelection
            value={model.invoiceStatus}
            invoiceReference={invoice?.invoiceReference}
            concurrencyToken={invoice?.concurrencyToken}
            onConcurrencyTokenChange={() => null}
          />
        </DlGroup>

        <AddressView label="Address" address={invoice?.address} />

        <TextView label="Job" value={invoice?.job?.jobNumber} link={invoice?.job?.jobReference && common.angularJsLinks.jobDetails + invoice?.job?.jobReference } />
        <DateView label="Invoice Date" value={invoice?.invoiceDateTimeUtc} />
        <DateView label="Due Date" value={invoice?.dueDateTimeUtc} />
        <TextView label="Currency" value={invoice?.currencyCode} />
        <TextView label="Reference" value={invoice?.additionalReference} />
        <TextView label="Sale Staff" value={invoice?.saleStaff?.name} />

        <CreatedView label="Created By" createdBy={invoice?.insertedBy} createdDateTimeUtc={invoice?.insertedDateTimeUtc} />
        <CreatedView label="Updated By" createdBy={invoice?.updatedBy} createdDateTimeUtc={invoice?.updatedDateTimeUtc} />

        <DlGroup cols={2}>
          <Dt>Invoice Line Items</Dt>
          <LineItemsTableComponent invoice={invoice} />
        </DlGroup>
      </Dl>
    </div>
  )
}

export default InvoiceOverview
