import React, { CSSProperties, useEffect } from "react"
import { observer } from "mobx-react"
import { A, ErrorInline, FormGroup, Label } from "library/styled/styled"
import { useClientEmailAddress } from "components/invoices/services/invoiceService"
import { Popover } from "@headlessui/react"
import AddEmailRecipient from "components/invoices/sendEmail/addEmailRecipient"
import Select, { MultiValue, OptionProps, components, MultiValueGenericProps, SingleValue } from "react-select"
import { dropdownVariant } from "library/styled/dropdown"
import { runInAction } from "mobx"
import { ThreeDots } from "react-loader-spinner"
import { ClientEmailAddress } from "components/invoices/05_sharedModel/invoiceModel"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { MultiSelectObservable } from "utils/multiSelectObservable"
import { GeneralError } from "models/shared/common"
import GeneralErrors from "library/generalErrors/generalErrors"
import ToolTip from "library/styled/tooltip"

interface Props {
  recipients: MultiSelectObservable<ClientEmailAddress>

  /**
   * The entity type of recipient i.e who the email should go to. Client, Supplier etc
   * */
  recipientEntityTypeReference: string

  /**
   * The reference of the entity type i.e client reference if email is going to client else supplier reference etc.
   */
  recipientEntityReference: string

  /**
   * Observable object which contains the recipient error message
   */
  errorMessage: GeneralError
}

const EmailRecipientSelection = (props: Props) => {
  const { data: contactInfo, isLoading } = useClientEmailAddress({
    clientReference: props.recipientEntityReference,
  })

  useEffect(() => {
    // Always select the first recipient from the contact list.
    if (contactInfo && contactInfo.length >= 1 && !props.recipients.any()) {
      props.recipients.addItem(contactInfo[0])
    }

    // eslint-disable-next-line
  }, [isLoading])

  const LoadingIndicator = () => {
    return (
      <ThreeDots
        height="30"
        width="30"
        radius="3"
        color="rgb(180, 183, 170,1)"
        ariaLabel="three-dots-loading"
        wrapperStyle={{ marginRight: "6px" }}
        visible={true}
      />
    )
  }

  const CustomOption = (props: OptionProps<ClientEmailAddress>) => {
    const { innerProps, innerRef, getStyles } = props
    return (
      <div ref={innerRef} {...innerProps} style={getStyles("option", props) as CSSProperties} className="overflow-hidden">
        <div className="mr-2">{props.data.name}</div>
        <span className="text-xs">{props.data.emailAddress}</span>
      </div>
    )
  }

  const CustomMultiValue: React.FC<MultiValueGenericProps<ClientEmailAddress>> = (props) => {
    return (
      <ToolTip text={props.data.emailAddress}>
        <span>
          <components.MultiValueContainer {...props}>{props.children}</components.MultiValueContainer>
        </span>
      </ToolTip>
    )
  }

  return (
    <>
      <FormGroup cols={6}>
        <div className={"grid grid-cols-12"}>
          <div className={"col-span-10"}>
            <Label className={"col-end-1"}>To</Label>
          </div>
          <div className={"col-span-2"}>
            <Popover
              className="relative float-right"
              onKeyDown={(e: any) => {
                if (e.keyCode === 9) {
                  e.stopPropagation()
                }
              }}
            >
              {({ open }) => (
                <>
                  <Popover.Button className={"border-0"}>
                    <A className={"text-sm"}>Add Recipient</A>
                  </Popover.Button>
                  {open && (
                    <Popover.Panel className="fixed z-10 w-screen max-w-xs right-6">
                      {({ close }) => (
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white sm:gap-8">
                            <AddEmailRecipient
                              onClose={() => close()}
                              onSave={(name: string, emailAddress: string) => {
                                props.recipients.addItem({ emailAddress: emailAddress, name: name })
                                close()
                              }}
                            ></AddEmailRecipient>
                          </div>
                        </div>
                      )}
                    </Popover.Panel>
                  )}
                </>
              )}
            </Popover>
          </div>
        </div>
        <Select
          classNamePrefix={"recipient-selection"}
          isMulti={true}
          styles={dropdownVariant.input}
          options={contactInfo}
          value={props.recipients.selectedItems}
          getOptionLabel={(option) => option.name ?? ""}
          getOptionValue={(option) => option.name ?? ""}
          placeholder="Required"
          isClearable={true}
          isLoading={isLoading}
          onChange={(e: MultiValue<ClientEmailAddress> | SingleValue<ClientEmailAddress>) => {
            runInAction(() => {
              props.recipients.addItems(e as ClientEmailAddress[])
            })
          }}
          noOptionsMessage={() => <h2>No client contacts Found. Please add recipient.</h2>}
          components={{ LoadingIndicator, Option: CustomOption, MultiValueLabel: CustomMultiValue }}
        />
        <GeneralErrors errorMessages={props.errorMessage} />
      </FormGroup>
    </>
  )
}

export default observer(EmailRecipientSelection)
