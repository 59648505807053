import React, { RefObject, useRef } from "react"
import { DragAndDropEventArgs, TreeViewComponent } from "@syncfusion/ej2-react-navigations"
import { UnassignedJobTemplate } from "components/scheduler/templates/templates"
import { CellClickEventArgs, ResourceDetails, ScheduleComponent } from "@syncfusion/ej2-react-schedule"
import { addClass, closest } from "@syncfusion/ej2-base"
import { MoveSchedulerJobInput, UnassignedJob } from "components/scheduler/05_models/models"

interface Props {
  scheduleRef: RefObject<ScheduleComponent>
  onJobMoved: (input: MoveSchedulerJobInput, isExternalEvent: boolean) => void
  unassignedJobs?: UnassignedJob[]
}

const UnassginedJobsView = ({ scheduleRef, onJobMoved, unassignedJobs }: Props) => {
  let treeObj = useRef<TreeViewComponent>(null)

  const onTreeDrag = (event: any): void => {
    if (scheduleRef.current?.isAdaptive) {
      let classElement: HTMLElement | null = scheduleRef.current?.element.querySelector(".e-device-hover")
      if (classElement) {
        classElement.classList.remove("e-device-hover")
      }
      if (event.target.classList.contains("e-work-cells")) {
        addClass([event.target], "e-device-hover")
      }
    }
  }

  const onTreeDragStop = (event: DragAndDropEventArgs): void => {
    //Block the Child Drop operation in TreeView
    let draggingItem: HTMLCollection = document.getElementsByClassName("e-drop-in")
    if (draggingItem.length == 1) {
      draggingItem[0].classList.add("e-no-drop")
      event.cancel = true
    }

    let treeElement: Element = closest(event.target, ".e-treeview")
    let classElement: HTMLElement | null | undefined = scheduleRef.current?.element.querySelector(".e-device-hover")
    if (classElement) {
      classElement.classList.remove("e-device-hover")
    }
    if (!treeElement) {
      event.cancel = true
      let scheduleElement: Element = closest(event.target, ".e-content-wrap")
      if (scheduleElement) {
        let treeviewData: Record<string, any>[] = treeObj.current?.fields.dataSource as Record<string, any>[]

        if (event.target.classList.contains("e-work-cells")) {
          const filteredData = treeviewData.find((item: any) => item.jobReference === event.draggedNodeData.id)
          let cellData: CellClickEventArgs | undefined = scheduleRef.current?.getCellDetails(event.target)
          let resourceDetails: ResourceDetails | undefined = scheduleRef.current?.getResourcesByIndex(cellData?.groupIndex ?? 0)

          //scheduleRef.current?.addEvent(eventData)
          const input: MoveSchedulerJobInput = {
            jobReference: filteredData?.jobReference,
            startDateTimeUtc: filteredData?.startDateTimeUtc,
            endDateTimeUtc: filteredData?.endDateTimeUtc,
            oldResource: null,
            newResource: {
              resourceReference: resourceDetails?.resourceData.resourceReference,
              isContractor: resourceDetails?.resourceData.isContractor,
              isStaffMember: resourceDetails?.resourceData.isStaffMember,
            },
          }
          onJobMoved(input, true)
        }
      }
    }
  }

  const onTreeDragStart = (e: any) => {
    document.body.classList.add("drag-cursor")
    let dragElementIcon: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".e-drag-item.treeview-external-drag .e-icon-expandable"
    )
    for (let i: number = 0; i < dragElementIcon.length; i++) {
      dragElementIcon[i].style.display = "none"
    }
  }

  const onItemSelecting = (args: any): void => {
    args.cancel = true
  }

  return (
    <>
      <div className="bg-white shadow-xl overflow-y-auto h-[calc(100vh-68px)]">
        <div className="flex flex-col">
          <div className="flex justify-center text-lg text-gray-600">
            <div className="text-center px-4 py-3 m-2">Unassigned Jobs</div>
          </div>
          <div>
            <div className="">
              <div className="border-b border-gray-200"></div>
              <TreeViewComponent
                ref={treeObj}
                cssClass="treeview-external-drag"
                dragArea=".drag-wrapper"
                nodeTemplate={UnassignedJobTemplate}
                fields={{ dataSource: unassignedJobs, text: "jobNumber", id: "jobReference" }}
                nodeDragStop={onTreeDragStop}
                nodeSelecting={onItemSelecting}
                nodeDragging={onTreeDrag}
                nodeDragStart={onTreeDragStart}
                allowDragAndDrop={true}
                allowEditing={false}
                delayUpdate={true}
              />
              {unassignedJobs?.length === 0 && (
                <div className="col-span-1 bg-white">
                  <div className="w-full flex items-center justify-between px-4 py-2 space-x-6">
                    <div className="flex-1">
                      <p className="text-gray-500 text-xs font-medium leading-5">
                        Great! - There are no unassigned jobs for selected date
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnassginedJobsView
