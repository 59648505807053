import React from "react"

const Divider = () => {
  return (
    <div className={`col-span-full`}>
      <hr />
    </div>
  )
}

export default Divider
