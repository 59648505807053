import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import { makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-react"
import React from "react"
import { DateRangePickerComponent, RangeEventArgs } from "@syncfusion/ej2-react-calendars"

interface Props {
  label: string
  name: string
  error?: any
  placeholder?: string
  value: DateRangeObservable
  showClearButton?: boolean
  showTodayButton?: boolean
  type?: "filter" | "input"
}

export class DateRangeObservable {
  start?: Date
  end?: Date
  errorMessage?: string

  constructor(start?: Date, end?: Date) {
    makeObservable(this, {
      start: observable,
      end: observable,
      errorMessage: observable,
    })
    this.start = start
    this.end = end
  }
}

const DatePickerRange = (props: Props) => (
  <>
    <FormGroup cols={3}>
      <Label htmlFor={props.name}>{props.label}</Label>
      <DateRangePickerComponent
        format="d MMM yy"
        startDate={props.value.start}
        endDate={props.value.end}
        delayUpdate={true}
        allowEdit={true}
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        change={(arg: RangeEventArgs) => {
          runInAction(() => {
            props.value.start = arg.startDate
            props.value.end = arg.endDate
          })
        }}
        cssClass={`${props.type === "filter" ? "filterDatePicker" : "inputDatePicker"}`}
        strictMode={true}
        start={"Month"}
        depth={"Month"}
      ></DateRangePickerComponent>
      {props.value?.errorMessage && <ErrorInline>{props.value?.errorMessage}</ErrorInline>}
    </FormGroup>
  </>
)
export default observer(DatePickerRange)
