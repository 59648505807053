export const invoiceStatus = {
  draft: {
    reference: "AB341621-3694-4D95-BB04-18357F84782B",
    name: "Draft",
  },
  submitted: {
    reference: "2BA240EB-87B7-4940-9D84-03A6106C3024",
    name: "Submitted",
  },
  approved: {
    reference: "CD9D3800-B0CD-407E-824A-103D9199BAD1",
    name: "Approved",
  },
  paid: {
    reference: "ADF93C53-319C-4E6F-A7DE-615B8205494F",
    name: "Paid",
  },
  void: {
    reference: "FAEABD7A-DEE3-40F2-B35E-FDFE5CCD26BF",
    name: "Void",
  },
  overdue: {
    reference: "1541BB86-4E6C-4738-A7B3-5B5E22C58C14",
    name: "Overdue",
  },
}
