import { StaffName } from "../models/people/staffName"

export const allStaffMember: StaffName = {
  staffReference: "9FCD9634-CC92-49D3-A9B5-C3B794B9B801",
  staffNumber: "N/A",
  givenName: "N/A",
  familyName: "N/A",
  fullName: "All Staff Members",
  initials: "N/A",
  isArchived: false,
  isAuthenticated: false,
  profileImageUrl: "",
  emailAddress: "N/A"
}