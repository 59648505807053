import React, { useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { handleServerError } from "common"
import { keys } from "constants/keys"
import { InputGroupButton } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { ErrorInline, Form, FormGroup, Label, TextArea, TextInput } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { useAddOrUpdateTemplate, useTemplateType } from "components/setting/template/templateSettingService"
import { Template, TemplateType } from "components/setting/template/05_sharedModel/template"
import InputGroupSelect from "library/observables/inputGroupSelect"
import { observer } from "mobx-react"
import InsertMergeFieldsDropdownMenu from "components/setting/template/06_sharedComponent/insertMergeField"
import { AddOrUpdateTemplateModel } from "components/setting/template/01_addOrEditTemplate/addOrEditTemplateModel"
import { action, reaction } from "mobx"
import { placeholderInputTypes } from "components/setting/template/05_sharedModel/placeholderInputTypes"
import InputGroupCheckbox from "library/observables/inputGroupCheckbox"
import TextInputGroup from "library/textInputGroup/textInputGroup"
import ToolTip from "library/styled/tooltip"
import GeneralErrors from "library/generalErrors/generalErrors"

interface Props {
  isOpen: boolean
  onClose: () => void
  template?: Template
  isEditMode?: boolean
}

const AddOrEditTemplateModal = ({ isOpen, onClose, template, isEditMode = false }: Props) => {
  const [templateModel] = useState(() => new AddOrUpdateTemplateModel())
  const { data: templateTypes } = useTemplateType()
  const { mutate: addOrUpdateTemplate, isLoading } = useAddOrUpdateTemplate()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen && !isEditMode && templateTypes) {
      templateModel.reset()
      templateModel.setTemplateType(templateTypes[0])
    }
  }, [isOpen, isEditMode, templateTypes])

  useEffect(() => {
    if (isOpen && isEditMode && templateTypes && template) {
      templateModel.reset(template)
      const selectedTemplateType =
        templateTypes.find((m) => m.templateTypeReference === template?.templateTypeReference) ?? templateTypes[0]
      templateModel.setTemplateType(selectedTemplateType)
    }
  }, [isOpen, isEditMode, template, templateTypes])

  reaction(
    () => templateModel.templateType.selectedOption,
    (option) => {
      templateModel.handleTemplateTypeChange(option)
    }
  )

  const handleSubmit = () => {
    const input = templateModel.getAddOrUpdateTemplateInput()

    addOrUpdateTemplate(input, {
      onError: (error: any) => {
        templateModel.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.templateQuery)
        successToaster(`Template ${isEditMode ? "updated" : "added"}`)
        onClose()
      },
    })
  }

  return (
    <>
      <Modal title={`${isEditMode ? "Edit" : "Add"} Template`} isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <InputGroupSelect<TemplateType>
            label="Template Type"
            id="templateType"
            value={templateModel.templateType}
            options={templateTypes}
            optionValue={"templateTypeReference"}
            optionLabel={"templateTypeName"}
            isInModal={true}
            isDisabled={isEditMode}
          />

          <TextInputGroup id={"name"} label="Name" value={templateModel.name} isRequired={true} />

          <InputGroupCheckbox cols={3} name="isDefault" label="Default" value={templateModel.isDefault} margin={false} />
          {isEditMode && (
            <InputGroupCheckbox cols={3} name="isDisabled" label="Disable" value={templateModel.isDisabled} margin={false} />
          )}

          {templateModel.templateType.selectedOption?.isDescriptionTemplateSupported && (
            <FormGroup cols={6}>
              <Label htmlFor={placeholderInputTypes.description}>Description</Label>
              {templateModel.templateType.selectedOption?.mergeFields.length > 0 && (
                <div className="text-xs font-normal">
                  <InsertMergeFieldsDropdownMenu templateModel={templateModel} id={placeholderInputTypes.description} />
                  <ToolTip text="Dynamic fields can be used in the description. These fields will be replaced with invoice information.">
                    <i className="far fa-circle-info text-gray-700 ml-2"></i>
                  </ToolTip>
                </div>
              )}
              <TextArea
                id={placeholderInputTypes.description}
                placeholder={"Required"}
                value={templateModel.descriptionTemplate.text ?? ""}
                onChange={action((e) => {
                  templateModel.descriptionTemplate.text = e.target.value
                })}
                rows={6}
              />
              {templateModel.descriptionTemplate.errorMessage && (
                <ErrorInline>{templateModel.descriptionTemplate.errorMessage}</ErrorInline>
              )}
            </FormGroup>
          )}

          {templateModel.templateType.selectedOption?.isSmsTemplateSupported && (
            <FormGroup cols={6}>
              <Label htmlFor={placeholderInputTypes.sms}>Sms Message</Label>
              {templateModel.templateType.selectedOption?.mergeFields.length > 0 && (
                <div className="text-xs font-normal">
                  <InsertMergeFieldsDropdownMenu templateModel={templateModel} id={placeholderInputTypes.sms} />
                  <ToolTip text="Dynamic fields can be used in the sms message. These fields will be replaced with invoice information before sms is sent.">
                    <i className="far fa-circle-info text-gray-700 ml-2"></i>
                  </ToolTip>
                </div>
              )}
              <TextArea
                id={placeholderInputTypes.sms}
                placeholder={"Required"}
                value={templateModel.smsTemplateMessage.text ?? ""}
                onChange={action((e) => {
                  templateModel.smsTemplateMessage.text = e.target.value
                })}
                rows={6}
              />
              {templateModel.smsTemplateMessage.errorMessage && (
                <ErrorInline>{templateModel.smsTemplateMessage.errorMessage}</ErrorInline>
              )}
            </FormGroup>
          )}

          {templateModel.templateType.selectedOption?.isEmailTemplateSupported && (
            <FormGroup cols={6}>
              <Label htmlFor={placeholderInputTypes.subject}>Email Subject</Label>
              {templateModel.templateType.selectedOption?.mergeFields.length > 0 && (
                <div className="text-xs font-normal">
                  <InsertMergeFieldsDropdownMenu templateModel={templateModel} id={placeholderInputTypes.subject} />
                  <ToolTip text="Dynamic fields can be used in the email subject. These fields will be replaced with invoice information before email is sent.">
                    <i className="far fa-circle-info text-gray-700 ml-2"></i>
                  </ToolTip>
                </div>
              )}
              <TextInput
                type="text"
                id={placeholderInputTypes.subject}
                placeholder={"Required"}
                value={templateModel.emailTemplateSubject.text ?? ""}
                onChange={action((e) => {
                  templateModel.emailTemplateSubject.text = e.target.value
                })}
              />
              {templateModel.emailTemplateSubject.errorMessage && (
                <ErrorInline>{templateModel.emailTemplateSubject.errorMessage}</ErrorInline>
              )}
            </FormGroup>
          )}

          {templateModel.templateType.selectedOption?.isEmailTemplateSupported && (
            <FormGroup cols={6}>
              <Label htmlFor={placeholderInputTypes.body}>Email Body</Label>
              {templateModel.templateType.selectedOption?.mergeFields.length > 0 && (
                <div className="text-xs font-normal">
                  <InsertMergeFieldsDropdownMenu templateModel={templateModel} id={placeholderInputTypes.body} />
                  <ToolTip text="Dynamic fields can be used in the email body. These fields will be replaced with invoice information before email is sent.">
                    <i className="far fa-circle-info text-gray-700 ml-2"></i>
                  </ToolTip>
                </div>
              )}
              <TextArea
                id={placeholderInputTypes.body}
                placeholder={"Required"}
                value={templateModel.emailTemplateBody.text ?? ""}
                onChange={action((e) => {
                  templateModel.emailTemplateBody.text = e.target.value
                })}
                rows={6}
              />
              {templateModel.emailTemplateBody.errorMessage && (
                <ErrorInline>{templateModel.emailTemplateBody.errorMessage}</ErrorInline>
              )}
            </FormGroup>
          )}

          <GeneralErrors errorMessages={templateModel.generalErrors}></GeneralErrors>

          <InputGroupButton type="button" onClick={handleSubmit} isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}

export default observer(AddOrEditTemplateModal)
