import React, { useMemo } from "react"
import { useDropzone } from "react-dropzone"

interface Props {
  onFileDropped: (file: File) => void
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(209, 213, 219)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
}

const focusedStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

const StyledDropzone = ({ onFileDropped }: Props) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (files) => {
      onFileDropped(files[0])
    },
  })

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div className="col-span-6">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className="block">
          <i className="fas fa-file-alt fa-lg" style={{ color: "#0078d7" }}></i>
          <div className="text-sm inline-flex pl-4">Drag 'n' drop your files here, or click to select files</div>
        </div>
      </div>
    </div>
  )
}

export default StyledDropzone
