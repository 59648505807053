import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

interface Props {
  open: boolean
  onClose: () => void
  title: string
  body: React.ReactNode
  type?: "filterPanel" | "detailPanel"
}

const SidePanel = ({ open, onClose, title, body, type }: Props) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className="pointer-events-auto shadow-xl"
                  style={{
                    backgroundColor: "#f9fafb",
                    borderLeft: "1px solid #e7eaec",
                    borderTop: "1px solid #e7eaec",
                    width: type === "filterPanel" ? "400px" : "720px",
                    marginTop: type === "filterPanel" ? "64px" : "0px",
                  }}
                >
                  <div className="flex h-full flex-col overflow-hidden bg-white pt-4 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <span className="sr-only">Close panel</span>
                          <button
                            type="button"
                            className="rounded-sm px-1.5 py-0.5 bg-white text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <i className="fas fa-xmark-large"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`relative mt-4 flex-1 border-t-2 border-gray-200 ${type === "filterPanel" ? "py-3 px-4" : ""}`}
                    >
                      {/* Replace with your content */}
                      {body}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel
