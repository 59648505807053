import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import CurrencyLabel from "../../../library/currencyLabel/currencyLabel"
import TaxTypeSelection, { TaxTypeObservable } from "../../../library/components/taxTypeSelection"
import ItemSelection from "./lineItemSelection"
import LineItemTextArea from "./lineItemTextArea"
import LineItemNumberField from "./lineItemNumber"
import LineItemTextField from "./lineItemTextField"
import ItemTaxSelection from "../../../library/components/itemTaxSelection"
import InvoiceLineItemObservable from "../05_sharedModel/invoiceLineItemObservable"
import { NumberObservable } from "library/observables/inputGroupNumber"
import { A } from "library/styled/styled"
import ToolTip from "library/styled/tooltip"
import { action, makeObservable, observable, runInAction } from "mobx"
import { v4 } from "uuid"
import _remove from "lodash/remove"
import { isSame } from "utils/extensions"
import { Link } from "react-router-dom"
import { TextObservable } from "library/observables/inputGroupText"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import CurrencyCodeLabel from "../../../library/currencySelection/currencyCodeLabel"
import { CurrencySelectionObservable } from "../../../library/currencySelection/currencySelection"
import { CurrencyFormatLabel } from "library/styled/description-list"
import { useExchangeRate } from "hooks/finance/currency"
import { ExchangeRate } from "models/finance/currency"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"

export class InvoiceLineItemModel {
  invoiceLineItems: InvoiceLineItemObservable[]

  taxType: TaxTypeObservable
  subTotal: NumberObservable
  totalDiscount: NumberObservable
  totalTax: NumberObservable
  totalAmount: NumberObservable

  constructor() {
    makeObservable(this, {
      invoiceLineItems: observable,
      setInvoiceLineItems: action,
    })
    this.invoiceLineItems = []
    this.subTotal = new NumberObservable()
    this.totalDiscount = new NumberObservable()
    this.totalTax = new NumberObservable()
    this.totalAmount = new NumberObservable()
    this.taxType = new TaxTypeObservable()
  }

  setInvoiceLineItems(invoice: Invoice) {
    this.taxType.taxTypeName = invoice.taxTypeName
    this.taxType.taxTypeReference = invoice.taxTypeReference
    this.subTotal.number = invoice.subTotalAmount
    this.totalDiscount.number = invoice.totalDiscountAmount
    this.totalTax.number = invoice.totalTaxAmount
    this.totalAmount.number = invoice.totalAmount

    this.invoiceLineItems = invoice.lineItemGroups.flatMap((m) => m.lineItems).map((m) => new InvoiceLineItemObservable(m))
  }
}

interface Props {
  localeCode: string
  orgCurrency?: string
  lineItemComponentModel: InvoiceLineItemModel
  currencySelection?: CurrencySelectionObservable
}

const InvoiceLineItems: React.FC<Props> = ({ lineItemComponentModel, localeCode, currencySelection, orgCurrency }: Props) => {
  const { data: exchangeRates } = useExchangeRate()
  const [rate, setRate] = useState<ExchangeRate>()

  useEffect(() => {
    if (exchangeRates && currencySelection?.currencyCode) {
      const currencyRate = exchangeRates.find((m) => m.currencyCode === currencySelection?.currencyCode)
      setRate(currencyRate)
    }
  }, [exchangeRates, currencySelection?.currencyCode])

  function calculateTotals() {
    let subTotal = 0
    let totalDiscount = 0
    let totalTax = 0

    lineItemComponentModel.invoiceLineItems.forEach((lineItem) => {
      if (lineItem.isLineItemInvalid) return
      // re-calculate the line total so that we get accurate invoice totals
      lineItem.clearErrors()
      lineItem.calculateLineTotals(lineItemComponentModel.taxType)
      subTotal += lineItem.totalLineAmount.number ?? 0
      totalDiscount += lineItem.totalLineDiscountAmount.number ?? 0
      totalTax += lineItem.totalLineTaxAmount.number ?? 0
    })

    lineItemComponentModel.subTotal.setValue(subTotal)
    lineItemComponentModel.totalDiscount.setValue(totalDiscount)
    lineItemComponentModel.totalTax.setValue(totalTax)
    lineItemComponentModel.totalAmount.setValue(
      lineItemComponentModel.taxType.isInclusive() ? subTotal : subTotal + totalTax ?? 0
    )
  }

  /**
   * This method is called when user types in the total line amount text box.
   * We allow users to provide the total line amount and then reverse calculate the sale price.
   **/
  function onLineAmountChange(lineItem: InvoiceLineItemObservable) {
    if (!lineItem.totalLineAmount.isUpdatedByOnChanged) return
    if (
      lineItem.totalLineAmount.number &&
      lineItem.totalLineAmount.number > 0 &&
      lineItem.quantity.number &&
      lineItem.quantity.number > 0
    ) {
      const newSalePrice = (lineItem.totalLineAmount.number ?? 0) / lineItem.quantity.number
      lineItem.salePrice.setValue(newSalePrice)
      lineItem.clearDiscount()
    }
    calculateTotals()
  }

  /**
   * When line item is selected we have to populate line item description sales and tax based on the item configuration.
   **/
  function onItemSelection(invoiceLineItem: InvoiceLineItemObservable) {
    // If selected item is being removed don't change any line item values.
    if (invoiceLineItem.itemSelection.itemReference) {
      invoiceLineItem.clearErrors()
      invoiceLineItem.quantity.setValue(1, 0)
      invoiceLineItem.description.text = invoiceLineItem.itemSelection.itemDescription || invoiceLineItem.itemSelection.itemName
      invoiceLineItem.salePrice.setValue(invoiceLineItem.itemSelection.itemSalePrice)

      //If the selected item has sale tax configured. We need to select the same sale tax on line item.
      if (invoiceLineItem.itemSelection.saleTax) {
        invoiceLineItem.taxRateSelection.taxReference = invoiceLineItem.itemSelection.saleTax?.taxReference
        invoiceLineItem.taxRateSelection.name = invoiceLineItem.itemSelection.saleTax?.name
        invoiceLineItem.taxRateSelection.rate = invoiceLineItem.itemSelection.saleTax?.effectiveRate
      }
      calculateTotals()
      return
    }
    // when invoiceLineItem.itemSelection.itemReference is null it means user is removing the item from invoice line item in which case we have to clear the line item fields.
    invoiceLineItem.description.text = ""
    invoiceLineItem.discount.text = ""
    invoiceLineItem.quantity.setValue()
    invoiceLineItem.salePrice.setValue()
    calculateTotals()
  }

  function addNewLineItem() {
    runInAction(() => {
      let invoiceLineItem = new InvoiceLineItemObservable()
      invoiceLineItem.lineItemReference.text = v4()
      lineItemComponentModel.invoiceLineItems.push(invoiceLineItem)
    })
  }

  function removeItem(item: InvoiceLineItemObservable) {
    runInAction(() => {
      _remove(lineItemComponentModel.invoiceLineItems, (x) => isSame(x.lineItemReference.text, item.lineItemReference.text))
      calculateTotals()
    })
  }

  return (
    <>
      <div className={"col-span-3"}>
        <div className={"grid grid-cols-6"}>
          <div className={"col-span-5"}></div>
          <div className={"col-span-1"}>
            <TaxTypeSelection
              id={"taxType"}
              taxType={lineItemComponentModel.taxType}
              onChange={() => {
                calculateTotals()
              }}
            ></TaxTypeSelection>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full bordered-table" id={"invoiceLineItemTable"}>
                <thead className="bg-gray-200 border border-gray-200">
                  <tr>
                    <th className="px-2 py-3.5  text-left text-sm font-semibold text-gray-900 w-36">
                      Item
                      <ToolTip className={"w-60"} text={"Select a line item or leave blank and provide line item description."}>
                        <i className="far fa-circle-info text-gray-700 ml-2"></i>
                      </ToolTip>
                    </th>
                    <th className="px-2 py-2 text-left text-sm font-semibold text-gray-900" style={{ minWidth: "5rem" }}>
                      Description
                    </th>
                    <th className="pr-3 text-sm font-semibold text-gray-900 w-28">Qty</th>
                    <th className="pr-3 text-sm font-semibold text-gray-900 w-32">Price</th>
                    <th className="pr-3 text-sm font-semibold text-gray-900 w-30">
                      Discount{" "}
                      <ToolTip
                        className={"w-60"}
                        text={
                          "Discounts can be entered as a numeric value or a percentage. Include the % symbol for a percentage discount."
                        }
                        id={"DiscountToolTipId"}
                      >
                        <i className="far fa-circle-info text-gray-700 ml-1"></i>
                      </ToolTip>
                    </th>
                    <th className="px-3.5 text-sm font-semibold text-gray-900 w-30">
                      Tax Rate
                      <ToolTip
                        overlay={
                          <span>
                            <Link to="/settings/invoice" target="_blank" className="underline">
                              Click here
                            </Link>{" "}
                            to manage tax settings.
                          </span>
                        }
                      >
                        <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-1"></i>
                      </ToolTip>
                    </th>
                    <th className="px-2 text-sm font-semibold text-gray-900 w-30">Tax Amount</th>
                    <th className="px-2 text-sm font-semibold text-gray-900 w-30">Amount</th>
                    <th className="px-2 text-sm font-semibold text-gray-900 w-10"></th>
                  </tr>
                </thead>
                <tbody >
                  {lineItemComponentModel.invoiceLineItems.map((lineItem, i) => (
                    <tr key={lineItem.lineItemReference.text} className="">
                      <td className="text-gray-500 align-top text-left">
                        <ItemSelection
                          id={`lineItemItemSearch-${i}`}
                          itemSearchObservable={lineItem.itemSelection}
                          onChange={(value) => onItemSelection(lineItem)}
                        ></ItemSelection>
                      </td>
                      <td className="text-gray-500 align-top text-left">
                        <LineItemTextArea id={`lineItemDescription-${i}`} value={lineItem.description} />
                      </td>
                      <td className="text-sm text-gray-500 border align-text-top">
                        <LineItemNumberField
                          id={`lineItemQty-${i}`}
                          decimalsLimit={4}
                          alwaysShowDecimals={false}
                          value={lineItem.quantity}
                          onBlur={() => calculateTotals()}
                          maxValue={2147483647}
                          isNumber={true}
                        ></LineItemNumberField>
                      </td>
                      <td className="text-sm text-gray-500 border align-text-top">
                        <LineItemNumberField
                          id={`lineItemSalePrice-${i}`}
                          decimalsLimit={4}
                          value={lineItem.salePrice}
                          alwaysShowDecimals={true}
                          onBlur={() => calculateTotals()}
                          maxValue={2147483647}
                          isNumber={true}
                        ></LineItemNumberField>
                      </td>
                      <td className="text-sm text-gray-500 border align-text-top text-right">
                        <LineItemTextField
                          id={`lineItemDiscount-${i}`}
                          value={lineItem.discount}
                          onBlur={() => calculateTotals()}
                        ></LineItemTextField>
                      </td>

                      <td className="text-sm text-gray-500 border align-text-top text-right">
                        <ItemTaxSelection
                          id={`taxRateSelection-${i}`}
                          taxRateObservable={lineItem.taxRateSelection}
                          isManualTax={lineItem.totalLineTaxAmount.isUpdatedByOnChanged}
                          onChange={() => {
                            // When user selects tax rate we have to override the tax amount entered by user.
                            if (lineItem.totalLineTaxAmount.isUpdatedByOnChanged) {
                              lineItem.totalLineTaxAmount.isUpdatedByOnChanged = false
                            }
                            calculateTotals()
                          }}
                        />
                      </td>
                      <td className="text-sm text-gray-500 border align-text-top text-right">
                        <LineItemNumberField
                          id={`lineItemTaxAmount-${i}`}
                          decimalsLimit={4}
                          value={lineItem.totalLineTaxAmount}
                          onBlur={() => {
                            // Set tax to manually populated if user provided line tax amount.
                            if (lineItem.totalLineTaxAmount.isUpdatedByOnChanged) {
                              lineItem.taxRateSelection.setManualTax()
                            }
                            calculateTotals()
                          }}
                          alwaysShowDecimals={true}
                          label="Tax Amount"
                          isNumber={true}
                        ></LineItemNumberField>
                      </td>
                      <td className="text-sm text-gray-500 border align-text-top text-right">
                        <LineItemNumberField
                          id={`lineItemTotalAmount-${i}`}
                          decimalsLimit={2}
                          value={lineItem.totalLineAmount}
                          onBlur={() => onLineAmountChange(lineItem)}
                          alwaysShowDecimals={true}
                          label="Total Amount"
                          minValue={0}
                          isNumber={true}
                        ></LineItemNumberField>
                      </td>

                      <td className="relative text-sm action-col text-center">
                        {i >= 1 && (
                          <button data-testid={`removeLineItem-${i}`} onClick={() => removeItem(lineItem)}>
                            <ToolTip text={"Remove Item"}>
                              <i className="fas text-red-500 fa-trash p-1.5 hover:bg-red-100 rounded-sm"></i>
                            </ToolTip>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className={"pt-3 text-sm"}>
        <A
          data-testid={"AddNewLineItem"}
          onClick={(event) => {
            addNewLineItem()
          }}
        >
          {" "}
          + Add Row
        </A>
      </div>

      <div className={"col-span-12 pt-5"}>
        <div className={"grid grid-cols-12"}>
          <div className={"col-span-5"}></div>
          <div className={"col-span-7"}>
            <div className="w-92 float-right text-sm flex flex-col space-y-2 justify-end">
              <div className="flex justify-between">
                <div>
                  Sub Total
                  {lineItemComponentModel.totalDiscount.number && lineItemComponentModel.totalDiscount.number > 0 ? (
                    <span className="text-xs pl-1">
                      (Includes{" "}
                      <CurrencyLabel
                        localeCode={localeCode}
                        id={"totalDiscount"}
                        value={lineItemComponentModel.totalDiscount}
                        className={"inline"}
                      ></CurrencyLabel>{" "}
                      Discount)
                    </span>
                  ) : null}
                </div>
                <div>
                  <CurrencyLabel localeCode={localeCode} id={"subTotal"} value={lineItemComponentModel.subTotal}></CurrencyLabel>
                </div>
              </div>
              <div className="flex justify-between">
                <div>Total Tax</div>
                <div>
                  <CurrencyLabel localeCode={localeCode} id={"totalTax"} value={lineItemComponentModel.totalTax}></CurrencyLabel>
                </div>
              </div>
              <div className="flex justify-between border-t border-b border-gray-300 py-2">
                <div>
                  <CurrencyCodeLabel name={"Total"} value={currencySelection}></CurrencyCodeLabel>
                </div>
                <div>
                  <CurrencyLabel
                    localeCode={localeCode}
                    id={"totalAmount"}
                    value={lineItemComponentModel.totalAmount}
                  ></CurrencyLabel>
                </div>
              </div>
              {orgCurrency !== currencySelection?.currencyCode && (
                <>
                  <div className="flex justify-end">
                    <span>
                      <ToolTip text={`1 ${rate?.baseCurrencyCode} = ${rate?.rate} ${rate?.currencyCode}`}>
                        <i className="far fa-info-circle"></i>
                      </ToolTip>{" "}
                      If paid at today's exchange rate you'd get{" "}
                      <CurrencyFormatLabel
                        amount={getTotalInBaseCurrency(lineItemComponentModel.totalAmount.number, rate?.rate)}
                        currencyCode={rate?.baseCurrencyCode}
                        localeCode={localeCode}
                      />
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(InvoiceLineItems)

function getTotalInBaseCurrency(total?: number, exchangeRate?: number) {
  if (total && exchangeRate) return total / exchangeRate
}
