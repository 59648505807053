import axios from "axios"
import { useMutation, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { localStorageService } from "shared/services/local-storage"
import { FileQueryInput, Files, RemoveFileInput } from "models/file/file"
import { Response } from "models/shared/response"
import { FileKeyValue } from "library/FileSelection/models"
import { getUserSession } from "utils/extensions"

//#region Files
const fetchFiles = (input: FileQueryInput) => {
  return graphQLClient.fetch(query.documentsCardQuery, input, true, common.fileApiUrl, keys.fileQuery)
}

function useFiles(input: FileQueryInput, enabled: boolean) {
  return useQuery([keys.fileQuery, input], () => fetchFiles(input), {
    enabled: enabled,
    select: (data: Response<Files>) => data.data.data.files.edges.map((m) => m.node)
  })
}

//#endregion

const uploadFile = async (input: any) => {
  const userSession = await getUserSession()
  // when uploading a file we have to send the file details via headers.
  const authorization = userSession?.token_type + " " + userSession?.access_token
  const headers = {
    authorization: authorization,
    "x-organisation": localStorageService.get(common.localStorageActiveOrganisationKey),
    "x-http-400-unsuccessful-request": true
  }

  return axios.post(common.fileUploadAPI, input, {
    headers: headers
  })
}

const useUploadFile = () => {
  return useMutation(uploadFile)
}

function linkFile(input: any) {
  return graphQLClient.mutation(mutation.addEntityToFile, input, "AddEntityToFile", common.fileApiUrl)
}

function useLinkFile() {
  return useMutation(linkFile)
}

function removeFile(input: RemoveFileInput) {
  return graphQLClient.mutation(mutation.removeFile, input, "RemoveFile", common.fileApiUrl)
}

function useRemoveFile() {
  return useMutation(removeFile)
}

function useSearchFile(input: FileQueryInput) {
  return useQuery([keys.fileSearchQuery, input], () => searchFiles(input), {
    select: (data: Response<Files>) =>
      data.data.data.files.edges.map((m) => new FileKeyValue(m.node.fileReference, m.node.fileName))
  })
}

function searchFiles(input: FileQueryInput) {
  return graphQLClient.fetch(query.fileSearchQuery, input, true, common.fileApiUrl, keys.fileQuery)
}

export { useFiles, useUploadFile, useLinkFile, useRemoveFile, useSearchFile }
