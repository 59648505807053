import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleMutationError, handleServerError, toUtcDateTime } from "common"
import { keys } from "constants/keys"
import { InputGroupButton, InputGroupTextarea } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form, FormGroup, Label } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { DateTimePickerInline } from "library/components/datePickers"
import { Reminder, UpdateReminderInput } from "components/shared/reminders/03_sharedModel/reminder"
import { useUpdateReminder } from "components/shared/reminders/services/reminderCardService"
import { EditReminderModel } from "components/shared/reminders/02_editReminder/editReminderModel"
import DateTimePicker from "library/observables/dateTimePicker"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { observer } from "mobx-react"
import GeneralErrors from "library/generalErrors/generalErrors"

interface Props {
  isOpen: boolean
  onClose: () => void
  reminder?: Reminder
}

const EditReminderModal = ({ reminder, isOpen, onClose }: Props) => {
  const [model] = useState(() => new EditReminderModel())
  const { mutate: updateReminder, isLoading } = useUpdateReminder()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen && reminder) model.reset(reminder)
  }, [isOpen, reminder])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const input: UpdateReminderInput = {
      reminderReference: reminder?.reminderReference,
      dueDateTimeUtc: model.dueDateTime.getUtcDateTime(),
      description: model.description.text ?? "",
    }

    updateReminder(input, {
      onError: (error: any) => {
        model.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.reminderQuery)
        successToaster("Reminder updated")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal title="Edit Reminder" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <FormGroup cols={6}>
            <div className="flex justify-start space-x-3">
              <div className="mt-3">
                <Label htmlFor="dueDateTime">Remind me on</Label>
              </div>
              <div className="w-72">
                <DateTimePicker value={model.dueDateTime} name="dueDateTime" />
              </div>
            </div>
          </FormGroup>

          <TextAreaInputGroup name="description" value={model.description} placeholder="Remind me about..." />
          <GeneralErrors errorMessages={model.generalErrors} />
          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}
export default observer(EditReminderModal)
