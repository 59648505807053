import React from "react"
import { InvoiceListModel } from "components/invoices/04_invoiceList/invoiceListModel"
import DateRangePicker from "library/observables/dateRangePicker"
import { DatePicker } from "library/components/datePickers"
import StaffSelection from "library/components/staffSelection"
import InputGroupCheckbox from "library/observables/inputGroupCheckbox"

interface Props {
  invoiceListModel: InvoiceListModel
}

const InvoiceListAdvanceFilters = ({ invoiceListModel }: Props) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <StaffSelection
          label="Sale Staff"
          name="saleStaff"
          value={invoiceListModel.saleStaff}
          type="input"
          placeholder="Filter by Sale Staff"
          isInModal={true}
        />

        <DateRangePicker
          label="Created Date"
          name="invoiceCreatedDateRange"
          value={invoiceListModel.createdDateRange}
          placeholder="Filter by Created Date"
          type="input"
        />

        <DateRangePicker
          label="Invoice Date"
          name="invoiceDateRange"
          value={invoiceListModel.invoiceDateRange}
          placeholder="Filter by Invoice Date"
          type="input"
        />

        <div className="col-span-3">
          <InputGroupCheckbox label="Show Invoices Sent To Client" value={invoiceListModel.isSent} name="isSent" />
        </div>

        <div className="col-span-3">
          <InputGroupCheckbox label="Show Disputed Invoices" value={invoiceListModel.isDisputed} name="isDisputed" />
        </div>
      </div>
    </>
  )
}

export default InvoiceListAdvanceFilters
