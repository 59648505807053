import React from "react"
import Conditional from "./conditional"

interface Props {
  name: string
  bgColor?: string,
  hexBgColor?: string
}

const Badge = ({ name, bgColor = "bg-blue", hexBgColor }: Props) => {
  return (
    <>
      <Conditional when={hexBgColor === undefined}>
      <span
        className={`inline-flex items-center rounded-md ${bgColor}-50 px-2 py-1 text-xs text-gray-600 mt-1 mr-1`}>
        {name}
      </span>
      </Conditional>
      <Conditional when={hexBgColor !== undefined}>
      <span
        style={{ backgroundColor: `${hexBgColor}40`, color: "black" }}
        className={`inline-flex items-center rounded-md px-2 py-1 text-xs text-gray-600 mt-1 mr-1`}>
        {name}
      </span>
      </Conditional>
    </>

  )
}

export default Badge