import { action, makeObservable, observable } from "mobx"
import { DatePickerObservable } from "library/observables/datePickers"
import { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { NumberObservable } from "library/numberInputGroup/numberInputGroup"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { PaymentMethod } from "models/finance/paymentMethod"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"

export class AddPaymentModel {
  paymentDate: DatePickerObservable
  paymentMethod: SelectObservable<PaymentMethod>
  currencyRate: NumberObservable
  amountPaid: NumberObservable
  description: TextObservable

  enableQuery: boolean
  generalErrors: GeneralError
  paymentReference: string
  openEmailModal: boolean
  constructor() {
    makeObservable(this, {
      enableQuery: observable,
      setEnableQuery: action,
      setPaymentMethod: action,
      generalErrors: observable,
      resetForm: action,
      handleApiErrors: action,
      paymentReference: observable,
      openEmailModal: observable,
      setPaymentReference: action,
      setOpenEmailModal: action,
    })
    this.paymentDate = new DatePickerObservable(new Date())
    this.paymentMethod = new SelectObservable<PaymentMethod>()
    this.currencyRate = new NumberObservable()
    this.amountPaid = new NumberObservable()
    this.description = new TextObservable()

    this.enableQuery = false
    this.generalErrors = {}
    this.paymentReference = ""
    this.openEmailModal = false
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod.selectedOption = { ...paymentMethod }
  }

  setEnableQuery(value: boolean) {
    this.enableQuery = value
  }

  setPaymentReference(reference?: string) {
    this.paymentReference = reference ?? ""
  }

  setOpenEmailModal(value: boolean) {
    this.openEmailModal = value
  }

  resetForm() {
    this.paymentDate.date = new Date()
    this.currencyRate.number = undefined
    this.amountPaid.number = undefined
    this.description.text = undefined

    this.clearErrors()
  }

  clearErrors() {
    this.paymentMethod.errorMessage = undefined
    this.paymentDate.errorMessage = undefined
    this.currencyRate.errorMessage = undefined
    this.amountPaid.errorMessage = undefined
    this.description.errorMessage = undefined

    this.generalErrors = {}
  }

  // Method to handle API error messages
  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    // Loop through the error messages
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "paymentMethodReference")) this.paymentMethod.errorMessage = v.description
      else if (isSame(v.property, "description")) this.description.errorMessage = v.description
      else if (isSame(v.property, "currencyRate")) this.currencyRate.errorMessage = v.description
      else if (isSame(v.property, "paymentDateTime")) this.paymentDate.errorMessage = v.description
      else if (isSame(v.property, "amount")) this.amountPaid.errorMessage = v.description
      else this.generalErrors[v.property] = v.description
    })
  }
}
