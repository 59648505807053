export const fileGql = {
  imageQuery: `query GetImages(
    $fileReference: String
    $entityTypeReference: String
    $entityReference: String
  ) {
    images(
      fileReference: $fileReference
      entityTypeReference: $entityTypeReference
      entityReference: $entityReference
    ) {
      fileReference
      imageContent
      imageName
      extension
      mimeType
      insertedBy
      insertedDateTimeUtc
      insertedBy
    }
  }`,
}
