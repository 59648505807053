import React from "react"
import { DateTimePickerComponent, Inject, MaskedDateTime } from "@syncfusion/ej2-react-calendars"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import { requiredDateValidation } from "utils/validation"
import { DatePickerObservable } from "library/observables/datePickers"

interface Props {
  label?: string
  name: string
  placeholder?: string
  value: DatePickerObservable
  showClearButton?: boolean
  showTodayButton?: boolean
  isRequired?: boolean,
  minDate?: Date
}

const DateTimePicker = (props: Props) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !props.value.errorMessage) {
      return
    }

    runInAction(() => {
      props.value.errorMessage = requiredDateValidation(props.value.date, props.label, props.isRequired)
    })
  }

  return (
    <>
      <FormGroup cols={3}>
        <Label htmlFor={props.name}>{props.label}</Label>
        <DateTimePickerComponent
          format="d MMM yyyy hh:mm a"
          value={props.value?.date}
          enableMask={true}
          openOnFocus={true}
          id={props.name}
          name={props.name}
          maskPlaceholder={{ day: "DD", month: "MMM", year: "YYYY", hour: "hh", minute: "mm" }}
          placeholder={props.placeholder}
          showTodayButton={props.showTodayButton ?? true}
          showClearButton={props.showClearButton ?? true}
          min={props.minDate}
          change={(arg, rest) => {
            runInAction(() => {
              props.value.date = arg.value
              validate(true)
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          cssClass="e-outline mt-1 py-0.5 mt-1 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-primary-100 focus:border-primary-100"
        >
          <Inject services={[MaskedDateTime]} />
        </DateTimePickerComponent>
        {props.value.errorMessage && <ErrorInline>{props.value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}
export default observer(DateTimePicker)
