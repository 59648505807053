import React, { useEffect, useState } from "react"
import TableDynamic from "library/styled/table"
import { DocumentCardModel } from "components/shared/documents/01_documentCard/documentCardModel"
import { useModalState } from "utils/modalStates"
import { File, RemoveFileInput } from "models/file/file"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import { observer } from "mobx-react"
import UploadFileModal from "components/shared/documents/02_uploadFile/uploadFile"
import { useFiles, useRemoveFile } from "components/shared/documents/services/documentCardService"
import LinkExistingFileModal from "components/shared/documents/03_linkExistingFile/linkExistingFile"
import { WarningAlert } from "library/styled/alerts"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import Conditional from "library/styled/conditional"
import { ButtonPrimary } from "library/styled/styled"
import { useDownloadFileLink } from "components/file/fileService"
import { DownloadLinkInput } from "components/file/fileModelGql"
import PreviewFileModal from "components/file/previewFile/previewFileModal"
import { PreviewModal } from "components/shared/documents/04_sharedModel/models"
import { isFilePreviewAvailable } from "utils/extensions"

interface Props {
  entityReference?: string
  entityTypeReference: string
  isTabComponent?: boolean
  // This props controls the noteQuery when set to true the component will load the query
  isSelected: boolean
  isOpenInModal?: boolean
}

const DocumentListTemplate = ({ entityReference, entityTypeReference, isTabComponent = false, isSelected, isOpenInModal = false }: Props) => {
  const [documentModel] = useState(() => new DocumentCardModel())
  const { addModal, setAddModal, alert, setAlert } = useModalState<File>()
  const { data: files, isLoading } = useFiles(
    documentModel.getFileInput(entityTypeReference, entityReference),
    documentModel.enableQuery
  )
  const { mutate: removeFile, isLoading: isDeleting } = useRemoveFile()
  const { mutate: downloadFileLink } = useDownloadFileLink()
  const [previewModal, setPreviewModal] = useState<PreviewModal>({
    file: undefined,
    fileUrl: undefined,
    isOpen: false,
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (entityReference && isSelected) documentModel.setEnableQuery()
  }, [entityReference, isSelected])

  const handleDelete = (file: File) => {
    const input: RemoveFileInput = {
      fileReference: file.fileReference,
      entityReference: entityReference ?? "",
      entityTypeReference: entityTypeReference,
    }

    removeFile(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.fileQuery)
        successToaster("File deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false }),
    })
  }

  const getDownloadLink = (file: File, preview: boolean) => {
    const input: DownloadLinkInput = {
      fileReference: file.fileReference,
    }

    downloadFileLink(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (response) => {
        if (response) {
          if (preview) setPreviewModal({ file: file, fileUrl: response.data.data.downloadLink.url, isOpen: true })
          else downloadFile(response.data.data.downloadLink.url, file.fileName)
        }
      },
    })
  }

  const downloadFile = (url: string, fileName: string) => {
    const link = document.createElement("a")
    link.href = url
    link.download = fileName
    link.click()
  }

  const options = [
    {
      name: "View",
      icon: "eye",
      action: (item: File) => getDownloadLink(item, true),
      isVisible: (item: File) => {
        return isFilePreviewAvailable(item.extension)
      },
    },
    {
      name: "Download",
      icon: "download",
      action: (item: File) => getDownloadLink(item, false),
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: File) => setAlert({ isOpen: true, item: item }),
    },
  ]

  return (
    <>
      <TableDynamic
        columns={cols}
        data={files}
        dataKey="fileReference"
        options={options}
        fixHeight={isTabComponent}
        isLoading={(isTabComponent && !isOpenInModal) ? isLoading : false}
        loadingWidth={500}
        columnLoadingWidths={[65, 35]}
      />
      {isTabComponent && (
        <div className="flex justify-end space-x-6 p-6">
          <ButtonPrimary onClick={() => documentModel.setUploadModal(true)}>Upload File</ButtonPrimary>
          <ButtonPrimary onClick={() => setAddModal(true)}>Link Existing File</ButtonPrimary>
        </div>
      )}

      <Conditional when={isTabComponent}>
        <LinkExistingFileModal
          isOpen={addModal}
          onClose={() => setAddModal(false)}
          entityReference={entityReference}
          entityTypeReference={entityTypeReference}
        />
        <UploadFileModal
          isOpen={documentModel.uploadModal.isOpen}
          onClose={() => documentModel.setUploadModal(false)}
          file={documentModel.uploadModal.file}
          entityReference={entityReference}
          entityTypeReference={entityTypeReference}
        />
      </Conditional>

      <WarningAlert
        title="File"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />

      <PreviewFileModal
        isOpen={previewModal.isOpen}
        onClose={() => setPreviewModal({ ...previewModal, isOpen: false })}
        file={previewModal.file}
        fileUrl={previewModal.fileUrl}
      />
    </>
  )
}

export default observer(DocumentListTemplate)

const cols: Column[] = [
  {
    id: "1",
    name: "Name",
    key: "fileName",
    columnTypeId: ColumnType.Text.Id,
  },
  {
    id: "2",
    name: "Size",
    key: "sizeInBytes",
    columnTypeId: ColumnType.FileSize.Id,
  },
]
