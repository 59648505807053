import React, { useEffect, useState } from "react"
import Select from "react-select"
import { A, ErrorInline, FormGroup, Label } from "../styled/styled"
import { observer } from "mobx-react"
import { useCurrency } from "../../hooks/finance/currency"
import { Currency } from "../../models/finance/currency"
import { makeObservable, observable, runInAction } from "mobx"
import { isEmpty, isProvided } from "../../utils/extensions"
import { filterStyle, inputStyle } from "../styled/dropdown"
import { Link } from "react-router-dom"
import ToolTip from "../styled/tooltip"

export class CurrencySelectionObservable {
  currencyCode?: string
  name?: string
  rate?: number
  errorMessage?: string

  constructor() {
    makeObservable(this, {
      currencyCode: observable,
      name: observable,
      rate: observable,
      errorMessage: observable,
    })
  }
}

interface Props {
  id: string
  label: string
  placeholder?: string
  isClearable?: boolean
  type?: string
  onChange?: (item: Currency) => void
  onBlur?: () => void
  value: CurrencySelectionObservable
}

/**
 * This component allows selection of organisation currencies. Note, the currency that users can select
 * using this component comes from accounting API.
 * */
const CurrencySelection = ({
  id,
  label,
  isClearable = true,
  placeholder = "Optional",
  type = "input",
  onChange,
  onBlur,
  value,
}: Props) => {
  const { data: currencies, isLoading } = useCurrency()

  useEffect(() => {
    if (currencies && isEmpty(value.currencyCode) && currencies.length > 0) {
      const currency = currencies[0]
      runInAction(() => {
        value.currencyCode = currency.currencyCode
        value.name = currency.name
      })
    }

    // eslint-disable-next-line
  }, [isLoading])

  function getDefaultValue(): Currency | undefined {
    if (isProvided(value.currencyCode)) {
      return currencies?.find((obj) => {
        return obj.currencyCode === value.currencyCode
      })
    }

    return undefined
  }

  return (
    <>
      <FormGroup cols={3}>
        <Label>
          {label}

          <ToolTip
            className={"w-60"}
            overlay={
              <span className={""}>
                <Link className={"underline"} to="/settings/invoice" target="_blank">
                  Click here
                </Link>{" "}
                to manage currency settings.
              </span>
            }
          >
            <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        </Label>
        <Select
          options={currencies}
          isSearchable={true}
          isClearable={isClearable}
          inputId={id}
          classNamePrefix={id}
          value={getDefaultValue()}
          getOptionLabel={(option) => option.name ?? "N/A"}
          getOptionValue={(option) => option!.currencyCode ?? "N/A" + option!.countryCode}
          placeholder={placeholder}
          className={`${type === "filter" ? "shadow-md w-72" : "ring-primary-100"}`}
          styles={type === "filter" ? filterStyle : inputStyle}
          formatOptionLabel={(data) => (
            <>
              <div className="overflow-hidden">
                <span className="">{`${data.name} (${data.currencyCode})`}</span>
              </div>
            </>
          )}
          onChange={(e) => {
            runInAction(() => {
              value.currencyCode = e!.currencyCode
              value.name = e!.name
              if (onChange) onChange({ name: e!.name, currencyCode: e!.currencyCode })
            })
          }}
          onBlur={(event) => {
            if (onBlur) {
              runInAction(() => {
                onBlur()
              })
            }
          }}
        ></Select>
        {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

export default observer(CurrencySelection)
