import React, { useEffect, useState } from "react"
import useLocalStorageState from "utils/localStorage"
import { itemColumns } from "./itemListColumns"
import { useItem } from "../itemService"
import DataGridNew from "library/styled/datagridNew"
import { Item } from "../04_itemSharedModel/itemModel"
import { ItemListModel } from "./itemListModel"
import { observer } from "mobx-react"
import ItemListFilters from "./itemListFilters"
import { useModalState } from "utils/modalStates"
import AddOrEditItemPage from "../01_addOrEditItem/addOrEditItemPage"
import ItemDetailTabs from "../02_itemDetail/itemDetailTabs"
import SidePanel from "library/styled/sidePanel"

function ItemListPage() {
  const {
    addModal: addItemModal,
    setAddModal: setAddItemModal,
    viewModal: viewEditItemModal,
    setViewModal: setViewItemModal,
  } = useModalState<Item>()

  const [columns, setColumns] = useLocalStorageState("itemColumns", itemColumns)
  const [itemListModel] = useState(() => new ItemListModel())
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, refetch } = useItem(itemListModel.getFilter())

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data) itemListModel.setItemList(data)
  }, [data])

  return (
    <>
      <AddOrEditItemPage isOpen={addItemModal} isEditMode={false} onClose={() => setAddItemModal(false)} />

      <div className="px-3 pt-3 space-y-3 xl16:px-6 xl16:pt-6 xl16:space-y-6">
        <div className="">
          <ItemListFilters itemListModel={itemListModel} />
        </div>

        <div className="">
          <DataGridNew<Item>
            title="Items"
            buttonText="New Item"
            data={itemListModel.itemList}
            columns={columns}
            dataKey="itemReference"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onRowClick={(item) => {
              setViewItemModal({ item: item, isOpen: true })
            }}
            onColumnChange={setColumns}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            noItemFoundMessage="No Items found"
            action={() => {
              setAddItemModal(true)
            }}
          />
        </div>
      </div>

      <SidePanel
        title={`Item - ${viewEditItemModal.item?.name}`}
        open={viewEditItemModal.isOpen}
        onClose={() => setViewItemModal({ ...viewEditItemModal, isOpen: false })}
        body={<ItemDetailTabs itemReference={viewEditItemModal.item?.itemReference} />}
        type="detailPanel"
      />
    </>
  )
}

export default observer(ItemListPage)
