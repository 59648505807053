import React, { useState } from "react"

import { observer } from "mobx-react"

import { Modal } from "library/styled/modal"
import InvoiceDetailTabs from "./invoiceDetailTabs"

interface Props {
  open: boolean
  onClose: () => void
  invoiceReference?: string
}

const InvoiceDetailModel = ({ open, onClose, invoiceReference }: Props) => {
  const [modalTitle, setModalTitle] = useState("")
  return (
    <>
      <Modal width={"max-w-5xl"} title={`Invoice # : ${modalTitle}`} isOpen={open} onClose={onClose} footer={true}>
        <InvoiceDetailTabs invoiceReference={invoiceReference} isOpenInModal={true} setModalTitle={setModalTitle} />
      </Modal>
    </>
  )
}

export default observer(InvoiceDetailModel)
