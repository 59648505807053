import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleServerError } from "common"
import { keys } from "constants/keys"
import { InputGroupButton } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form, FormGroup, Label } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { Note, UpdateNoteInput } from "components/shared/notes/03_sharedModel/note"
import { useNoteType, useUpdateNote } from "components/shared/notes/services/noteCardService"
import { EditNoteModel } from "components/shared/notes/02_editNote/editNoteModel"
import TextAreaInputGroup from "library/textAreaInputGroup/textAreaInputGroup"
import { observer } from "mobx-react"
import GeneralErrors from "library/generalErrors/generalErrors"
import InputGroupSelect from "library/observables/inputGroupSelect"
import { NoteType } from "../03_sharedModel/noteType"

interface Props {
  isOpen: boolean
  onClose: () => void
  note?: Note
}

const EditNoteModal = ({ note, isOpen, onClose }: Props) => {
  const [editNoteModel] = useState(() => new EditNoteModel())
  const { data: noteTypes } = useNoteType(isOpen)
  const { mutate: updateNote, isLoading } = useUpdateNote()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen && note && noteTypes) {
      editNoteModel.reset(note)
      const selectedNoteType = noteTypes.find((m) => m.noteTypeReference === note?.noteTypeReference) ?? noteTypes[0]
      editNoteModel.setNoteType(selectedNoteType)
    }
  }, [isOpen, note, noteTypes])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const input: UpdateNoteInput = {
      noteReference: note?.noteReference,
      isVisibleToClient: note?.isVisibleToClient,
      description: editNoteModel.description.text ?? "",
      noteTypeReference: editNoteModel.noteType.selectedOption?.noteTypeReference ?? "",
    }

    updateNote(input, {
      onError: (error: any) => {
        editNoteModel.handleApiErrors(error)
        handleServerError(error.response)
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.noteQuery)
        successToaster("Note updated")
        onClose()
      },
    })
  }

  return (
    <>
      <Modal title="Edit Note" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <FormGroup cols={6}>
            <div className="flex justify-start space-x-3">
              <div className="mt-3">
                <Label htmlFor="dueDateTime">Note Type</Label>
              </div>
              <div className="w-72">
                <InputGroupSelect<NoteType>
                  value={editNoteModel.noteType}
                  id="noteType"
                  options={noteTypes}
                  optionValue={"noteTypeReference"}
                  optionLabel={"name"}
                />
              </div>
            </div>
          </FormGroup>
          <TextAreaInputGroup label="Write a Note" name="description" value={editNoteModel.description} placeholder="Required" />
          <GeneralErrors errorMessages={editNoteModel.generalErrors} />
          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}
export default observer(EditNoteModal)
