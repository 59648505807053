import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { AddTaxInput, RemoveTaxInput, Taxes, UpdateTaxInput } from "models/finance/tax"

function useTax() {
  return useQuery(keys.taxQuery, fetchTaxes, {
    select: (data: Response<Taxes>) => data.data.data.taxes,
  })
}

function fetchTaxes() {
  return graphQLClient.fetch(query.taxQuery, null, true, common.accountingApiUrl, keys.taxQuery)
}

function addTax(input: AddTaxInput) {
  return graphQLClient.mutation(mutation.addTax, input, "AddTax", common.accountingApiUrl)
}

function useAddTax() {
  return useMutation(addTax)
}

function updateTax(input: UpdateTaxInput) {
  return graphQLClient.mutation(mutation.updateTax, input, "UpdateTax", common.accountingApiUrl)
}

function useUpdateTax() {
  return useMutation(updateTax)
}

function removeTax(input: RemoveTaxInput) {
  return graphQLClient.mutation(mutation.removeTax, input, "RemoveTax", common.accountingApiUrl)
}

function useRemoveTax() {
  return useMutation(removeTax)
}

export { useTax, useAddTax, useUpdateTax, useRemoveTax }
