import { action, makeObservable, observable } from "mobx"

export default class UploadImageModel {
  fileName?: string
  fileExtension?: string
  fileContentBase64?: string
  entityTypeReference?: string
  entityReference?: string
  errorMessage?: string
  fileData?: any

  constructor(entityTypeReference?: string, entityReference?: string, fileContentBase64?: string) {
    makeObservable(this, {
      fileName: observable,
      fileContentBase64: observable,
      entityTypeReference: observable,
      entityReference: observable,
      errorMessage: observable,
      fileExtension: observable,
      setError: action,
      resetFile: action,
      handleApiErrors: action,
    })

    this.entityTypeReference = entityTypeReference
    this.entityReference = entityReference
    this.fileContentBase64 = fileContentBase64
  }

  handleApiErrors(errorResponse: any) {
    errorResponse.response.data.messages.forEach((v: any) => {
      this.errorMessage = v.description
    })
  }

  resetFile() {
    this.fileName = ""
    this.fileContentBase64 = ""
    this.errorMessage = ""
    this.fileExtension = ""
  }

  setError(error?: string) {
    this.errorMessage = error
  }
}
