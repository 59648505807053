import React, { useEffect, useState } from "react"
import UploadImageModel from "./uploadImageModel"
import { isEmpty, isProvided } from "../../../utils/extensions"
import { observer } from "mobx-react"
import { useUploadImage } from "../fileService"
import { runInAction } from "mobx"
import { successToaster } from "../../../library/styled/toasters"
import { Modal } from "../../../library/styled/modal"
import { ErrorInline, Form, FormGroup } from "../../../library/styled/styled"
import Conditional from "../../../library/styled/conditional"

interface Props {
  isOpen: boolean
  onClose: () => void
  onUploadSuccess: (uploadImageModel: UploadImageModel) => void
  entityReference: string
  entityTypeReference: string
  modalTitle: string
  isUploadingLogo?: boolean
}

const UploadImageModal = (props: Props) => {
  const [uploadImageModel] = useState(() => new UploadImageModel(props.entityTypeReference, props.entityReference))
  const { mutate: uploadImage, isLoading } = useUploadImage()

  useEffect(() => {
    if (props.isOpen) {
      uploadImageModel.resetFile()
    }
  }, [props.isOpen])

  const handleImageSelection = (e: any) => {
    const file = e.target.files[0]
    runInAction(() => {
      uploadImageModel.fileData = file
      uploadImageModel.fileName = file.name.split(".")[0]
      uploadImageModel.fileExtension = file.name.split(".")[1]
    })

    const reader = new FileReader()
    reader.onloadend = () => {
      runInAction(() => {
        uploadImageModel.fileContentBase64 = reader?.result as string
      })
    }
    reader.readAsDataURL(file)
  }

  const onSave = () => {
    if (isEmpty(uploadImageModel.fileName)) {
      uploadImageModel.setError("Please select a file")
      return
    }
    uploadImageModel.setError(undefined)
    let newFile = new File([uploadImageModel.fileData], `${uploadImageModel.fileName}.${uploadImageModel.fileExtension}`, {
      type: uploadImageModel.fileData.type,
    })

    let file = new FormData()
    file.append("file", newFile)
    file.append("operationName", "UploadImage")
    file.append("entityTypeReference", uploadImageModel.entityTypeReference ?? "")
    file.append("entityReference", uploadImageModel.entityReference ?? "")

    uploadImage(file, {
      onError: (error: any) => {
        uploadImageModel.handleApiErrors(error)
      },
      onSuccess: (e) => {
        props.onUploadSuccess(uploadImageModel)
        props.onClose()
        successToaster("Image upload successfully")
      },
    })
  }

  return (
    <>
      <Modal
        title={props.modalTitle}
        isOpen={props.isOpen}
        onClose={props.onClose}
        footer={true}
        buttonText="Save"
        action={onSave}
        isButtonLoading={isLoading}
      >
        <Form>
          <Conditional when={props.isUploadingLogo}>
            <FormGroup cols={12}>
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <i className="fa-sharp fa-light fa-circle-info h-5 w-5 text-blue-700"></i>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      <p>
                        For best results, please upload your company logo using a large dimension of at least 500 pixels in width
                        and height. This will help ensure the best possible quality and prevent blurriness. If you don't have a
                        logo yet, don't worry! You can create one for free using Adobe's logo maker.{" "}
                        <a className={"underline"} target={"_blank"} href={"https://express.adobe.com/express-apps/logo-maker/."}>
                          Click here to create your logo.
                        </a>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Conditional>
          {/* show file which has already been loaded by root component*/}
          <Conditional when={isProvided(uploadImageModel.fileContentBase64)}>
            <FormGroup cols={6}>
              <img src={uploadImageModel.fileContentBase64} className="h-20" alt="" />
            </FormGroup>
          </Conditional>

          <FormGroup cols={6}>
            <label className="block">
              <span className="sr-only">Choose Image</span>
              <input
                type="file"
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-100 file:text-primary-900 hover:file:bg-blue-100"
                onChange={handleImageSelection}
              />
            </label>
            {uploadImageModel.errorMessage && <ErrorInline>{uploadImageModel.errorMessage}</ErrorInline>}
          </FormGroup>
        </Form>
      </Modal>
    </>
  )
}

export default observer(UploadImageModal)
