import React, { useEffect, useState } from "react"
import { CreatedView, DateView, DescriptionView, TextView } from "library/styled/description-list"
import { Modal } from "library/styled/modal"
import { Dd, Dl, DlGroup, Dt } from "library/styled/styled"
import { Payment } from "components/payment/05_sharedModel/paymentModel"
import { keys } from "constants/keys"
import { entityTypes } from "constants/entityTypes"
import { successToaster } from "library/styled/toasters"
import SendEmailPage from "components/invoices/sendEmail/sendEmailModal"
import { invoicePaymentEmailConfig } from "constants/emailConfiguration"
import { useQueryClient } from "react-query"
import { useMetadata } from "context/metadata-context"
import { getTotalInBaseCurrency, toCurrency } from "utils/extensions"

interface Props {
  isOpen: boolean
  onClose: () => void
  onEdit: () => void
  payment?: Payment
  clientReference: string
}

const ViewPaymentModal = ({ isOpen, onClose, onEdit, payment, clientReference }: Props) => {
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const { organisation } = useMetadata()
  const [locale, setLocale] = useState<string>()
  const queryClient = useQueryClient()


  useEffect(() => {
    if (organisation?.locale) setLocale(`${organisation?.locale.languageCode}-${organisation?.locale.countryCode}`)
  }, [organisation])

  const handleEmailSent = () => {
    setOpenEmailModal(false)
    void queryClient.invalidateQueries([
      keys.emailsQuery,
      {
        entityReference: payment?.invoice?.invoiceReference,
        entityTypeReference: entityTypes.invoice.entityTypeReference
      }
    ])
    successToaster("Email sent")
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Payment Details"
        onEdit={onEdit}
        footer={true}
        action={() => setOpenEmailModal(true)}
        buttonText="Email Receipt"
        editButtonText={"Edit Payment"}
      >
        <Dl>
          <TextView label="Payment Number" value={payment?.paymentNumber} />
          <DateView label="Date Paid" value={payment?.paymentDateTimeUtc} />
          <TextView label="Payment Method" value={payment?.paymentMethodName} />
          <DlGroup cols={1}>
            <Dt>Amount Paid</Dt>
            <Dd>
              {toCurrency(payment?.amount, payment?.currencyCode, locale)}
              {organisation?.currency.currencyCode !== payment?.currencyCode && (
                <span className="pl-1">
                  (
                  {toCurrency(
                    getTotalInBaseCurrency(payment?.amount, payment?.currencyRate),
                    organisation?.currency.currencyCode,
                    locale
                  )}
                  )
                </span>
              )}
            </Dd>
          </DlGroup>
          <DescriptionView value={payment?.description} />
          <CreatedView label="Created By" createdBy={payment?.insertedBy}
                       createdDateTimeUtc={payment?.insertedDateTimeUtc} />
          <CreatedView label="Updated By" createdBy={payment?.updatedBy}
                       createdDateTimeUtc={payment?.updatedDateTimeUtc} />
        </Dl>
      </Modal>

      <SendEmailPage
        title="Email Receipt"
        senderEntityTypeReference={entityTypes.payment.entityTypeReference}
        senderEntityReference={payment?.paymentReference ?? ""}
        recipientEntityTypeReference={entityTypes.client.entityTypeReference}
        recipientEntityReference={clientReference}
        onEmailSent={handleEmailSent}
        onClose={() => setOpenEmailModal(false)}
        isOpen={openEmailModal}
        emailConfiguration={invoicePaymentEmailConfig}
      ></SendEmailPage>
    </>
  )
}

export default ViewPaymentModal
