import React from "react"
import { DescriptionView, TextView } from "library/styled/description-list"
import { Modal } from "library/styled/modal"
import { Dl } from "library/styled/styled"
import { Category } from "models/organisation/category"

interface Props {
  isOpen: boolean
  onClose: () => void
  category?: Category
}

const ViewCategoryModal = ({ isOpen, onClose, category }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Category Details" footer={false}>
    <Dl>
      <TextView label="Name" value={category?.name} />
      <TextView label="Assigned To" value={category?.entityType!.name} />
      <DescriptionView value={category?.description} />
    </Dl>
  </Modal>
)

export default ViewCategoryModal
