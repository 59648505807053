import React, { Fragment, useEffect, useRef, useState } from "react"
import ReactPaginate from "react-paginate"
import { handleMutationError } from "common"
import { fileAccess, filePermission } from "constants/fileAccess"
import { useQueryClient } from "react-query"
import { useStaffName } from "hooks/people/staff"
import { InputGroupDropdown, InputGroupTextarea } from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { ErrorInline, Form, FormGroup, Label, Table, TBody, Td, TextInput, Th, THead } from "library/styled/styled"
import ToggleSwitch from "library/styled/switch"
import TagsInput from "library/styled/tags"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { StaffName } from "models/people/staffName"
import { useUploadFile } from "components/shared/documents/services/documentCardService"
import { isProvided } from "utils/extensions"

const shareOptions = [fileAccess.allStaff, fileAccess.onlyMe, fileAccess.private]

interface Props {
  isOpen: boolean
  onClose: () => void
  file: any
  entityReference?: string
  entityTypeReference: string
}

const UploadFileModal = ({ file = null, isOpen, onClose, entityTypeReference, entityReference }: Props) => {
  const [error, setError] = useState<any>({})
  const [selectedFile, setSelectedFile] = useState<any>()
  const [fileName, setFileName] = useState("")
  const [showPreview, setShowPreview] = useState(true)
  const { data } = useStaffName(true)
  const [permissions, setPermissions] = useState<any>({})
  const [fileAccess, setFileAccess] = useState<any>(shareOptions[0].name)
  const form = useRef<any>()
  const [tags, setTags] = useState<any>([])
  const [staffPermissions, setStaffPermissions] = useState<any>([])
  const { mutate: uploadFile, isLoading } = useUploadFile()
  const queryClient = useQueryClient()

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<StaffName[] | undefined>([])
  const [pageCount, setPageCount] = useState(0)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 5

  useEffect(() => {
    if (isOpen) {
      setError({})
      setFileAccess(shareOptions[0].name)
      setStaffPermissions([])
      setFileName("")
      setPermissions({})
      setTags([])
      setSelectedFile(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    if (data) {
      const endOffset = itemOffset + itemsPerPage
      setCurrentItems(data?.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(data.length / itemsPerPage))
    }
  }, [itemOffset, itemsPerPage, data])

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % (data?.length ?? 1)
    setItemOffset(newOffset)
  }

  useEffect(() => {
    if (file && file.name) {
      setSelectedFile(file)
      setFileName(file.name.split(".")[0])
    }
  }, [file])

  useEffect(() => {
    if (data && isOpen) {
      let permissionsTemp: any = {}
      data.forEach((staff) => {
        permissionsTemp[staff.staffReference] = {
          read: false,
          update: false,
          delete: false,
        }
      })
      setPermissions(permissionsTemp)
    }
  }, [data, isOpen])

  const removeFile = () => {
    setSelectedFile(undefined)
    setFileName("")
  }

  const handleFile = (e: any) => {
    setShowPreview(true)
    const fileTemp = e.target.files[0]
    setSelectedFile(fileTemp)
    setFileName(fileTemp.name.split(".")[0])
    setError({})
  }

  const handleFileName = (e: any) => {
    setFileName(e.target.value)
  }

  const handleTagInputChange = (options: any) => {
    setTags(options)
  }

  const togglePermission = (staff: StaffName, type: any, permissionReference: string) => {
    const staffReference = staff.staffReference
    let permissionTemp = permissions[staff.staffReference]

    if (permissionTemp[type]) {
      setStaffPermissions((prevState: any) =>
        prevState.filter((prevItem: any) => {
          if (prevItem.staffReference === staffReference && prevItem.permissionReference === permissionReference) return null
          return prevItem
        })
      )
    } else {
      const staffPermission = {
        staffReference,
        permissionReference,
      }
      setStaffPermissions([...staffPermissions, staffPermission])
    }

    permissionTemp[type] = !permissionTemp[type]
    setPermissions({ ...permissions, staffReference: permissionTemp })
  }

  const handleFileAccess = (fileAccess: any) => {
    setFileAccess(fileAccess.name)
  }

  const handleSubmit = () => {
    const { fileDescription, fileAccess } = form.current

    let newFile = new File([selectedFile], isProvided(fileName) ? fileName + "." + selectedFile?.name.split(".")[1] : "", {
      type: selectedFile?.type,
    })

    let file = new FormData()
    file.append("file", newFile)
    file.append("operationName", "UploadFile")
    file.append("entityTypeReference", entityTypeReference)
    file.append("entityReference", entityReference ?? "")
    file.append("description", fileDescription.value)
    file.append("accessLevelReference", fileAccess.value)
    file.append("staffPermissions", JSON.stringify(staffPermissions))
    file.append("tags", JSON.stringify(tags.map((m: any) => m.label)))

    uploadFile(file, {
      onError: (error: any) => handleMutationError(error.response, setError),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.fileQuery)
        onClose()
        successToaster("File added")
      },
    })
  }

  return (
    <>
      <Modal
        title="Add Document"
        isOpen={isOpen}
        onClose={onClose}
        footer={true}
        buttonText="Save"
        action={handleSubmit}
        isButtonLoading={isLoading}
        width="max-w-4xl"
      >
        <Form ref={form}>
          {selectedFile?.name ? (
            <FormGroup cols={6}>
              {showPreview ? (
                <img src={URL.createObjectURL(selectedFile)} className="h-20" alt="" onError={() => setShowPreview(false)} />
              ) : (
                <div className="bg-gray-200 h-20 w-20 rounded-md">
                  <i className="fas fa-file fa-2x pt-6 pl-7 text-gray-400"></i>
                </div>
              )}
              <div className="text-sm text-gray-400 pt-2">
                {selectedFile.name}
                <button type="button" onClick={removeFile} className="text-primary-900 text-xs pl-4">
                  Remove file
                </button>
              </div>
              {error["file"] && <ErrorInline>{error["file"]}</ErrorInline>}
            </FormGroup>
          ) : (
            <FormGroup cols={6}>
              <label className="block">
                <span className="sr-only">Choose File</span>
                <input
                  type="file"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-blue-100 file:text-primary-900 hover:file:bg-blue-100"
                  onChange={handleFile}
                />
              </label>
              {error["file"] && <ErrorInline>{error["file"]}</ErrorInline>}
            </FormGroup>
          )}

          <FormGroup>
            <Label htmlFor="fileName">File Name</Label>
            <TextInput
              type="text"
              name="fileName"
              id="fileName"
              placeholder="Required"
              value={fileName}
              onChange={handleFileName}
            />
            {error["fileName"] && <ErrorInline>{error["fileName"]}</ErrorInline>}
          </FormGroup>
          <FormGroup cols={3}>
            <Label htmlFor="tags">Tags</Label>
            <TagsInput name="tags" onChange={handleTagInputChange} />
          </FormGroup>
          <InputGroupTextarea name="fileDescription" error={error} />
          <InputGroupDropdown
            name="fileAccess"
            label="Share With"
            options={shareOptions}
            value="reference"
            displayLabel="name"
            error={error}
            isInModal={true}
            onChange={handleFileAccess}
          />
        </Form>
        {fileAccess === shareOptions[2].name && (
          <Fragment>
            <Table className="table-fixed">
              <THead>
                <tr>
                  <Th className="w-1/3">Name</Th>
                  <Th className="w-1/6">Read</Th>
                  <Th className="w-1/6">Update</Th>
                  <Th className="w-1/6">Delete</Th>
                </tr>
              </THead>
              <TBody>
                {currentItems?.map((item, idx) => (
                  <tr className={`${idx % 2 === 0 ? undefined : "bg-gray-50"}`} key={item.staffReference}>
                    <Td className="w-1/3">
                      <div className="">
                        <img alt="img" src={item.profileImageUrl} className="h-5 w-5 rounded-full inline mr-2"></img>
                        {item.fullName}
                      </div>
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitch
                        enabled={permissions[item.staffReference] ? permissions[item.staffReference].read : false}
                        setEnabled={() => togglePermission(item, "read", filePermission.read.id)}
                        tooltip=""
                      />
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitch
                        enabled={permissions[item.staffReference] ? permissions[item.staffReference].update : false}
                        setEnabled={() => togglePermission(item, "update", filePermission.update.id)}
                        tooltip=""
                      />
                    </Td>
                    <Td className="w-1/6">
                      <ToggleSwitch
                        enabled={permissions[item.staffReference] ? permissions[item.staffReference].delete : false}
                        setEnabled={() => togglePermission(item, "delete", filePermission.delete.id)}
                        tooltip=""
                      />
                    </Td>
                  </tr>
                ))}
              </TBody>
            </Table>
            <div className="flex justify-center py-2">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </Fragment>
        )}
      </Modal>
    </>
  )
}

export default UploadFileModal
