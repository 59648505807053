import React, { useRef, useState, useEffect } from "react"
import { ThreeDotsSpinner } from "library/styled/spinners"

interface Props {
  url: string
}

const FileViewer: React.FC<Props> = ({ url }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const interval = useRef<NodeJS.Timeout | null>(null)

  const [loaded, setLoaded] = useState(false)

  const clearCheckingInterval = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  const onIframeLoaded = () => {
    clearCheckingInterval()
    setLoaded(true)
  }

  useEffect(() => {
    interval.current = setInterval(() => {
      try {
        // google Docs page is blank (204), hence we need to reload the iframe.
        if (iframeRef.current?.contentWindow?.document.body.innerHTML === "") {
          iframeRef.current.src = url
        }
      } catch (e) {
        // google Docs page is being loaded, but will throw CORS error.
        // it means that the page won't be blank, and we can remove the checking interval.
        onIframeLoaded()
      }
    }, 4000) // 4000ms is a reasonable time to load a 2MB document

    return () => {
      clearCheckingInterval()
    }
  }, [])

  return (
    <>
      <iframe ref={iframeRef} className="h-[50rem] w-full" src={url} onLoad={onIframeLoaded}></iframe>
      {!loaded && <ThreeDotsSpinner left="48%" />}
    </>
  )
}

export default FileViewer
