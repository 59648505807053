import React from "react"
import { observer } from "mobx-react"
import { ErrorInline, FormGroup, Label, TextInput } from "../styled/styled"
import { action, makeObservable, observable, runInAction } from "mobx"
import ToolTip from "../styled/tooltip"
import { integerValidation, maxNumberValidation, minNumberValidation, requiredNumberValidation } from "utils/validation"

export class NumberObservable {
  number?: number
  errorMessage?: string

  constructor(number?: number) {
    makeObservable(this, {
      number: observable,
      errorMessage: observable,
    })

    this.number = number
  }
}

interface Props {
  id: string
  placeholder?: string
  label?: string
  value: NumberObservable
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  tooltip?: string
  isRequired?: boolean
  isInteger?: boolean
  minValue?: number
  maxValue?: number
  cols?: number
  disabled?: boolean
}

const NumberInputGroup = ({
  id,
  placeholder = "Optional",
  label,
  value,
  tooltip,
  isRequired,
  isInteger,
  minValue,
  maxValue,
  onFocus,
  onBlur,
  onChange,
  cols = 3,
  disabled,
}: Props) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredNumberValidation(value.number, label, isRequired)
      if (!value.errorMessage) value.errorMessage = integerValidation(value.number, label, isInteger)
      if (!value.errorMessage) value.errorMessage = minNumberValidation(value.number, label, minValue)
      if (!value.errorMessage) value.errorMessage = maxNumberValidation(value.number, label, maxValue)
    })
  }
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={id}>
        {label}
        {tooltip && (
          <ToolTip text={tooltip} id={"Id"}>
            <i className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}
      </Label>
      <TextInput
        type="number"
        id={id}
        data-testid={id}
        placeholder={placeholder ?? isRequired ? "Required" : "Optional"}
        value={value.number === undefined ? "" : value.number}
        onChange={action((e) => {
          const parsedValue = parseFloat(e.target.value)
          if (!isNaN(parsedValue)) {
            value.number = parsedValue
          } else {
            value.number = undefined
          }

          validate(true)
          if (onChange) onChange(e)
        })}
        onBlur={(event) => {
          validate()
          if (onBlur) onBlur(event)
        }}
        onFocus={onFocus}
        disabled={disabled}
        className={disabled ? "bg-gray-100" : ""}
      />
      {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(NumberInputGroup)
