import { TextObservable } from "library/observables/inputGroupText"
import { runInAction } from "mobx"
import { AddOrUpdateItemInput } from "../04_itemSharedModel/inputModels"
import { isSame } from "utils/extensions"
import { Item } from "../04_itemSharedModel/itemModel"
import { TaxRateObservable } from "library/components/itemTaxSelection"
import { NumberObservable } from "library/observables/inputGroupNumber"
import { SelectOptionObservable } from "../../../library/observables/inputGroupDropdown"
import { itemTypeService } from "../../../constants/itemTypes"

export default class addOrEditItemPageModel {
  itemReference: TextObservable
  itemTypeSelection: SelectOptionObservable
  itemCode: TextObservable
  name: TextObservable
  description: TextObservable
  salePrice: NumberObservable
  taxRateSelection: TaxRateObservable

  constructor() {
    this.itemReference = new TextObservable()
    this.itemTypeSelection = new SelectOptionObservable(itemTypeService.reference, itemTypeService.name)
    this.itemCode = new TextObservable()
    this.name = new TextObservable()
    this.description = new TextObservable()
    this.salePrice = new NumberObservable()
    this.taxRateSelection = new TaxRateObservable()
  }

  getUpdateItemInput() {
    const input: AddOrUpdateItemInput = {
      itemReference: this.itemReference.text,
      itemTypeReference: this.itemTypeSelection.reference,
      itemCode: this.itemCode.text,
      name: this.name.text,
      description: this.description.text,
      sale: {
        unitPrice: this.salePrice.number,
        taxReference: this.taxRateSelection.taxReference,
      },
    }
    return input
  }

  setItem(item: Item) {
    runInAction(() => {
      this.itemReference.text = item.itemReference
      this.itemTypeSelection.reference = item.itemType.itemTypeReference
      this.itemTypeSelection.name = item.itemType.name
      this.itemCode.text = item.itemCode
      this.name.text = item.name
      this.description.text = item.description
      this.salePrice.number = item.sale.unitPrice
      this.taxRateSelection.taxReference = item.sale.taxReference
      this.taxRateSelection.name = item.sale.taxName
    })
  }

  clear() {
    this.itemTypeSelection = new SelectOptionObservable(itemTypeService.reference, itemTypeService.name)
    this.itemCode = new TextObservable()
    this.name = new TextObservable()
    this.description = new TextObservable()
    this.salePrice = new NumberObservable()
  }

  // Method to handle API error messages
  handleApiErrors(errorResponse: any) {
    runInAction(() => {
      // Loop through the error messages
      errorResponse.response?.data.messages?.forEach((v: any) => {
        if (isSame(v.property, "ItemTypeReference")) {
          this.itemTypeSelection.errorMessage = v.description
        }
        if (isSame(v.property, "Name")) {
          this.name.errorMessage = v.description
        }
        if (isSame(v.property, "ItemCode")) {
          this.itemCode.errorMessage = v.description
        }
        if (isSame(v.property, "Description")) {
          this.description.errorMessage = v.description
        }
        if (isSame(v.property, "Sale.UnitPrice")) {
          this.salePrice.errorMessage = v.description
        }
        if (isSame(v.property, "Sale.TaxReference")) {
          this.taxRateSelection.errorMessage = v.description
        }
      })
    })
  }
}
