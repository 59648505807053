import React from "react"
import { toLocaleDate, toLocaleDateTime } from "common"
import { Address } from "models/shared/address"
import { DlGroup, Dt, Dd, Label } from "library/styled/styled"
import { showDashIfEmpty, toCurrency } from "utils/extensions"
import { StaffMember } from "../../components/Task/04_sharedModel/taskModel"
import ToolTip from "./tooltip"

interface Props {
  label?: string
  value?: string | number | undefined
  breakAll?: boolean
  address?: Address
  cols?: number
  link?: string
}

interface BooleanViewProps {
  label?: string
  value?: boolean
}

interface CreatedViewProps {
  label?: string
  createdBy?: string
  createdDateTimeUtc?: string
}

interface TagViewProps {
  label: string
  list?: any[]
  optionLabel: string
  optionValue: string
}

interface CurrencyFormatLabelProps {
  amount?: number
  currencyCode?: string
  localeCode?: string
  cols?: number
  label?: string
}

interface StaffMemberViewProps {
  staffMembers?: StaffMember[]
}

const TextView = ({ label, value, breakAll = false, cols = 1, link = "" }: Props) => {
  return (
    <DlGroup cols={cols}>
      <Dt>{label}</Dt>
      <Dd breakAll={breakAll} isLink={link ? true : false} id={label}>
        {link ? (
          <a className={"underline"} href={link}>
            {showDashIfEmpty(value)}
          </a>
        ) : (
          showDashIfEmpty(value)
        )}
      </Dd>
    </DlGroup>
  )
}
const CreatedView = ({ label = "Created By", createdBy, createdDateTimeUtc }: CreatedViewProps) => {
  return (
    <DlGroup>
      <Dt>{label}</Dt>
      <Dd>{!(createdBy === null || undefined) ? `${createdBy} | ${toLocaleDateTime(createdDateTimeUtc)}` : "-"}</Dd>
    </DlGroup>
  )
}
const DescriptionView = ({ label = "Description", value }: Props) => {
  return (
    <DlGroup cols={2}>
      <Dt>{label}</Dt>
      <Dd>{showDashIfEmpty(value)}</Dd>
    </DlGroup>
  )
}
const DateView = ({ label, value }: Props) => {
  return (
    <DlGroup>
      <Dt>{label}</Dt>
      <Dd>{showDashIfEmpty(toLocaleDate(value))}</Dd>
    </DlGroup>
  )
}
const DateTimeView = ({ label, value }: Props) => {
  return (
    <DlGroup>
      <Dt>{label}</Dt>
      <Dd>{showDashIfEmpty(toLocaleDateTime(value))}</Dd>
    </DlGroup>
  )
}
const AddressView = ({ label = "Address", address, cols = 2 }: Props) => {
  return (
    <DlGroup cols={cols}>
      <Dt>{label}</Dt>
      {address !== null && address?.route && (
        <Dd>{`${address?.route} ${address?.city} ${address?.regionName} ${address?.postalCode} ${address?.countryName}`}</Dd>
      )}
      {(address === null || address?.route === undefined) && <Dd>-</Dd>}
    </DlGroup>
  )
}
const LabelView = ({ label, cols = 2 }: Props) => {
  return (
    <DlGroup cols={cols}>
      <Label>{label}</Label>
    </DlGroup>
  )
}

const BooleanView = ({ label, value }: BooleanViewProps) => {
  return (
    <DlGroup>
      <Dt>{label}</Dt>
      <Dd>{value ? "Yes" : "No"}</Dd>
    </DlGroup>
  )
}

const TagView = ({ label, list, optionLabel, optionValue }: TagViewProps) => {
  return (
    <DlGroup cols={6}>
      <Dt>{label}</Dt>
      <Dd>
        {list?.length === 0 && <div>-</div>}
        {list?.map((option) => (
          <div
            key={option[optionValue]}
            className="inline rounded-sm text-xs p-1 mr-2 mb-2 cursor-default bg-gray-100 tracking-wide"
          >
            {option[optionLabel]}
          </div>
        ))}
      </Dd>
    </DlGroup>
  )
}

const CurrencyView = ({ amount, currencyCode, localeCode, label, cols }: CurrencyFormatLabelProps) => {
  return (
    <DlGroup cols={cols}>
      <Dt>{label}</Dt>
      <Dd>{toCurrency(amount, currencyCode, localeCode)}</Dd>
    </DlGroup>
  )
}

// Formats to currency label with currency code
// send currencyCode as undefined if you don't want to show currencyCode prefix
const CurrencyFormatLabel = ({ amount, currencyCode, localeCode }: CurrencyFormatLabelProps) => {
  return <>{toCurrency(amount, currencyCode, localeCode)}</>
}

const StaffMemberView = ({ staffMembers }: StaffMemberViewProps) => {
  return (
    <DlGroup cols={1}>
      <Dt>Staff Members</Dt>
      <Dd> {staffMembers?.map((staff: StaffMember, idx: number) => (
        <ToolTip key={staff.staffReference} text={staff.name} id={staff.staffReference}>
           <span className="inline-flex items-center">
                <img alt="" src={staff.profileImageUrl}
                     className="h-7 w-7 rounded-full inline-block ring-2 ring-white" />
           </span>
        </ToolTip>
      ))}</Dd>
      {(staffMembers?.length ?? 0) <= 0 && <Dd>-</Dd>}
    </DlGroup>
  )
}

export {
  TextView,
  DescriptionView,
  DateView,
  AddressView,
  LabelView,
  BooleanView,
  CreatedView,
  TagView,
  CurrencyFormatLabel,
  DateTimeView,
  CurrencyView,
  StaffMemberView
}
