export const mutation = {
  createCustomFieldDefinition: `mutation CreateCustomFieldDefinition($input: CreateCustomFieldDefinitionInput!) {
        createCustomFieldDefinition(input: $input) {
          definitionReference
          normalizedName
        }
      }`,
  updateCustomFieldDefinition: `mutation UpdateCustomFieldDefinition($input: UpdateCustomFieldDefinitionInput!) {
    updateCustomFieldDefinition(input: $input) {
      definitionReference
      definitionReference
    }
  }`,
  deleteCustomFieldDefinition: `mutation DeleteCustomFieldDefinition($input: DeleteCustomFieldDefinitionInput!) {
    deleteCustomFieldDefinition(input: $input) {
      definitionReference
    }
  }`,
  createCategory: `mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      categoryReference
    }
  }`,
  updateCategory: `mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      categoryReference    
    }
  }`,
  deleteCategory: `mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      categoryReference
    }
  }`,
  toggleFeatureSetting: `mutation ToggleFeatureSetting($input: ToggleFeatureSettingInput!) {
    toggleFeatureSetting(input: $input) {
      featureSettingReference
      isEnabled
    }
  }`,
  addJobState: `mutation AddState($input: AddStateInput!) {
    addState(input: $input) {    
      stateReference
    }
  }`,
  updateJobState: `mutation UpdateState($input: UpdateStateInput!) {
    updateState(input: $input) {    
      stateReference
    }
  }`,
  removeJobState: `mutation RemoveState($input: RemoveStateInput!) {
    removeState(input: $input) {    
      stateReference
    }
  }`,
  addPhoneNumberType: `mutation AddPhoneNumberType($input: AddPhoneNumberTypeInput!) {
    addPhoneNumberType(input: $input) {
      phoneNumberTypeReference
    }
  }`,
  updatePhoneNumberType: `mutation UpdatePhoneNumberType($input: UpdatePhoneNumberTypeInput!) {
    updatePhoneNumberType(input: $input) {
      phoneNumberTypeReference
    }
  }`,
  removePhoneNumberType: `mutation RemovePhoneNumberType($input: RemovePhoneNumberTypeInput!) {
    removePhoneNumberType(input: $input) {
      phoneNumberTypeReference
    }
  }`,
  addAddressType: `mutation AddAddressType($input: AddAddressTypeInput!) {
    addAddressType(input: $input) {
      addressTypeReference
    }
  }`,
  updateAddressType: `mutation UpdateAddressType($input: UpdateAddressTypeInput!) {
    updateAddressType(input: $input) {
      addressTypeReference
    }
  }`,
  removeAddressType: `mutation RemoveAddressType($input: RemoveAddressTypeInput!) {
    removeAddressType(input: $input) {
      addressTypeReference
    }
  }`,
  addContactType: `mutation AddContactType($input: AddContactTypeInput!) {
    addContactType(input: $input) {
      contactTypeReference
    }
  }`,
  updateContactType: `mutation UpdateContactType($input: UpdateContactTypeInput!) {
    updateContactType(input: $input) {
      contactTypeReference
    }
  }`,
  removeContactType: `mutation RemoveContactType($input: RemoveContactTypeInput!) {
    removeContactType(input: $input) {
      contactTypeReference
    }
  }`,
  addTimeEntryType: `mutation AddTimeEntryType($input: AddTimeEntryTypeInput!) {
    addTimeEntryType(input: $input) {    
      timeEntryTypeReference
    }
  }`,
  updateTimeEntryType: `mutation UpdateTimeEntryType($input: UpdateTimeEntryTypeInput!) {
    updateTimeEntryType(input: $input) {    
      timeEntryTypeReference
    }
  }`,
  removeTimeEntryType: `mutation RemoveTimeEntryType($input: RemoveTimeEntryTypeInput!) {
    removeTimeEntryType(input: $input) {    
      timeEntryTypeReference
    }
  }`,
  addLeaveType: `mutation AddLeaveType($input: AddLeaveTypeInput!) {
    addLeaveType(input: $input) {
      leaveTypeReference
    }
  }`,
  updateLeaveType: `mutation UpdateLeaveType($input: UpdateLeaveTypeInput!) {
    updateLeaveType(input: $input) {
      leaveTypeReference
    }
  }`,
  removeLeaveType: `mutation RemoveLeaveType($input: RemoveLeaveTypeInput!) {
    removeLeaveType(input: $input) {
      leaveTypeReference
    }
  }`,
  addInquiryState: `mutation AddInquiryState($input: AddStateInput!) {
    addState(input: $input) {    
      stateReference
    }
  }`,
  updateInquiryState: `mutation UpdateInquiryState($input: UpdateStateInput!) {
    updateState(input: $input) {    
      stateReference
    }
  }`,
  removeInquiryState: `mutation RemoveInquiryState($input: RemoveStateInput!) {
    removeState(input: $input) {    
      stateReference
    }
  }`,
  addInquirySource: `mutation AddInquirySource($input: AddSourceInput!) {
    addSource(input: $input) {    
      sourceReference
    }
  }`,
  updateInquirySource: `mutation UpdateInquirySource($input: UpdateSourceInput!) {
    updateSource(input: $input) {    
      sourceReference
    }
  }`,
  removeInquirySource: `mutation RemoveInquirySource($input: RemoveSourceInput!) {
    removeSource(input: $input) {    
      sourceReference
    }
  }`,
  addRole: `mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      roleReference
    }
  }`,
  updateRole: `mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      roleReference
    }
  }`,
  removeRole: `mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      roleReference
    }
  }`,
  addRolePermission: `mutation AddPermissionToRole($input: AddPermissionToRoleInput!) {
    addPermissionToRole(input: $input) {
      permissionReference
      roleReference
    }
  }`,
  removeRolePermission: `mutation RemovePermissionFromRole($input: RemovePermissionFromRoleInput!) {
    removePermissionFromRole(input: $input) {
     permissionReference
     roleReference
   }
 }`,
  addReviewLink: `mutation AddReviewLink($input: AddReviewLinkInput!) {
    addReviewLink(input: $input) {
      reviewLinkReference
    }
  }`,
  updateFeatureSettingAttributeValue: `mutation UpdateFeatureSettingAttributeValue($input: UpdateFeatureSettingAttributeValueInput!) {
    updateFeatureSettingAttributeValue(input: $input) {
      featureSettingAttributeValueReference
    }
  }`,
  getOrAddDraftClient: `mutation GetOrAddDraftClient($input: GetOrAddDraftClientInput!) {
    getOrAddDraftClient(input: $input) {
      clientReference
      clientNumber
    }
  }`,
  addContact: `mutation AddContact($input: AddContactInput!) {
    addContact(input: $input) {
      contactReference    
    }
  }`,
  updateContact: `mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      contactReference    
    }
  }`,
  removeContact: `mutation RemoveContact($input: RemoveContactInput!) {
    removeContact(input: $input) {
      contactReference   
    }
  }`,
  addNote: `mutation AddNote($input: AddNoteInput!) {
    addNote(input: $input) {
      noteReference
    }
  }`,
  updateNote: `mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      noteReference
    }
  }`,
  removeNote: `mutation RemoveNote($input: RemoveNoteInput!) {
    removeNote(input: $input) {
      noteReference
    }
  }`,
  addReminder: `mutation AddReminder($input: AddReminderInput!) {
    addReminder(input: $input) {
      reminderReference
    }
  }`,
  updateReminder: `mutation UpdateReminder($input: UpdateReminderInput!) {
    updateReminder(input: $input) {    
      reminderReference
    }
  }`,
  removeReminder: `mutation RemoveReminder($input: RemoveReminderInput!) {
    removeReminder(input: $input) {
      reminderReference
    }
  }`,
  addAddress: `mutation AddAddress($input: AddAddressInput!) {
    addAddress(input: $input) {
      addressReference
    }
  }`,
  updateAddress: `mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      addressReference
    }
  }`,
  removeAddress: `mutation RemoveAddress($input: RemoveAddressInput!) {
    removeAddress(input: $input) {
      addressReference
    }
  }`,
  addEntityToFile: `mutation AddEntityToFile($input: AddEntityToFileInput!) {
    addEntityToFile(input: $input) {
      fileReference   
    }
  }`,
  addCustomField: `mutation AddCustomField($input: AddCustomFieldInput!) {
    addCustomField(input: $input) {
      customFieldResponse {
        fieldReference
      }
    }
  }`,
  updateCustomField: `mutation UpdateCustomField($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      customFieldResponse {
        fieldReference
      }
    }
  }`,
  removeCustomField: `mutation RemoveCustomField($input: RemoveCustomFieldInput!) {
    removeCustomField(input: $input) {
      customFieldReference
    }
  }`,
  updateClient: `mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      clientReference
      clientNumber
    }
  }`,
  removeNotification: `mutation RemoveNotificationHistory($input: RemoveNotificationHistoryInput!) {
    removeNotificationHistory(input: $input) {
      notificationHistoryReference
    }
  }`,
  readNotification: `mutation SetNotificationHistoryToRead($input: SetNotificationHistoryToReadInput!) {
    setNotificationHistoryToRead(input: $input) {
      notificationHistoryReference
    }
  }`,
  readAllNotifications: `mutation SetAllNotificationsToRead($input: SetAllNotificationToReadInput!) {
    setAllNotificationsToRead(input: $input) {
      userReference
    }
  }`,
  updatePartnerOrganisation: `mutation UpdatePartnerOrganisation($input: UpdatePartnerOrganisationInput!) {
    updatePartnerOrganisation(input: $input) {
      organisationReference
    }
  }`,
  updateCustomFieldOption: `mutation UpdateCustomFieldOption($input: UpdateCustomFieldOptionInput!) {
    updateCustomFieldOption(input:$input){    
      optionReference  
    }
  }`,
  deleteCustomFieldOption: `mutation RemoveCustomFieldOption($input: RemoveCustomFieldOptionInput!) {
    removeCustomFieldOption(input:$input){    
      optionReference  
    }
  }`,
  addCustomFieldOption: `mutation AddCustomFieldOption($input: AddCustomFieldOptionInput!) {
    addCustomFieldOption(input:$input){    
      optionReference  
    }
  }`,
  updateOrganisation: `mutation UpdateOrganisationDetail($input: UpdateOrganisationDetailInput!) {
    updateOrganisationDetail(input: $input) {
      organisationReference      
    }
  }`,
  updateSequenceNumber: `mutation UpdateSequenceNumber($input: UpdateSequenceNumberInput!) {
    updateSequenceNumber(input: $input) {
      sequenceNumberTypeReference
    }
  }`,
  addOrUpdateTaxIdentification: `mutation AddOrUpdateTaxIdentification($input: AddOrUpdateTaxIdentificationInput!) {
    addOrUpdateTaxIdentification(input: $input) {
      name
      number
    }
  }`,
  addTax: `mutation AddTax($input: AddTaxInput!) {
    addTax(input: $input) {
      taxReference   
    }
  }`,
  updateTax: `mutation UpdateTax($input:UpdateTaxInput!) {
    updateTax(input: $input) {
      taxReference   
    }
  }`,
  removeTax: `mutation RemoveTax($input:RemoveTaxInput!) {
    removeTax(input: $input) {
      taxReference   
    }
  }`,
  addCurrency: `mutation AddCurrency($input: AddCurrencyInput!) {
    addCurrency(input: $input) {
      currencyReference  
    }
  }`,
  updateCurrency: `mutation UpdateCurrency($input: UpdateCurrencyInput!) {
    updateCurrency(input: $input) {
      currencyReference  
    }
  }`,
  removeCurrency: `mutation RemoveCurrency($input: RemoveCurrencyInput!) {
    removeCurrency(input: $input) {
      currencyReference  
    }
  }`,
  addPaymentMethod: `mutation AddPaymentMethod($input: AddPaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      paymentMethodReference 
    }
  }`,
  updatePaymentMethod: `mutation UpdatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      paymentMethodReference 
    }
  }`,
  removePaymentMethod: `mutation RemovePaymentMethod($input: RemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      paymentMethodReference 
    }
  }`,
  addInvoiceReminder: `mutation AddInvoiceReminder($input: AddInvoiceReminderInput!) {
    addInvoiceReminder(input: $input) {
      invoiceReminderReference
    }
  }`,
  updateInvoiceReminder: `mutation UpdateInvoiceReminder($input: UpdateInvoiceReminderInput!) {
    updateInvoiceReminder(input: $input) {
      invoiceReminderReference
    }
  }`,
  removeInvoiceReminder: `mutation RemoveInvoiceReminder($input: RemoveInvoiceReminderInput!) {
    removeInvoiceReminder(input: $input) {
      invoiceReminderReference
    }
  }`,
  getOrAddInvoice: `mutation GetOrAddInvoice($input: GetOrAddInvoiceInput!) {
    getOrAddInvoice(input: $input) {
      invoiceReference
      concurrencyToken    
    }
  }`,
  updateInvoice: `mutation UpdateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      invoiceReference
      invoiceNumber
      concurrencyToken    
    }
  }`,
  updateInvoiceStatus: `mutation UpdateInvoiceStatus($input: UpdateInvoiceStatusInput!) {
    updateInvoiceStatus(input: $input) {    
      invoiceReference
      concurrencyToken
    }
  }`,
  sendEmail: `mutation SendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      emailReference
    }
  }`,
  addOrUpdateTemplate: `mutation AddOrUpdateTemplate($input: AddOrUpdateTemplateInput!) {
    addOrUpdateTemplate(input: $input) {
      templateReference
    }
  }`,
  removeTemplate: `mutation RemoveTemplate($input: RemoveTemplateInput!) {
    removeTemplate(input: $input) {
      templateReference
    }
  }`,
  removeFile: `mutation RemoveFile($input: RemoveFileInput!) {
    removeFile(input: $input) {
      fileReference  
    }
  }`,
  addOrUpdateReportBranding: `mutation AddOrUpdateReportBranding($input: AddOrUpdateReportBrandingInput!) {
    addOrUpdateReportBranding(input: $input) {
      reportReference
    }
  }`,
  addNoteType: `mutation AddNoteType($input: AddNoteTypeInput!) {
    addNoteType(input: $input) {
      noteTypeReference
    }
  }`,
  updateNoteType: `mutation UpdateNoteType($input: UpdateNoteTypeInput!) {
    updateNoteType(input: $input) {
      noteTypeReference
    }
  }`,
  removeNoteType: `mutation RemoveNoteType($input: RemoveNoteTypeInput!) {
    removeNoteType(input: $input) {
      noteTypeReference
    }
  }`,
  removeInvoice: `mutation RemoveInvoice($input: RemoveInvoiceInput!) {
    removeInvoice(input: $input) {
      invoiceReference    
    }
  }`,
  addPayment: `mutation AddPayment($input: AddPaymentInput!) {
    addPayment(input: $input) {
      paymentReference
    }
  }`,
  updatePayment: `mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      paymentReference
    }
  }`,
  removePayment: `mutation RemovePayment($input: RemovePaymentInput!) {
    removePayment(input: $input) {
      paymentReference
    }
  }`,
  generateReportLink: `mutation GenerateReportLink($input: GenerateReportLinkInput!) {
    generateReportLink(input: $input) {
      reportUrl
    }
  }`,
  getOrAddItem: `mutation GetOrAddItem($input: GetOrAddItemInput!) {
    getOrAddItem(input: $input) {
      itemReference
    }
  }`,
  updateItem: `mutation UpdateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      itemReference    
    }
  }`,
  removeItem: `mutation RemoveItem($input: RemoveItemInput!) {
    removeItem(input: $input) {
      itemReference
    }
  }`,
  downloadLink: `mutation DownloadLink($input: DownloadLinkInput!) {
    downloadLink(input: $input) {
      fileReference
      url
      expiryDateTimeUtc
    }
  }`,
  updateFile: `mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      fileReference    
    }
  }`,
  moveSchedulerJob: `mutation MoveSchedulerJob($input: MoveSchedulerJobInput!) {
    moveSchedulerJob(input: $input) {
      jobReference
      jobResourceReference
     }
   }`,
  moveJob: `mutation MoveJob($input: MoveJobInput!) {
    moveJob(input: $input) {
      jobReference
    }
  }`,
  updateSchedulerResourceDisplayOrder: `mutation UpdateSchedulerResourceDisplayOrder($input: UpdateSchedulerResourceDisplayOrderInput!) {
    updateSchedulerResourceDisplayOrder(input: $input) {
      result
    }
  }`,
  updateTask: `mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {    
      taskReference
    }
  }`,
  addTask: `mutation AddTask($input: AddTaskInput!) {
    addTask(input: $input) {    
      taskReference
    }
  }`,
  removeTask: `mutation RemoveTask($input: RemoveTaskInput!) {
    removeTask(input: $input) {    
      taskReference
    }
  }`,
  addTaskStatus: `mutation AddTaskStatus($input: AddTaskStatusInput!) {
    addTaskStatus(input: $input) {    
      taskStatusReference
    }
  }`,
  updateTaskStatus: `mutation UpdateTaskStatus($input: UpdateTaskStatusInput!) {
    updateTaskStatus(input: $input) {    
      taskStatusReference
    }
  }`,
  removeTaskStatus: `mutation RemoveTaskStatus($input: RemoveTaskStatusInput!) {
    removeTaskStatus(input: $input) {    
      taskStatusReference
    }
  }`,
  changeTaskStatus: `mutation ChangeTaskStatus($input: ChangeTaskStatusInput!) {
    changeTaskStatus(input: $input) {    
      taskReference
    }
  }`

}
