import { SelectOptionObservable } from "library/observables/inputGroupDropdown"
import { TextObservable } from "library/observables/inputGroupText"
import { PrimaryContact } from "models/people/client"

export default class PrimaryContactObservable {
    emailAddress: TextObservable
    givenName: TextObservable
    familyName: TextObservable
    dateOfBirth: TextObservable
    fullName: TextObservable
    phoneNumberReference: TextObservable
    phoneNumber: TextObservable
    PhoneNumberType: SelectOptionObservable
  
    constructor() {
      this.emailAddress = new TextObservable()
      this.givenName = new TextObservable()
      this.familyName = new TextObservable()
      this.dateOfBirth = new TextObservable()
      this.fullName = new TextObservable()
      this.phoneNumberReference = new TextObservable()
      this.phoneNumber = new TextObservable()
      this.PhoneNumberType = new SelectOptionObservable()
    }
  
    setPrimaryContact(contact: PrimaryContact | undefined) {
      this.emailAddress.text = contact?.emailAddress
      this.givenName.text = contact?.givenName
      this.familyName.text = contact?.familyName
      this.dateOfBirth.text = contact?.dateOfBirth
      this.fullName.text = contact?.fullName
      this.phoneNumberReference.text = contact?.phoneNumberReference
      this.phoneNumber.text = contact?.phoneNumber
      this.PhoneNumberType.reference = contact?.phoneNumberTypeReference
      this.PhoneNumberType.name = contact?.phoneNumberTypeName
    }
  }