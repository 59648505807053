import React from "react"
import { observer } from "mobx-react"
import { useMetadata } from "../../../context/metadata-context"
import { useTaxIdentification } from "../../../hooks/finance/taxIdentification"
import { Link } from "react-router-dom"
import { isProvided } from "../../../utils/extensions"
import Conditional from "../../../library/styled/conditional"
import { A, FormGroup, Label, LabelLight } from "../../../library/styled/styled"
import ToolTip from "../../../library/styled/tooltip"

const InvoiceOrganisationDetail = () => {
  const { organisation } = useMetadata()
  const { data: taxIdentification } = useTaxIdentification()

  function getAddress() {
    const isRouteProvided = isProvided(organisation?.address?.route)
    const isRegionProvided = isProvided(organisation?.address?.regionName)
    const isCityProvided = isProvided(organisation?.address?.city)
    const isCountryProvided = isProvided(organisation?.address?.countryName)
    const isPostCodeProvided = isProvided(organisation?.address?.postalCode)

    return (
      <>
        <Conditional when={isRouteProvided}>{organisation?.address?.route}</Conditional>
        <Conditional when={!isRouteProvided}>
          <span className={"pl-1"}>
            Company address is not setup.
            <div>
              Please{" "}
              <Link to="/settings" target="_blank" className="text-primary-800 hover:text-primary-900">
                click here
              </Link>{" "}
              to setup company address.
            </div>
          </span>
        </Conditional>
        <Conditional when={isCityProvided}>
          <div>
            {organisation?.address?.city}
            {isRegionProvided ? "" : isPostCodeProvided ? " " + organisation?.address?.postalCode : ""}
          </div>
        </Conditional>
        <Conditional when={isRegionProvided}>
          <span>{organisation?.address?.regionName}</span>
        </Conditional>
        <Conditional when={isPostCodeProvided && isRegionProvided}>
          <span>{" " + organisation?.address?.postalCode}</span>
        </Conditional>
        <Conditional when={isCountryProvided && isRouteProvided}>
          <div>{organisation?.address?.countryName}</div>
        </Conditional>
      </>
    )
  }

  return (
    <>
      <FormGroup className="text-right">
        <div className="pb-0.5 block text-base font-medium text-gray-700">
          {organisation?.name}
          <ToolTip
            className={"w-60"}
            overlay={
              <span>
                Organisation details are displayed on customer invoice.{" "}
                <Link to="/settings" target="_blank" className="underline">
                  Click here
                </Link>{" "}
                to edit these details.
              </span>
            }
          >
            <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        </div>

        <LabelLight className={"pb-1.5"}>{organisation?.phoneNumber ?? ""}</LabelLight>
        <LabelLight className={"pb-0.5"}>{organisation?.emailAddress ?? ""}</LabelLight>
        <Conditional when={isProvided(taxIdentification?.name)}>
          <LabelLight>
            {taxIdentification?.name}: {taxIdentification?.number}
            <ToolTip
              className={"w-60"}
              overlay={
                <span>
                  Tax identification (Gst,vat etc) are displayed on customer invoice.{" "}
                  <Link to="/settings/invoice" target="_blank" className="underline">
                    Click here
                  </Link>{" "}
                  to edit these settings.
                </span>
              }
            >
              <i data-testid={"text-input-group-tool-tip"} className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          </LabelLight>
        </Conditional>

        <LabelLight className="pt-3">{getAddress()}</LabelLight>
      </FormGroup>
    </>
  )
}

export default observer(InvoiceOrganisationDetail)
