import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import NotificationTabs from "./notification-tabs"

interface Props {
  open: boolean
  onClose: () => void
  unreadCount: number | undefined
}

const NotificationBar = ({ open, onClose, unreadCount }: Props) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{
                    backgroundColor: "#f9fafb",
                    borderLeft: "1px solid #e7eaec",
                    borderTop: "1px solid #e7eaec",
                    width: "360px",
                    marginTop: "64px",
                  }}
                >
                  <div className="flex flex-col shadow-xl">
                    <div className="border-b border-gray-200">
                      <div className="-mb-px">
                        <NotificationTabs onClose={onClose} unreadCount={unreadCount} />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default NotificationBar
