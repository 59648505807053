import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handleMutationError } from "common"
import { keys } from "constants/keys"
import {
  InputGroupButton,
  InputGroupColor,
  InputGroupNumber,
  InputGroupText,
  InputGroupTextarea
} from "library/styled/input-group"
import { Modal } from "library/styled/modal"
import { Form } from "library/styled/styled"
import { successToaster } from "library/styled/toasters"
import { useAddTaskStatus } from "../../../Task/services/taskService"
import { AddTaskStatusInput } from "../../../Task/04_sharedModel/inputModels"

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AddTaskStatus = ({ isOpen, onClose }: Props) => {
  const { mutate: addTaskStatus, isLoading } = useAddTaskStatus()
  const [error, setError] = useState({})
  const queryClient = useQueryClient()

  useEffect(() => {
    setError({})
  }, [isOpen])

  const handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault()
    const { name, description, color, displayOrder } = event.currentTarget.elements
    const input: AddTaskStatusInput = {
      name: name.value,
      color: color.value,
      displayOrder: parseInt(displayOrder.value),
      description: description.value
    }

    addTaskStatus(input, {
      onError: (error: any) => handleMutationError(error.response, setError),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.taskStatusQuery)
        successToaster("Task Status created")
        onClose()
      }
    })
  }

  return (
    <>
      <Modal title="Add Task Status" isOpen={isOpen} onClose={onClose}>
        <Form onSubmit={handleSubmit}>
          <InputGroupText label="Name" name="name" placeholder="Required" error={error} />
          <InputGroupColor label="Color" name="color" error={error} />
          <InputGroupNumber label="Display Order" name="displayOrder" error={error} />
          <InputGroupTextarea label="Description" name="description" error={error} />
          <InputGroupButton isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  )
}

export default AddTaskStatus
