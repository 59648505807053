import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import {
  AddAddressTypeInput,
  AddContactTypeInput,
  AddPhoneNumberTypeInput,
  AddressTypes,
  ContactTypes,
  PhoneNumberTypes,
  RemoveAddressTypeInput,
  RemoveContactTypeInput,
  RemovePhoneNumberTypeInput,
  UpdateAddressTypeInput,
  UpdateContactTypeInput,
  UpdatePhoneNumberTypeInput,
} from "models/people/peopleTypes"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"

//#region Phone Number Type
function usePhoneNumberType() {
  return useQuery(keys.phoneNumberTypeQuery, fetchPhoneNumberTypes, {
    select: (data: Response<PhoneNumberTypes>) => data.data.data.phoneNumberTypes,
  })
}

function fetchPhoneNumberTypes() {
  return graphQLClient.fetch(query.phoneNumberTypeQuery, null, true, common.peopleApiUrl, keys.phoneNumberTypeQuery)
}

function addPhoneNumberType(input: AddPhoneNumberTypeInput) {
  return graphQLClient.mutation(mutation.addPhoneNumberType, input, "AddPhoneNumberType", common.peopleApiUrl)
}

function useAddPhoneNumberType() {
  return useMutation(addPhoneNumberType)
}

function updatePhoneNumberType(input: UpdatePhoneNumberTypeInput) {
  return graphQLClient.mutation(mutation.updatePhoneNumberType, input, "UpdatePhoneNumberType", common.peopleApiUrl)
}

function useUpdatePhoneNumberType() {
  return useMutation(updatePhoneNumberType)
}

function removePhoneNumberType(input: RemovePhoneNumberTypeInput) {
  return graphQLClient.mutation(mutation.removePhoneNumberType, input, "RemovePhoneNumberType", common.peopleApiUrl)
}

function useRemovePhoneNumberType() {
  return useMutation(removePhoneNumberType)
}
//#endregion

//#region Address Type
function useAddressType() {
  return useQuery(keys.addressTypeQuery, fetchAddressTypes, {
    select: (data: Response<AddressTypes>) => data.data.data.addressTypes,
  })
}

function fetchAddressTypes() {
  return graphQLClient.fetch(query.addressTypeQuery, null, true, common.peopleApiUrl, keys.addressTypeQuery)
}

function addAddressType(input: AddAddressTypeInput) {
  return graphQLClient.mutation(mutation.addAddressType, input, "AddAddressType", common.peopleApiUrl)
}

function useAddAddressType() {
  return useMutation(addAddressType)
}

function updateAddressType(input: UpdateAddressTypeInput) {
  return graphQLClient.mutation(mutation.updateAddressType, input, "UpdateAddressType", common.peopleApiUrl)
}

function useUpdateAddressType() {
  return useMutation(updateAddressType)
}

function removeAddressType(input: RemoveAddressTypeInput) {
  return graphQLClient.mutation(mutation.removeAddressType, input, "RemoveAddressType", common.peopleApiUrl)
}

function useRemoveAddressType() {
  return useMutation(removeAddressType)
}
//#endregion

//#region Contact Type
function useContactType() {
  return useQuery(keys.contactTypeQuery, fetchContactTypes, {
    select: (data: Response<ContactTypes>) => data.data.data.contactTypes,
  })
}

function fetchContactTypes() {
  return graphQLClient.fetch(query.contactTypeQuery, null, true, common.peopleApiUrl, keys.contactTypeQuery)
}

function addContactType(input: AddContactTypeInput) {
  return graphQLClient.mutation(mutation.addContactType, input, "AddContactType", common.peopleApiUrl)
}

function useAddContactType() {
  return useMutation(addContactType)
}

function updateContactType(input: UpdateContactTypeInput) {
  return graphQLClient.mutation(mutation.updateContactType, input, "UpdateContactType", common.peopleApiUrl)
}

function useUpdateContactType() {
  return useMutation(updateContactType)
}

function removeContactType(input: RemoveContactTypeInput) {
  return graphQLClient.mutation(mutation.removeContactType, input, "RemoveContactType", common.peopleApiUrl)
}

function useRemoveContactType() {
  return useMutation(removeContactType)
}
//#endregion

export {
  usePhoneNumberType,
  useAddPhoneNumberType,
  useUpdatePhoneNumberType,
  useRemovePhoneNumberType,
  useAddressType,
  useAddAddressType,
  useUpdateAddressType,
  useRemoveAddressType,
  useContactType,
  useAddContactType,
  useUpdateContactType,
  useRemoveContactType,
}
