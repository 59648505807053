import React, { Fragment, useEffect, useState } from "react"
import useLocalStorageState from "utils/localStorage"
import { invoiceColumns } from "components/invoices/04_invoiceList/invoiceListColumns"
import { useInvoice, useInvoiceDetail, useInvoiceStatus } from "components/invoices/services/invoiceService"
import DataGridNew from "library/styled/datagridNew"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import InvoiceListFilters from "components/invoices/04_invoiceList/invoiceListFilters"
import { InvoiceListModel } from "components/invoices/04_invoiceList/invoiceListModel"
import { observer } from "mobx-react"
import { ModalState, useModalState } from "utils/modalStates"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import InvoiceDetailModel from "../03_invoiceDetail/invoiceDetailModel"
import SidePanel from "library/styled/sidePanel"
import InvoiceDetailTabs from "../03_invoiceDetail/invoiceDetailTabs"

function InvoiceListPage() {
  const [columns, setColumns] = useLocalStorageState("invoiceColumns", invoiceColumns)
  const [invoiceListModel] = useState(() => new InvoiceListModel())
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, refetch } = useInvoice(invoiceListModel.getFilter())
  const { data: invoiceStatuses } = useInvoiceStatus()
  const { viewModal, setViewModal } = useModalState<Invoice>()
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false)
  const [statusColors, setStatusColors] = useState({})
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const useModal = searchParams.get("useModal")
  const { data: invoiceDetail } = useInvoiceDetail(true, params.invoiceReference)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (data) invoiceListModel.setInvoiceList(data)
  }, [data])

  useEffect(() => {
    if (params.invoiceReference) {
      if (useModal) {
        setViewModal({ item: invoiceDetail, isOpen: true })
        return
      }
      setViewInvoiceModal(true)
    }
  }, [params.invoiceReference, invoiceDetail])

  useEffect(() => {
    if (invoiceStatuses) {
      let x: any = {}
      invoiceStatuses.forEach(
        (m) =>
          (x[m.invoiceStatusReference] = {
            textColor: m.textColor,
            bgColor: m.bgColor,
          })
      )
      setStatusColors(x)
    }
  }, [invoiceStatuses])

  return (
    <Fragment>
      <div className="px-3 pt-3 space-y-3 xl16:px-6 xl16:pt-6 xl16:space-y-6">
        <div className="">
          <InvoiceListFilters invoiceListModel={invoiceListModel} />
        </div>

        <div className="">
          <DataGridNew<Invoice>
            title="Invoices"
            buttonText="New Invoice"
            data={invoiceListModel.invoiceList}
            columns={columns}
            dataKey="invoiceReference"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onRowClick={(item) => {
              setViewModal({ item: item, isOpen: true })
            }}
            onColumnChange={setColumns}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            noItemFoundMessage="No Invoice found"
            action={() => {
              navigate("/invoices/create")
            }}
            statusColor={statusColors}
          />
        </div>
      </div>

      <InvoiceDetailModel
        open={viewInvoiceModal}
        onClose={() => {
          setViewInvoiceModal(false)
          navigate("/invoices")
        }}
        invoiceReference={params.invoiceReference}
      />

      <SidePanel
        title={`Invoice # : ${viewModal.item?.invoiceNumber}`}
        open={viewModal.isOpen}
        onClose={() => {
          setViewModal({ ...viewModal, isOpen: false })
          if (useModal) navigate("/invoices")
        }}
        body={<InvoiceDetailTabs invoiceReference={viewModal.item?.invoiceReference} />}
        type="detailPanel"
      />
    </Fragment>
  )
}

export default observer(InvoiceListPage)
