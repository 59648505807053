import "react-toastify/dist/ReactToastify.css"
import React, { useEffect } from "react"
import { FeatureProvider } from "context/feature-context"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback, FullPageLoading } from "library/styled/styled"
import AuthenticatedApp from "authenticated-app"
import { MetadataProvider } from "context/metadata-context"
import { useAuth } from "react-oidc-context"
import { monitorVersionChange, storeCurrentPath, loadAngularJsOrganisation } from "./utils/extensions"

function App() {
  const auth = useAuth()


  useEffect(() => {
    monitorVersionChange()
    loadAngularJsOrganisation()

    let addSilentRenewError = auth.events.addSilentRenewError(error => {
      console.log("Oidc.Event.SilentRenewError", error)
    })

    let addAccessTokenExpiring = auth.events.addAccessTokenExpiring(ev => {
      console.log("Oidc.Event.AccessTokenExpiring", ev)
    })

    let addAccessTokenExpired = auth.events.addAccessTokenExpired(ev => {
      console.log("Oidc.Event.AccessTokenExpired", ev)
    })

    return () => {
      auth.events.removeSilentRenewError(addSilentRenewError)
      auth.events.removeAccessTokenExpiring(addAccessTokenExpiring)
      auth.events.removeAccessTokenExpired(addAccessTokenExpired)
    }
  }, [])

  if (auth.activeNavigator === "signoutRedirect" || auth.isLoading) {
    console.log("1 - app.tsx -> activeNavigator", auth.activeNavigator, auth.isLoading)
    return <FullPageLoading />
  }

  // Handle sign in silent errors. This should only happen if user has signed out of identity server in another browser.
  // In this case, the user must be redirected to identity server to login.
  if (auth.error) {
    console.log("2 - app.tsx -> failed silient renew", auth.error)

    // Save the current url so that we can redirect user back to their page.
    storeCurrentPath()

    auth.signinRedirect().then(value => {
      console.log("3 - app.tsx -> auth.error -> signinRedirect success")
    }).catch(reason => {
      console.log("4 - app.tsx -> auth.error -> signinRedirect failed", reason)
    })
    return <FullPageLoading />
  }

  if (!auth.isAuthenticated) {
    // Save the current url so that we can redirect user back to their page.
    storeCurrentPath()

    console.log("5 - un-authenticated")
    auth.signinRedirect().then(value => {
      console.log("6 - app.tsx -> signinRedirect success")
    }).catch(reason => {
      console.log("7 - app.tsx -> signinRedirect failed", reason)
    })
    return <FullPageLoading />
  }

  console.log("8- Authenticated")
  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <FeatureProvider>
          <MetadataProvider>
            <AuthenticatedApp />
          </MetadataProvider>
        </FeatureProvider>
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default App
