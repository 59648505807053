import React, { ChangeEvent, useState } from "react"
import Select from "react-select"
import CheckBox from "library/styled/checkbox"
import { dropdownVariant } from "library/styled/dropdown"
import { Button, ButtonLight, CheckboxInput, ErrorInline, FormGroup, Label, TextArea, TextInput } from "library/styled/styled"
import TagsInput from "library/styled/tags"

interface Props {
  name: string
  error: any
  placeholder?: string
  label?: string
  value?: any
  errorKey?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  addMargin?: boolean
  cols?: number
  disabled?: boolean
  reference?: any
}

interface TextAreaProps {
  name: string
  error: any
  placeholder?: string
  label?: string
  value?: string | undefined
  errorKey?: string
  cols?: number
  rows?: number
  disabled?: boolean
  reference?: any
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}
interface CheckBoxProps {
  name: string
  error?: any
  placeholder?: string
  label: string
  value?: boolean
  errorKey?: string
  onChange?: () => void
  margin?: boolean
  cols?: number
  tooltip?: string
  marginTop?: number
}
interface TagsProps {
  name: string
  error: any
  label: string
  onChange: () => void
  cols?: number
}
interface DropdownProps<T = any> {
  name: string
  label?: string
  onChange?: (item: T) => void
  cols?: number
  options?: Array<T>
  defaultValue?: any
  value?: any
  displayLabel: string
  placeholder?: string
  error: any
  errorKey?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  selected?: any
  isMulti?: boolean
  isDisabled?: boolean
}
interface ButtonProps {
  name?: string
  isLoading?: boolean
  isDisabled?: boolean
  buttonText?: string
  isPrimary?: boolean
  onClick?: (info1?: any, info2?: any) => void
  type?: "submit" | "button" | "reset" | undefined
}

const InputGroupText = ({
  name,
  error,
  placeholder = "Optional",
  label,
  value = undefined,
  errorKey,
  onChange,
  addMargin = true,
  cols = 3,
  disabled = false,
  reference,
}: Props) => {
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={name}>{label}</Label>
      <TextInput
        type="text"
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        addMargin={addMargin}
        disabled={disabled}
        ref={reference}
        className={`${disabled ? "bg-gray-100" : "bg-white"}`}
      />
      {errorKey
        ? error[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}

const InputGroupNumber = ({ name, error, placeholder = "Optional", label, value = undefined, errorKey }: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <TextInput type="number" name={name} id={name} placeholder={placeholder} defaultValue={value} />
      {errorKey
        ? error[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupDecimal = ({ name, error, placeholder = "Optional", label, value = undefined, errorKey }: Props) => {
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <TextInput type="number" step={0.1} name={name} id={name} placeholder={placeholder} defaultValue={value} />
      {errorKey
        ? error[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupColor = ({ name, error, placeholder = "Optional", label, value = "#000000" }: Props) => {
  const [color, setColor] = useState(value)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value)
  }
  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <input
        className="h-8 my-1"
        type="color"
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={value}
        onChange={handleChange}
      />
      <span className="align-super">{color}</span>
      {error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupTextarea = ({
  name,
  error,
  placeholder = "Optional",
  label = "Description",
  value = undefined,
  errorKey,
  cols = 6,
  rows = 3,
  disabled = false,
  reference,
  onChange,
}: TextAreaProps) => {
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={name}>{label}</Label>
      <TextArea
        ref={reference}
        rows={rows}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={value}
        disabled={disabled}
        onChange={onChange}
        className={`${disabled ? "bg-gray-100" : "bg-white"}`}
      />
      {errorKey
        ? error[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupCheckbox = ({
  name,
  label,
  value = false,
  onChange,
  cols = 6,
  margin = true,
  tooltip = "",
  marginTop = 8,
  error = "",
}: CheckBoxProps) => {
  return (
    <FormGroup cols={cols} className={`${cols === 3 && margin ? `mt-${marginTop}` : ""}`}>
      <CheckBox label={label} name={name} tooltip={tooltip}>
        <CheckboxInput id={name} name={name} type="checkbox" defaultChecked={value} onChange={onChange} />
      </CheckBox>
      {error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupTag = ({ name, label, onChange, cols = 6, error }: TagsProps) => {
  return (
    <FormGroup cols={cols}>
      <Label htmlFor={name}>{label}</Label>
      <TagsInput name={name} onChange={onChange} />
      {error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupDropdown = ({
  name,
  label,
  onChange,
  cols = 3,
  options = [],
  defaultValue = options[0],
  value,
  displayLabel,
  placeholder = "Optional",
  error,
  errorKey,
  isInModal = false,
  isSearchable = true,
  isClearable = false,
  selected,
  isMulti = false,
  isDisabled = false,
}: DropdownProps) => {
  return (
    <FormGroup cols={cols}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        isMulti={isMulti}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        defaultValue={defaultValue ? defaultValue : null}
        name={name}
        id={name}
        getOptionLabel={(option) => option[displayLabel]}
        getOptionValue={(option) => option[value]}
        onChange={onChange}
        placeholder={placeholder}
        className="ring-primary-100"
        styles={dropdownVariant.input}
        menuPortalTarget={isInModal ? null : document.body}
        menuPosition={isInModal ? undefined : "fixed"}
        value={selected}
        isDisabled={isDisabled}
      ></Select>
      {errorKey
        ? error[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}
const InputGroupButton = ({
  name = "submit-button",
  isLoading,
  buttonText = "Save",
  isPrimary = true,
  onClick,
  type = "submit",
  isDisabled = false,
}: ButtonProps) => {
  return (
    <FormGroup cols={6} className="flex justify-end">
      {isPrimary ? (
        <Button type={type} id={name} disabled={isLoading || isDisabled} onClick={onClick}>
          {buttonText}
          {isLoading && <i className="fas fa-spinner-third fa-spin text-white ml-2"></i>}
        </Button>
      ) : (
        <ButtonLight type={type} id={name} disabled={isLoading || isDisabled} onClick={onClick}>
          {buttonText}
          {isLoading && <i className="fas fa-spinner-third fa-spin text-primary-900 ml-2"></i>}
        </ButtonLight>
      )}
    </FormGroup>
  )
}

export {
  InputGroupText,
  InputGroupTextarea,
  InputGroupButton,
  InputGroupColor,
  InputGroupNumber,
  InputGroupCheckbox,
  InputGroupDropdown,
  InputGroupTag,
  InputGroupDecimal,
}
