import React, { useEffect, useState } from "react"
import { WarningAlert } from "library/styled/alerts"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import TableDynamic from "library/styled/table"
import { Reminder } from "components/shared/reminders/03_sharedModel/reminder"
import { useModalState } from "utils/modalStates"
import { useReminder, useRemoveReminder } from "components/shared/reminders/services/reminderCardService"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import { observer } from "mobx-react"
import EditReminderModal from "components/shared/reminders/02_editReminder/editReminder"
import AddReminderModal from "components/shared/reminders/01_reminderCard/addReminderModal"
import { ButtonPrimary } from "library/styled/styled"
import ToggleSwitch from "library/styled/switch"

interface Props {
  entityReference?: string
  entityTypeReference: string
  isTabComponent?: boolean
  // This props controls the noteQuery when set to true the component will load the query
  isSelected: boolean
  isOpenInModal?: boolean
}

const ReminderCard = ({
                        entityReference,
                        entityTypeReference,
                        isTabComponent = false,
                        isSelected,
                        isOpenInModal = false
                      }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const [includePastReminders, setIncludePastReminders] = useState(isTabComponent)
  const { data: reminders, isLoading } = useReminder(
    {
      first: 100,
      entityReference: entityReference,
      entityTypeReference: entityTypeReference,
      includePastReminders: includePastReminders ? "true" : "false"
    },
    enabled
  )
  const { mutate: removeReminder, isLoading: isDeleting } = useRemoveReminder()
  const { addModal, setAddModal, editModal, setEditModal, alert, setAlert } = useModalState<Reminder>()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (entityReference && isSelected) setEnabled(true)
  }, [entityReference, isSelected])

  const handleDelete = (item: Reminder) => {
    const input = {
      reminderReference: item.reminderReference
    }
    removeReminder(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.reminderQuery)
        successToaster("Reminder deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }

  const options = [
    {
      name: "Edit",
      icon: "pen-circle",
      action: (item: Reminder) => setEditModal({ isOpen: true, item: item })
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: Reminder) => setAlert({ isOpen: true, item: item })
    }
  ]

  return (
    <>
      <TableDynamic
        columns={cols}
        data={reminders}
        dataKey="reminderReference"
        options={options}
        fixHeight={isTabComponent}
        noItemFoundMessage={includePastReminders ? "There are no results to show at this time." : "No upcoming reminders"}
        isLoading={(isTabComponent && !isOpenInModal) ? isLoading : false}
        columnLoadingWidths={[45, 25, 30]}
      />

      {isTabComponent && (
        <div className="flex justify-end p-6">
          <div className="my-auto pr-6 font-medium text-base space-x-6">
            <span>Future</span>
            <span className="">
              <ToggleSwitch enabled={includePastReminders} setEnabled={setIncludePastReminders} />
            </span>
            <span>All</span>
          </div>

          <ButtonPrimary onClick={() => setAddModal(true)}>
            <i className="far fa-plus pr-2"></i>Add Reminder
          </ButtonPrimary>
        </div>
      )}

      <EditReminderModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        reminder={editModal.item}
      />

      <WarningAlert
        title="Reminder"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />

      {isTabComponent && (
        <AddReminderModal
          isOpen={addModal}
          onClose={() => setAddModal(false)}
          entityTypeReference={entityTypeReference}
          entityReference={entityReference}
        />
      )}
    </>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Description",
    key: "description",
    columnTypeId: ColumnType.Description.Id
  },
  {
    id: "2",
    name: "Due",
    key: "dueDateTimeUtc",
    columnTypeId: ColumnType.DateTime.Id,
    width: 4
  },
  {
    id: "3",
    name: "Created",
    key: "insertedDateTimeUtc",
    columnTypeId: ColumnType.Date.Id,
    width: 4
  }
]

export default observer(ReminderCard)
