import React, { useEffect, useState } from "react"
import { WarningAlert } from "library/styled/alerts"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"
import TableDynamic from "library/styled/table"
import { Note } from "components/shared/notes/03_sharedModel/note"
import { useModalState } from "utils/modalStates"
import { useNote, useRemoveNote } from "components/shared/notes/services/noteCardService"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { successToaster } from "library/styled/toasters"
import { useQueryClient } from "react-query"
import { observer } from "mobx-react"
import EditNoteModal from "components/shared/notes/02_editNote/editNote"
import AddNoteModal from "components/shared/notes/01_noteCard/addNoteModal"
import { ButtonPrimary } from "library/styled/styled"
import { MenuOption } from "library/dropdownMenu/hamburgerMenu"

interface Props {
  entityReference?: string
  entityTypeReference: string
  // This props controls the noteQuery when set to true the component will load the query
  isSelected: boolean
  isTabComponent?: boolean
  isOpenInModal?: boolean
}

const NoteListTemplate = ({ entityReference, entityTypeReference, isSelected, isTabComponent = false, isOpenInModal = false }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: notes, isLoading } = useNote(
    { first: 100, entityReference: entityReference ?? "", entityTypeReference: entityTypeReference },
    enabled
  )
  const { mutate: removeNote, isLoading: isDeleting } = useRemoveNote()
  const { addModal, setAddModal, editModal, setEditModal, alert, setAlert } = useModalState<Note>()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (entityReference && isSelected) setEnabled(true)
  }, [entityReference, isSelected])

  const handleDelete = (item: Note) => {
    const input = {
      noteReference: item.noteReference,
    }
    removeNote(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.noteQuery)
        successToaster("Note deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false }),
    })
  }

  const options: MenuOption[] = [
    {
      name: "Edit",
      icon: "pen-circle",
      action: (item: Note) => setEditModal({ isOpen: true, item: item }),
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: Note) => setAlert({ isOpen: true, item: item }),
    },
  ]

  return (
    <>
      <TableDynamic
        columns={cols}
        data={notes}
        dataKey="noteReference"
        options={options}
        fixHeight={isTabComponent}
        isLoading={(isTabComponent && !isOpenInModal) ? isLoading : false}
        columnLoadingWidths={[70, 30]}
      />

      {isTabComponent && (
        <div className="flex justify-end p-6">
          <ButtonPrimary onClick={() => setAddModal(true)}>
            <i className="far fa-plus pr-2"></i>Add Note
          </ButtonPrimary>
        </div>
      )}

      <EditNoteModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        note={editModal.item}
      />

      <WarningAlert
        title="Note"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />

      {isTabComponent && (
        <AddNoteModal
          isOpen={addModal}
          onClose={() => setAddModal(false)}
          entityTypeReference={entityTypeReference}
          entityReference={entityReference}
        />
      )}
    </>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Description",
    key: "description",
    columnTypeId: ColumnType.Description.Id,
  },
  {
    id: "3",
    name: "Created On",
    key: "insertedDateTimeUtc",
    columnTypeId: ColumnType.Date.Id,
    width: 4,
  },
]

export default observer(NoteListTemplate)
