import React from "react"
import { observer } from "mobx-react"
import { Response } from "models/shared/response"
import { Client, Clients } from "models/people/client"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { common, handleQueryError } from "common"
import { keys } from "constants/keys"
import AsyncSelect from "react-select/async"
import { makeObservable, observable, runInAction } from "mobx"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { requiredValidation } from "utils/validation"
import { isProvided } from "utils/extensions"
import { dropdownVariant } from "../styled/dropdown"

export class ClientSelectionObservable {
  clientReference?: string
  clientName?: string
  emailAddress?: string
  errorMessage?: string

  constructor(clientReference?: string, clientName?: string, emailAddress?: string) {
    makeObservable(this, {
      clientReference: observable,
      clientName: observable,
      errorMessage: observable,
    })

    this.clientReference = clientReference
    this.clientName = clientName
    this.emailAddress = emailAddress
  }
}

interface Props {
  clientSelection: ClientSelectionObservable
  isRequired?: boolean
  testClients?: Client[]
}

const ClientSelectionComponent = (props: Props) => {
  const promiseOptions = (inputValue: string) =>
    new Promise<Client[]>((resolve) => {
      resolve(searchClients(inputValue))
    })

  const searchClients = async (searchQuery: string): Promise<Client[]> => {
    if (props.testClients) return props.testClients

    const queryInput = {
      first: 100,
      searchTerm: searchQuery.trim(),
    }
    const response: Response<Clients> = await graphQLClient.fetch(
      query.clientQuery,
      queryInput,
      true,
      common.peopleApiUrl,
      keys.clientQuery
    )
    if (handleQueryError(response)) return [] as Client[]

    return response.data.data.clients.edges.map((m) => m.node)
  }

  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !props.clientSelection.errorMessage) {
      return
    }

    const clientLabel = "Client"
    props.clientSelection.errorMessage = requiredValidation(props.clientSelection.clientReference, clientLabel, props.isRequired)
  }

  function getSelectedValue(): Client | undefined {
    if (isProvided(props.clientSelection.clientReference) && isProvided(props.clientSelection.clientName)) {
      return {
        clientReference: props.clientSelection.clientReference,
        primaryContact: { fullName: props.clientSelection.clientName },
      }
    }

    return undefined
  }

  return (
    <>
      <FormGroup role={"ClientSelection"}>
        <Label>{"Client"}</Label>
        <AsyncSelect
          cacheOptions
          defaultOptions
          name="Client"
          inputId="Client"
          loadOptions={promiseOptions}
          value={getSelectedValue()}
          getOptionLabel={(client) => client.primaryContact?.fullName ?? ""}
          getOptionValue={(client) => client.primaryContact?.fullName ?? ""}
          placeholder="Search by client's name, phone or email"
          styles={dropdownVariant.input}
          isClearable={true}
          onChange={(e) => {
            runInAction(() => {
              props.clientSelection.clientReference = e?.clientReference
              props.clientSelection!.clientName = e?.primaryContact?.fullName
              validate(true)
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
        />
        {props.clientSelection.errorMessage && <ErrorInline>{props.clientSelection.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

export default observer(ClientSelectionComponent)
