import React, { useState, useEffect, useRef } from "react"
import { FormGroup, TextInput } from "library/styled/styled"

interface Props {
  countryCode: string | null
  onAddressChange: (result: google.maps.places.PlaceResult) => void
  clearSearch: any
}
let autoComplete: any

const SearchLocationInput = ({ countryCode, onAddressChange, clearSearch }: Props) => {
  const [query, setQuery] = useState("")
  const autoCompleteRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    handleScriptLoad()
  }, [])

  useEffect(() => {
    clearSearch.current = () => setQuery("")
  }, [])

  useEffect(() => {
    autoComplete.setOptions({ componentRestrictions: { country: countryCode } })
    setQuery("")
  }, [countryCode])

  const handleScriptLoad = () => {
    const autocompleteBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(-36.870725, 174.762049),
      new window.google.maps.LatLng(-36.818164, 174.806428)
    )

    if (autoCompleteRef.current)
      autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        types: ["geocode"],
        bounds: autocompleteBounds,
        componentRestrictions: { country: countryCode },
      })
    autoComplete.setFields(["address_components", "formatted_address", "geometry"])
    autoComplete.addListener("place_changed", handlePlaceSelect)
  }

  const handlePlaceSelect = () => {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.formatted_address
    setQuery(query)
    onAddressChange(addressObject)
  }

  return (
    <>
      <FormGroup cols={6}>
        <div className="mt-1 relative rounded-md">
          <TextInput
            type="text"
            ref={autoCompleteRef}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search address using google"
            value={query}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <i className="h-5 w-5 text-gray-400 fa-light fa-magnifying-glass" aria-hidden="true" />
          </div>
        </div>
      </FormGroup>
    </>
  )
}

export default SearchLocationInput
