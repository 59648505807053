function requiredValidation(text?: string, fieldName?: string, isRequired?: boolean) {
  if (!isRequired) return undefined

  let value = text?.length ?? 0
  if (value <= 0) {
    return `${fieldName} is required`
  }

  return undefined
}

function maxLengthValidation(text?: string, fieldName?: string, maxLength?: number) {
  let value = text?.length ?? 0
  if (!maxLength) return undefined

  if (maxLength && value > maxLength) {
    return `${fieldName} cannot exceed ${maxLength} characters. ${value}/${maxLength}`
  }

  return undefined
}

function maxCurrencyValueValidation(value?: number, fieldName?: string, maxValue?: number) {
  if (!maxValue || !value) return undefined

  if (maxValue && value > maxValue) {
    return `${fieldName} value cannot exceed ${maxValue}` // TODO format currency
  }

  return undefined
}

function emailAddressValidation(text?: string, fieldName?: string, isEmail?: boolean) {
  if (!isEmail) return undefined

  let value = text?.length ?? 0
  if (value <= 0) return undefined

  if (!text!.includes("@") || !text!.includes(".")) {
    return `Please provide a valid email address`
  }

  return undefined
}

function phoneNumberValidation(text?: string, isPhoneNumber?: boolean) {
  if (!isPhoneNumber) return undefined

  let value = text?.length ?? 0
  if (value <= 0) return undefined

  let regex = new RegExp("^[0-9 +]+$")
  if (!regex.test(text!)) {
    return `Please provide a valid phone number. Supported characters are '+' spaces and numbers.`
  }

  return undefined
}

function requiredNumberValidation(number?: number, fieldName?: string, isRequired?: boolean) {
  if (!isRequired) return undefined

  if (number === undefined || isNaN(number)) {
    return `${fieldName ?? "Field"} is required`
  }

  return undefined
}

function integerValidation(number?: number, fieldName?: string, isInteger?: boolean) {
  if (!isInteger) return undefined

  if (number?.toString().includes(".")) {
    return `Decimal value is not allowed for ${fieldName ?? "this field"}. Please enter integer value.`
  }

  return undefined
}

function numberValidation(number?: number, fieldName?: string, isNumber?: boolean) {
  if (!isNumber) return undefined

  if (isNaN(number ?? 0)) {
    return `Please enter a valid number`
  }

  return undefined
}

function minNumberValidation(value?: number, fieldName?: string, minValue?: number) {
  if (minValue === undefined) return undefined

  if (value === undefined || value < minValue) {
    return `${fieldName ? fieldName + " value" : "Value"} should be greater than ${minValue}`
  }

  return undefined
}

function maxNumberValidation(value?: number, fieldName?: string, maxValue?: number) {
  if (maxValue === undefined) return undefined

  if (value !== undefined && value > maxValue) {
    return `${fieldName ? fieldName + " value" : "Value"} cannot exceed ${maxValue}`
  }

  return undefined
}

function requiredDateValidation(date?: Date, fieldName?: string, isRequired?: boolean) {
  if (!isRequired) return undefined
  if (date === null) return `${fieldName} is required`

  return undefined
}

export {
  phoneNumberValidation,
  requiredValidation,
  maxLengthValidation,
  emailAddressValidation,
  requiredNumberValidation,
  integerValidation,
  minNumberValidation,
  maxNumberValidation,
  requiredDateValidation,
  numberValidation,
}
