import React from "react"
import { CreatedView, DescriptionView, TextView } from "library/styled/description-list"
import { Dl, DlGroup } from "library/styled/styled"
import { Item } from "components/items/04_itemSharedModel/itemModel"

interface Props {
  item?: Item
}

const ItemOverview = ({ item }: Props) => {
  return (
    <div className="overflow-y-auto side-detail-height">
      <Dl addExtraPadding={false}>
        <TextView label="Item Name" value={item?.name} />
        <TextView label="Item Code" value={item?.itemCode} />
        <TextView label="Item Type" value={item?.itemType.name} />
        <DlGroup cols={1}></DlGroup>
        <TextView label="Sale Price" value={item?.sale.unitPrice} />
        <TextView label="Sale Tax Name" value={item?.sale.taxName} />
        <DescriptionView value={item?.description} />
        <CreatedView createdBy={item?.insertedBy} createdDateTimeUtc={item?.insertedDateTimeUtc} />
        <CreatedView label="Updated By" createdBy={item?.updatedBy} createdDateTimeUtc={item?.updatedDateTimeUtc} />
      </Dl>
    </div>
  )
}

export default ItemOverview
