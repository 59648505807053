import { ErrorInline, FormGroup, Label, TextArea } from "../styled/styled"
import React from "react"
import { action, runInAction } from "mobx"
import { observer } from "mobx-react"
import { TextObservable } from "../textInputGroup/textInputGroup"
import { maxLengthValidation, requiredValidation } from "../../utils/validation"
import ToolTip from "../styled/tooltip"
import { Link } from "react-router-dom"

interface TextAreaProps {
  name: string
  label?: string
  value: TextObservable
  cols?: number
  rows?: number
  toolTip?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  isRequired?: boolean
  maxLength?: number
  placeholder?: string
  showTemplateTooltip?: boolean
  component?: JSX.Element
}

const TextAreaInputGroup = ({
  name,
  label,
  value,
  cols = 6,
  rows = 3,
  toolTip,
  onChange,
  onFocus,
  onBlur,
  isRequired,
  maxLength,
  placeholder,
  showTemplateTooltip = false,
  component,
}: TextAreaProps) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !value.errorMessage) {
      return
    }

    runInAction(() => {
      value.errorMessage = requiredValidation(value.text, label, isRequired)
      if (!value.errorMessage) value.errorMessage = maxLengthValidation(value.text, label, maxLength)
    })
  }

  return (
    <FormGroup cols={cols}>
      <Label htmlFor={name}>
        {label}

        {toolTip && (
          <ToolTip text={toolTip} id={"Id"}>
            <i className="far fa-circle-info text-gray-700 ml-2"></i>
          </ToolTip>
        )}
        {showTemplateTooltip && (
          <ToolTip
            id={"templateId"}
            overlay={
              <span>
                {" "}
                This field is auto-populated with a default template.
                <Link to="/settings/template" target="_blank" className="underline">
                  {" "}
                  Click here
                </Link>{" "}
                to visit your Settings and customize this message.
              </span>
            }
          >
            <i className="far fa-envelope-open text-gray-700 ml-2"></i>
          </ToolTip>
        )}
        {component && <div className="inline float-right">{component}</div>}
      </Label>
      <TextArea
        rows={rows}
        name={name}
        id={name}
        placeholder={placeholder ? placeholder : isRequired ? "Required" : "Optional"}
        value={value.text ?? ""}
        onChange={action((e) => {
          value.text = e.target.value
          validate(true)
          if (onChange) onChange(e)
        })}
        onFocus={onFocus}
        onBlur={(event) => {
          validate()
          if (onBlur) onBlur(event)
        }}
      />
      {value.errorMessage && <ErrorInline>{value.errorMessage}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(TextAreaInputGroup)
