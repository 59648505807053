import React from "react"
import {
  CreatedView,
  DateTimeView,
  DescriptionView, StaffMemberView, TextView
} from "library/styled/description-list"
import { Dl, DlGroup, Dt } from "library/styled/styled"
import { Task, TaskCategory, TaskReminder, TaskStatus } from "../04_sharedModel/taskModel"
import { common, handlePopupError, toLocaleDateTime } from "../../../common"
import { entityTypes } from "../../../constants/entityTypes"
import { showDashIfEmpty } from "../../../utils/extensions"
import Conditional from "../../../library/styled/conditional"
import Badge from "../../../library/styled/badges"
import Divider from "../../../library/components/divider"
import { SelectObservable } from "../../../library/selectInputGroup/selectInputGroup"
import InputGroupSelect from "../../../library/observables/inputGroupSelect"
import { useChangeTaskStatus, useTaskStatusQuery } from "../services/taskService"
import { ChangeTaskStatusInput } from "../04_sharedModel/inputModels"
import { keys } from "../../../constants/keys"
import { successToaster } from "../../../library/styled/toasters"
import { useQueryClient } from "react-query"

interface Props {
  task?: Task
}

const TaskOverviewTab = ({ task }: Props) => {

  const { data: taskStatus } = useTaskStatusQuery()
  const queryClient = useQueryClient()
  const { mutate: changeTaskStatus, isLoading: isChangingTaskStatus } = useChangeTaskStatus()

  const selectedTaskStatus: TaskStatus = {
    taskStatusReference: task?.taskStatusReference ?? "",
    name: task?.taskStatusName ?? ""
  }

  const handleChangeTaskStatus = (taskStatusReference: string) => {
    const input: ChangeTaskStatusInput = {
      taskReference: task?.taskReference ?? "",
      taskStatusReference: taskStatusReference,
      concurrencyToken: task?.concurrencyToken ?? ""
    }

    changeTaskStatus(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.taskQuery])
        void queryClient.invalidateQueries([keys.taskDetailQuery])
        successToaster(`Task Status Changed`)
      }
    })
  }

  return (
    <div className={`overflow-y-auto side-detail-height`}>
      <Dl addExtraPadding={false}>

        <TextView label="Task Title" value={task?.name} />
        <DescriptionView label="Description" value={task?.description} />
        <DlGroup cols={1}>
          <Dt>{"Task Status"}</Dt>
          <div className={"w-2/3"}>
            <InputGroupSelect<TaskStatus>
              value={new SelectObservable<TaskStatus>(selectedTaskStatus)}
              onChange={item1 => {
                handleChangeTaskStatus(item1?.taskStatusReference ?? "")
              }}
              id=""
              options={taskStatus}
              optionValue={"taskStatusReference"}
              optionLabel={"name"}
              type={"input"}
              isDisabled={isChangingTaskStatus}
            />
          </div>
        </DlGroup>

        <DlGroup cols={1}>
          <Dt>{"Task Category"}</Dt>
          {task?.taskCategory?.map((category: TaskCategory, idx: number) => (
            <Badge key={category.taskCategoryReference!} bgColor={"bg-blue"}
                   name={category.name!}></Badge>

          ))}
          <Conditional when={(task?.taskCategory?.length ?? 0) <= 0}>
            -
          </Conditional>
        </DlGroup>


        <Conditional when={task?.entityTypeReference === entityTypes.client.entityTypeReference}>
          <TextView label="Client" value={task?.entityName}
                    link={task?.entityReference && common.angularJsLinks.clientDetails + task.entityReference} />
        </Conditional>

        <Conditional when={task?.entityTypeReference === entityTypes.job.entityTypeReference}>
          <TextView label="Job" value={task?.entityName}
                    link={task?.entityReference && common.angularJsLinks.jobDetails + task?.entityReference} />
        </Conditional>

        <Conditional when={task?.entityTypeReference === entityTypes.general.entityTypeReference}>
          <TextView label="Task Type" value={"General"} />
        </Conditional>

        {task?.assignToAllStaffMembers && <TextView label="Staff Member" value={"All Staff Members"} />}
        {!task?.assignToAllStaffMembers && <StaffMemberView staffMembers={task?.staffMembers} />}

        <div className={"col-span-full"}>
          <hr />
        </div>
        <DlGroup cols={2}>
          <div className={"block text-base font-medium text-gray-700"}>Schedule</div>
        </DlGroup>

        <DateTimeView label="Start Date" value={task?.startDateTimeUtc} />
        <DateTimeView label="End Date" value={task?.endDateTimeUtc} />
        <DateTimeView label="Due Date" value={task?.dueDateUtc} />

        <Divider />

        <DlGroup cols={2}>
          <div className={"block text-base font-medium text-gray-700"}>Reminders</div>
        </DlGroup>

        <DlGroup cols={2}>
          {task?.taskReminders?.map((reminder: TaskReminder, idx: number) => (
            <span key={reminder.taskReminderReference}>
                <i className="fa-regular fa-alarm-clock"></i>
                <span className={"pl-1 pr-2"}>{showDashIfEmpty(toLocaleDateTime(reminder.reminderDateTimeUtc))}
                </span>
              </span>
          ))}
          {(task?.taskReminders?.length ?? 0) <= 0 && <span>{"There are no reminders set for this task."}</span>}
        </DlGroup>


        <Divider />

        <CreatedView label="Created By" createdBy={task?.insertedBy}
                     createdDateTimeUtc={task?.insertedDateTimeUtc} />
        <CreatedView label="Updated By" createdBy={task?.updatedBy}
                     createdDateTimeUtc={task?.updatedDateTimeUtc} />

      </Dl>
    </div>
  )
}

export default TaskOverviewTab
