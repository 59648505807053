import React from "react"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import Accordian from "library/styled/accordian"
import { observer } from "mobx-react"
import ReminderListTemplate from "components/shared/reminders/01_reminderCard/reminderListTemplate"
import AddReminderTemplate from "components/shared/reminders/01_reminderCard/addReminderTemplate"

interface Props {
  entityReference: TextObservable
  entityTypeReference: string
  width?: string
}

const ReminderCard = ({ entityReference, entityTypeReference, width }: Props) => {
  return (
    <>
      <Accordian width={width} title="Reminders" showLink={false}>
        <AddReminderTemplate entityTypeReference={entityTypeReference} entityReference={entityReference.text} />
        <ReminderListTemplate
          entityTypeReference={entityTypeReference}
          entityReference={entityReference.text}
          isSelected={true}
        />
      </Accordian>
    </>
  )
}

export default observer(ReminderCard)
