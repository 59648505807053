import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { dropdownVariant } from "library/styled/dropdown"
import Select from "react-select"
import { Address, Addresses, AddressQueryInput } from "models/people/address"
import { action, makeAutoObservable, runInAction } from "mobx"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { common } from "common"
import { keys } from "constants/keys"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { ClientSelectionObservable } from "../clientSelection/clientSelection"
import { isProvided } from "../../utils/extensions"

export class AddressObservable {
  errorMessage?: string
  reference?: string
  route?: string
  regionCode?: string
  regionName?: string
  postalCode?: string
  city?: string
  countryCode?: string
  countryName?: string
  latitude?: string
  longitude?: string

  constructor() {
    makeAutoObservable(this, {
      setAddress: action,
    })
  }

  setAddress(address: Address | null) {
    this.route = address?.route
    this.reference = address?.addressReference
    this.regionName = address?.regionName
    this.regionCode = address?.regionCode
    this.city = address?.city
    this.countryCode = address?.countryCode
    this.countryName = address?.countryName
    this.postalCode = address?.postalCode
    this.errorMessage = undefined
  }
}

interface Props {
  clientSelectionObservable: ClientSelectionObservable
  addressSelectionObservable: AddressObservable
  isAdding?: boolean
}

const ClientAddressSelection = (props: Props) => {
  const [addresses, setAddresses] = useState<Address[]>()

  useEffect(() => {
    const queryInput: AddressQueryInput = {
      first: 500,
      businessEntityReference: props.clientSelectionObservable.clientReference,
    }
    async function addressQuery() {
      const response: Response<Addresses> = await graphQLClient.fetch(
        query.addressQuery,
        queryInput,
        true,
        common.peopleApiUrl,
        keys.addressQuery
      )
      if (response) {
        const addressList = response.data.data.addresses.edges.map((value) => value.node)
        setAddresses(addressList)
        // Don't auto select address on editing invoice etc
        // TEST - Adding invoice shuld auto select
        // test - edit invoice should not replace existing address (if client has 2 address)
        // test - editing invoice and invoice does address auto selected
        if (props.isAdding) {
          props.addressSelectionObservable.setAddress(addressList[0])
        }
      }
    }
    addressQuery()
  }, [props.clientSelectionObservable.clientReference])

  function getAddress(address: Address): string {
    let addressLabel = ""

    if (address.route) {
      addressLabel += address.route
    }

    if (address.city) {
      addressLabel += ", " + address.city
    }

    if (address.regionName) {
      addressLabel += ", " + address.regionName
    }

    if (address.countryName) {
      addressLabel += ", " + address.countryName
    }

    if (address.postalCode) {
      addressLabel += ", " + address.postalCode
    }

    return addressLabel
  }

  function getValue(): Address | undefined {
    if (isProvided(props.addressSelectionObservable.route)) {
      return {
        route: props.addressSelectionObservable.route,
        regionName: props.addressSelectionObservable.regionName,
        city: props.addressSelectionObservable.city,
        countryName: props.addressSelectionObservable.countryName,
        postalCode: props.addressSelectionObservable.postalCode,
      }
    }
    return undefined
  }

  return (
    <>
      <FormGroup>
        <Label>{"Client Address"}</Label>

        <Select
          options={addresses}
          inputId={"clientAddress"}
          getOptionLabel={(m) => getAddress(m)}
          getOptionValue={(m) => getAddress(m)}
          placeholder="Optional"
          styles={dropdownVariant.input}
          isClearable={true}
          value={getValue()}
          onChange={(e) => props.addressSelectionObservable.setAddress(e)}
          classNamePrefix="address"
        />
        {props.addressSelectionObservable.errorMessage && (
          <ErrorInline>{props.addressSelectionObservable.errorMessage}</ErrorInline>
        )}
      </FormGroup>
    </>
  )
}

export default observer(ClientAddressSelection)
