import { useState } from "react"

export interface ModalState<T = any> {
  isOpen: boolean
  item: T | undefined
}

function useModalState<T = any>() {
  const [addModal, setAddModal] = useState(false)
  const [viewModal, setViewModal] = useState<ModalState<T>>({
    isOpen: false,
    item: undefined,
  })
  const [editModal, setEditModal] = useState<ModalState<T>>({
    isOpen: false,
    item: undefined,
  })
  const [alert, setAlert] = useState<ModalState<T>>({
    isOpen: false,
    item: undefined,
  })
  return {
    addModal,
    setAddModal,
    viewModal,
    setViewModal,
    editModal,
    setEditModal,
    alert,
    setAlert,
  }
}

export { useModalState }
