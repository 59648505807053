import { action, makeObservable, observable, runInAction } from "mobx"
import InvoiceLineItemObservable from "../05_sharedModel/invoiceLineItemObservable"
import { InvoiceLineItemGroupInput, UpdateInvoiceInput } from "../05_sharedModel/InputModels"
import { v4, v4 as uuId } from "uuid"
import { isContains, isEmpty, isSame } from "utils/extensions"
import { InvoiceLineItemModel } from "../06_sharedComponent/invoiceLineItems"
import { DatePickerObservable } from "library/observables/datePickers"
import { StaffSelectionObservable } from "library/components/staffSelection"
import { CurrencySelectionObservable } from "library/currencySelection/currencySelection"
import { TextObservable } from "library/observables/inputGroupText"
import { NumberObservable } from "library/observables/inputGroupNumber"
import { AddressObservable } from "library/clientAddressSelection/clientAddressSelection"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import addDays from "date-fns/addDays"
import { Invoice } from "components/invoices/05_sharedModel/invoiceModel"
import { InvoiceStatusObservable } from "components/invoices/06_sharedComponent/invoiceStatusSelection"
import { Template } from "../../setting/template/05_sharedModel/template"
import { templateTypes } from "../../../constants/templateTypes"
import { JobSelectionObservable } from "library/jobSelection/jobSelection"

export default class addOrEditInvoicePageModel {
  invoiceReference: TextObservable
  invoiceNumber: TextObservable
  title: TextObservable
  description: TextObservable
  paymentTerms: TextObservable
  clientSelection: ClientSelectionObservable
  addressSelection: AddressObservable
  jobSelection: JobSelectionObservable
  invoiceDateTime: DatePickerObservable
  dueDateTime: DatePickerObservable
  additionalReference: TextObservable
  currencySelection: CurrencySelectionObservable
  staffSelection: StaffSelectionObservable
  isReminderDisabled: BooleanObservable
  lineItemModel: InvoiceLineItemModel
  concurrencyToken: TextObservable
  useLineItemGroup: boolean
  openSendEmailModal: boolean
  isAddMode: boolean
  isEditMode: boolean
  isSent: BooleanObservable
  isDisputed: BooleanObservable
  invoiceStatus: InvoiceStatusObservable
  paymentInstructionTemplates: Template[] = []
  messageForClientTemplates: Template[] = []
  pdfModal = { isOpen: false, reportLink: "" }

  constructor(addEmptyLineItem: boolean = false, initializeInvoiceDates: boolean) {
    makeObservable(this, {
      openSendEmailModal: observable,
      setOpenSendEmailModal: action,
      handleApiErrors: action,
      isAddMode: observable,
      setIsAddMode: action,
      setInvoice: action,
      isEditMode: observable,
      setIsEditMode: action,
      pdfModal: observable,
      setPdfModal: action,
      setInvoiceNumber: action,
      setConcurrencyToken: action,
    })

    this.invoiceReference = new TextObservable()
    this.invoiceNumber = new TextObservable()
    this.title = new TextObservable()
    this.description = new TextObservable()
    this.paymentTerms = new TextObservable()
    this.clientSelection = new ClientSelectionObservable()
    this.addressSelection = new AddressObservable()
    this.jobSelection = new JobSelectionObservable()

    this.additionalReference = new TextObservable()
    this.currencySelection = new CurrencySelectionObservable()
    this.staffSelection = new StaffSelectionObservable()
    this.isReminderDisabled = new BooleanObservable()
    this.useLineItemGroup = false
    this.concurrencyToken = new TextObservable()
    this.lineItemModel = new InvoiceLineItemModel()
    this.openSendEmailModal = false
    this.isAddMode = false
    this.isEditMode = false
    this.isSent = new BooleanObservable(false)
    this.isDisputed = new BooleanObservable(false)
    this.invoiceStatus = new InvoiceStatusObservable()

    if (initializeInvoiceDates) {
      const invoiceDateTime = new Date()
      const dueDateTime = addDays(invoiceDateTime, 7)
      this.invoiceDateTime = new DatePickerObservable(invoiceDateTime)
      this.dueDateTime = new DatePickerObservable(dueDateTime)
    } else {
      this.invoiceDateTime = new DatePickerObservable()
      this.dueDateTime = new DatePickerObservable()
    }

    if (addEmptyLineItem) {
      let invoiceLineItem = new InvoiceLineItemObservable()
      invoiceLineItem.lineItemReference = new TextObservable(v4())
      invoiceLineItem.description = new TextObservable()
      invoiceLineItem.quantity = new NumberObservable()
      invoiceLineItem.salePrice = new NumberObservable()
      invoiceLineItem.discount = new TextObservable()
      invoiceLineItem.discountPercentage = new NumberObservable()
      invoiceLineItem.totalLineAmount = new NumberObservable()
      this.lineItemModel.invoiceLineItems.push(invoiceLineItem)
    }
  }

  getUpdateInvoiceInput(invoice?: Invoice): UpdateInvoiceInput {
    const input: UpdateInvoiceInput = {
      invoiceReference: this.invoiceReference.text,
      invoiceNumber: this.invoiceNumber.text,
      description: this.description.text,
      paymentTerms: this.paymentTerms.text,
      taxTypeReference: this.lineItemModel.taxType.taxTypeReference,
      clientReference: this.clientSelection.clientReference,
      jobReference: this.jobSelection.jobReference,
      invoiceDateTime: this.invoiceDateTime.getUtcDateTime(),
      dueDateTime: this.dueDateTime.date ? this.dueDateTime.getUtcDateTime() : null,
      additionalReference: this.additionalReference.text,
      currencyCode: this.currencySelection.currencyCode,
      currencyRate: 1,
      saleStaffReference: this.staffSelection.staffReference,
      isReminderEnabled: !this.isReminderDisabled.value,
      useLineItemGroup: false,
      concurrencyToken: this.concurrencyToken.text,
      lineItemGroups: [],
      isSent: this.isSent.value,
      isDisputed: this.isDisputed.value,
    }

    if (this.addressSelection.route) {
      input.address = {
        route: this.addressSelection.route,
        regionCode: this.addressSelection.regionCode,
        regionName: this.addressSelection.regionName,
        postalCode: this.addressSelection.postalCode,
        city: this.addressSelection.city,
        countryCode: this.addressSelection.countryCode,
        countryName: this.addressSelection.countryName,
      }
    }

    let defaultInputGroup: InvoiceLineItemGroupInput

    // If invoice is provided in request which means it is for edit screen
    if (invoice) {
      defaultInputGroup = {
        lineItemGroupName: invoice.lineItemGroups[0].lineItemGroupName,
        lineItemGroupReference: invoice.lineItemGroups[0].lineItemGroupReference,
        lineItems: [],
      }
    } else {
      defaultInputGroup = {
        lineItemGroupName: "temp",
        lineItemGroupReference: uuId(),
        lineItems: [],
      }
    }

    this.lineItemModel.invoiceLineItems.forEach((value) => {
      defaultInputGroup.lineItems.push({
        lineItemReference: value.lineItemReference.text ?? uuId(),
        storageReference: undefined,
        itemReference: value.itemSelection.itemReference,
        itemDisplayOrder: 0,
        description: value.description.text,
        quantity: value.quantity.number,
        unitCostPrice: undefined,
        unitMarkupAmount: undefined,
        unitMarkupPercentage: undefined,
        unitSalePrice: value.salePrice.number,
        discountPercentage: value.discountPercentage.number,
        discountAmount: value.discountAmount.number,
        totalDiscountAmount: value.totalDiscountAmount,
        totalAmount: value.totalLineAmount.number,
        taxAmount: value.totalLineTaxAmount.number,
        taxReference: value.taxRateSelection.taxReference,
      })
    })
    input.lineItemGroups?.push(defaultInputGroup)

    return input
  }

  setOpenSendEmailModal(value: boolean) {
    this.openSendEmailModal = value
  }

  setIsAddMode(isAddMode: boolean) {
    this.isAddMode = isAddMode
  }

  setIsEditMode(isAddMode: boolean) {
    this.isEditMode = isAddMode
  }

  setInvoice(invoice: Invoice) {    
    this.invoiceReference.text = invoice.invoiceReference
    this.invoiceNumber.text = invoice.invoiceNumber
    this.title.text = invoice.title
    this.paymentTerms.text = invoice.paymentTerms
    this.description.text = invoice.description
    this.clientSelection.clientReference = invoice.client.clientReference
    this.clientSelection.clientName = invoice.client.name
    this.jobSelection.jobReference = invoice.job?.jobReference
    this.jobSelection.jobNumber = invoice.job?.jobNumber
    this.additionalReference.text = invoice.additionalReference
    this.addressSelection.setAddress(invoice.address)
    this.dueDateTime.date = new Date(Date.parse(invoice?.dueDateTimeUtc ?? ""))
    this.invoiceDateTime.date = new Date(Date.parse(invoice?.invoiceDateTimeUtc ?? ""))
    this.isSent.value = invoice.isSent
    this.isDisputed.value = invoice.isDisputed
    this.invoiceStatus.invoiceStatusReference = invoice.invoiceStatusReference
    this.invoiceStatus.name = invoice.invoiceStatusName

    this.currencySelection.currencyCode = invoice.currencyCode
    this.staffSelection.staffReference = invoice.saleStaff?.staffReference
    this.staffSelection.fullName = invoice.saleStaff?.name
    this.staffSelection.profileImageUrl = invoice.saleStaff?.profileImageUrl
    this.isReminderDisabled.value = !invoice.isReminderEnabled
    this.concurrencyToken.text = invoice.concurrencyToken
    this.lineItemModel.setInvoiceLineItems(invoice)
  }

  setTemplates(templates: Template[], isAddMode: boolean) {
    this.paymentInstructionTemplates = templates.filter(
      (value) => value.templateTypeReference === templateTypes.invoicePaymentInstruction.templateTypeReference
    )

    this.messageForClientTemplates = templates.filter(
      (value) => value.templateTypeReference === templateTypes.invoiceMessageForClient.templateTypeReference
    )

    if (isAddMode) {
      this.description.text = this.messageForClientTemplates.find((value) => value.isDefault)?.descriptionTemplate
      this.paymentTerms.text = this.paymentInstructionTemplates.find((value) => value.isDefault)?.descriptionTemplate
    }
  }

  setPdfModal(isOpen: boolean, link: string) {
    this.pdfModal = {
      isOpen: isOpen,
      reportLink: link,
    }
  }

  setInvoiceNumber(number?: string) {
    this.invoiceNumber.text = number
  }

  setConcurrencyToken(token?: string) {
    this.concurrencyToken.text = token
  }

  handleApiErrors(errorResponse: any) {
    runInAction(() => {
      errorResponse.response.data.messages.forEach((v: any) => {
        if (isSame(v.property, "InvoiceReference")) {
          this.invoiceReference.errorMessage = v.description
        }

        if (isSame(v.property, "InvoiceNumber")) {
          this.invoiceNumber.errorMessage = v.description
        }

        if (isSame(v.property, "Title")) {
          this.title.errorMessage = v.description
        }

        if (isSame(v.property, "Description")) {
          this.description.errorMessage = v.description
        }

        if (isSame(v.property, "PaymentTerms")) {
          this.paymentTerms.errorMessage = v.description
        }

        if (isSame(v.property, "TaxTypeReference")) {
          this.lineItemModel.taxType.errorMessage = v.description
        }

        if (isSame(v.property, "ClientReference")) {
          this.clientSelection.errorMessage = v.description
        }

        if (isSame(v.property, "InvoiceDateTime")) {
          this.invoiceDateTime.errorMessage = v.description
        }
        if (isSame(v.property, "DueDateTime")) {
          this.dueDateTime.errorMessage = v.description
        }

        if (isSame(v.property, "AdditionalReference")) {
          this.additionalReference.errorMessage = v.description
        }

        if (isSame(v.property, "CurrencyCode")) {
          this.currencySelection.errorMessage = v.description
        }

        if (isSame(v.property, "CurrencyRate")) {
          this.currencySelection.errorMessage = v.description
        }

        if (isSame(v.property, "SaleStaffReference")) {
          this.staffSelection.errorMessage = v.description
        }

        if (isSame(v.property, "ConcurrencyToken")) {
          this.concurrencyToken.errorMessage = v.description
        }

        if (isSame(v.property, "jobReference")) {
          this.clientSelection.errorMessage = v.description
        }

        // show 1 property ad a time for address.
        if (isContains(v.property, "Address") && isEmpty(this.addressSelection.errorMessage)) {
          this.addressSelection.errorMessage = v.description
        }

        if (isContains(v.property, "LineItemGroups")) {
          for (let i = 0; i < this.lineItemModel.invoiceLineItems.length; i++) {
            if (isContains(v.property, `LineItems[${i}].LineItemReference`)) {
              this.lineItemModel.invoiceLineItems[i].lineItemReference.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].ItemReference`)) {
              this.lineItemModel.invoiceLineItems[i].itemSelection.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].StorageReference`)) {
              this.lineItemModel.invoiceLineItems[i].itemSelection.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].Description`)) {
              this.lineItemModel.invoiceLineItems[i].description.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].Quantity`)) {
              this.lineItemModel.invoiceLineItems[i].quantity.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].UnitSalePrice`)) {
              this.lineItemModel.invoiceLineItems[i].salePrice.errorMessage = v.description
            }

            if (
              isContains(v.property, `LineItems[${i}].DiscountPercentage`) ||
              isContains(v.property, `LineItems[${i}].DiscountAmount`)
            ) {
              this.lineItemModel.invoiceLineItems[i].discount.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].TaxReference`)) {
              this.lineItemModel.invoiceLineItems[i].taxRateSelection.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].TaxAmount`)) {
              this.lineItemModel.invoiceLineItems[i].totalLineTaxAmount.errorMessage = v.description
            }

            if (isContains(v.property, `LineItems[${i}].TotalAmount`)) {
              this.lineItemModel.invoiceLineItems[i].totalLineAmount.errorMessage = v.description
            }
          }
        }
      })
    })
  }
}
