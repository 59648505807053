import { useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { EnabledFeatures } from "models/identity/features"
import { EnabledPermissions } from "models/identity/permissions"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { localStorageService } from "shared/services/local-storage"
import { UserOrganisations } from "models/identity/userOrganisation"
import { UserDetails } from "models/identity/userDetail"

async function getUserOrganisation() {
  let organisationId = localStorageService.get(common.localStorageActiveOrganisationKey)
  if (organisationId) return organisationId

  const response: Response<UserOrganisations> = await graphQLClient.fetch(
    query.userOrganisationQuery,
    null,
    false,
    common.identityApiUrl,
    "UserOrganisationQuery"
  )

  const userOrganisations = response.data.data.userOrganisations.edges
  organisationId = userOrganisations[0].node.organisationId
  localStorageService.set(common.localStorageActiveOrganisationKey, organisationId)
  return organisationId
}

function useUserOrganisation() {
  return useQuery(keys.userOrganisationQuery, fetchUserOrganisations, {
    select: (response: Response<UserOrganisations>) => response.data.data.userOrganisations.edges.map((item) => item.node),
  })
}

function fetchUserOrganisations() {
  return graphQLClient.fetch(query.userOrganisationQuery, null, false, common.identityApiUrl, keys.userOrganisationQuery)
}

function useUserDetail() {
  return useQuery(keys.userDetailQuery, fetchUserDetail, {
    select: (response: Response<UserDetails>) => response.data.data.userDetails,
  })
}

const fetchUserDetail = () => {
  return graphQLClient.fetch(query.userDetailQuery, null, true, common.identityApiUrl, keys.userDetailQuery)
}

const useEnabledFeature = () => {
  return useQuery(keys.enabledFeatureQuery, fetchEnabledFeatures, {
    select: (response: Response<EnabledFeatures>) => response.data.data.enabledFeatures,
  })
}

const fetchEnabledFeatures = () => {
  return graphQLClient.fetch(query.enabledFeatureQuery, null, true, common.identityApiUrl, keys.enabledFeatureQuery)
}

function useEnabledPermission() {
  return useQuery(keys.enabledPermissionQuery, fetchEnabledPermissions, {
    select: (response: Response<EnabledPermissions>) => response.data.data.enabledPermissions,
  })
}

const fetchEnabledPermissions = () => {
  return graphQLClient.fetch(query.enabledPermissionQuery, null, true, common.identityApiUrl, keys.enabledPermissionQuery)
}

export { useUserDetail, getUserOrganisation, useEnabledFeature, useEnabledPermission, useUserOrganisation }
