export class TaskSortType {
  reference: string
  name: string

  constructor(reference: string, name: string) {
    this.reference = reference
    this.name = name
  }
}

export const dueNextTaskType = new TaskSortType("740D1938-BD43-44FC-9138-124DB6787E8C", "Due Next")
export const newestFirstTaskType = new TaskSortType("9ED2E5A6-8A89-4EA4-9E6C-AB08095F615F", "Newest First")

export const taskSortTypes: TaskSortType[] = [newestFirstTaskType, dueNextTaskType]
