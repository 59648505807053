import React from "react"
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu"
import ToolTip from "library/styled/tooltip"

export interface MenuOption<T = any> {
  name: string
  icon?: string
  action: (item: T) => void
  iconClass?: string
  disabled?: boolean
  tooltip?: string
}

export interface MenuOptionTable<T = any> {
  name: string
  icon?: string
  action: (item: T) => void
  isVisible?: (item: T) => boolean
}

interface Props {
  options?: MenuOption[]
  // If true, menu is rendered as a direct child of document.body
  portal?: boolean
  listItem?: any
}

interface TableMenuProps {
  options?: MenuOptionTable[]
  // If true, menu is rendered as a direct child of document.body
  portal?: boolean
  listItem?: any
}

const HamburgerMenu = ({ options, portal = false, listItem }: Props) => {
  return (
    <>
      <Menu
        menuButton={
          <MenuButton>
            {" "}
            <i className="fa-solid fa-lg fa-ellipsis px-2 py-3 rounded-md text-gray-500 hover:bg-gray-200"></i>
          </MenuButton>
        }
        transition
        align="end"
        portal={portal}
      >
        {options?.map((item) => (
          <ToolTip key={item.name} show={item.tooltip !== undefined && item.disabled} text={item.tooltip} placement="left">
            <MenuItem onClick={() => item.action(listItem)} disabled={item.disabled}>
              {item.icon && (
                <i className={`fas fa-${item.icon} ${item.disabled ? "text-gray-400" : "text-primary-900"} pr-2`}></i>
              )}
              {item.iconClass && <i className={`${item.iconClass} pr-2`}></i>}
              {item.name}
            </MenuItem>
          </ToolTip>
        ))}
      </Menu>
    </>
  )
}

const HamburgerMenuTable = ({ options, portal = false, listItem }: TableMenuProps) => {
  return (
    <>
      <Menu
        menuButton={
          <MenuButton>
            <i className="fa-solid fa-lg fa-ellipsis px-2 py-3 rounded-md text-gray-500 hover:bg-gray-200"></i>
          </MenuButton>
        }
        transition
        align="end"
        portal={portal}
      >
        {options?.map(
          (item) =>
            (item.isVisible === undefined || item.isVisible(listItem)) && (
              <MenuItem key={item.name} onClick={() => item.action(listItem)}>
                {item.icon && <i className={`fas fa-${item.icon} text-primary-900 pr-2`}></i>}
                {item.name}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  )
}

export { HamburgerMenu, HamburgerMenuTable }
