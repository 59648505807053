import React, { useEffect } from "react"
import { observer } from "mobx-react"
import Select from "react-select"
import { action, makeObservable, observable, runInAction } from "mobx"
import { Tax } from "models/finance/tax"
import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import { isEmpty, isProvided } from "utils/extensions"
import { useTax } from "hooks/finance/tax"
import { inputStyle } from "library/styled/dropdown"
import { Link } from "react-router-dom"

export class TaxRateObservable {
  taxReference?: string
  name?: string
  rate?: number
  errorMessage?: string
  isDisabled: boolean = false

  constructor(taxReference?: string, name?: string, rate?: number) {
    makeObservable(this, {
      taxReference: observable,
      name: observable,
      rate: observable,
      isDisabled: observable,
      errorMessage: observable,
    })

    this.taxReference = taxReference
    this.name = name
    this.rate = rate ?? 0
  }
  get taxRate() {
    return this.rate ?? 0
  }

  setManualTax() {
    this.taxReference = undefined
    this.rate = undefined
    this.name = "Manual"
  }

  setNoTaxAndDisable() {
    this.taxReference = undefined
    this.rate = undefined
    this.name = "No Tax"
    this.isDisabled = true
  }

  enableTaxRateSelection() {
    this.isDisabled = false
  }
}

interface Props {
  id?: string
  name?: string
  label?: string
  type?: string
  taxRateObservable: TaxRateObservable
  onBlur?: () => void
  onChange?: () => void
  isEditMode?: boolean
  isManualTax?: boolean
}

const inputStyle2 = {
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    fontSize: 14,
    borderRadius: 0,
    //shadow: 0,
    borderColor: state.isFocused ? "#ffffff" : "#ffffff",
    "&:hover": {
      borderColor: state.isFocused ? "#c43a3a" : "#bd1770",
    },
    border: 1,
    // The following disables all border colors on element
    boxShadow: "none",
    textAlign: "center",
  }),
  input: (provided: any) => ({
    ...provided,
    // Removes blue square border around typing indicator
    "input:focus": {
      boxShadow: "none",
    },
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14,
    width: 200,
    textAlign: "left",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#6b7280",
  }),
}

const ItemTaxSelection = (props: Props) => {
  const { data: taxes, isLoading } = useTax()

  useEffect(() => {
    if (taxes && taxes.filter((value) => !value.isDisabled).length > 0) {
      if (!props.isEditMode && props.name) {
        runInAction(() => {
          props.taxRateObservable.name = undefined
        })
      }
      let defaultTax: Tax
      //if Taxtypeselection change for Exclusive or inclusive tax then set default tax rate or set default tax for create invoice screen
      if (
        isEmpty(props.taxRateObservable.name) ||
        (props.taxRateObservable.name === "No Tax" && !props.taxRateObservable.taxReference && !props.isManualTax)
      ) {
        defaultTax =
          taxes?.find((obj) => {
            return obj.isDefault
          }) ?? taxes![0]

        runInAction(() => {
          props.taxRateObservable.taxReference = defaultTax.taxReference
          props.taxRateObservable.name = defaultTax.name
          props.taxRateObservable.rate = defaultTax.effectiveRate
          if (props.onChange) {
            props.onChange()
          }
        })
      } else {
        let filteredTax = taxes.find((m) => m.taxReference === props.taxRateObservable.taxReference)
        if (filteredTax) {
          runInAction(() => {
            props.taxRateObservable.rate = filteredTax?.effectiveRate
          })
        }
      }
    }
    // eslint-disable-next-line
  }, [isLoading, props.taxRateObservable.isDisabled])

  function getDefaultValue(): Tax {
    return {
      taxReference: props.taxRateObservable.taxReference,
      name: props.taxRateObservable.name,
      effectiveRate: props.taxRateObservable.rate,
    }
  }

  function getName(tax: Tax) {
    if (!tax || !tax.taxReference) {
      return ""
    }

    return `${tax.name} ${tax.effectiveRate ? " (" + tax.effectiveRate + "%)" : ""}`
  }

  return (
    <>
      <FormGroup cols={3}>
        {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
        <Select
          classNamePrefix={props.id}
          inputId={props.id}
          options={taxes?.filter((m) => !m.isDisabled)}
          getOptionLabel={(m) => getName(m) ?? ""}
          getOptionValue={(m) => m.name ?? ""}
          value={getDefaultValue()}
          styles={props.type === "input" ? inputStyle : inputStyle2}
          isClearable={true}
          isSearchable={true}
          isDisabled={props.taxRateObservable.isDisabled}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          onBlur={action((event) => {
            if (props.onBlur) props.onBlur()
          })}
          onChange={(e) => {
            runInAction(() => {
              props.taxRateObservable.name = e?.name
              props.taxRateObservable.rate = e?.effectiveRate
              props.taxRateObservable.taxReference = e?.taxReference
              if (isProvided(props.taxRateObservable.errorMessage)) props.taxRateObservable.errorMessage = undefined

              if (props.onChange) {
                props.onChange()
              }
            })
          }}
          noOptionsMessage={() => (
            <>
              {" "}
              <h2>No tax rates found.</h2>
              <span>
                <Link to="/settings/invoice" target="_blank" className="underline">
                  Click here
                </Link>{" "}
                to go to settings and add tax rates
              </span>
            </>
          )}
          menuPlacement={"bottom"}
          menuPortalTarget={props.type === "input" ? document.body : undefined}
          menuPosition={"fixed"}
        />
        {props.taxRateObservable.errorMessage && (
          <ErrorInline data-testid={`${props.id}-error-label`}>{props.taxRateObservable.errorMessage}</ErrorInline>
        )}
      </FormGroup>
    </>
  )
}

export default observer(ItemTaxSelection)
