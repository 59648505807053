import React, { Fragment, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { useCategory, useDeleteCategory } from "hooks/organisation/category"
import Accordian from "library/styled/accordian"
import { WarningAlert } from "library/styled/alerts"
import TableDynamic from "library/styled/table"
import { successToaster } from "library/styled/toasters"
import { useModalState } from "utils/modalStates"
import AddCategoryModal from "components/setting/shared/category/add-category"
import EditCategoryModal from "components/setting/shared/category/editCategory"
import ViewCategoryModal from "components/setting/shared/category/viewCategory"
import { Category, RemoveCategoryInput } from "models/organisation/category"
import { Column } from "models/shared/column"
import { ColumnType } from "constants/columns"

interface Props {
  entityTypeReference: string
}

const CategorySetting = ({ entityTypeReference }: Props) => {
  const {
    addModal,
    setAddModal,
    viewModal,
    setViewModal,
    editModal,
    setEditModal,
    alert,
    setAlert
  } = useModalState<Category>()
  const { data } = useCategory()
  const [categories, setCategories] = useState<Category[]>([])
  const { mutate: deleteCategory, isLoading: isDeleting } = useDeleteCategory()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (data) {
      const filteredCategories = data.filter((m) => m.entityType!.entityTypeReference === entityTypeReference)
      setCategories(filteredCategories)
    }
  }, [data, entityTypeReference])

  const handleDelete = (category: Category) => {
    const input: RemoveCategoryInput = {
      categoryReference: category.categoryReference
    }

    deleteCategory(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries(keys.categoryQuery)
        successToaster("Category deleted")
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }

  const options = [
    {
      name: "Edit",
      icon: "pen-circle",
      action: (item: Category) => setEditModal({ isOpen: true, item: item })
    },
    {
      name: "Delete",
      icon: "trash",
      action: (item: Category) => setAlert({ isOpen: true, item: item })
    }
  ]

  return (
    <Fragment>
      <Accordian title="Categories" action={() => setAddModal(true)} linkText="Add Category">
        <TableDynamic
          columns={cols}
          data={categories}
          dataKey="categoryReference"
          options={options}
          onRowClick={(item) => setViewModal({ isOpen: true, item: item })}
        />
      </Accordian>

      <AddCategoryModal isOpen={addModal} onClose={() => setAddModal(false)}
                        entityTypeReference={entityTypeReference} />
      <ViewCategoryModal
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        category={viewModal.item}
      />
      <EditCategoryModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        category={editModal.item}
      />
      <WarningAlert
        title="Category"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />
    </Fragment>
  )
}

const cols: Column[] = [
  {
    id: "1",
    name: "Name",
    key: "name",
    columnTypeId: ColumnType.Text.Id
  },
  {
    id: "2",
    name: "Description",
    key: "description",
    columnTypeId: ColumnType.Text.Id
  }
]

export default CategorySetting
