import { action, makeObservable, observable } from "mobx"
import { FileQueryInput } from "models/file/file"
import { TextObservable } from "library/textInputGroup/textInputGroup"

type uploadModal = {
  file: File | null
  isOpen: boolean
}

export class DocumentCardModel {
  uploadModal: uploadModal
  enableQuery: boolean
  constructor() {
    makeObservable(this, {
      uploadModal: observable,
      handleFileDropped: action,
      enableQuery: observable,
      setEnableQuery: action,
      setUploadModal: action,
    })
    this.uploadModal = { file: null, isOpen: false }
    this.enableQuery = false
  }

  getFileInput(entityTypeReference: string, entityReference?: string) {
    const obj: FileQueryInput = {
      entityReference: entityReference ?? "",
      entityTypeReference: entityTypeReference,
    }
    return obj
  }

  handleFileDropped(file: File) {
    this.uploadModal = {
      file: file,
      isOpen: true,
    }
  }

  setUploadModal(open: boolean) {
    this.uploadModal = {
      file: null,
      isOpen: open,
    }
  }

  setEnableQuery() {
    this.enableQuery = true
  }
}
