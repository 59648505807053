import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { classNames } from "common"

interface Props {
  onClick: (item: any) => void
  options?: any
  menuButton: JSX.Element
}

const DropdownMenu = ({ onClick, options, menuButton }: Props) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="">{menuButton}</Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 -translate-y-3 scale-95"
          enterTo="transform opacity-100 translate-y-0 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 origin-top-left absolute left-0 w-max rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options?.map((menuItem: any, index: number) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={() => onClick(menuItem)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-1.5 text-sm w-full text-left font-medium"
                      )}
                    >
                      {menuItem.icon && <i className={`fas fa-${menuItem.icon} text-primary-900 w-4 h-3`}></i>}
                      <span className="pl-1">{menuItem.name}</span>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}

export default DropdownMenu
