import { action, makeObservable, observable } from "mobx"
import { InfiniteData } from "react-query"
import { Response } from "models/shared/response"
import { File, FileQueryInput, Files } from "models/file/file"
import { entityTypes } from "constants/entityTypes"
import { TextObservable } from "library/textInputGroup/textInputGroup"

export class DocumentListModel {
  documentList: File[] = []
  fileName: TextObservable
  tag: TextObservable
  constructor() {
    makeObservable(this, {
      documentList: observable,
      setDocumentList: action,
    })
    this.fileName = new TextObservable("")
    this.tag = new TextObservable("")
  }

  getFilter() {
    const obj: FileQueryInput = {
      first: 10,
      after: "",
      entityTypeReference: entityTypes.document.entityTypeReference,
      fileName: this.fileName.text,
      tag: this.tag.text,
    }
    return obj
  }

  setDocumentList(data: InfiniteData<Response<Files>>) {
    let tempList: File[] = []
    data.pages.forEach((page) => {
      const pageData = page.data.data.files.edges.map((item) => item.node)
      tempList = tempList.concat(pageData)
    })
    this.documentList = tempList
  }
}
