import React, { useEffect } from "react"
import Select from "react-select"
import { useStaffName } from "hooks/people/staff"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-react"
import ToolTip from "library/styled/tooltip"
import { filterStyle, inputStyle } from "../styled/dropdown"

export class StaffSelectionObservable {
  staffReference?: string
  fullName?: string
  profileImageUrl?: string
  errorMessage?: string

  constructor(staffReference?: string, fullName?: string, profileImageUrl?: string) {
    makeObservable(this, {
      staffReference: observable,
      fullName: observable,
      profileImageUrl: observable,
      errorMessage: observable,
    })

    this.staffReference = staffReference
    this.fullName = fullName
    this.profileImageUrl = profileImageUrl
  }
}

interface Props {
  label: string
  name: string
  error?: any
  isClearable?: boolean
  value: StaffSelectionObservable
  placeholder?: string
  type?: "input" | "filter"
  isInModal?: boolean
  tooltip?: string
  onChange?: () => void
  cols?: number
}

interface OptionLabelProps {
  fullName?: string
  staffReference?: string
  profileImageUrl?: string
}

const StaffSelection = ({
  label,
  name,
  error,
  isClearable = true,
  value,
  placeholder = "Optional",
  type = "input",
  isInModal = false,
  tooltip,
  onChange,
  cols = 3,
}: Props) => {
  const { data: staffMembers } = useStaffName()

  const formatOptionLabel = (staff: OptionLabelProps) => {
    return (
      <div className="overflow-hidden">
        <img src={staff.profileImageUrl} className="h-5 w-5 rounded-full inline mr-2" alt=""></img>
        <span className="">{staff.fullName}</span>
      </div>
    )
  }

  return (
    <>
      <FormGroup cols={cols}>
        <Label htmlFor={name}>
          {label}
          {tooltip && (
            <ToolTip text={tooltip} id={"Id"}>
              <i className="far fa-circle-info text-gray-700 ml-2"></i>
            </ToolTip>
          )}
        </Label>
        <Select
          options={staffMembers}
          isSearchable={true}
          isClearable={isClearable}
          onChange={(e) => {
            runInAction(() => {
              value.staffReference = e?.staffReference
              value.fullName = e?.fullName
              value.profileImageUrl = e?.profileImageUrl
            })
            if (onChange) onChange()
          }}
          name={name}
          id={name}
          inputId={name}
          classNamePrefix={name}
          getOptionLabel={(staff: OptionLabelProps) => staff.fullName ?? ""}
          getOptionValue={(staff: OptionLabelProps) => staff.staffReference ?? ""}
          placeholder={placeholder}
          className={`${type === "filter" ? "shadow-md" : "ring-primary-100"}`}
          styles={type === "filter" ? filterStyle : inputStyle}
          menuPosition={isInModal ? "fixed" : undefined}
          formatOptionLabel={formatOptionLabel}
          value={value?.staffReference ? value : null}
          menuPlacement={"bottom"}
          menuPortalTarget={document.body}
        ></Select>
        {value?.errorMessage && <ErrorInline>{value?.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}

export default observer(StaffSelection)
