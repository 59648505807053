import { SelectObservable } from "library/selectInputGroup/selectInputGroup"
import { FileSelectionObservable } from "library/FileSelection/models"
import { ClientSelectionObservable } from "library/clientSelection/clientSelection"
import { StaffSelectionObservable } from "library/components/staffSelection"
import { action, makeObservable, observable } from "mobx"
import { EntityType, entityTypes } from "constants/entityTypes"
import { FileQueryInput } from "models/file/file"
import { FileSource } from "components/shared/documents/04_sharedModel/models"
import { ContractorSelectionObservable } from "library/contractorSelection/contractorSelection"
import { JobSelectionObservable } from "library/jobSelection/jobSelection"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isEmpty, isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"

export class LinkExistingFileModel {
  source: SelectObservable<FileSource>
  fileSources: EntityType[] = []
  file: FileSelectionObservable
  fileEntityReference: string
  client: ClientSelectionObservable
  staff: StaffSelectionObservable
  contractor: ContractorSelectionObservable
  job: JobSelectionObservable
  generalErrors: GeneralError
  constructor() {
    makeObservable(this, {
      fileEntityReference: observable,
      setFileEntityReference: action,
      reset: action,
      resetEntitySelection: action,
      handleApiErrors: action,
      generalErrors: observable,
      fileSources: observable,
      setFileSources: action,
    })
    this.fileEntityReference = ""
    this.source = new SelectObservable<FileSource>(entityTypes.document)
    this.file = new FileSelectionObservable()
    this.client = new ClientSelectionObservable()
    this.staff = new StaffSelectionObservable()
    this.contractor = new ContractorSelectionObservable()
    this.job = new JobSelectionObservable()
    this.generalErrors = {}
  }

  setFileSources(entityTypeReference: string) {
    if (entityTypeReference === entityTypes.document.entityTypeReference)
      this.fileSources = [entityTypes.job, entityTypes.client, entityTypes.staff, entityTypes.contractor]
    else this.fileSources = [entityTypes.document, entityTypes.job, entityTypes.client, entityTypes.staff, entityTypes.contractor]
    this.source = new SelectObservable<FileSource>(this.fileSources[0])
  }

  setFileEntityReference() {
    if (this.source.selectedOption?.entityTypeReference === entityTypes.client.entityTypeReference)
      this.fileEntityReference = this.client.clientReference ?? ""
    else if (this.source.selectedOption?.entityTypeReference === entityTypes.staff.entityTypeReference)
      this.fileEntityReference = this.staff.staffReference ?? ""
    else if (this.source.selectedOption?.entityTypeReference === entityTypes.contractor.entityTypeReference)
      this.fileEntityReference = this.contractor.contractorReference ?? ""
    else if (this.source.selectedOption?.entityTypeReference === entityTypes.job.entityTypeReference)
      this.fileEntityReference = this.job.jobReference ?? ""

    if (isEmpty(this.fileEntityReference)) {
      this.file.fileReference = ""
      this.file.fileName = ""
    }
  }

  resetEntitySelection() {
    this.fileEntityReference = ""
    this.file = new FileSelectionObservable()
    this.job = new JobSelectionObservable()
    this.client = new ClientSelectionObservable()
    this.staff = new StaffSelectionObservable()
    this.contractor = new ContractorSelectionObservable()
  }

  reset(entityTypeReference: string) {
    this.setFileSources(entityTypeReference)
    this.resetEntitySelection()
    this.generalErrors = {}
  }

  getSearchFileInput() {
    const obj: FileQueryInput = {
      first: 20,
      fileReference: "",
      fileName: "",
      entityReference: this.fileEntityReference,
      entityTypeReference: this.source.selectedOption?.entityTypeReference,
    }
    return obj
  }

  // Method to handle API error messages
  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    // Loop through the error messages
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "fileReference")) this.file.errorMessage = v.description
      else this.generalErrors[v.property] = v.description
    })
  }
}
