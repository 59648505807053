import { useInfiniteQuery, useQuery, useMutation, InfiniteData } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { Job, JobQueryInput, Jobs } from "models/job/job"
import { AddJobStateInput, JobStates, RemoveJobStateInput, UpdateJobStateInput } from "models/job/jobState"
import { ReactQueryParams } from "models/shared/common"
import { Response } from "models/shared/response"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { ClientQueryInput, Clients } from "models/people/client"
import { ClientKeyValue } from "library/components/clientSelectionMultiComponent"
import { JobKeyValue } from "library/jobSelection/jobSelection"

//#region Jobs
function useJob(input: JobQueryInput) {
  const result = useInfiniteQuery([keys.jobQuery, input], fetchJobs, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.jobs.pageInfo.hasNextPage) {
        return lastPage?.data.data.jobs.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Jobs>>) => setJobData(data)
  })
  return { ...result, jobs: jobs }
}

const fetchJobs = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.jobQuery, input, true, common.legacyGraphQlUrl, keys.jobQuery)
}
let jobs: Job[] = []

function setJobData(data: InfiniteData<Response<Jobs>>) {
  jobs = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.jobs.edges.map((item) => item.node)
    jobs = jobs.concat(pageData)
  })
}

//#endregion

//#region Job state
function useJobState() {
  return useQuery(keys.jobStateQuery, fetchJobStates, {
    select: (data: Response<JobStates>) => data.data.data.states
  })
}

function fetchJobStates() {
  return graphQLClient.fetch(query.jobStateQuery, null, true, common.legacyGraphQlUrl, keys.jobStateQuery)
}

function addJobState(input: AddJobStateInput) {
  return graphQLClient.mutation(mutation.addJobState, input, "AddState", common.legacyGraphQlUrl)
}

function useAddJobState() {
  return useMutation(addJobState)
}

function updateJobState(input: UpdateJobStateInput) {
  return graphQLClient.mutation(mutation.updateJobState, input, "UpdateState", common.legacyGraphQlUrl)
}

function useUpdateJobState() {
  return useMutation(updateJobState)
}

function removeJobState(input: RemoveJobStateInput) {
  return graphQLClient.mutation(mutation.removeJobState, input, "RemoveState", common.legacyGraphQlUrl)
}

function useRemoveJobState() {
  return useMutation(removeJobState)
}

function useSearchJob(input: JobQueryInput) {
  return useQuery([keys.jobQuery, input], () => searchJobs(input), {
    select: (data: Response<Jobs>) =>
      data.data.data.jobs.edges.map((m) => new JobKeyValue(m.node.jobReference, m.node.jobNumber,
        m.node.description, m.node.address, m.node.clientName))
  })
}

function searchJobs(input: JobQueryInput) {
  return graphQLClient.fetch(query.jobQuery, input, true, common.legacyGraphQlUrl, keys.jobQuery)
}

//#endregion

export { useJob, useJobState, useAddJobState, useUpdateJobState, useRemoveJobState, useSearchJob }
