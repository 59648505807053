import { action, makeObservable, observable, runInAction } from "mobx"
import { TextObservable } from "library/textInputGroup/textInputGroup"
import { Attachment, Attribute, Recipient } from "components/invoices/05_sharedModel/InputModels"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { emailRecipientType } from "constants/emailRecipientType"
import { isContains, isSame } from "utils/extensions"
import { ClientEmailAddress } from "components/invoices/05_sharedModel/invoiceModel"
import { MultiSelectObservable } from "utils/multiSelectObservable"
import { GeneralError } from "models/shared/common"

export default class sendEmailModel {
  emailConfigurationReference: TextObservable
  entityTypeReference: TextObservable
  entityReference: TextObservable
  emailSubject: TextObservable
  emailBody: TextObservable
  recipients: MultiSelectObservable<ClientEmailAddress>
  attributes: Attribute[]
  attachments: Attachment[]
  sendMeACopy: BooleanObservable
  recipientErrorMessage: GeneralError
  generalErrors: GeneralError

  constructor() {
    this.emailConfigurationReference = new TextObservable()
    this.entityTypeReference = new TextObservable()
    this.entityReference = new TextObservable()
    this.emailSubject = new TextObservable()
    this.emailBody = new TextObservable()
    this.sendMeACopy = new BooleanObservable()
    this.recipientErrorMessage = {}
    this.generalErrors = {}
    this.recipients = new MultiSelectObservable<ClientEmailAddress>()
    this.attributes = []
    this.attachments = []

    makeObservable(this, {
      attributes: observable,
      attachments: observable,
      recipientErrorMessage: observable,
      generalErrors: observable,
      reset: action,
    })
  }

  getRecipients(): Recipient[] {
    return this.recipients.selectedItems.map((value) => ({
      recipientTypeReference: emailRecipientType.to.reference,
      emailAddress: value.emailAddress,
      name: value.name,
    }))
  }

  handleApiErrors(errorResponse: any) {
    runInAction(() => {
      errorResponse.response.data.messages.forEach((v: any) => {
        if (isSame(v.property, "Subject")) {
          this.emailSubject.errorMessage = v.description
        } else if (isSame(v.property, "Body")) {
          this.emailBody.errorMessage = v.description
        } else if (isContains(v.property, "recipients")) this.recipientErrorMessage[v.property] = v.description
        else this.generalErrors[v.property] = v.description
      })
    })
  }

  reset() {
    this.emailSubject.text = ""
    this.emailBody.text = ""
    this.recipients.selectedItems = []
    this.sendMeACopy.value = false
    this.attachments = []
    this.attributes = []

    this.clearErrors()
  }

  clearErrors() {
    runInAction(() => {
      this.emailSubject.errorMessage = undefined
      this.emailBody.errorMessage = undefined
      this.generalErrors = {}
      this.recipientErrorMessage = {}
    })
  }
}
