import React, { useState } from "react"
import FilterInputText from "library/observables/filterInputs"
import { InvoiceListModel } from "components/invoices/04_invoiceList/invoiceListModel"
import StaffSelection from "library/components/staffSelection"
import ClientSelectionComponent from "library/components/clientSelectionComponent"
import { useInvoiceStatus } from "components/invoices/services/invoiceService"
import InputGroupMultiSelect from "library/observables/inputGroupMultiSelect"
import { InvoiceStatus } from "components/invoices/05_sharedModel/invoiceModel"
import SidePanel from "library/styled/sidePanel"
import InvoiceListAdvanceFilters from "components/invoices/04_invoiceList/invoiceListAdvanceFilters"
import DateRangePicker from "library/observables/dateRangePicker"

interface Props {
  invoiceListModel: InvoiceListModel
}

const InvoiceListFilters = ({ invoiceListModel }: Props) => {
  const { data: invoiceStates } = useInvoiceStatus()
  const [sidePanel, setSidePanel] = useState(false)
  return (
    <>
      <div className="flex row-auto grid grid-cols-5 gap-4">
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <FilterInputText
            name="invoiceNumber"
            value={invoiceListModel.invoiceNumberSearch}
            placeholder="Filter by Invoice Number"
          />
        </div>

        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <ClientSelectionComponent
            label=""
            name="saleStaff"
            value={invoiceListModel.client}
            type="filter"
            placeholder="Filter by Client"
          />
        </div>

        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <InputGroupMultiSelect<InvoiceStatus>
            options={invoiceStates}
            name="invoiceStates"
            optionLabel={"name"}
            optionValue={"invoiceStatusReference"}
            value={invoiceListModel.invoiceStates}
            type="filter"
            placeholder="Filter by Invoice Status"
            isInModal={true}
          />
        </div>
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <DateRangePicker
            label=""
            name="invoiceDueDateRange"
            value={invoiceListModel.dueDateRange}
            placeholder="Filter by Due Date"
            type="filter"
          />
        </div>
      </div>

      <button
        onClick={() => setSidePanel(true)}
        type="button"
        className="filter-btn bg-white text-gray-400 shadow-2xl focus:outline-none hover:bg-gray-200 hover:text-gray-500"
      >
        <i className="fal fa-filter text-gray-500 ml-3" aria-hidden="true"></i>{" "}
        <span className="text-sm text-gray-500">More Filters</span>
      </button>

      <SidePanel
        title="Invoice Filters"
        open={sidePanel}
        onClose={() => setSidePanel(false)}
        body={<InvoiceListAdvanceFilters invoiceListModel={invoiceListModel} />}
        type="filterPanel"
      />
    </>
  )
}

export default InvoiceListFilters
