import React, { CSSProperties } from "react"
import Select, { StylesConfig } from "react-select"

interface Props {
  options: any
  defaultValue?: any
  onChange?: (item: any) => void
  placeholder?: string
  value: string
  label: string
  type?: string
  name: string
  isClearable?: boolean
  isSearchable?: boolean
  isInModal?: boolean
  selected?: any
}

const filterStyle = {
  container: (base: any) => ({
    ...base,
    borderRadius: 6,
    height: "auto"
  }),
  control: (base: any, state: { isFocused: any }) => ({
    ...base,
    fontSize: 14,
    borderRadius: 6,
    borderColor: state.isFocused ? "rgb(151 208 252)" : "transparent",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(151 208 252)" : "transparent"
    },
    boxShadow: state.isFocused ? "0 0 0 1px rgb(151 208 252)" : 0
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none"
    }
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14
  })
}

const gridStyle = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: 14,
    borderRadius: 6,
    minHeight: 33.9,
    width: 120,
    borderColor: state.isFocused ? "rgb(151 208 252)" : "rgb(209 213 219)",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(151 208 252)" : "rgb(209 213 219)"
    },
    boxShadow: state.isFocused ? "0 0 0 1px rgb(151 208 252)" : 0
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none"
    }
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: 6
  })
}

const inputStyle = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: 14,
    borderRadius: 6,
    shadow: 0,
    minHeight: 33.9,
    marginTop: 4,
    borderColor: state.isFocused ? "rgb(151 208 252)" : "rgb(209 213 219)",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(151 208 252)" : "rgb(209 213 219)"
    },
    boxShadow: state.isFocused ? "0 0 0 1px rgb(151 208 252)" : 0
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: 14
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  }),
  input: (provided: any) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none"
    }
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: 6
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    padding: 6
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#6b7280"
  })
}

const dropdownVariant = {
  filter: filterStyle,
  input: inputStyle,
  grid: gridStyle
}

const SelectDropdown = ({
                          options = [],
                          defaultValue = options[0],
                          onChange,
                          placeholder,
                          value,
                          label,
                          type = "input",
                          name,
                          isClearable = true,
                          isSearchable = true,
                          isInModal = false,
                          selected
                        }: Props) => {
  return (
    <Select
      options={options}
      isSearchable={isSearchable}
      isClearable={isClearable}
      defaultValue={defaultValue ? defaultValue : null}
      name={name}
      id={name}
      getOptionLabel={(option) => option[label]}
      getOptionValue={(option) => option[value]}
      onChange={onChange}
      placeholder={placeholder}
      className="react-select"
      styles={type === "filter" ? filterStyle : inputStyle}
      menuPosition={isInModal ? "fixed" : undefined}
      value={selected}
      classNamePrefix="select-selection"
      menuPlacement={"bottom"}
      menuPortalTarget={document.body}
    ></Select>
  )
}

export { SelectDropdown, dropdownVariant, filterStyle, inputStyle }
