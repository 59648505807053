import { action, makeObservable, observable } from "mobx"
import { AddOrUpdateTemplateInput, Template, TemplateType } from "components/setting/template/05_sharedModel/template"
import { SelectObservable } from "library/models/selectObservable"
import { TemplateMergeField } from "components/setting/invoice/05_sharedModel/templateMergeField"
import { placeholderInputTypes } from "components/setting/template/05_sharedModel/placeholderInputTypes"
import { TextObservable } from "library/observables/inputGroupText"
import { BooleanObservable } from "library/observables/inputGroupCheckbox"
import { AxiosError } from "axios"
import { ErrorResponse } from "models/shared/response"
import { isSame } from "utils/extensions"
import { GeneralError } from "models/shared/common"

export class AddOrUpdateTemplateModel {
  templateReference: string
  templateType: SelectObservable<TemplateType>
  name: TextObservable
  descriptionTemplate: TextObservable
  smsTemplateMessage: TextObservable
  emailTemplateSubject: TextObservable
  emailTemplateBody: TextObservable
  isDisabled: BooleanObservable
  isDefault: BooleanObservable
  selectedInputId: string
  generalErrors: GeneralError

  constructor(template?: Template) {
    makeObservable(this, {
      setTemplateType: action,
      insertPlaceholder: action,
      setPlaceholderInput: action,
      resetTemplateDescriptions: action,
      generalErrors: observable,
      reset: action,
      handleApiErrors: action,
    })
    this.templateReference = template?.templateReference ?? ""
    this.templateType = new SelectObservable<TemplateType>()
    this.name = new TextObservable(template?.name)
    this.descriptionTemplate = new TextObservable(template?.descriptionTemplate)
    this.smsTemplateMessage = new TextObservable(template?.smsTemplateMessage)
    this.emailTemplateSubject = new TextObservable(template?.emailTemplateSubject)
    this.emailTemplateBody = new TextObservable(template?.emailTemplateBody)
    this.isDefault = new BooleanObservable(template?.isDefault)
    this.isDisabled = new BooleanObservable(template?.isDisabled)
    this.selectedInputId = placeholderInputTypes.description
    this.generalErrors = {}
  }

  handleTemplateTypeChange(type?: TemplateType) {
    if (type?.isDescriptionTemplateSupported) this.setPlaceholderInput(placeholderInputTypes.description)
    else if (type?.isSmsTemplateSupported) this.setPlaceholderInput(placeholderInputTypes.sms)
    else this.setPlaceholderInput(placeholderInputTypes.subject)

    this.resetTemplateDescriptions()
  }

  setTemplateType(type: TemplateType) {
    this.templateType.selectedOption = type
  }

  setPlaceholderInput(id: string) {
    this.selectedInputId = id
  }

  insertPlaceholder(field: TemplateMergeField) {
    const element = document.getElementById(this.selectedInputId) as HTMLInputElement
    const position = element?.selectionStart ?? 0
    if (this.selectedInputId === placeholderInputTypes.description) {
      this.descriptionTemplate.text = this.setPlaceholderValue(this.descriptionTemplate.text ?? "", field, position)
    } else if (this.selectedInputId === placeholderInputTypes.sms) {
      this.smsTemplateMessage.text = this.setPlaceholderValue(this.smsTemplateMessage.text ?? "", field, position)
    } else if (this.selectedInputId === placeholderInputTypes.subject) {
      this.emailTemplateSubject.text = this.setPlaceholderValue(this.emailTemplateSubject.text ?? "", field, position)
    } else if (this.selectedInputId === placeholderInputTypes.body) {
      this.emailTemplateBody.text = this.setPlaceholderValue(this.emailTemplateBody.text ?? "", field, position)
    }
  }

  setPlaceholderValue(value: string, field: TemplateMergeField, position: number) {
    let textToInsert = field.normalizedName
    let textBeforeCursorPosition = value.substring(0, position)
    let textAfterCursorPosition = value.substring(position, value.length)
    return textBeforeCursorPosition + textToInsert + textAfterCursorPosition
  }

  getAddOrUpdateTemplateInput() {
    const input: AddOrUpdateTemplateInput = {
      templateReference: this.templateReference,
      templateTypeReference: this.templateType.selectedOption?.templateTypeReference ?? "",
      name: this.name.text ?? "",
      descriptionTemplate: this.descriptionTemplate.text ?? "",
      smsTemplateMessage: this.smsTemplateMessage.text ?? "",
      emailTemplateSubject: this.emailTemplateSubject.text ?? "",
      emailTemplateBody: this.emailTemplateBody.text ?? "",
      description: "",
      isDisabled: this.isDisabled.value,
      isDefault: this.isDefault.value,
    }
    return input
  }

  reset(template?: Template) {
    this.templateReference = template?.templateReference ?? ""
    this.templateType = new SelectObservable<TemplateType>()
    this.name = new TextObservable(template?.name)
    this.descriptionTemplate = new TextObservable(template?.descriptionTemplate)
    this.smsTemplateMessage = new TextObservable(template?.smsTemplateMessage)
    this.emailTemplateSubject = new TextObservable(template?.emailTemplateSubject)
    this.emailTemplateBody = new TextObservable(template?.emailTemplateBody)
    this.isDefault = new BooleanObservable(template?.isDefault)
    this.isDisabled = new BooleanObservable(template?.isDisabled)
    this.selectedInputId = placeholderInputTypes.description
    this.generalErrors = {}
  }

  resetTemplateDescriptions() {
    this.descriptionTemplate.text = ""
    this.smsTemplateMessage.text = ""
    this.emailTemplateBody.text = ""
    this.emailTemplateSubject.text = ""
  }

  // Method to handle API error messages
  handleApiErrors(errorResponse: AxiosError<ErrorResponse>) {
    // Loop through the error messages
    errorResponse.response?.data.messages?.forEach((v) => {
      if (isSame(v.property, "templateTypeReference")) this.templateType.errorMessage = v.description
      else if (isSame(v.property, "name")) this.name.errorMessage = v.description
      else if (isSame(v.property, "descriptionTemplate")) this.descriptionTemplate.errorMessage = v.description
      else if (isSame(v.property, "smsTemplateMessage")) this.smsTemplateMessage.errorMessage = v.description
      else if (isSame(v.property, "emailTemplateSubject")) this.emailTemplateSubject.errorMessage = v.description
      else if (isSame(v.property, "emailTemplateBody")) this.emailTemplateBody.errorMessage = v.description
      else if (isSame(v.property, "isDisabled")) this.isDisabled.errorMessage = v.description
      else if (isSame(v.property, "isDefault")) this.isDefault.errorMessage = v.description
      else if (isSame(v.property, "templateReference")) {
        this.generalErrors[v.property] = v.description
      }
    })
  }
}
