import { InfiniteData, useInfiniteQuery, useQuery } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { query } from "shared/graphql/queries"
import { Staff, StaffQueryInput, Staffs } from "models/people/staff"
import { ReactQueryParams } from "models/shared/common"
import { Response } from "models/shared/response"
import { StaffNames } from "models/people/staffName"

//#region Staff

const fetchStaffMembers = ({ queryKey, pageParam = null }: ReactQueryParams) => {
  let input = queryKey[1]
  input.after = pageParam
  return graphQLClient.fetch(query.staffQuery, input, true, common.peopleApiUrl, keys.staffQuery)
}

function useStaff(input: StaffQueryInput) {
  const result = useInfiniteQuery([keys.staffQuery, input], fetchStaffMembers, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.data.staffs.pageInfo.hasNextPage) {
        return lastPage?.data.data.staffs.pageInfo.endCursor
      }
      return undefined
    },
    onSuccess: (data: InfiniteData<Response<Staffs>>) => setStaffData(data),
  })
  return { ...result, staff: staff }
}

let staff: Staff[] = []
function setStaffData(data: InfiniteData<Response<Staffs>>) {
  staff = []
  data.pages.forEach((page) => {
    const pageData = page.data.data.staffs.edges.map((item) => item.node)
    staff = staff.concat(pageData)
  })
}
//#endregion

//#region StaffNames
function useStaffName(showActiveOnly?: boolean) {
  return useQuery(keys.staffNameQuery, fetchStaffNames, {
    select: (data: Response<StaffNames>) => {
      if (showActiveOnly) return data.data.data.staffNames.filter((m) => !m.isArchived)
      return data.data.data.staffNames
    },
  })
}

function fetchStaffNames() {
  return graphQLClient.fetch(query.staffNameQuery, null, true, common.peopleApiUrl, keys.staffNameQuery)
}
//#endregion

export { useStaff, useStaffName }
