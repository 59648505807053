import React, { useEffect } from "react"
import { FullPageLoading } from "library/styled/styled"
import { useAuth } from "react-oidc-context"
import { clearLogoutData } from "../../../utils/extensions"

function Logout() {
  const auth = useAuth()

  useEffect(() => {
    clearLogoutData()
    auth.signoutRedirect({ extraQueryParams: { "ReactJs": "ReactJs" } }).then(value => {
      console.log("Logout.tsx -> signoutRedirect success")
    }).catch(reason => {
      console.log("Logout.tsx -> signoutRedirect failed", reason)
    })
    
  }, [])

  return <FullPageLoading />
}

export default Logout
