import { ErrorInline, FormGroup, Label } from "library/styled/styled"
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select"
import { filterStyle, inputStyle } from "library/styled/dropdown"
import React from "react"
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import { MultiSelectObservable } from "library/models/selectObservable"
import { KeyValuePair } from "models/shared/common"

interface DropdownProps<T> {
  name: string
  label?: string
  onChange?: (item: MultiValue<T>) => void
  cols?: number
  options?: OptionsOrGroups<T, GroupBase<T>> | undefined
  value: MultiSelectObservable<T>
  optionValue: keyof T
  optionLabel: keyof T
  placeholder?: string
  error?: any
  errorKey?: string
  isInModal?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  type?: "filter" | "input"
}

const InputGroupMultiSelect = <T extends KeyValuePair>({
  name,
  label,
  onChange,
  cols = 3,
  options = [],
  value,
  optionValue,
  optionLabel,
  placeholder = "Optional",
  error,
  errorKey,
  isInModal = false,
  isSearchable = true,
  isClearable = false,
  isDisabled = false,
  type = "input",
}: DropdownProps<T>) => {
  return (
    <FormGroup cols={cols}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        isMulti={true}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        id={name}
        value={value.selectedOptions}
        getOptionLabel={(option: T) => option[optionLabel]}
        getOptionValue={(option: T) => option[optionValue]}
        onChange={(e) => {
          runInAction(() => {
            value.selectedOptions = [...e]
          })
          if (onChange) onChange(e)
        }}
        placeholder={placeholder}
        styles={type === "filter" ? filterStyle : inputStyle}
        className={`${type === "filter" ? "shadow-md w-full" : "ring-primary-100"}`}
        menuPortalTarget={isInModal ? null : document.body}
        menuPosition={isInModal ? undefined : "fixed"}
        isDisabled={isDisabled}
      ></Select>
      {errorKey
        ? error?.[errorKey] && <ErrorInline>{error[errorKey]}</ErrorInline>
        : error?.[name] && <ErrorInline>{error[name]}</ErrorInline>}
    </FormGroup>
  )
}

export default observer(InputGroupMultiSelect)
