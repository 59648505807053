import React from "react"
import { Fragment, useState } from "react"
import { toLocaleDate, toLocaleDateTime, toLocaleShortDate } from "common"
import ClientListSettings from "components/client/clientList/setting/clientListSetting"
import { ColumnType } from "constants/columns"
import { useFeature } from "context/feature-context"
import { Column } from "models/shared/column"
import { EnabledFeature } from "models/identity/features"
import { List } from "library/styled/list"
import { ButtonSecondarySmall, TBody, Td, Th, THead } from "library/styled/styled"

interface Props {
  title: string
  buttonText: string
  columns: Column[]
  showSetting?: boolean
  data: any
  dataKey: string
  onRowClick: (item: any) => void
  hasNextPage: boolean | undefined
  fetchNextPage: () => void
  onColumnChange: (cols: Column[]) => void
  selected?: string
  isViewingDetail?: boolean
  statusColor?: any
  action?: any
  showNavLink?: boolean
}

const DataGrid = ({
  title,
  buttonText,
  columns,
  showSetting = true,
  data,
  dataKey,
  onRowClick,
  hasNextPage,
  fetchNextPage,
  onColumnChange,
  selected,
  isViewingDetail = false,
  statusColor = {},
  action,
  showNavLink = false,
}: Props) => {
  const [settingModal, setSettingModal] = useState(false)
  const { enabledFeatures } = useFeature()

  return (
    <Fragment>
      <List
        title={title}
        buttonText={buttonText}
        action={action}
        settingIcon={true}
        settingAction={() => setSettingModal(true)}
        showNavLink={showNavLink}
      >
        <div className="tbl-header">
          <table cellPadding="0" cellSpacing="0" border={0} className="table-fixed">
            <THead>
              <tr>
                {columns.map(
                  (column) =>
                    column.isEnabled &&
                    enabledFeatures?.[column.featureName as keyof EnabledFeature] && <Th key={column.id}>{column.name}</Th>
                )}
              </tr>
            </THead>
          </table>
        </div>
        <div className="overflow-y-auto table-scroll-max-height tbl-content">
          <table cellPadding="0" cellSpacing="0" border={0} className="table-fixed">
            <TBody>
              {data?.map((item: any, idx: number) => (
                <tr
                  onClick={() => onRowClick(item)}
                  className={`cursor-pointer hover:bg-gray-100 ${
                    isViewingDetail && selected === item[dataKey] ? "bg-gray-200" : ""
                  } ${idx % 2 === 0 ? undefined : "bg-gray-50"}`}
                  key={item[dataKey]}
                >
                  {columns.map(
                    (column) =>
                      column.isEnabled &&
                      enabledFeatures?.[column.featureName as keyof EnabledFeature] && (
                        <Fragment key={column.id}>
                          {column.innerKey ? (
                            item[column.key] ? (
                              <Fragment>
                                {column.columnTypeId === ColumnType.Text.Id && (
                                  <Td key={column.id}>{item[column.key][column.innerKey]}</Td>
                                )}
                                {column.columnTypeId === ColumnType.Email.Id && (
                                  <Td key={column.id}>{item[column.key][column.innerKey]}</Td>
                                )}
                                {column.columnTypeId === ColumnType.DateTime.Id && (
                                  <Td key={column.id}>{toLocaleDateTime(item[column.key][column.innerKey])}</Td>
                                )}
                                {column.columnTypeId === ColumnType.ListOfImages.Id && (
                                  <Td key={column.id}>
                                    <div className="flex overflow-hidden truncate -space-x-1">
                                      {item[column.key].map((subItem: any, idx: number) => (
                                        <span key={subItem.name} className="inline-flex items-center">
                                          <img
                                            alt=""
                                            src={subItem.profileImageUrl}
                                            className="h-7 w-7 rounded-full inline-block ring-2 ring-white"
                                          />
                                        </span>
                                      ))}
                                    </div>
                                  </Td>
                                )}
                                {column.columnTypeId === ColumnType.ListOfDates.Id && (
                                  <Td key={column.id}>
                                    {item[column.key].map((subItem: any, idx: number) => (
                                      <span key={idx}>
                                        {idx < 3 && (
                                          <span>
                                            {idx > 0 && <span className="px-2">|</span>}
                                            {toLocaleShortDate(subItem.startDateTimeUtc)}
                                          </span>
                                        )}
                                        {idx === 3 && <span className="text-xs pl-2">...</span>}
                                      </span>
                                    ))}
                                  </Td>
                                )}
                              </Fragment>
                            ) : (
                              <Td key={column.id}></Td>
                            )
                          ) : (
                            <Fragment>
                              {column.columnTypeId === ColumnType.LabelId.Id && (
                                <Td key={column.id}>
                                  <div className="flex items-center">
                                    <div className="rounded-lg bg-secondary-100">
                                      <div className="text-primary-900 font-medium text-xs px-2 py-1">{item[column.key]}</div>
                                    </div>
                                  </div>
                                </Td>
                              )}
                              {column.columnTypeId === ColumnType.Text.Id && <Td key={column.id}>{item[column.key]}</Td>}
                              {column.columnTypeId === ColumnType.Email.Id && <Td key={column.id}>{item[column.key]}</Td>}
                              {column.columnTypeId === ColumnType.Date.Id && (
                                <Td key={column.id}>{toLocaleDate(item[column.key])}</Td>
                              )}
                              {column.columnTypeId === ColumnType.LabelType.Id && (
                                <Td key={column.id}>
                                  <div className="flex items-center">
                                    <div className={`rounded-full ${column.className} bg-opacity-20`}>
                                      <div className="text-xs px-2 py-1">{item[column.key]}</div>
                                    </div>
                                  </div>
                                </Td>
                              )}
                              {column.columnTypeId === ColumnType.Status.Id && (
                                <Td key={column.id}>
                                  <div className="flex items-center">
                                    <div
                                      className={`rounded-full hover:shadow-lg`}
                                      style={{
                                        backgroundColor: `${statusColor[item[column.statusKey as keyof string]]}40`,
                                      }}
                                    >
                                      <div className="text-xs px-3 py-1 text-gray-900">{item[column.key]}</div>
                                    </div>
                                  </div>
                                </Td>
                              )}
                              {column.columnTypeId === ColumnType.profileName.Id && (
                                <Td key={column.id}>
                                  <div className="flex items-center">
                                    <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                                    <div className="ml-2">{item.givenName + " " + item.familyName}</div>
                                  </div>
                                </Td>
                              )}
                              {column.columnTypeId === ColumnType.StaffName.Id && (
                                <Td key={column.id}>
                                  <div className="flex items-center">
                                    <img alt="" src={item.profileImageUrl} className="h-7 w-7 rounded-full inline-block" />
                                    <div className="ml-2">{item.staffName}</div>
                                  </div>
                                </Td>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )
                  )}
                </tr>
              ))}
            </TBody>
          </table>
        </div>
        <div className="flex flex-row justify-center px-6 py-3">
          <ButtonSecondarySmall disabled={!hasNextPage} onClick={fetchNextPage}>
            Load More
          </ButtonSecondarySmall>
        </div>
      </List>

      <ClientListSettings
        isOpen={settingModal}
        onClose={() => setSettingModal(false)}
        columns={columns}
        onChange={onColumnChange}
      />
    </Fragment>
  )
}
export default DataGrid
