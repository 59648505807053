import React from "react"
import FilterInputText from "library/observables/filterInputs"
import { DocumentListModel } from "components/documents/01_documentList/documentListModel"

interface Props {
  documentListModel: DocumentListModel
}

const DocumentListFilters = ({ documentListModel }: Props) => {
  return (
    <>
      <div className="flex row-auto grid grid-cols-5 gap-4">
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <FilterInputText name="fileName" value={documentListModel.fileName} placeholder="Filter by Full File Name" />
        </div>
        <div className="col-span-5 md:col-span-2 xl13:col-span-1">
          <FilterInputText name="tag" value={documentListModel.tag} placeholder="Filter by Tag" />
        </div>
      </div>
    </>
  )
}

export default DocumentListFilters
