import React, { useEffect, useState } from "react"
import { SelectingEventArgs, TabComponent } from "@syncfusion/ej2-react-navigations"
import { successToaster } from "library/styled/toasters"
import { MenuOption } from "library/dropdownMenu/hamburgerMenu"
import { HamburgerMenu } from "library/dropdownMenu/hamburgerMenu"
import { WarningAlert } from "library/styled/alerts"
import { handlePopupError } from "common"
import { keys } from "constants/keys"
import { useQueryClient } from "react-query"
import { useModalState } from "utils/modalStates"
import { useDownloadFileLink, useFileDetail } from "components/file/fileService"
import { useRemoveFile } from "components/shared/documents/services/documentCardService"
import { File, RemoveFileInput } from "models/file/file"
import DocumentOverview from "components/documents/02_documentDetail/overview/documentOverview"
import { entityTypes } from "constants/entityTypes"
import PreviewFileModal from "components/file/previewFile/previewFileModal"
import { DownloadLinkInput } from "components/file/fileModelGql"
import { isFilePreviewAvailable } from "utils/extensions"
import EditFileModal from "components/file/editFile/editFileModal"

interface Props {
  fileReference?: string
  onFileDeleted: () => void
}

const DocumentDetailTabs = ({ fileReference, onFileDeleted }: Props) => {
  const [enabled, setEnabled] = useState(false)
  const { data: file } = useFileDetail(enabled, fileReference)
  const { alert, setAlert, viewModal, setViewModal, editModal, setEditModal } = useModalState<File>()
  const [fileUrl, setFileUrl] = useState("")
  const { mutate: downloadFileLink } = useDownloadFileLink()
  const { mutate: removeFile, isLoading: isDeleting } = useRemoveFile()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (fileReference) {
      setEnabled(true)
    }
  }, [fileReference])

  const handleDelete = () => {
    const input: RemoveFileInput = {
      fileReference: file?.fileReference ?? "",
      entityReference: entityTypes.document.reference,
      entityTypeReference: entityTypes.document.entityTypeReference
    }
    removeFile(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: () => {
        void queryClient.invalidateQueries([keys.fileQuery])
        successToaster(`File ${file?.fileName} deleted`)
        onFileDeleted()
      },
      onSettled: () => setAlert({ ...alert, isOpen: false })
    })
  }

  const getDownloadLink = (preview: boolean) => {
    const input: DownloadLinkInput = {
      fileReference: file?.fileReference
    }

    downloadFileLink(input, {
      onError: (error: any) => handlePopupError(error.response),
      onSuccess: (response) => {
        if (response) {
          if (preview) {
            setFileUrl(response.data.data.downloadLink.url)
            setViewModal({ ...viewModal, isOpen: true })
          } else downloadFile(response.data.data.downloadLink.url)
        }
      }
    })
  }

  const downloadFile = (url: string) => {
    const link = document.createElement("a")
    link.href = url
    link.download = file?.fileName ?? ""
    link.click()
  }

  const menuOptions: MenuOption[] = [
    {
      name: "View",
      icon: "eye",
      action: () => getDownloadLink(true),
      disabled: !isFilePreviewAvailable(file?.extension ?? ""),
      tooltip: "Preview is not available for this file type. You can download it instead."
    },
    {
      name: "Download",
      icon: "download",
      action: () => getDownloadLink(false)
    },
    {
      name: "Delete",
      iconClass: "far fa-trash text-red-400",
      action: () => setAlert({ item: file, isOpen: true })
    }
  ]

  return (
    <>
      <TabComponent id="defaultTab" selectedItem={0}
                    animation={{ next: { effect: "None" }, previous: { effect: "None" } }}>
        <div className="e-tab-header">
          <div className={"pl-3"}> Overview</div>
        </div>
        <div className="e-content">
          <div>
            <DocumentOverview file={file} />
          </div>
        </div>
      </TabComponent>

      <div className="absolute top-1 right-20">
        <button
          className="px-2 py-0.5 rounded-md text-sky-700 hover:bg-sky-100"
          onClick={() => setEditModal({ ...editModal, isOpen: true })}
        >
          <i className="far fa-pen-to-square h-4"></i>
        </button>
      </div>

      <div className="absolute top-2 right-6">
        <HamburgerMenu options={menuOptions} />
      </div>

      <WarningAlert
        title="File"
        value={alert.item}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
        onDelete={handleDelete}
        isLoading={isDeleting}
      />

      <PreviewFileModal
        isOpen={viewModal.isOpen}
        onClose={() => setViewModal({ ...viewModal, isOpen: false })}
        file={file}
        fileUrl={fileUrl}
      />

      <EditFileModal
        isOpen={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
        fileReference={fileReference}
      />
    </>
  )
}

export default DocumentDetailTabs
