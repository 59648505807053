import React from "react"
import { DatePickerComponent, PopupObjectArgs } from "@syncfusion/ej2-react-calendars"
import { ErrorInline, FormGroup, Label } from "../styled/styled"
import { observer } from "mobx-react"
import { makeObservable, observable, runInAction } from "mobx"
import { toUtcDateTime } from "../../common"
import addDays from "date-fns/addDays"
import { requiredDateValidation, requiredValidation } from "utils/validation"

export class DatePickerObservable {
  date?: Date
  errorMessage?: string

  constructor(date?: Date) {
    makeObservable(this, {
      date: observable,
      errorMessage: observable
    })
    this.date = date
  }

  getUtcDateTime(): string | undefined {
    if (this.date) return toUtcDateTime(this.date)
    return undefined
  }

  setDate(dateTimeStr?: string) {
    if (dateTimeStr) {
      this.date = new Date(dateTimeStr)
    }
  }
}

interface Props {
  label: string
  name: string
  placeholder?: string
  value: DatePickerObservable
  showClearButton?: boolean
  showTodayButton?: boolean
  showDueInDays?: boolean
  isRequired?: boolean
  cols?: number
  customLabel?: React.ReactNode
}

const DatePicker = (props: Props) => {
  function validate(runIfHasError: boolean = false) {
    // runIfHasError validations will only re-run if error message is set.
    // This is mostly used to re-validate the input using onChange method.
    if (runIfHasError && !props.value.errorMessage) {
      return
    }

    runInAction(() => {
      props.value.errorMessage = requiredDateValidation(props.value.date, props.label, props.isRequired)
    })
  }

  return (
    <>
      <FormGroup cols={props.cols ?? 3}>
        <Label htmlFor={props.name}>
          {props.label}{" "}
          {props.customLabel && props.customLabel}
        </Label>
        <DatePickerComponent
          format="d MMM yyyy"
          value={props.value?.date}
          allowEdit={false}
          openOnFocus={true}
          id={props.name}
          name={props.name}
          placeholder={props.placeholder}
          showTodayButton={props.showTodayButton ?? true}
          showClearButton={props.showClearButton ?? true}
          open={(arg: PopupObjectArgs, rest) => {
            if (props.showDueInDays) {
              const container = document.createElement("div")
              container.className = "pt-2 flex justify-center"

              const in7DayLink = document.createElement("a")
              in7DayLink.appendChild(document.createTextNode("7 Days"))
              in7DayLink.className = "font-medium text-blue-600 dark:text-blue-500 hover:underline"

              const in14DaysLink = document.createElement("a")
              in14DaysLink.appendChild(document.createTextNode("14 Days"))
              in14DaysLink.className = "pl-2 font-medium text-blue-600 dark:text-blue-500 hover:underline"

              const in30DaysLink = document.createElement("a")
              in30DaysLink.appendChild(document.createTextNode("30 Days"))
              in30DaysLink.className = "pl-2 font-medium text-blue-600 dark:text-blue-500 hover:underline"

              container.appendChild(in7DayLink)
              container.appendChild(in14DaysLink)
              container.appendChild(in30DaysLink)

              in7DayLink.addEventListener("click", () => {
                runInAction(() => {
                  props.value!.date = addDays(new Date(), 7)
                })
              })

              in14DaysLink.addEventListener("click", () => {
                runInAction(() => {
                  props.value!.date = addDays(new Date(), 14)
                })
              })

              in30DaysLink.addEventListener("click", () => {
                runInAction(() => {
                  props.value!.date = addDays(new Date(), 30)
                })
              })

              const calendarElement = arg.popup?.element.getElementsByClassName("e-calendar")
              const headerElement = arg.popup?.element.getElementsByClassName("e-header e-month")
              if (calendarElement && headerElement) {
                calendarElement[0].insertBefore(container, headerElement[0])
              }
            }
          }}
          change={(arg, rest) => {
            runInAction(() => {
              props.value.date = arg.value
              validate(true)
            })
          }}
          onBlur={(event) => {
            runInAction(() => {
              validate()
            })
          }}
          cssClass="e-outline mt-1 py-0.5 mt-1 shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-primary-100 focus:border-primary-100"
        />
        {props.value.errorMessage && <ErrorInline>{props.value.errorMessage}</ErrorInline>}
      </FormGroup>
    </>
  )
}
export default observer(DatePicker)
