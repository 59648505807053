import { useQuery, useMutation } from "react-query"
import { common } from "common"
import { keys } from "constants/keys"
import { graphQLClient } from "shared/graphql/graphQLClient"
import { mutation } from "shared/graphql/mutations"
import { query } from "shared/graphql/queries"
import { Response } from "models/shared/response"
import { OrganisationDetails, UpdateOrganisationInput } from "models/organisation/organisationDetail"
import { FeatureSettings } from "models/organisation/featureSetting"
import { AddReviewLinkInput, ReviewLinks } from "models/organisation/reviewLink"

function useOrganisationDetail() {
  return useQuery(keys.organisationDetailQuery, fetchOrganisationDetails, {
    select: (response: Response<OrganisationDetails>) => response.data.data.organisationDetail,
  })
}

function fetchOrganisationDetails() {
  return graphQLClient.fetch(query.organisationDetailQuery, null, true, common.organisationApiUrl, keys.organisationDetailQuery)
}

function updateOrganisation(input: UpdateOrganisationInput) {
  return graphQLClient.mutation(mutation.updateOrganisation, input, "UpdateOrganisationDetail", common.organisationApiUrl)
}

function useUpdateOrganisationDetail() {
  return useMutation(updateOrganisation)
}

function useFeatureSetting() {
  return useQuery(keys.featureSettingQuery, fetchFeatureSettings, {
    select: (response: Response<FeatureSettings>) => response.data.data.featureSettings,
  })
}

function fetchFeatureSettings() {
  return graphQLClient.fetch(query.featureSettingQuery, null, true, common.organisationApiUrl, keys.featureSettingQuery)
}

function toggleFeatureSetting(input: any) {
  return graphQLClient.mutation(mutation.toggleFeatureSetting, input, "ToggleFeatureSetting", common.organisationApiUrl)
}

function useToggleFeatureSetting() {
  return useMutation(toggleFeatureSetting)
}

function useReviewLink() {
  return useQuery(keys.reviewLinkQuery, fetchReviewLinks, {
    select: (response: Response<ReviewLinks>) => response.data.data.reviewLinks[0],
  })
}

function fetchReviewLinks() {
  return graphQLClient.fetch(query.reviewLinkQuery, null, true, common.organisationApiUrl, keys.reviewLinkQuery)
}

function addReviewLink(input: AddReviewLinkInput) {
  return graphQLClient.mutation(mutation.addReviewLink, input, "AddReviewLink", common.organisationApiUrl)
}

function useAddReviewLink() {
  return useMutation(addReviewLink)
}

function updateFeatureSettingAttributeValue(input: any) {
  return graphQLClient.mutation(
    mutation.updateFeatureSettingAttributeValue,
    input,
    "UpdateFeatureSettingAttributeValue",
    common.organisationApiUrl
  )
}

function useUpdateFeatureSettingAttributeValue() {
  return useMutation(updateFeatureSettingAttributeValue)
}

function useFeatureSettingAttributeValue(input: { featureSettingAttributeValueReference: string }) {
  return useQuery([keys.featureSettingAttributeValueQuery, input], () => fetchFeatureSettingAttributeValue(input), {
    select: (response) => response.data.data.featureSettingAttributeValue.value,
  })
}

function fetchFeatureSettingAttributeValue(input: any) {
  return graphQLClient.fetch(
    query.featureSettingAttributeValueQuery,
    input,
    true,
    common.organisationApiUrl,
    keys.featureSettingAttributeValueQuery
  )
}

export {
  useOrganisationDetail,
  useUpdateOrganisationDetail,
  useFeatureSetting,
  useToggleFeatureSetting,
  useReviewLink,
  useAddReviewLink,
  useFeatureSettingAttributeValue,
  useUpdateFeatureSettingAttributeValue,
}
